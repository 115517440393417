import {expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import Module from '_entity/Course/Module/Module';

export default class ModuleApi extends SchoolApi {
	get namespace(){
		return 'modules';
	}

	get entity_class(){
		return Module;
	}

	read(id){
		expectsString(id);
		return this.json.get(id);
	}

	remove(id){
		expectsString(id);
		return this.json.delete(id);
	}

	edit(id, data){
		return this.json.put(id, data);
	}

	list(){
		return this.json.get();
	}

	create(courseID, data){
		expectsString(courseID);
		return this.json.post(`by_course/${courseID}`, data);
	}

	readByOrder(order){
		return this.json.get(`by_order/${order}`);
	}
}
