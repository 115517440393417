import {ifNotEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Item from '_entity/Course/Module/Item/Item';
import Tooltiper from 'hoc/ui/Tooltiper';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

export default class SchoolLevelHeader extends React.Component {
	static propTypes = {
		item: PropTypes.instanceOf(Item).isRequired,
		className: PropTypes.string,
		onSelect: PropTypes.func,
	};

	render(){
		const {className, onSelect} = this.props;
		const item: Item = this.props.item;
		const items: Item[] = item.module.items;

		return (
			<Nav
				id="level-header"
				className={className}
				onSelect={(id) => ifNotEmpty(id, () => onSelect(items.find(i => i.id === id)), true)}
				activeKey={item.id}
			>
				<Tooltiper tooltip="Voltar para módulos" placement="bottom">
					<LinkContainer to="/">
						<Button
							as={Nav.Link} variant="light"
						>
							<i className="fa fa-chevron-left text-muted" />
						</Button>
					</LinkContainer>
				</Tooltiper>
				{
					items.map((i: Item) => (
						<Tooltiper
							placement="bottom"
							key={`level-${i.id}`}
							tooltip={
								<div>
									Item #{i.order}
									<div className="text-muted">{i.name}</div>
								</div>
							}
						>
							<Button
								as={Nav.Link}
								variant="secondary"
								eventKey={i.id}
								className={i.id === item.id ? 'active' : ''}
							>
								<span className="text-light">#{i.order}</span>
							</Button>
						</Tooltiper>
					))
				}
			</Nav>
		);
	}
}
