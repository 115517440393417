import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import CourseUser from '_entity/Acess/CourseUser';
import ChangeCostForm from '_form/Access/ChangeCostForm';
import ChangeEmailForm from '_form/Access/ChangeEmailForm';
import ChangePasswordForm from '_form/Access/ChangePasswordForm';
import OpenCloseCourseForm from '_form/Access/OpenCloseCourseForm';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import CoursePage from 'app/pages/CourseView/CoursePage';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import React from 'react';
import {Accordion, AccordionCollapse, AccordionToggle, Alert, Card} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

function SettingsPage(props){
	const dispatch = useDispatch();
	const changePassword = (data) => CourseReducerUtils.changePassword.dispatch(dispatch, data);
	const changePasswordState = useSelector(CourseReducerUtils.changePassword.map);
	const user:CourseUser = useAppitUserAwareness()
	const changeCost = (data) => CourseReducerUtils.changeCost.dispatch(dispatch, data);
	const changeCostState = useSelector(CourseReducerUtils.changeCost.map);
	const changeEmails = (data) => CourseReducerUtils.changeEmails.dispatch(dispatch, data);
	const changeEmailsState = useSelector(CourseReducerUtils.changeEmails.map);
	const openClose = (data) => CourseReducerUtils.openClose.dispatch(dispatch, data);
	const openCloseState = useSelector(CourseReducerUtils.openClose.map);

	return (
		<CoursePage breadcrumb={[{path: CourseRoutes.SETTINGS, text: 'Configurações'}]}>
			<Alert className="text-center" variant={user.course.is_open ? "success" : 'danger'}>
				Matriculas
				<div className="font-weight-bold">
					{user.course.is_open ? "Abertas" : 'Fechadas'}
				</div>
			</Alert>
			<Accordion>
				<AccordionToggle as={Card.Header} eventKey="price">
					Preço
				</AccordionToggle>
				<AccordionCollapse eventKey="price">
					<Card.Body>
						<ChangeCostForm
							course={user.course}
							onSubmit={changeCost}
							success={changeCostState.finished}
							error={changeCostState.error}
						/>
					</Card.Body>
				</AccordionCollapse>
				<AccordionToggle as={Card.Header} eventKey="mail">
					Email
				</AccordionToggle>
				<AccordionCollapse eventKey="mail">
					<Card.Body>
						<ChangeEmailForm
							course={user.course}
							onSubmit={changeEmails}
							success={changeEmailsState.finished}
							error={changeEmailsState.error}
						/>
					</Card.Body>
				</AccordionCollapse>
				<AccordionToggle as={Card.Header} eventKey="password">
					Senha
				</AccordionToggle>
				<AccordionCollapse eventKey="password">
					<Card.Body>
						<ChangePasswordForm
							onSubmit={changePassword}
							success={changePasswordState.finished}
							error={changePasswordState.error}
						/>
					</Card.Body>
				</AccordionCollapse>
				<AccordionToggle as={Card.Header} eventKey="open">
					{user.course.is_open ? "Fechar" : "Abrir"} Matriculas
				</AccordionToggle>
				<AccordionCollapse eventKey="open">
					<Card.Body>
						<OpenCloseCourseForm
							onSubmit={openClose}
							success={openCloseState.finished}
							error={openCloseState.error}
						/>
					</Card.Body>
				</AccordionCollapse>
			</Accordion>
		</CoursePage>
	);
}

export default SettingsPage;
