import SchoolApi from '_api/SchoolApi';
import CourseIntegration from '_entity/Course/CourseIntegration';

export default class CourseIntegrationApi extends SchoolApi {
	get namespace(){
	    return 'course_integrations';
	}

	get entity_class(){
	    return CourseIntegration;
	}

	create(courseID,data){
		return this.json.post(`by_course/${courseID}`,data)
	}

	remove(id){
		return this.json.delete(id)
	}
}