import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import {LearningObjectReducerUtils} from '_reducer/Course/Module/LearningObject/LearningObjectReducer';
import SchoolLearningObject from 'app/components/new/Content/Item/SchoolLearningObject';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import RewardSection from 'app/pages/AdminView/Content/Sections/RewardSection';
import {ModuleShowingBreadcrumb} from 'app/pages/AdminView/Module/ModuleShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import Buttons from 'hoc/ui/Buttons';
import React, {useCallback, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams} from 'react-router-dom';

export const LearningObjectShowingBreadcrumb = (learningObject: LearningObject) => ([
	...ModuleShowingBreadcrumb(learningObject.module),
	{path: AdminRoutes.LEARNING_OBJECT_SHOWING.replace(':id', learningObject.id), text: learningObject.name},
]);

function LearningObjectShowingPage(props){
	const readState = useSelector(LearningObjectReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: learningObjectID} = useParams();
	const learningObject: LearningObject = readState.data;
	const read = () => LearningObjectReducerUtils.read.dispatch(dispatch);
	const simView = useCallback((data) => LearningObjectReducerUtils.sim_view.dispatch(dispatch, learningObjectID, data), [
		dispatch,
		learningObjectID,
	]);
	const clearFeedback = useCallback(() => LearningObjectReducerUtils.sim_view.dispatch_clear(dispatch), [dispatch]);
	const simViewState = useSelector(LearningObjectReducerUtils.sim_view.map);

	useEffect(() => {
			LearningObjectReducerUtils.read.dispatch(dispatch, learningObjectID);

			return () => {
				LearningObjectReducerUtils.read.dispatch_clear(dispatch);
			};
		},
		[learningObjectID, dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			fluid
			actions={(
				<LinkContainer to={AdminRoutes.LEARNING_OBJECT_EDITING.replace(':id', learningObjectID)}>
					{Buttons.EDIT}
				</LinkContainer>
			)}
			breadcrumb={LearningObjectShowingBreadcrumb(learningObject)}
		>
			<div className="like-body" data-color={learningObject.module.course.username}>
				<SchoolLearningObject
					className="vh-100"
					learningObject={learningObject}
					feedback={simViewState.data}
					isFeedbackShowing={simViewState.begined}
					isFeedbackLoading={!simViewState.finished}
					onSave={simView}
					onClear={clearFeedback}
				/>
				<Card className="rounded-0 border-0">
					<Card.Body>
						<RewardSection element={learningObject} afterDelete={read} />
					</Card.Body>
				</Card>
			</div>
		</AdminPage>
	);
}

export default LearningObjectShowingPage;