import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {toFixedLocale} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import {InformativeReducerUtils} from '_reducer/Informative/InformativeReducer';
import AvatarElement from 'app/components/avatar/AvatarElement';
import {useStudentViewConfig} from 'app/pages/StudentView/StudentViewConfig';
import SchoolLoader from 'app/SchoolLoader';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

function SchoolSidebarRightWidgetRanking(){
	const dispatch = useDispatch()
	const rankingState = useSelector(InformativeReducerUtils.ranking.map);
	const user:Student = useAppitUserAwareness();
	const {ranking:top} = useStudentViewConfig();

	useEffect(() => {
		InformativeReducerUtils.ranking.dispatch(dispatch, top)
		return () => {
			InformativeReducerUtils.ranking.dispatch_clear(dispatch)
		}
	},[dispatch,top])

	if(!rankingState.finished){
		return <SchoolLoader basic />;
	}

	const {ranking} = rankingState.data;
	const first: Student = ranking[0];
	const inTop = !!ranking.find(s => user.id === s.id)
	if(!inTop){
		ranking.pop();
	}

	// return null;

	return (
		<div className="ranking">
			{ranking.map((student,index) => <RankingItem key={`top-${index}`} student={student} first={first}/>)}
			{!inTop && (
				<RankingItem student={user} first={first}/>
			)}
		</div>
	);
}


export default SchoolSidebarRightWidgetRanking;

function RankingItem({first,student}){
	const user:Student = useAppitUserAwareness();
	let percentValue = 0;
	if(first){
		percentValue = first.info.sum_points === 0 ? 0 : student.info.sum_points / first.info.sum_points * 100
	}
	if(percentValue < 5 || Number.isNaN(percentValue)){
		percentValue = 5;
	}
	const isMe = student.id === user.id;

	return (
		<Tooltiper
			key={`ranking-${student.id}`}
			placement="bottom"
			tooltip={<div>
				{isMe ?
					<strong>VOCÊ</strong> : student.name}
			</div>}
		>
			<div className={`ranking-item ${isMe ? 'ranking-item-me' : ''}`}>
				<div className="ranking-avatar">
					<AvatarElement src={student.avatar} />
				</div>
				<div className="ranking-progress">
					<svg
						width="20"
						height="100"
						viewBox="0 0 20 100"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
					>
						<rect width="20" height="100" fill={`url(#percent_${percentValue})`} />
						<defs>
							<linearGradient
								id={`percent_${percentValue}`}
								x1="0"
								y1="0"
								x2={percentValue}
								y2="0"
								gradientUnits="userSpaceOnUse"
							>
								<stop offset="0.16406" stopColor="#0F2144" />
								<stop offset="0.291043" stopColor="#EED793" />
							</linearGradient>
						</defs>
					</svg>
					<div className="position-absolute text-light">
						{toFixedLocale(student.info.sum_points)} {student.course.points_name}
					</div>
				</div>
			</div>
		</Tooltiper>
	);
}
