import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Alternative from '_entity/Course/Module/Item/Content/Question/Alternative';
import Question from '_entity/Course/Module/Item/Content/Question/Question';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import {FieldArray} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Form, InputGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

class FormQuestion extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		question: PropTypes.instanceOf(Question),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};
	state = {
		...super.state,
		alternatives: [],
		touched: false,
	};

	isNew = () => !this.props.question.is_created;

	handleData = (data) => {
		return data;
	};

	validate = (data) => {
		const errors = {};
		if(!data.description){
			errors.description = true;
		}
		if(data.alternatives.length === 0){
			errors.alternatives = true;
		}
		else{
			for(const alternative of data.alternatives){
				if(!alternative.description){
					errors.alternatives = true;
				}
			}
		}

		return errors;
	};

	form(){
		const question: Question = this.props.question;
		const alternatives = this.state.touched ? this.state.alternatives : question.alternatives;
		if(alternatives.length === 0){
			alternatives.push({});
		}

		return (
			<SchoolForm
				validate={this.validate}
				onSubmit={this.onSubmit}
				initialValues={{
					is_poker: question.type ? question.type === 'poker' : false,
					description: ifEmpty(question.description, ''),
					alternatives: ifEmpty(alternatives, [{}]).map((a: Alternative) => ({
						id: ifEmpty(a.id, ''),
						description: ifEmpty(a.description, ''),
						feedback: ifEmpty(a.feedback, ''),
						feedback_odds: ifEmpty(a.is_poker && a.feedback_odds, ''),
						odds: ifEmpty(a.is_poker && a.odds, ''),
						points_value: ifEmpty(a.points_value, ''),
						is_correct: ifEmpty(a.is_correct,false)
					})),
					points_progression: ifEmpty(question.points_progression, [
						{
							attempt: 1,
							percentage: 100,
						},
					]),
				}}
				submitText="Salvar"
				submitProps={{className: 'mt-3'}}
				asFunc
			>
				{
					({values, setFieldValue}) => (
						<>
							<SchoolFormSimpleField
								withoutGroup
								withoutLabel
								component={Form.Check}
								name="type"
								componentProps={{
									type: 'switch',
									checked: values.is_poker,
									className: 'border-0 shadow-none',
									onChange: (e) => setFieldValue(`is_poker`, e.target.checked),
									label: values.is_poker ? 'Pergunta com odds' : 'Pergunta simples',
								}}
							/>
							<SchoolFormSimpleField name="description" label="Pergunta" component="textarea" />
							<FieldArray
								name="alternatives"
								render={arrayHelpers => (<>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-block"
										onClick={() => arrayHelpers.push({
											description: '',
											feedback: '',
											feedback_odds: '',
											odds: '',
											points_value: '',
											is_correct: false,
										})}
										tabIndex="-1"
									>
										<i className="fa fa-plus fa-fw" /> Alternativa
									</Button>
									{values.alternatives.map((alternative, index) => (
										<Form.Row
											className={`pb-2 pt-4 ml-2 ${index > 0 ? 'border-top' : ''}`}
											key={`alternativa_${index}`}
										>
											<Col>
												<SchoolFormSimpleField
													name={`alternatives.${index}.description`}
													withoutLabel
													placeholder="Descrição..."
													component="textarea"
													componentProps={{
														style: {
															maxHeight: '12em',
															minHeight: '4em',
															height: '4em',
														},
													}}
												/>
												<Form.Row>
													<Col>
														<SchoolFormSimpleField
															name={`alternatives.${index}.feedback`}
															label="Feedback Geral"
															placeholder="Feedback geral..."
															component="textarea"
															componentProps={{
																style: {
																	minHeight: '7.2em',
																	height: '4em',
																},
															}}
														/>
													</Col>
													{values.is_poker && <Col>
														<SchoolFormSimpleField
															name={`alternatives.${index}.feedback_odds`}
															label="Feedback da sorte"
															labelClassName
															placeholder="Feedback da sorte..."
															component="textarea"
															componentProps={{
																style: {
																	minHeight: '7.2em',
																	height: '4em',
																},
															}}
														/>
													</Col>}
													<Col>
														<SchoolFormSimpleField
															name={`alternatives.${index}.points_value`}
															label="Pontos"
															placeholder="Pontos..."
															type="number"
														/>
														{values.is_poker
															? <SchoolFormSimpleField
																name={`alternatives.${index}.odds`}
																label="Odds"
																placeholder="Odds..."
																type="number"
															/>
															: <SchoolFormSimpleField
																label="Resposta correta"
																component={Form.Check}
																name={`alternatives.${index}.is_correct`}
																componentProps={{
																	type: 'switch',
																	checked: values.alternatives[index].is_correct,
																	className: 'border-0 shadow-none',
																	onChange: (e) => setFieldValue(`alternatives.${index}.is_correct`, e.target.checked),
																	label: values.alternatives[index].is_correct ? 'Sim' : 'Não',
																}}
															/>
														}
													</Col>
												</Form.Row>
											</Col>
											<Col sm="auto">
												<Button
													variant="outline-danger"
													size="sm"
													tabIndex="-1"
													onClick={() => arrayHelpers.remove(index)}
												>
													<i className="fa fa-times fa-fw" />
												</Button>
											</Col>
										</Form.Row>
									))}
								</>)}
							/>
							<FieldArray
								name="points_progression"
								render={arrayHelpers => (<>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-block"
										onClick={() => arrayHelpers.push({
											attempt: '',
											percentage: '',
										})}
										tabIndex="-1"
									>
										<i className="fa fa-plus fa-fw" /> Progressão dos pontos
									</Button>
									{values.points_progression.map((point_progression, index) => (
										<Form.Row
											className={`pb-2 pt-4 ml-2 ${index > 0 ? 'border-top' : ''}`}
											key={`progression_${index}`}
										>
											<Col sm="auto">
												<InputGroup>
													<InputGroup.Prepend>
														<InputGroup.Text>
															Na tentativa
														</InputGroup.Text>
													</InputGroup.Prepend>
													<SchoolFormSimpleField
														name={`points_progression.${index}.attempt`}
														componentProps={{
															className: 'text-right',
														}}
														withoutLabel
														withoutGroup
														placeholder="Tenativa..."
													/>
													<InputGroup.Append>
														<InputGroup.Text>
															<u><sup>a</sup></u>
														</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
											</Col>
											<Col>
												<InputGroup>
													<InputGroup.Prepend>
														<InputGroup.Text>
															resulta em
														</InputGroup.Text>
													</InputGroup.Prepend>
													<SchoolFormSimpleField
														name={`points_progression.${index}.percentage`}
														componentProps={{
															className: 'text-right',
														}}
														withoutLabel
														withoutGroup
														placeholder="Porcentagem dos pontos..."
													/>
													<InputGroup.Append>
														<InputGroup.Text>
															% dos pontos
														</InputGroup.Text>
													</InputGroup.Append>
												</InputGroup>
											</Col>
										</Form.Row>
									))}
								</>)}
							/>
							<ul className="small text-muted mt-4">
								<li>
									<strong>Feedback geral</strong> é o feedback padrão é usando em:
									<ul>
										<li>
											Para respostas erradas.
										</li>
										<li>
											Para respostas certas que não possuam alternativas com feedback da melhor
											resposta.
										</li>
									</ul>
								</li>
								<li>
									<strong>Feedback da sorte</strong> substitui o feedback geral somente
									quando a resposta for sorteada como a resposta certa
								</li>
								<li>
									<strong>Odds</strong> são usados para fazer o calculo da % de acerto, através da
									soma das odds de todas alternativas.
									<ul>
										<li>
											Ex: Alternativa A possui 10 odds e Alternativa B possui 10 odds, a chance de
											A ser a correta é de 10/20
										</li>
										<li>
											Ex: Alternativa A possui 1 odds e Alternativa B possui 0 odds, a chance de B
											ser a correta é de 0/1
										</li>
										<li>
											Ex: Alternativa A possui 10 odds e Alternativa B possui 5 odds, a chance de
											A ser a correta é de 10/15 e de B é 5/15
										</li>
									</ul>
								</li>
								<li>
									<strong>Pontos</strong> Quando a resposta for considerada certa o usuário ganha
									esses pontos, o usuário pode responder ate atingir a quantidade maxima de pontos
									<ul>
										<li>
											Ex: Alternativa A possui 10 pontos e alternativa B possui 5 pontos, o
											usuário pode responder até ele acertar marcando o a alternativa A. Caso o
											usuário marque a alternativa B e seja a correta, ele pode responder
											novamente.
										</li>
										<li>
											Os pontos não acumulam, somente a maior quantidade de pontos de uma pergunta
											é contabilizado
										</li>
									</ul>
								</li>
							</ul>
						</>
					)
				}
			</SchoolForm>
		);
	}
}

export default FormQuestion;
