import PropTypes from 'prop-types';
import React, {createContext, useContext} from 'react';
import {Breadcrumb, Nav} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {LinkContainer} from 'react-router-bootstrap';

const linkShape = PropTypes.shape({
	text: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
});

NewSchoolBreadcrumbHeader.propTypes = {
	onSelect: PropTypes.func,
	links: PropTypes.oneOfType([
		PropTypes.arrayOf(linkShape),
		linkShape,
	]),
};

NewSchoolBreadcrumbHeader.getPrevious = (breadcrumb) => {
	if(breadcrumb.length === 0){
		return null;
	}

	if(breadcrumb.length > 1){
		return breadcrumb[breadcrumb.length - 2];
	}

	return breadcrumb[breadcrumb.length - 1];
};


const NewSchoolBreadcrumbHeaderContext = createContext({
	breadcrumb: [],
	previous: null,
	pushPrevious: () => null,
});

/**
 * @returns {{breadcrumb: [], previous: null, pushPrevious: (function(): null)}}
 */
export function useNewSchoolBreadcrumbHeader(){
	return useContext(NewSchoolBreadcrumbHeaderContext);
}

export function withNewSchoolBreadcrumbHeader(Component){
	class withNewSchoolBreadcrumbHeader extends React.Component {
		static displayName = `WithNewSchoolBreadcrumbHeader(${Component.displayName || Component.name})`;

		static contextType = NewSchoolBreadcrumbHeaderContext;

		render(){
			return <Component {...this.props} breadcrumbHeader={this.context} />;
		}
	}

	return withNewSchoolBreadcrumbHeader;
}

export const NewSchoolBreadcrumbHeaderConsumer = NewSchoolBreadcrumbHeaderContext.Consumer;

function NewSchoolBreadcrumbHeader({links, onSelect, children}){
	links = Array.isArray(links) ? links : [links];
	const previous = NewSchoolBreadcrumbHeader.getPrevious(links);
	const history = useHistory();
	const pushPrevious = () => {
		history.push(previous.path)
	};
	return (
		<NewSchoolBreadcrumbHeaderContext.Provider value={{breadcrumb: links, previous, pushPrevious}}>
			<Nav id="breadcrumb-header" as={Breadcrumb} listProps={{className: 'container'}} onSelect={onSelect}>
				{links.map(({path, text}, index) => (
					<LinkContainer to={path} key={`${path}-${index}`}>
						<Breadcrumb.Item
							linkProps={{className: 'text-light'}}
							active={links.length - 1 === index}
						>
							{text}
						</Breadcrumb.Item>
					</LinkContainer>
				))}
			</Nav>
			{children}
		</NewSchoolBreadcrumbHeaderContext.Provider>
	);
}

export default NewSchoolBreadcrumbHeader;