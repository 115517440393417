import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import {getOrThrow, ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Content from '_entity/Course/Module/Item/Content/Content';
import Alternative from '_entity/Course/Module/Item/Content/Question/Alternative';
import AnsweredQuestion from '_entity/Progress/AnsweredQuestion';
import SchoolEntity from '_entity/SchoolEntity';

export default class Question extends SchoolEntity {

	get description(): string{
		return this.getDataValue('description');
	}

	get type(): string{
		return this.getDataValue('type_name');
	}

	get is_poker(){
		return this.type === 'poker';
	}

	get is_answered(): boolean{
		return this.getDataValue('is_answered');
	}

	get points_progression(){
		return ifEmpty(this.getDataValue('points_progression'),[]);
	}

	get locked(): boolean{
		return this.getDataValue('locked');
	}

	get content(): Content{
		return entityResolver(this.getDataValue('content'), Content);
	}

	get last_answer(): AnsweredQuestion{
		return entityResolver(this.getDataValue('last_answer'), AnsweredQuestion);
	}

	get alternatives(): Alternative[]{
		return entityResolver(this.getDataValue('alternatives'), Alternative, {question: this});
	}

	get answers(): AnsweredQuestion{
		return entityResolver(this.getDataValue('answers'), AnsweredQuestion, {question: this});
	}

	get info(){
		const data = this.getData();
		return {
			get count_my_answers(){
				return getOrThrow(data, 'info_count_my_answers');
			},
		};
	}
}
