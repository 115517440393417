// import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import InformativeApi from '_api/Informative/InformativeApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const InformativeReducerOptions = new SchoolReducerOptions('informative', InformativeApi);

/**
 *
 * @type {{
 *     ranking: ReducerBasicOption
 *     points: ReducerBasicOption
 *     generatePayPagseguro: ReducerBasicOption
 *     initPayRoyalpag: ReducerBasicOption
 *     generatePayRoyalpag: ReducerBasicOption
 *     redirectRoyalpag: ReducerBasicOption
 *     myCourseInfo: ReducerBasicOption
 * }}
 */
export const InformativeReducerUtils = InformativeReducerOptions.options();
