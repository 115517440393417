import Content from '_entity/Course/Module/Item/Content/Content';
import PropTypes from 'prop-types';

import React from 'react';
import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';

ContentSelector.propTypes = {
	contents: PropTypes.arrayOf(PropTypes.instanceOf(Content)).isRequired,
	onSelect: PropTypes.func.isRequired,
};

/**
 *
 * @param {Content[]} contents
 * @param onSelect
 * @returns {*}
 * @constructor
 */
function ContentSelector({contents, onSelect}){
	contents = [...contents];
	const groupedContents = {};
	contents.sort((a, b) => {
		if(a.module.order > b.module.order){
			return 1;
		}
		if(a.module.order < b.module.order){
			return -1;
		}
		if(a.order > b.order){
			return 1;
		}
		if(a.order < b.order){
			return -1;
		}

		return 0;
	});
	for(const content of contents){
		const label = 'Módulo: #' + content.module.order + ' ' + content.module.name;
		if(!groupedContents[label]){
			groupedContents[label] = [];
		}
		groupedContents[label].push({
			value: content.id,
			label: `#${content.order} ${content.name}`,
			object: content,
		});
	}

	return (
		<Form.Group>
			<Form.Label>Conteúdo</Form.Label>
			<ReactSelect
				theme={theme => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: 'var(--primary25)',
						primary50: 'var(--primary50)',
						primary75: 'var(--primary75)',
						primary: 'var(--primary)',
					},
				})}
				classNamePrefix="react-select"
				onChange={({object}) => onSelect(object)}
				placeholder="Selecione um conteúdo..."
				options={Object.keys(groupedContents).map(label => ({
					label: (
						<div className="d-flex align-items-center justify-content-between">
							{label}
						</div>
					),
					options: groupedContents[label],
				}))}
			/>
		</Form.Group>
	);
}

export default ContentSelector;