// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ContentRangeApi from '_api/Course/Module/Content/ContentRangeApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ContentRangeReducerOptions = new SchoolReducerOptions('content_range', ContentRangeApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 *     listAll: ReducerBasicOption
 *     create: ReducerBasicOption
 *     delete: ReducerBasicOption
 *     edit: ReducerBasicOption
 *     read: ReducerBasicOption
 * }}
 */
export const ContentRangeReducerUtils: $ObjMap<string, ReducerBasicOption> = ContentRangeReducerOptions.options();
