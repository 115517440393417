import {CourseIntegrationReducerUtils} from '_reducer/Course/CourseIntegrationReducer';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import {ButtonGroup, ListGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

/**
 *
 * @param {Course} course
 * @param children
 * @returns {*}
 * @constructor
 */
function CourseIntegrationSection({course,children}){
	const dispatch = useDispatch();
	const remove = (id) => CourseIntegrationReducerUtils.remove.dispatch(dispatch,id);
	const removeState = useSelector(CourseIntegrationReducerUtils.remove.map)

	useEffect(() => {
		SchoolOperationAlert({
			successMessage: `Integração deletado!`,
			successAction: () => {
				CourseIntegrationReducerUtils.remove.dispatch_clear(dispatch);
			},
			finished: removeState.finished,
			error: removeState.error,
		});
	},[dispatch,removeState])

	return (
		<>
			<ListGroup horizontal>
				{course.apis.map((api) => (
					<ListGroup.Item
						className="p-0 d-flex flex-row flex-grow-1"
						style={{maxWidth: '300px'}}
						key={api.id}
					>
						<div className="p-3 text-uppercase flex-grow-1 d-flex flex-column">
							<Tooltiper tooltip={api.name}>
								<img src={api.image} className="img-fluid flex-grow-0 align-self-center mb-2"  alt={api.name}/>
							</Tooltiper>
							<div className="flex-root text-nowrap small">
								<div>
									<span className="text-muted">EMAIL:</span> {api.email}
								</div>
								<div>
									<span className="text-muted">KEY:</span> {api.key}
								</div>
							</div>
						</div>
						<ButtonGroup>
							<Button
								size="sm"
								variant="danger"
								className="rounded-0"
								onClick={() => SchoolConfirmAlert({
									title: 'Realmente deseja deletar essa integração?',
									action: () => remove(api.id),
								})}
							>
								<i className="fa fa-trash" />
							</Button>
						</ButtonGroup>
					</ListGroup.Item>
				))}
			</ListGroup>
			{children}
			<LinkContainer to={AdminRoutes.COURSE_INTEGRATION_CREATING.replace(":id",course.id)}>
				<Button variant="primary" className="mt-3">
					Adicionar Integração
				</Button>
			</LinkContainer>
		</>
	);
}

export default CourseIntegrationSection;