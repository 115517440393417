import SchoolEntity from '_entity/SchoolEntity';

export default class CourseMailing extends SchoolEntity{
	get username(){
		return this.getDataValue('username')
	}

	get host(){
		return this.getDataValue('host')
	}
	get port(){
		return this.getDataValue('port')
	}
}