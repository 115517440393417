import React from 'react';


export const DisplayInfo = ({value, text, inlineBlock, reverse = false, textTag: TextTag = 'div', valueTag: ValueTag = 'div', valueProps = {}, textProps = {}}) => {
	const valueClass = `text-uppercase h5 ${inlineBlock ? "d-inline-block" : "d-inline"}`;
	const textClass = `text-muted small  ${inlineBlock ? "d-inline-block" : "d-inline"}`;
	const FirstTag = reverse ? ValueTag : TextTag;
	const SecondTag = reverse ? TextTag : ValueTag;
	const firstProps = reverse ? {...valueProps, className: valueClass} : {...textProps, className: textClass};
	const secondProps = reverse ? {...textProps, className: textClass} : {...valueProps, className: valueClass};

	firstProps.className += ' mr-1';

	return <>
		<FirstTag {...firstProps}>{reverse ? value : text}</FirstTag>
		<SecondTag {...secondProps}>{reverse ? text : value}</SecondTag>
	</>;
};