import Student from '_entity/Acess/Student';
import BadgeType from '_entity/Game/Badge';
import ViewedItem from '_entity/Progress/ViewedItem';
import SchoolEntity from '_entity/SchoolEntity';

export default class EarnedBadge extends SchoolEntity {
	static TYPES = ['champion', 'deadline', 'luck', 'time'];

	get type(): string{
		 throw new Error();
	}

	get student(): Student{
		return this.getDataValue('student', Student);
	}

	get viewed_item(): ViewedItem{
		return this.getDataValue('viewed_item', ViewedItem);
	}

	get name(){
		throw new Error()
	}

	get description(){
		throw new Error()
	}

	get badge():BadgeType{
		return this.getDataValue('badge',BadgeType)
	}
}

export const GameBadgeUtils = {
	getName(type){
		throw new Error()
	},
	getDescription(type){
		throw new Error()
	},
};