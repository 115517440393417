import SchoolApi from '_api/SchoolApi';
import BadgeType from '_entity/Game/Badge';

export default class BadgeTypeApi extends SchoolApi {
	get namespace(){
	    return 'badges';
	}

	get entity_class(){
	    return BadgeType;
	}

	create(id,data){
		return this.multipart.post(`by_course/${id}`,data)
	}

	edit(id,data){
		return this.multipart.post(id,data)
	}

	read(id){
		return this.json.get(id)
	}

	remove(id){
		return this.json.delete(id)
	}
}