import {ifEmpty, parseIfJson} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import FormEntity from '_form/FormEntity';
import BadgeSelect from '_form/Game/BadgeSelect';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFile from 'app/components/form/SchoolFormFile';
import SchoolSelect from 'app/components/form/SchoolSelect';
import doc from 'doc.pdf';
import {FieldArray} from 'formik';
import {ifNull} from 'hoc/reducer/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Form, InputGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export default class FormLearningObject extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course).isRequired,
		learningObject: PropTypes.instanceOf(LearningObject),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		learningObject: {},
	};
	WantedFields = Object.keys(LearningObject.WantedNames);
	FeedbackFields = Object.keys(LearningObject.FeedbackNames);
	state = {
		...super.state,
		done: true,
	};

	isNew = () => !this.props.learningObject.id;

	handleData = (values) => {
		values = {...values};
		if(typeof values.map.reference === 'string'){
			// values.map.reference = JSON.parse(values.map.reference);
		}

		return values;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];
			if(key === 'manual'){
				continue;
			}
			if(key === 'map'){
				if(!values.manual){
					continue;
				}
				// console.log(value)
				for(const index in value.actions){
					const action = value.actions[index];

					if(action.badgeOrPoints){
						if(!action.pointsValue){
							errors[`map.actions.${index}.pointsValue`] = true;

						}
						if(!action.badgeName){
							errors[`map.actions.${index}.badgeName`] = true;
						}
					}
					if(!action.operation){
						errors[`map.actions.${index}.operation`] = true;
					}
					if(!action.target){
						errors[`map.actions.${index}.target`] = true;
					}
				}
			}
			if(key === 'file' && !this.isNew()){
				continue;
			}
			if(this.WantedFields.indexOf(key) < 0 && !value){
				errors[key] = true;
			}
			if(this.FeedbackFields.indexOf(key) < 0 && !value){
				errors[key] = true;
			}
		}
		if(!this.state.done){
			errors.done = true;
		}

		return errors;
	};

	form(){
		const learningObject: LearningObject = this.props.learningObject;
		const course: Course = this.props.course;
		const initialAction = {
			operation: '',
			pointsValue: '',
			badgeName: '',
			target: '',
			exclusiveName: '',
		};
		const initial = {
			name: ifNull(learningObject.name, ''),
			order: ifNull(learningObject.order, 1),
			file: '',
			wanted: {...learningObject.wanted},
			feedback: {...learningObject.feedback},
			map: {
				actions: [...learningObject.actions],
			},
			reference_items: [
				{
					reference: {},
					info: {},
				},
			],
			manual: !this.isNew(),
			force_landscape: !!learningObject.force_landscape,
		};

		const optionsMapActionOperations = [
			// {value: 'percentage', label: '(%) Aproveitamento'},
			{value: 'each', label: 'A cada'},
			{value: 'gte', label: 'Maior ou igual a'},
			{value: 'gt', label: 'Maior que'},
			{value: 'eq', label: 'Igual a'},
			{value: 'lte', label: 'Menor ou igual a'},
			{value: 'lt', label: 'Menor que'},
		];
		const optionsMapActionTypes = [
			// {value: 'percentage', label: '(%) Aproveitamento'},
			{value: 'hit', label: 'Acerto'},
			{value: 'miss', label: 'Erro'},
		];
		const badgeOrPoints = [
			{value: true, label: 'Badge'},
			{value: false, label: <span className="text-capitalize">{course.points_name}</span>},
		];

		const keyMap = {};
		// eslint-disable-next-line
		const setKey = (setFieldValue, infoOrReference) => (path, file) => {
			const match = infoOrReference
				? ifEmpty(path.match(/(?:^keys\\|\/)(.*)(?:\.info\.json$)/), [])
				: ifEmpty(path.match(/(?:^keys\\|\/)(.*)(?:\.reference\.json$)/), []);

			if(!!match[1]){
				const keyName = match[1];
				let index = 0;
				if(!keyMap[keyName]){
					keyMap[keyName] = Object.keys(keyMap).length;
				}

				index = keyMap[keyName];

				setFieldValue(`reference_items.${index}.keyName`, keyName);
				file.async('string')
					.then(data => {
						setFieldValue(`reference_items.${index}.${infoOrReference ? 'info' : 'reference'}`, parseIfJson(data));
					});
			}
		};

		return (<>
			<SchoolForm
				validate={this.validate}
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				asFunc
			>
				{({values, setFieldValue}) => (<>
					<Form.Row>
						<Col>
							<SchoolFormSimpleField name="name" label="Nome" />
						</Col>
						<Col>
							<SchoolFormSimpleField name="order" label="Ordem" />
						</Col>
					</Form.Row>
					<Form.Group>
						<Form.Label>
							Dados a serem enviados para o objeto
						</Form.Label>
						<Form.Row className="row-cols-3 border rounded px-4 pt-3">
							{this.WantedFields.map(d => (
								<Col key={d}>
									<Form.Group>
										<SchoolFormSimpleField
											withoutGroup
											component={Form.Check}
											name={`wanted.${d}`}
											label={LearningObject.WantedNames[d]}
											componentProps={{
												type: 'switch',
												checked: values.wanted[d],
												className: 'border-0 shadow-none',
												onChange: (e) => setFieldValue(`wanted.${d}`, e.target.checked),
												label: values.wanted[d] ? 'Sim' : 'Não',
											}}
										/>
										<Form.Text>
											{LearningObject.WantedDescription[d]}
										</Form.Text>
									</Form.Group>
								</Col>
							))}
						</Form.Row>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Forçar landscape
						</Form.Label>
						<div className="border rounded px-4 pt-3">
							<SchoolFormSimpleField
								withoutGroup
								withoutLabel
								component={Form.Check}
								name="force_landscape"
								componentProps={{
									type: 'switch',
									checked: values.force_landscape,
									className: 'border-0 shadow-none',
									onChange: (e) => setFieldValue(`force_landscape`, e.target.checked),
									label: values.force_landscape ? 'Sim' : 'Não',
								}}
							>
								<Form.Text>
									Usuário será forçado a utilizar o dispositivo em landscape para exibi-lo
								</Form.Text>
							</SchoolFormSimpleField>
						</div>
					</Form.Group>
					<Form.Group>
						<Form.Label>
							Informações do feedback
						</Form.Label>
						<Form.Row className="row-cols-4 border rounded px-4 pt-3">
							{this.FeedbackFields.map(d => (
								<Col key={d}>
									<Form.Group>
										<SchoolFormSimpleField
											withoutGroup
											component={Form.Check}
											name={`feedback.${d}`}
											label={LearningObject.FeedbackNames[d]}
											componentProps={{
												type: 'switch',
												checked: values.feedback[d],
												className: 'border-0 shadow-none',
												onChange: (e) => setFieldValue(`feedback.${d}`, e.target.checked),
												label: values.feedback[d] ? 'Sim' : 'Não',
											}}
										/>
									</Form.Group>
								</Col>
							))}
						</Form.Row>
					</Form.Group>
					<Form.Group>
						<SchoolFormFile
							name="file"
							label="Arquivos do objeto de aprendizagem"
							fileProps={{
								onChange: ({file}) => {
									setFieldValue('file', file);
									// this.setState({done: false});
									// JSZip.loadAsync(file)
									// 	.then(zip => {
									// 		zip.forEach((path, file) => {
									// 			// console.log(path)
									// 			setKey(setFieldValue, true)(path, file);
									// 			setKey(setFieldValue, false)(path, file);
									// 		});
									// 		this.setState({done: true});
									// 		zip.remove('keys');
									// 		zip.generateAsync({
									// 			type: 'base64',
									// 			compression: 'DEFLATE',
									// 			compressionOptions: {
									// 				level: 9,
									// 			},
									// 		}).then(data => {
									// 			// console.log(data);
									// 			setFieldValue('file', data);
									// 		});
									// 	});
									// .then(content => {
									// 	content = JSON.parse(content);
									// 	setFieldValue(`map.reference`, JSON.stringify(content.reference));
									// 	setFieldValue(`map.type`, JSON.stringify(content.type));
									// });
								},
								accept: '.zip',
							}}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Mapa de premiações</Form.Label>
						<Form.Group className="border rounded px-4 pt-3">
							<FieldArray
								name="map.actions"
								render={arrayHelpers => (<>
									<Form.Group>
										<Button
											variant="outline-primary"
											size="sm"
											className="d-block"
											onClick={() => arrayHelpers.push({...initialAction})}
											tabIndex="-1"
										>
											<i className="fa fa-plus fa-fw" /> Ação
										</Button>
									</Form.Group>
									{values.map.actions.map((action, index) => (
										<Form.Group key={`map_action_${index}`}>
											<Form.Label>Ação #{index + 1}</Form.Label>
											<Form.Row
												className={`rounded pl-3 py-3 border border-right-0`}
											>
												<Col>
													<Form.Row>
														<Col>
															<SchoolSelect
																name={`map.actions.${index}.operation`}
																onChange={({value}) => setFieldValue(`map.actions.${index}.operation`, value)}
																placeholder="Escolha uma operação..."
																label="Operação"
																defaultValue={optionsMapActionOperations.find(({value}) => value === action.operation)}
																options={optionsMapActionOperations}
															/>
														</Col>
														<Col>
															<SchoolFormSimpleField
																name={`map.actions.${index}.target`}
																placeholder="Digite um valor..."
																label="Objetivo"
															/>
														</Col>
														<Col>
															<SchoolSelect
																defaultValue={optionsMapActionTypes.find(({value}) => value === action.type)}
																name={`map.actions.${index}.type`}
																onChange={({value}) => setFieldValue(`map.actions.${index}.type`, value)}
																placeholder="Selecione um tipo..."
																label="Tipo"
																options={optionsMapActionTypes}
															/>
														</Col>
													</Form.Row>
													<Form.Row>
														<Col>
															<SchoolSelect
																name={`map.actions.${index}.badgeOrPoints`}
																onChange={({value}) => setFieldValue(`map.actions.${index}.badgeOrPoints`, value)}
																label="Badge ou pontos?"
																defaultValue={badgeOrPoints.find(({value}) => value === !!action.badgeName)}
																options={badgeOrPoints}
															/>
														</Col>
														<Col>
															<Form.Group>
																<Form.Label>{action.badgeOrPoints ? 'Badge' : 'Pontos'}</Form.Label>
																{action.badgeOrPoints
																	? <BadgeSelect
																		name={`map.actions.${index}.badgeName`}
																		withoutGroup
																		onChange={({value}) => setFieldValue(`map.actions.${index}.badgeName`, value)}
																		placeholder="Badge..."
																		defaultValue={action.badgeName}
																		badges={course.badges}
																	/>
																	: <InputGroup>
																		<SchoolFormSimpleField
																			name={`map.actions.${index}.pointsValue`}
																			type="number"
																			label="Recompensa"
																			withoutGroup
																			withoutLabel
																		/>
																		<InputGroup.Append>
																			<InputGroup.Text>
																				{course.points_name}
																			</InputGroup.Text>
																		</InputGroup.Append>
																	</InputGroup>
																}
															</Form.Group>
														</Col>
													</Form.Row>
												</Col>
												<Col sm="auto">
													<Button
														variant="outline-danger"
														size="sm"
														tabIndex="-1"
														onClick={() => arrayHelpers.remove(index)}
													>
														<i className="fa fa-times fa-fw" />
													</Button>
												</Col>
											</Form.Row>
										</Form.Group>
									))}
								</>)}
							/>
						</Form.Group>
					</Form.Group>
				</>)
				}
			</SchoolForm>
			<Form.Text>
				<ul>
					<li>
						Para saber como a comunicação entre o objeto e o sistema eh feita
						{' '}
						<a
							className="text-primary"
							href={doc}
							target="_blank"
							rel="noopener noreferrer"
						>
							clique aqui
						</a>
					</li>
					<li>
						<strong>Mapa de premiações</strong> é usado para definir as premiações que o objeto dará ao
						usuário,
						a depender do resultado de acertos/erros.
						O objeto tambem pode enviar badges, e pontos não mapeados.
					</li>
				</ul>
			</Form.Text>
		</>);
	}
}
