import {AppitAlertConfirm} from '@appit.com.br/react-misc/src/alert/AppitAlert.js';
import Student from '_entity/Acess/Student';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import {SchoolOperationAlert} from 'hoc/alert';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactSelect from 'react-select';

PaymentIgnoreSelector.propTypes = {
	student: PropTypes.instanceOf(Student),
	onGrant: PropTypes.func,
	onRevoke: PropTypes.func,
};

/**
 *
 * @param {Student} student
 * @param onIgnore
 * @param onConsider
 * @param props
 * @returns {*}
 * @constructor
 */
function PaymentIgnoreSelector({student, onIgnore, onConsider, ...props}){
	const dispatch = useDispatch();
	const ignoreState = useSelector(StudentReducerUtils.ignore.map);
	const considerState = useSelector(StudentReducerUtils.consider.map);


	const ignore = useCallback(() => {
		AppitAlertConfirm(
			<div>Deseja realmente <strong>desconsiderar</strong> o pagamento?</div>,
			"O aluno consiguira acessar o sistema independente do status do pagamento.",
			() => StudentReducerUtils.ignore.dispatch(dispatch, student.id)
		)
	},[dispatch, student.id]);
	const consider = useCallback(() => {
		AppitAlertConfirm(
			<div>Deseja realmente <strong>considerar</strong> o pagamento?</div>,
			"O aluno so consiguira acessar o sistema caso o pagamento seja concluido.",
			() => StudentReducerUtils.consider.dispatch(dispatch, student.id)
		)
	}, [dispatch, student.id]);

	useEffect(() => {
		return () => {
			StudentReducerUtils.ignore.dispatch_clear(dispatch);
			StudentReducerUtils.consider.dispatch_clear(dispatch);
		};
	}, [dispatch]);

	useEffect(() => {
		SchoolOperationAlert({
			successMessage: 'O status do pagamento não será considerado.',
			successAction: () => {
				StudentReducerUtils.ignore.dispatch_clear(dispatch);
				if(onIgnore){
					onIgnore()
				}
			},
			finished: ignoreState.finished,
			error: ignoreState.error,
		});
	}, [dispatch,ignoreState, onIgnore]);

	useEffect(() => {
		SchoolOperationAlert({
			successMessage: 'O status do pagamento será considerado.',
			successAction: () => {
				StudentReducerUtils.consider.dispatch_clear(dispatch);
				if(onConsider){
					onConsider()
				}
			},
			finished: considerState.finished,
			error: considerState.error,
		});
	}, [dispatch,considerState, onConsider]);

	const options = [
		{value: false, label: 'NÃO'},
		{value: true, label: 'SIM'},
	];

	return (
		<ReactSelect
			theme={theme => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: 'var(--primary25)',
					primary50: 'var(--primary50)',
					primary75: 'var(--primary75)',
					primary: 'var(--primary)',
				},
			})}
			// styles={{container: () => ({width: 200})}}
			// className="d-inline-block"
			defaultValue={options.find(o => o.value === student.payment.ignore)}
			classNamePrefix="react-select"
			onChange={({value}) => {
				if(value !== student.payment.ignore){
					value ? ignore() : consider()
				}
			}}
			placeholder="Selecione um conteúdo..."
			options={options}
		/>
	);
}

export default PaymentIgnoreSelector;
