import Module from '_entity/Course/Module/Module';
import FormRewardMap from '_form/Game/FormRewardMap';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {RewardMapReducerUtils} from '_reducer/Game/RewardMapReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ModuleShowingBreadcrumb} from 'app/pages/AdminView/Module/ModuleShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const RewardMapForModuleCreatingBreadcrumb = (module: Module) => ([
	...ModuleShowingBreadcrumb(module),
	{path: AdminRoutes.REWARD_MAP_MODULE_CREATING.replace(':id', module.id), text: 'Adicionar Recompensa'},
]);

function RewardMapForModuleCreatingPage(props){
	const createState = useSelector(RewardMapReducerUtils.createForModule.map);
	const readState = useSelector(ModuleReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: moduleID} = useParams();
	const module: Module = readState.data;
	const create = (data) => RewardMapReducerUtils.createForModule.dispatch(dispatch, moduleID, data);

	useEffect(() => {
			ModuleReducerUtils.read.dispatch(dispatch, moduleID);

			return () => {
				ModuleReducerUtils.read.dispatch_clear(dispatch);
				RewardMapReducerUtils.createForModule.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={RewardMapForModuleCreatingBreadcrumb(module)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormRewardMap
					showOnStep
					showOnView
					showOnRanking
					course={module.course}
					error={createState.error}
					success={createState.finished}
					afterSuccessAlert={pushPrevious}
					onSubmit={create}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default RewardMapForModuleCreatingPage;