import SchoolEntity from '_entity/SchoolEntity';


export default class CourseFAQ extends SchoolEntity {

	get description_question(){
		return this.getDataValue('description_question');
	}

	get description_answer(){
		return this.getDataValue('description_answer');
	}
}