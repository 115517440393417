// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import LearningObjectApi from '_api/Course/Module/LearningObject/LearningObjectApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const LearningObjectReducerOptions = new SchoolReducerOptions('learning_objects', LearningObjectApi);

/**
 *
 * @type {{
 *     create: ReducerBasicOption,
 *     read: ReducerBasicOption,
 *     list: ReducerBasicOption,
 *     edit: ReducerBasicOption,
 *     data: ReducerBasicOption,
 *     data_multiple: ReducerBasicOption,
 *     data_keyname: ReducerBasicOption,
 *     sim_view: ReducerBasicOption
 * }}
 */
export const LearningObjectReducerUtils = LearningObjectReducerOptions.options();
