import BadgeType from '_entity/Game/Badge';
import SchoolSelect from 'app/components/form/SchoolSelect';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

/**
 *
 * @param name
 * @param placeholder
 * @param label
 * @param setFieldValue
 * @param badges
 * @returns {*}
 * @constructor
 */
function BadgeSelect({name, placeholder, label, onChange, badges,defaultValue,withoutLabel,withoutGroup}){
	const options = badges.map((badge: BadgeType) => ({
		value: badge.id,
		label: (<div className="d-flex align-items-center">
			<InlineSVG src={badge.image} alt="" className="square-40px" />
			<div className="d-flex flex-column">
				<div>{badge.name}</div>
				<div className="small text-muted">
					{badge.description}
				</div>
			</div>
		</div>),
	}));

	return (
		<SchoolSelect
			withoutLabel={withoutLabel}
			withoutGroup={withoutGroup}
			name={name}
			label={label}
			onChange={onChange}
			placeholder={placeholder}
			styles={{
				valueContainer: (provided, state) => ({
					...provided,
					// height: '5em',
				}),
			}}
			defaultValue={options.find(o => o.value === defaultValue)}
			options={options}
		/>
	);
}

export default BadgeSelect;