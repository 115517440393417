import Course from '_entity/Course/Course';
import Item from '_entity/Course/Module/Item/Item';
import Module from '_entity/Course/Module/Module';
import RewardMap from '_entity/Game/RewardMap';
import RewardMapOnEvent from '_entity/Game/RewardMapOnEvent';
import RewardMapOnRanking from '_entity/Game/RewardMapOnRanking';
import RewardMapOnStep from '_entity/Game/RewardMapOnStep';
import RewardMapOnView from '_entity/Game/RewardMapOnView';
import {RewardMapReducerUtils} from '_reducer/Game/RewardMapReducer';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {ListGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

RewardSection.propTypes = {
	element: PropTypes.oneOfType([
		PropTypes.instanceOf(Module),
		PropTypes.instanceOf(Item),
		PropTypes.instanceOf(Course),
	]).isRequired,
};

/**
 *
 * @param {Item|Module} element
 * @param {Function} afterDelete
 * @param children
 * @returns {*}
 * @constructor
 */
function RewardSection({element, afterDelete, children}){
	const dispatch = useDispatch();
	const remove = (id) => RewardMapReducerUtils.remove.dispatch(dispatch, id);
	const removeState = useSelector(RewardMapReducerUtils.remove.map);
	let addPath = null;
	if(element instanceof Item){
		addPath = AdminRoutes.REWARD_MAP_ITEM_CREATING.replace(':id', element.id);
	}
	if(element instanceof Module){
		addPath = AdminRoutes.REWARD_MAP_MODULE_CREATING.replace(':id', element.id);
	}
	if(element instanceof Course){
		addPath = AdminRoutes.REWARD_MAP_COURSE_CREATING.replace(':id', element.id);
	}
	// afterDelete = useCallback(() => callIfFunction(afterDelete),[]);

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Recompensa deletado!`,
				successAction: () => {
					RewardMapReducerUtils.remove.dispatch_clear(dispatch);
					afterDelete();
				},
				finished: removeState.finished,
				error: removeState.error,
			});
		},
		//eslint-disable-next-line
		[dispatch, removeState],
	);

	return (
		<>
			<ListGroup horizontal>
				{element.reward_maps.map((reward: RewardMap) => (
					<ListGroup.Item
						className="p-0 d-flex flex-column flex-grow-1"
						style={{maxWidth: '300px'}}
						key={reward.id}
					>
						<div className="p-3 text-uppercase h5 mb-0 flex-grow-1">
							{!!reward.badge
								? <div className="small text-muted">
									<small>Badge:</small>
									<strong>{reward.badge.name}</strong>
								</div>
								: <div className="small text-muted">
									<small>Pontos:</small>
									<strong>{reward.points_value}</strong>
								</div>
							}
							{reward instanceof RewardMapOnStep && (
								<div className="small text-muted">
									<small>Na atividade</small>
									{' '}
									<strong>{reward.step_activity}</strong>
									{' '}
									<small>no nivel</small>
									{' '}
									<strong>{reward.step_level}</strong>
									{' '}
									<small>após a tentativa</small>
									{' '}
									<strong>{reward.step_attempt}</strong>
								</div>
							)}
							{reward instanceof RewardMapOnView && (
								<div className="small text-muted">
									<small>Na</small>
									{' '}
									<strong>visualização</strong>
								</div>
							)}
							{reward instanceof RewardMapOnRanking && (
								<div className="small text-muted">
									<small>Na&nbsp;posição</small>
									{' '}
									<strong>{reward.position_value}&nbsp; do ranking</strong>
								</div>
							)}
							{reward instanceof RewardMapOnEvent && (
								<div className="small text-muted">
									<small>No&nbsp;evento</small>
									{' '}
									<strong>{RewardMapOnEvent.Names[reward.event_name]}</strong>
								</div>
							)}
						</div>
						<Button
							block
							size="sm"
							variant="danger"
							onClick={() => SchoolConfirmAlert({
								title: 'Realmente deseja deletar essa recompensa?',
								action: () => remove(reward.id),
							})}
						>
							<i className="fa fa-trash" />
						</Button>
					</ListGroup.Item>
				))}
			</ListGroup>
			{children}
			<LinkContainer to={addPath}>
				<Button variant="primary" className="mt-3">
					Adicionar Recompensa
				</Button>
			</LinkContainer>
		</>
	);
}

export default RewardSection;