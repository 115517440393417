import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Module from '_entity/Course/Module/Module';
import AvatarFeedbackField from '_form/AvatarFeedbackField';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolLoader from 'app/SchoolLoader';
import PropTypes from 'prop-types';
import React from 'react';
import {Col} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

class FormModule extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		module: PropTypes.instanceOf(Module),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		module: {},
	};

	isNew = () => !this.props.module.id;

	getAlertTitle(): string{
		return `Módulo ${this.isNew() ? 'criado' : 'editado'} com sucesso`;
	}

	getAlertMessage(): string{
		return 'Módulo foi salvo!';
	}

	form(){
		const module: Module = this.props.module;
		if(this.props.success || !this.isShowing()){
			return <SchoolLoader basic />;
		}
		const initial = {
			name: ifEmpty(module.name, ''),
			order: ifEmpty(module.order, 1),
			avatar: ifEmpty(module.avatar, ''),
		}

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				asFunc
			>
				{({setFieldValue}) => (<>

					<Row>
						<Col>
							<SchoolFormSimpleField name="name" label="Nome" />
						</Col>
						<Col>
							<SchoolFormSimpleField name="order" label="Ordem" type="number" />
						</Col>
					</Row>
					<AvatarFeedbackField name="avatar" initial={initial.avatar} setFieldValue={setFieldValue}/>

				</>)}
			</SchoolForm>
		);
	}
}

export default FormModule;
