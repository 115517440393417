import RewardMapOnStep from '_entity/Game/RewardMapOnStep';
import RewardMapOnView from '_entity/Game/RewardMapOnView';

export const RewardMapSelector = {
	getClass(data){
		if(['for_item_on_view', 'for_module_on_view'].indexOf(data.type_name) >= 0){
			return RewardMapOnView;
		}
		if(['for_item_on_step', 'for_module_on_step'].indexOf(data.type_name) >= 0){
			return RewardMapOnStep;
		}
	},
};