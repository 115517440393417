import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';

import {createBridge} from '@appit.com.br/react-misc/src/uniform/AppitUniform';
import AppitSelectField from '@appit.com.br/react-misc/src/uniform/components/AppitSelectField';
import {toFixedLocale} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import {SubmitField} from '_form/SubmitField';
import {InformativeReducerUtils} from '_reducer/Informative/InformativeReducer';
import {Loader} from 'app/Template/TemplateLoaderContext';
import {useSchoolCourseAlert} from 'hoc/alert';
import {openPopup} from 'hoc/utils';
import moment from 'moment';
import React, {createRef, useCallback, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {AutoField, AutoForm} from 'uniforms-bootstrap4';

/**
 *
 * @param asFrame
 * @param refresh
 * @param {StudentPayment} payment
 * @returns {string|*}
 * @constructor
 */
function PaymentRoyalpag({asFrame, refresh, payment}){
	const dispatch = useDispatch();
	const initPayState = useSelector(InformativeReducerUtils.initPayRoyalpag.map);
	const genPayState = useSelector(InformativeReducerUtils.generatePayRoyalpag.map);
	const redirectState = useSelector(InformativeReducerUtils.redirectRoyalpag.map);
	const student: Student = useAppitUserAwareness();
	const redirect = useCallback(() => {
		InformativeReducerUtils.redirectRoyalpag.dispatch(dispatch);
	}, [dispatch]);
	const genPay = useCallback((data) => {
		saveData(data);
		InformativeReducerUtils.generatePayRoyalpag.dispatch(dispatch, data);
	}, [dispatch]);
	const [data, saveData] = useState({
		email: student.email,
	});
	const [payUrl, setPayUrl] = useState();
	// console.log(payment)
	// const showForm = !payment.type || (payment.type === 'pagseguro'
	// 	? !payment.status === 'not_started'
	// 	: ['canceled', 'refunded'].includes(payment.status));
	const [now, setNow] = useState(moment());
	const start = moment('08:30', 'hh:mm:ss');
	const end = moment('23:30', 'hh:mm:ss');

	useEffect(() => {
		setInterval(() => {
			setNow(moment());
		}, 1000);
	}, []);

	useEffect(() => {
		InformativeReducerUtils.initPayRoyalpag.dispatch(dispatch);
	}, [dispatch]);

	useEffect(() => {
		if(redirectState.finished && !redirectState.error){
			openPopup(payment.royalpag_url, 'Pagamento');
		}
	}, [redirectState, payment.royalpag_url]);
	const Alerts = useSchoolCourseAlert(student.course);

	useEffect(() => {
		if(genPayState.finished && !genPayState.error){
			const {url} = genPayState.data;
			Alerts.OperationAlert({
				finished: !url,
				error: !url,
				failureMessage: 'Houve um erro na comunicação com a royalpag',
			});
			if(asFrame){
				setPayUrl(url);
			}
			else{
				openPopup(url, 'Pagamento');
			}
			refresh();
		}
	}, [genPayState, asFrame, refresh, Alerts]);

	if(!initPayState.finished){
		return <Loader />;
	}

	if(initPayState.error){
		return 'Erro desconhecido';
	}
	let errorSection;
	if(genPayState.error){
		errorSection = 'Erro desconhecido';
		console.log(genPayState.error);
		if(genPayState.error.isAxiosError && genPayState.error.response.data.errors){
			const errors = genPayState.error.response.data.errors;

			errorSection = <ul className="p-4">
				{Object.keys(errors).map((errorField) => {
					const error = errors[errorField];


					if(errorField === 'Email'){
						return <li key={errorField}>Email invalido, {error}</li>;
					}
					if(errorField === 'Nickname'){
						return <li key={errorField}>Nickname invalido, {error}</li>;
					}

					return <li key={errorField}>Erro na comunicação, {error}</li>;
				})}
			</ul>;
		}
	}
	const {rooms, disabled, sandbox, is_new} = initPayState.data;

	if(disabled){
		return <h3>Metodo ainda não esta disponivel</h3>;
	}

	if(!asFrame){
		return <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center p-4">
			{now.isBetween(start, end)
				? (<>
					{is_new
						? <RoomForm onSubmit={genPay} rooms={rooms} data={data} cost={student.course.cost} />
						: <Button
							onClick={redirect}
							variant="link"
							size="lg"
						>
							Clique aqui para continuar pelo RoyalPag
						</Button>
					}
					<div className="w-100 text-left">
						<div className="small text-muted">Um popup será aberto</div>
						<div className="small text-muted">
							Disponivel de {start.format('HH:mm')} às {end.format('HH:mm')}
						</div>
					</div>
				</>)
				: <div className="w-100 h4 text-center">
					<div className="text-muted">
						O pagamento pela RoyalPag pode ser feito entre {start.format('HH:mm')} às {end.format('HH:mm')}
					</div>
				</div>
			}
			{errorSection}
		</div>;
	}

	return (
		payUrl
			? <iframe title="Pagamento" src={payUrl} frameBorder="0" data-sandbox={sandbox ? 'yes' : undefined} />
			: <h3>Selecione uma sala</h3>
	);
}

export default PaymentRoyalpag;

function RoomForm({rooms, onSubmit, data, cost}){
	const options = rooms.filter(r => r.valorFicha > 0).map(r => ({
		label: () => (<>
			{r.name} - <span className="text-muted">
			<small>R$</small>
			{' '}
			<span>{r.valorFicha}</span>
			{' '}
			<small>por ficha</small>
			{' '}
			<small className="text-muted">(Total: {toFixedLocale(r.fichas)} fichas)</small>
		</span>
		</>),
		value: r.value,
	}));

	const ref = createRef();
	const bridge = createBridge({
		title: '',
		type: 'object',
		properties: {
			nickname: {
				type: 'string',
				uniforms: {
					type: 'email',
				},
			},
			email: {
				type: 'string',
				format: 'email',
				uniforms: {
					type: 'email',
				},
			},
			room: {
				options,
				uniforms: {
					component: AppitSelectField,
				},
			},
		},
		required: ['email', 'nickname', 'room'],
	});

	return (
		<AutoForm
			className="w-100"
			model={data}
			ref={ref}
			schema={bridge}
			onSubmit={onSubmit}
			placeholder={true}
			label={false}
		>
			<AutoField name="room" />
			<AutoField name="email" />
			<AutoField name="nickname" />
			<SubmitField primary>Clique aqui para fazer o pagamento pelo RoyalPag</SubmitField>
		</AutoForm>
	);
}
