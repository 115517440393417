import BadgeType from '_entity/Game/Badge';
import FormBadge from '_form/Game/FormBadge';
import {BadgeTypeReducerUtils} from '_reducer/Game/BadgeTypeReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const BadgeEditingBreadcrumb = (badge: BadgeType) => ([
	...CourseShowingBreadcrumb(badge.course),
	{
		path: AdminRoutes.BADGE_EDITING.replace(':id', badge.id),
		text: 'Editar badge',
	},
]);

function BadgeEditingPage(props){
	const editingState = useSelector(BadgeTypeReducerUtils.edit.map);
	const readState = useSelector(BadgeTypeReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: badgeID} = useParams();
	const badge: BadgeType = readState.data;
	const edit = (data) => BadgeTypeReducerUtils.edit.dispatch(dispatch, badgeID, data);

	useEffect(() => {
			BadgeTypeReducerUtils.read.dispatch(dispatch, badgeID);

			return () => {
				BadgeTypeReducerUtils.read.dispatch_clear(dispatch);
				BadgeTypeReducerUtils.edit.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={BadgeEditingBreadcrumb(badge)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormBadge
					success={editingState.finished}
					error={editingState.error}
					onSubmit={edit}
					badge={readState.data}
					afterSuccessAlert={pushPrevious}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default BadgeEditingPage;