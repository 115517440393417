import {asset, ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFileImage from 'app/components/form/SchoolFormFileImage';
import defaultAvatar from 'assets/images/avatar.svg';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

class FormStudent extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		student: PropTypes.instanceOf(Student),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		student: new Student({
			name: '',
			internal_username: '',
			email: '',
			avatar_src: '',
		}),
	};
	state = {
		file: null,
	};

	isNew = () => !this.props.student.id;

	handleData = (values) => {
		values = {...values};
		if(!!this.state.file){
			values.file = this.state.file;
		}

		return values;
	};

	getAlertTitle(): string{
		return `Estudante ${this.isNew() ? 'criado' : 'editado'} com sucesso`;
	}

	getAlertMessage(): string{
		return 'Estudante foi salvo!';
	}

	form(){
		const student: Student = this.props.student;
		const initial = {
			name: ifEmpty(student.name, ''),
			internal_username: ifEmpty(student.internal_username, ''),
			email: ifEmpty(student.email, ''),
			file: '',
		};
		if(this.isNew()){
			initial.password = '';
		}

		const onChange = ({file}) => {
			// console.log('change');
			this.setState({file});
		};
		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
			>
				<SchoolFormFileImage
					src={!!student.avatar ? asset(student.avatar) : defaultAvatar}
					label="Avatar"
					fileProps={{onChange}}
				/>
				<SchoolFormSimpleField name="name" label="Nome" />
				<Row>
					<Col>
						<SchoolFormSimpleField name="internal_username" label="Usuário" />
					</Col>
					<Col>
						<SchoolFormSimpleField name="email" label="Email" type="email" />
					</Col>
				</Row>
				{this.isNew() && <SchoolFormSimpleField name="password" label="Senha" type="password" />}
			</SchoolForm>
		);
	}
}

export default FormStudent;
