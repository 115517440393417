import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import ContentRange from '_entity/Course/Module/Item/Content/ContentRange';

export default class ContentRangeApi extends SchoolApi {
	get namespace(){
		return 'content_ranges';
	}

	get entity_class(){
		return ContentRange;
	}

	list(contentID){
		expectsString(contentID);
		return this.json.get(`by_content/${contentID}`);
	}

	read(rangeID){
		expectsString(rangeID);
		return this.json.get(`${rangeID}`);
	}

	listAll(){
		return this.json.get();
	}

	create(contentID, data){
		expectsString(contentID);
		expectsObject(data);
		return this.json.post(`by_content/${contentID}`, data);
	}

	edit(rangeId, data){
		expectsString(rangeId);
		expectsObject(data);
		return this.json.put(`${rangeId}`, data);
	}

	delete(id){
		expectsString(id);
		return this.json.delete(id);
	}
}
