import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Student from '_entity/Acess/Student';
import PaymentPagseguro from 'app/pages/WaitingView/PaymentPagseguro';
import PaymentRoyalpag from 'app/pages/WaitingView/PaymentRoyalpag';
import React, {useState} from 'react';
import {Button, ButtonGroup, Card} from 'react-bootstrap';

/**
 *
 * @param {[]} methods
 * @param refresh
 * @returns {*}
 * @constructor
 */
function PaymentSelector({methods, refresh}){
	const student: Student = useAppitUserAwareness();
	const [type, setType] = useState(methods.length === 1 ? methods[0] : null);
	const differentType = !!student.payment.type && !!type && student.payment.type !== type;
	const locked = differentType && !['not_started', 'canceled', 'refunded'].includes(student.payment.status);


	return (
		<Card className="h-100 overflow-hidden">
			<Card.Header>
				<h3>
					Escolha a forma de pagamento
				</h3>
			</Card.Header>
			<ButtonGroup className="payments">
				{methods.includes('royalpag') && <RoyalpagButton type={type} setType={setType} />}
				{methods.includes('pagseguro') && <PagseguroButton type={type} setType={setType} />}
			</ButtonGroup>
			<Card.Body className="p-0 flex-grow-1 overflow-hidden payments-form">
				{locked
					? (<div className="h3 mb-0 h-100 text-muted d-flex align-items-center justify-content-center">
						Você já selecionou outro metodo - {student.payment.type.toUpperCase()}
					</div>)
					: (<>
						{type === 'royalpag' && <PaymentRoyalpag payment={student.payment} refresh={refresh} />}
						{type === 'pagseguro' && <PaymentPagseguro payment={student.payment} refresh={refresh} />}
					</>)
				}
				{!type && (
					<div className="h3 mb-0 h-100 text-muted d-flex align-items-center justify-content-center">
						Nenhuma forma de pagamento selecionada
					</div>
				)}
			</Card.Body>
			<Card.Footer>
				<Button onClick={() => window.location.reload()}>
					Atualizar pagina
				</Button>
			</Card.Footer>
		</Card>
	);
}

export default PaymentSelector;

function PagseguroButton({type, setType}){
	return <Button
		variant="link"
		active={type === 'pagseguro'}
		onClick={() => setType('pagseguro')}
	>
		<img
			className="img-fluid"
			style={{maxHeight: '3em'}}
			src="https://assets.pagseguro.com.br/ps-bootstrap/v6.73.3/img/logos/pagbank/pagbank-logo-animado_35px.gif"
			alt="PagSeguro"
		/>
	</Button>;
}

function RoyalpagButton({type, setType}){
	return <Button
		variant="link"
		active={type === 'royalpag'}
		onClick={() => setType('royalpag')}
	>
		<img
			className="img-fluid"
			style={{height: '5em'}}
			src="https://royalpag.com/images/logo-header.png"
			alt="RoyalPag"
		/>
	</Button>
}
