// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ContentGlossaryApi from '_api/Course/Module/Content/ContentGlossaryApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ContentGlossaryReducerOptions = new SchoolReducerOptions('content_glossary', ContentGlossaryApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 *     create: ReducerBasicOption
 *     delete: ReducerBasicOption
 * }}
 */
export const ContentGlossaryReducerUtils: $ObjMap<string, ReducerBasicOption> = ContentGlossaryReducerOptions.options();
