// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import AnsweredQuestionApi from '_api/Progress/AnsweredQuestionApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const AnsweredQuestionReducerOptions = new SchoolReducerOptions('answered', AnsweredQuestionApi);

/**
 *
 * @type {{
 *     answer: ReducerBasicOption
 *     answerFeedback: ReducerBasicOption
 * }}
 */
export const AnsweredQuestionReducerUtils = AnsweredQuestionReducerOptions.options();
