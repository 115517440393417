import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import {LearningObjectReducerUtils} from '_reducer/Course/Module/LearningObject/LearningObjectReducer';
import IframeUtils, {iframeTX} from 'hoc/IframeUtils';
import PropsTypes from 'prop-types';
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Fade} from 'react-bootstrap';
import {findDOMNode} from 'react-dom';
import InlineSVG from 'react-inlinesvg';
import {useDispatch, useSelector} from 'react-redux';

SchoolLearningObject.propTypes = {
	learningObject: PropsTypes.instanceOf(LearningObject).isRequired,
	save: PropsTypes.func,
	onLoad: PropsTypes.func,
	onlyLandscape: PropsTypes.bool,
	isFeedbackLoading: PropsTypes.bool,
	isFeedbackShowing: PropsTypes.bool,
	feedback: PropsTypes.shape({
		points_value: PropsTypes.number,
		badge: PropsTypes.shape({
			image_src: PropsTypes.string,
			name: PropsTypes.string,
			description: PropsTypes.string,
		}),
		earnings: PropsTypes.number,
		losses: PropsTypes.number,
		correct_counter: PropsTypes.number,
	}),
};

SchoolLearningObject.defaultProps = {
	withToolbar: false,
	onlyLandscape: false,
	isFeedbackLoading: false,
	isFeedbackShowing: false,
	save: () => null,
	feedback: {
		points_value: null,
		badge: null,
		earnings: null,
		losses: null,
		correct_counter: null,
	},
};

/**
 *
 * @param className
 * @param {LearningObject} learningObject
 * @param locked
 * @param feedback
 * @param isFeedbackLoading
 * @param isFeedbackShowing
 * @param {boolean} onlyLandscape
 * @param children
 * @param {Function} onLoad
 * @param onClear
 * @returns {*}
 * @constructor
 */
function SchoolLearningObject({className, learningObject, locked, feedback, isFeedbackLoading, isFeedbackShowing, children, onLoad, onlyLandscape, onClear: fn_onClear, onSave: fn_onSave, onStep: fn_onStep, onSaveOnStep: fn_onSaveOnStep}){
	const ref = useRef();
	const [contentWindow: WindowProxy, setContentWindow] = useState();
	const dispatch = useDispatch();
	const dataState = useSelector(LearningObjectReducerUtils.data.map);
	const dataMultipleSate = useSelector(LearningObjectReducerUtils.data_multiple.map);
	const dataKeynameState = useSelector(LearningObjectReducerUtils.data_keyname.map);
	const [amount, setAmount] = useState(null);
	const [keyname, setKeyname] = useState(null);
	const stamp = useMemo(
		() => new Date().getTime(),
		// eslint-disable-next-line
		[learningObject.id],
	);
	// console.log("stamp",stamp)
	const defaultCallback = useCallback(() => null, []);

	const onClear = typeof fn_onClear === 'function' ? fn_onClear : defaultCallback;
	const onSave = typeof fn_onSave === 'function' ? fn_onSave : defaultCallback;
	const onStep = typeof fn_onStep === 'function' ? fn_onStep : defaultCallback;
	const onSaveOnStep = typeof fn_onSaveOnStep === 'function' ? fn_onSaveOnStep : defaultCallback;

	useLayoutEffect(() => {
		const utils = IframeUtils({
			height: (payload) => !!payload && (findDOMNode(ref.current).style.height = payload.height + 'px'),
			answer: (keyName) => {
				setKeyname(keyName);
				return true;
			},
			multiple_data: ({amount}) => {
				setAmount(amount);
				return true;
			},
			clear: () => {
				onClear();
				return true;
			},
			step: (arg = {}) => {
				// console.log(arg);
				if(!locked){
					if(Array.isArray(arg)){
						onStep({
							multi: arg.map(a => ({
								setActivity: a.setActivity || a.activity,
								stepLevel: a.stepLevel || a.level,
								countValue: a.countValue || a.count || 0,
							})),
						});
					}
					else{
						const {activity, setActivity, stepLevel, level, count, countValue} = arg;
						onStep({
							setActivity: setActivity || activity,
							stepLevel: stepLevel || level,
							countValue: countValue || count || 0,
						});
					}
				}

				return true;
			},
			save_on_step: (arg = {}) => {
				if(!locked){
					if(Array.isArray(arg)){
						onSaveOnStep({
							multi: arg.map(a => ({
								stepActivity: a.setActivity || a.activity,
								stepLevel: a.stepLevel || a.level,
								countValue: a.countValue || a.count || 0,
							})),
						});
					}
					else{
						const {activity, setActivity, stepLevel, level, count, countValue} = arg;
						onSaveOnStep({
							stepActivity: setActivity || activity,
							stepLevel: stepLevel || level,
							countValue: countValue || count || 0,
						});
					}
				}

				return true;
			},
			save: ({data, keyName, points, pointsValues, pointsValue, badge, badgeNames, badgeName, hits, misses} = {}) => {
				if(!locked){

					pointsValues = pointsValues || pointsValue || points;
					if(!Array.isArray(pointsValues)){
						pointsValues = [pointsValues];
					}
					badgeNames = badgeNames || badgeName || badge;
					if(!Array.isArray(badgeNames)){
						badgeNames = [badgeNames];
					}

					// console.log(onSave)
					onSave({
						data,
						keyName,
						pointsValues,
						badgeNames,
						hits,
						misses,
					});
				}

				return true;
			},
		});
		utils.start();

		return () => {
			// console.log('utils stop');
			utils.stop();
		};
	}, [ref, locked, onSave, onClear, onSaveOnStep, onStep]);

	useEffect(() => {
		if(dataState.finished && !!contentWindow){
			iframeTX(contentWindow, 'data', dataState.data);
		}
	}, [dataState, contentWindow]);

	useEffect(() => {

		return () => {
			LearningObjectReducerUtils.data_multiple.dispatch_clear(dispatch);
			LearningObjectReducerUtils.data_keyname.dispatch_clear(dispatch);
			LearningObjectReducerUtils.data.dispatch_clear(dispatch);
		};
	}, [dispatch, contentWindow]);

	useLayoutEffect(() => {
		LearningObjectReducerUtils.data.dispatch(dispatch, learningObject.id);
		findDOMNode(ref.current).addEventListener('load', (e) => {
			setContentWindow(e.target.contentWindow);
		}, true);
	}, [dispatch, ref, learningObject.id]);

	useEffect(() => {
		if(!!amount){
			LearningObjectReducerUtils.data_multiple.dispatch(dispatch, learningObject.id, amount);
		}
	}, [dispatch, learningObject.id, amount]);

	useEffect(() => {
		if(!!keyname){
			LearningObjectReducerUtils.data_keyname.dispatch(dispatch, learningObject.id, keyname);
		}
	}, [dispatch, learningObject.id, keyname]);

	useEffect(() => {
		if(dataMultipleSate.finished && !!contentWindow){
			contentWindow.postMessage({type: 'multiple_data', payload: dataMultipleSate.data}, '*');
		}
	}, [contentWindow, dataMultipleSate]);

	useEffect(() => {
		if(dataKeynameState.finished && !!contentWindow){
			const payload = dataKeynameState.data.answer[keyname];
			contentWindow.postMessage({type: 'answer', payload}, '*');
		}
	}, [keyname, contentWindow, dataKeynameState]);

	const classes = ['school-item'];
	if(onlyLandscape){
		classes.push('school-item-landscape-only');
	}
	if(isFeedbackShowing){
		classes.push('school-item-show-feedback');
	}
	if(!!className){
		classes.push(className);
	}

	const hideSpinner = !isFeedbackLoading && feedback;

	return (
		<div ref={ref} className={classes.join(' ')}>
			{children}
			<Fade in={!!isFeedbackShowing} mountOnEnter={true} unmountOnExit={true}>
				<div className="school-item-feedback">
					{learningObject.feedback.hits && (
						<div className="school-item-feedback-section">
							<div className="school-item-text">
								<div className="school-item-feedback-title">Acertos</div>
								<div className="school-item-feedback-desc" />
							</div>
							<div className={`school-item-feedback-value school-item-feedback-value-single ${!hideSpinner ? 'school-item-feedback-value-loading' : ''}`}>
								<div>
									{hideSpinner
										? (feedback.total_counter
												? (<>
													<div>{feedback.correct_counter}</div>
													<small className="text-muted">de</small>
													<div>{feedback.total_counter}</div>
												</>)
												: feedback.correct_counter
										)
										: <i className="fa fa-spinner fa-spin" />
									}
								</div>
							</div>
						</div>
					)}
					{(learningObject.feedback.earnings || learningObject.feedback.losses) && (
						<div className="school-item-feedback-section">
							<div className="school-item-text">
								<div className="school-item-feedback-title">Fichas</div>
								<div className="school-item-feedback-desc" />
							</div>
							<div className={`school-item-feedback-value school-item-feedback-value-double ${!hideSpinner ? 'school-item-feedback-value-loading' : ''}`}>
								<div>
									{hideSpinner
										? <>
											{learningObject.feedback.earnings && (
												<div className="mb-auto">
													<div>Ganhas:</div>
													<div>{feedback.earnings}</div>
												</div>
											)}
											{learningObject.feedback.losses && (
												<div className="mt-auto">
													<div>Perdas:</div>
													<div>{feedback.losses}</div>
												</div>
											)}
										</>
										: <i className="fa fa-spinner fa-spin" />
									}
								</div>
							</div>
						</div>
					)}
					{(learningObject.feedback.badges || true) && (
						<div className="school-item-feedback-section">
							<div className="school-item-text">
								<div className="school-item-feedback-title">Badges</div>
								<div className="school-item-feedback-desc">
									{hideSpinner && !!feedback.badge && (<>
										<strong>{feedback.badge.name}</strong>
										<span>{feedback.badge.description}</span>
									</>)}
								</div>
							</div>
							<div className={`school-item-feedback-value school-item-feedback-value-icon ${!hideSpinner ? 'school-item-feedback-value-loading' : ''}`}>
								<div>
									{hideSpinner
										? (!!feedback.badge
												? <InlineSVG src={feedback.badge.image_src} />
												: <small>Você não conseguiu o badge</small>
										)
										: <i className="fa fa-spinner fa-spin" />
									}
								</div>
							</div>
							<div className="school-item-text">
								<div className="school-item-feedback-desc">
									{hideSpinner && !!feedback.badge && (<>
										<strong>{feedback.badge.name}</strong>
										{feedback.badge.description}
									</>)}
								</div>
							</div>
						</div>
					)}
				</div>
			</Fade>
			<iframe
				key={stamp}
				className="school-item-element"
				src={asset(learningObject.src) + `?${stamp}`}
				title={learningObject.name}
				height="auto"
				width="100%"
				onLoad={onLoad}
			/>
			{onlyLandscape && (
				<div className="school-item-portrait">
					<h4 className="p-4 text-center">
						<i className="fa fa-mobile-alt fa-rotate-90 fa-5x" />
					</h4>
					<h3 className="text-muted text-center">
						Use o celular na horizontal ou aumente o tamanho da janela para ver o objeto.
					</h3>
				</div>
			)}
		</div>
	);
}

export default SchoolLearningObject;
