import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import avatar from 'assets/images/avatar.svg';
import PropTypes from 'prop-types';
import React from 'react';
import {Form} from 'react-bootstrap';

class SchoolFormFileImage extends React.Component {
	static propTypes = {
		fileProps: PropTypes.object,
		label: PropTypes.string,
		src: PropTypes.string,
		accept: PropTypes.string,
		imgClassName: PropTypes.string,
	};
	static defaultProps = {
		fileProps: {},
		label: 'selecione uma imagem',
		imgClassName: '',
		src: avatar,
		accept: 'image/*',
	};
	state = {
		labelText: this.props.label,
		src: this.props.src,
	};
	imgRef = React.createRef();

	render(){
		const {fileProps, label, accept, imgClassName} = this.props;
		const onChange = (e) => {
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => this.imgRef.current.src = reader.result;
			if(typeof fileProps.onChange === 'function'){
				fileProps.onChange({event: e, file});
			}
			this.setState({
				labelText: file.name,
			});
		};
		fileProps.className = 'form-control-lg ' + ifNull(fileProps.className, '');
		fileProps.label = <span className="text-primary">{this.state.labelText}</span>;
		return (
			<Form.Group className="d-flex align-items-end">
				<div className="pr-3" style={{maxWidth: '10em'}}>
					<img
						ref={this.imgRef}
						src={this.state.src}
						alt=""
						className={`w-100 h-100 ${imgClassName}`}
						onError={() => this.setState({src: avatar})}
					/>
				</div>
				<div className="w-100">
					{!!label && <Form.Label>{label}</Form.Label>}
					<Form.File
						{...fileProps}
						name="file"
						custom
						accept={accept}
						onChange={onChange}
					/>
				</div>
			</Form.Group>
		);
	}
}

export default SchoolFormFileImage;
