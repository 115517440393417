import SchoolApi from '_api/SchoolApi';
import Student from '_entity/Acess/Student';

export default class StudentApi extends SchoolApi {
	get namespace(){
		return 'students';
	}

	get entity_class(){
		return Student;
	}

	pay_boleto(data){
		return this.json.post(`pay/boleto`,data);
	}

	changeCPF(data){
		return this.json.patch(`change_cpf`,data);
	}

	changeName(data){
		return this.json.patch(`change_name`,data);
	}

	change_field(id,data){
		return this.json.patch(`${id}/change_field`,data);
	}

	pay_credit_card(data){
		return this.json.post(`pay/credit_card`,data);
	}

	refund(){
		return this.json.post(`pay/refund`,{});
	}

	check_my_payment(){
		return this.json.get(`check/payment`);
	}

	list(){
		return this.json.get();
	}

	listPage(page,status,query=""){
		const params = {
			page,
			query,
			status,
			nocache: new Date().getTime(),
		};

		return this.json.get(`/search_and_pagination/${btoa(JSON.stringify(params))}`,{
			// withCredentials: true,
		});
	}

	read(id){
		return this.json.get(`${id}/show`);
	}

	create(data){
		return this.multipart.post('', data);
	}

	editMe(data){
		return this.multipart.post('profile/edit', data);
	}

	changePassword(data){
		return this.json.patch('profile/change_password', data);
	}

	revoke(id){
		return this.json.patch(`${id}/revoke`, {});
	}

	grant(id){
		return this.json.patch(`${id}/grant`, {});
	}

	ignore(id){
		return this.json.patch(`${id}/ignore`, {});
	}

	consider(id){
		return this.json.patch(`${id}/consider`, {});
	}
}