import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFile from 'app/components/form/SchoolFormFile';
import React from 'react';

export default class FormContentFile extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};
	state = {
		file: null,
	};

	handleData = (data) => {
		data.file = this.state.file;

		return data;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];
			if(!value){
				errors[key] = true;
			}
		}
		if(!this.state.file){
			errors.file = true;
		}

		return errors;
	};

	form(){
		const onChange = ({file}) => {
			this.setState({file});
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				validate={this.validate}
				initialValues={{
					file_name: '',
					tags: '',
				}}
				submitText="Adicionar"
			>
				<SchoolFormSimpleField name="file_name" label="Nome" />
				<SchoolFormFile fileProps={{onChange}} />
				<SchoolFormSimpleField name="tags" label="Tags" />
			</SchoolForm>
		);
	}
}