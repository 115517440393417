import BadgeType from '_entity/Game/Badge';
import SchoolEntity from '_entity/SchoolEntity';

export default class RewardMap extends SchoolEntity {

	get points_value(){
		return this.getDataValue('points_value');
	}

	get badge(){
		return this.getDataValue('badge',BadgeType);
	}

	get type_name(){
		return this.getDataValue('type_name');
	}
}