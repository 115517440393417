import classnames from 'classnames';
import React from 'react';
import {filterDOMProps, useForm} from 'uniforms';
import {gridClassName} from 'uniforms-bootstrap4';

export function SubmitField({
								className,
								disabled,
								inputClassName,
								inputRef,
								name,
								children,
	primary,
								wrapClassName,
	grid,
	inputProps={},
	noWrap,
								...props
							}: SubmitFieldProps){
	const {error, state, submitting} = useForm();
	const hasWrap = !!((grid === false ? false : state.grid) || wrapClassName);

	const blockInput = (
		<button
			{...inputProps}
			className={classnames(`btn btn-${primary ? "primary" : "light"} btn-block`, inputClassName)}
			disabled={submitting || (disabled === undefined ? !!(error || state.disabled) : disabled)}
			ref={inputRef}
			type="submit"
		>
			<span>
				{children}
			</span>
			{submitting && <span className="ml-3 fa-spin fa-spinner" />}
		</button>
	);

	if(noWrap){
		return blockInput;
	}

	return (
		<div
			className={classnames(className, {
				'is-invalid': error,
				row: grid === false ? false : state.grid,
			})}
			{...filterDOMProps(props)}
		>
			{hasWrap && (
				<label
					className={classnames(
						'col-form-label',
						gridClassName(state.grid, 'label'),
					)}
				>
					&nbsp;
				</label>
			)}
			{hasWrap && (
				<div
					className={classnames(
						wrapClassName,
						gridClassName(state.grid, 'input'),
					)}
				>
					{blockInput}
				</div>
			)}
			{!hasWrap && blockInput}
		</div>
	);
}