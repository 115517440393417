import SchoolApi from '_api/SchoolApi';
import Like from '_entity/Social/Like';

export default class LikeApi extends SchoolApi {
	get namespace(){
		return 'likes';
	}

	get entity_class(){
		return Like;
	}

	create(commentID){
		return this.json.put(`by_comment/${commentID}`, {});
	}
}