// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ContentTipApi from '_api/Course/Module/Content/ContentTipApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ContentTipReducerOptions = new SchoolReducerOptions('content_tip', ContentTipApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 *     create: ReducerBasicOption
 *     delete: ReducerBasicOption
 * }}
 */
export const ContentTipReducerUtils: $ObjMap<string, ReducerBasicOption> = ContentTipReducerOptions.options();
