import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import {dateOrNull, throwIfUndefined} from '@appit.com.br/react-utils/src/utils/utils';
import StudentAccess from '_entity/Acess/StudentAccess';
import StudentEvent from '_entity/Acess/StudentEvent';
import StudentPayment from '_entity/Acess/StudentPayment';
import User from '_entity/Acess/User';
import Course from '_entity/Course/Course';
import EarnedBadge from '_entity/Game/EarnedBadge';
import Points from '_entity/Game/Points';
import AnsweredQuestion from '_entity/Progress/AnsweredQuestion';
import ViewedItem from '_entity/Progress/ViewedItem';
import Comment from '_entity/Social/Comment';
import Like from '_entity/Social/Like';

export default class Student extends User {

	get avatar(): string{
		return this.getDataValue('avatar_src');
	}

	get payment():StudentPayment{
		return this.getDataValue('payment',StudentPayment,{student: this})
	}

	get last_access():StudentAccess{
		return this.getDataValue("last_access",StudentAccess)
	}

	get accesses():StudentAccess[]{
		return this.getDataValue("accesses",StudentAccess)
	}

	get active(): boolean{
		return this.getDataValue('active');
	}

	get internal_username(){
		return this.getDataValue('internal_username');
	}

	get avatar_stamp(): ?Date{
		return dateOrNull(this.getDataValue('avatar_stamp'));
	}

	get events(): StudentEvent[]{
		return this.getDataValue('events', StudentEvent);
	}

	get completed(){
		return this.getDataValue('completed');
	}

	get info(): Object{
		const data = this.getData();
		const progress = {
			get count_complete_question(){
				return throwIfUndefined(data.info_count_complete_question);
			},
			get count_complete_item(){
				return throwIfUndefined(data.info_count_complete_item);
			},
			get count_complete_content(){
				return throwIfUndefined(data.info_count_complete_content);
			},
			get count_complete_learning_object(){
				return throwIfUndefined(data.info_count_complete_learning_object);
			},
		}

		const index = {
			get total(){
				return throwIfUndefined(data.info_index)
			},
			get question(){
				return throwIfUndefined(data.info_index_question)
			},
			get content(){
				return throwIfUndefined(data.info_index_content)
			},
			get object(){
				return throwIfUndefined(data.info_index_learning_object)
			},
		}

		return {
			get ranking(){
				return throwIfUndefined(data.info_ranking);
			},
			get sum_points(){
				return throwIfUndefined(data.info_sum_points);
			},
			get progress(){
				return progress;
			},
			get index(){
				return index;
			},
			get count_badges(){
				throwIfUndefined(data.info_count_badges);
				return {
					get champion(){
						return data.info_count_badges.count_champion;
					},
					get deadline(){
						return data.info_count_badges.count_luck;
					},
					get luck(){
						return data.info_count_badges.count_deadline;
					},
					get time(){
						return data.info_count_badges.count_time;
					},
				};
			},
		};
	}

	get course(): Course{
		return entityResolver(this.getDataValue('course'), Course);
	}

	get viewed_items(): ViewedItem[]{
		return entityResolver(this.getDataValue('viewed_items'), ViewedItem, {student: this});
	}

	get answers(): AnsweredQuestion{
		return entityResolver(this.getDataValue('answers'), AnsweredQuestion, {student: this});
	}

	get badges(): EarnedBadge{
		return entityResolver(this.getDataValue('badges'), EarnedBadge, {student: this});
	}

	get points(): Points[]{
		return entityResolver(this.getDataValue('points'), Points, {student: this});
	}

	get comments(): Comment[]{
		return entityResolver(this.getDataValue('comments'), Comment, {user: this});
	}

	get likes(): Like[]{
		return entityResolver(this.getDataValue('likes'), Like, {user: this});
	}

	has_event(name){
		for(const event of this.events){
			if(event.name === name){
				return true;
			}
		}

		return false;
	}
}
