import {createBridge} from '@appit.com.br/react-misc/src/uniform/AppitUniform';
import {SubmitField} from '_form/SubmitField';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import {SchoolOperationAlert} from 'hoc/alert';
import React, {createRef, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {AutoFields, AutoForm} from 'uniforms-bootstrap4';

ChangeNameForm.propTypes = {

};

export default function ChangeNameForm(){
	const dispatch = useDispatch();
	const changeName = (data) => StudentReducerUtils.changeName.dispatch(dispatch,data)
	const changeNameState = useSelector(StudentReducerUtils.changeName.map);
	const ref = createRef();
	const bridge = createBridge({
		title: '',
		type: "object",
		properties: {
			name: {
				label: "Nome Completo",
				type: "string",
				uniforms: {
					inputClassName: "rounded-0"
				}
			}
		}
    })

	useEffect(() => {
		SchoolOperationAlert({
			successTitle: "Nome foi alterado com sucesso",
			successAction: () => {
				window.location.reload()
			},
			error: changeNameState.error,
			finished: changeNameState.finished,
		});
	},[changeNameState])

	return (
		<AutoForm
			ref={ref}
			schema={bridge}
			onSubmit={changeName}
			placeholder={true}
			label={false}>
			<Row noGutters>
				<Col>
					<AutoFields/>
				</Col>
				<Col sm="auto">
					<SubmitField primary inputClassName="rounded-0">Salvar</SubmitField>
				</Col>
			</Row>
		</AutoForm>
	);
}
