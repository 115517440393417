import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import {getOrThrow} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import SchoolEntity from '_entity/SchoolEntity';


export default class Comment extends SchoolEntity {
	get user(): Student{
		return entityResolver(this.getDataValue('user'), Student);
	}

	get original(): Comment{
		return entityResolver(this.getDataValue('original'), Comment);
	}

	get body(): string{
		return this.getDataValue('body');
	}

	get liked(): boolean{
		return this.getDataValue('is_liked');
	}

	get info(){
		const data = this.getData();

		return {
			get count_likes(){
				return getOrThrow(data, 'count_likes');
			},
			get count_replies(){
				return getOrThrow(data, 'count_replies');
			},
		};
	}
}
