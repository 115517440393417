import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import Student from '_entity/Acess/Student';
import Content from '_entity/Course/Module/Item/Content/Content';
import Question from '_entity/Course/Module/Item/Content/Question/Question';
import Item from '_entity/Course/Module/Item/Item';
import {QuestionReducerUtils} from '_reducer/Course/Module/Content/Question/QuestionReducer';
import {AnsweredQuestionReducerUtils} from '_reducer/Progress/AnsweredQuestionReducer';
import SchoolQuestion from 'app/components/new/Content/Item/SchoolQuestion';
import ItemInfoElement from 'app/pages/StudentView/Content/Element/ItemInfoElement';
import SchoolLoader from 'app/SchoolLoader';
import {useSchoolCourseAlert} from 'hoc/alert';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

QuestionElement.propTypes = {
	content: PropTypes.instanceOf(Content).isRequired,
	toItem: PropTypes.func,
};

/**
 *
 * @param {Content} content
 * @param toItem
 * @param refreshPoints
 * @returns {*}
 * @constructor
 */
function QuestionElement({content, toItem, refreshPoints}){
	const [redo, setRedo] = useState();
	const dispatch = useDispatch();
	const questionState: ReducerStateType = useSelector(QuestionReducerUtils.read.map);
	const answerState: ReducerStateType = useSelector(AnsweredQuestionReducerUtils.answer.map);
	const feedbackState: ReducerStateType = useSelector(AnsweredQuestionReducerUtils.answerFeedback.map);
	// const answer: AnsweredQuestion = answerState.data;

	// const finished = answerState.finished;
	// const error = answerState.error;
	const user: Student = useAppitUserAwareness();

	const items = content.module.items;
	const currentIndex = items.findIndex(i => i.id === content.id);
	const nextItem: Item = items[currentIndex + 1];

	const sendAnswer = useCallback((alternativeID) => AnsweredQuestionReducerUtils.answer.dispatch(dispatch, content.id, alternativeID), [
		dispatch,
		content.id,
	]);
	const showFeedback = useCallback(() => AnsweredQuestionReducerUtils.answerFeedback.dispatch(dispatch, content.id), [
		dispatch,
		content.id,
	]);
	const Alerts = useSchoolCourseAlert(user.course, content.module);
	const goPrevious = useCallback(
		() => content && toItem(content),
		// eslint-disable-next-line
		[content && content.id],
	);
	const goNext = useCallback(
		() => nextItem && toItem(nextItem),
		// eslint-disable-next-line
		[nextItem && nextItem.id],
	);

	useEffect(() => {
		QuestionReducerUtils.read.dispatch(dispatch, content.id);

		return () => {
			// QuestionReducerUtils.read.dispatch_clear(dispatch);
		};
	}, [dispatch, content.id]);

	useEffect(() => {
		if(answerState.error){
			Alerts.Feedback('Não foi possível responder a pergunta', 'Tente novamente mais tarde');
		}
		else if(answerState.finished){
			showFeedback();
		}
	}, [answerState, Alerts, showFeedback]);


	useEffect(() => {
		const {error, finished} = feedbackState;
		let {feedback, correct, points, last} = feedbackState.data || {};

		if(!finished){
			return;
		}

		if(error){
			Alerts.Feedback('Não foi possível visualizar o feedback');
		}
		else{
			if(!feedback){
				feedback = correct ? 'Sua resposta foi correta.' : 'Você não conseguiu dessa vez.';
			}
			if(points && points > 0){
				feedback = (<div className="d-flex flex-column">
					<div>
						{feedback}
					</div>
					<div className="mt-1 text-success small">
						Você ganhou <strong>{points} {user.course.points_name}</strong>
					</div>
				</div>);
			}
			const top = correct ? 'Parabéns!' : 'Que pena...';
			if(last){
				Alerts.Feedback(top, feedback, () => {
					refreshPoints();
					AnsweredQuestionReducerUtils.answer.dispatch_clear(dispatch);
					AnsweredQuestionReducerUtils.answerFeedback.dispatch_clear(dispatch);
					goPrevious();
				});
			}
			else{
				Alerts.FeedbackWithCancel(
					top,
					feedback,
					'Rever vídeo',
					({isConfirmed}) => {
						refreshPoints();
						AnsweredQuestionReducerUtils.answer.dispatch_clear(dispatch);
						AnsweredQuestionReducerUtils.answerFeedback.dispatch_clear(dispatch);
						if(isConfirmed){
							goPrevious();
						}
						else{
							goNext();
						}
					},
				);
			}
		}
	}, [dispatch, refreshPoints, feedbackState, Alerts, toItem, goPrevious,goNext,user.course.points_name]);


	if(!questionState.finished){
		return <SchoolLoader />;
	}
	const question: Question = questionState.data;

	return (
		<SchoolQuestion
			question={question}
			last_answer={question.last_answer}
			onSubmit={sendAnswer}
			sending={answerState.begined && !feedbackState.finished}
			retry={redo}
			tryNumber={question.info.count_my_answers}
			previousHandler={() => goPrevious()}
			nextHandler={() => goNext()}
			nextHandlerDisabled={!nextItem}
			retryHandler={() => setRedo(true)}
			retryDisabled={question.locked}
			retryShowing={question.is_answered || answerState.error}
		>
			<ItemInfoElement element={question} />
		</SchoolQuestion>
	);
}

export default QuestionElement;
