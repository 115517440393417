import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import ContentFile from '_entity/Course/Module/Item/Content/ContentFile';
import {ContentFileReducerUtils} from '_reducer/Course/Module/Content/ContentFileReducer';
import {EmptyMessage} from 'app/components/message/messages';
import WidgetSearch from 'app/pages/StudentView/Content/Widgets/WidgetSearch';
import SchoolLoader from 'app/SchoolLoader';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

/**
 *
 * @param {Content} content
 * @returns {*}
 * @constructor
 */
function WidgetFiles({content}){
	const dispatch = useDispatch();
	const listState = useSelector(ContentFileReducerUtils.list.map);
	const files: ContentFile[] = listState.data;
	const contentID = content.id;

	useEffect(() => {
		ContentFileReducerUtils.list.dispatch(dispatch, contentID);
		return () => {
			ContentFileReducerUtils.list.dispatch_clear(dispatch);
		};
	}, [dispatch, contentID]);

	if(!listState.finished){
		return <SchoolLoader basic />;
	}

	return (
		<div className="files">
			<div className="file-separator" />
			<WidgetSearch
				className="file-entries"
				items={files.sort((a: ContentFile, b: ContentFile) => {
					if(a.file_name > b.file_name){
						return 1;
					}
					if(a.file_name > b.file_name){
						return -1;
					}

					return 0;
				})}
				filter={['name', 'tags']}
				empty={<EmptyMessage message="Nenhum arquivo disponível" />}
			>
				{(file: ContentFile) => <Tooltiper tooltip={file.file_name} key={file.id}>
					<a
						href={asset(file.src)}
						target="_blank"
						rel="noreferrer noopener"
						className="file-entry"
					>
						<i className={file.icon} />
					</a>
				</Tooltiper>}
			</WidgetSearch>
		</div>
	);
}

export default WidgetFiles;
