import Course from '_entity/Course/Course';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import PropTypes from 'prop-types';
import React from 'react';

class OpenCloseCourseForm extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course)
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (values) => {

		return values;
	};

	getAlertTitle(): string{
		return `Matriculas ${this.props.course.is_open ? "fechadas" : "abertas"} com sucesso`;
	}

	getAlertMessage(): string{
		return 'Informações foram salvas!';
	}

	form(){
		const initial = {
			password: "",
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Confirmar"
				submittingText="Confirmando..."
			>
				<SchoolFormSimpleField name="password" label="Senha" type="password" autoComplete="new-password"/>
			</SchoolForm>
		);
	}
}

export default OpenCloseCourseForm;