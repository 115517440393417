import {createBridge} from '@appit.com.br/react-misc/src/uniform/AppitUniform';
import FormStudent from '_form/Access/FormStudent';
import {SubmitField} from '_form/SubmitField';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import {Stamp} from '_ui/EntityUi';
import AvatarElement from 'app/components/avatar/AvatarElement';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import SchoolPaginator from 'app/components/Paginator/SchoolPaginator';
import CoursePage from 'app/pages/CourseView/CoursePage';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import AccessButton from 'app/pages/CourseView/Student/Buttons/AccessButton';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {createRef, useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, InputGroup} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams} from 'react-router-dom';
import {AutoField, AutoForm} from 'uniforms-bootstrap4';

export const CourseStudentBreadcrumb = (action) => {
	if(action === 'add'){
		return [
			...CourseStudentBreadcrumb('list'),
			{path: CourseRoutes.STUDENT_ACTIONS.replace(':action', 'add'), text: 'Adicionar aluno'},
		];
	}

	return [
		{path: CourseRoutes.STUDENT_ACTIONS.replace(':action', 'list'), text: 'Alunos'},
	];
};

function CourseStudentPage(props){
	const {action} = useParams();
	const dispatch = useDispatch();
	const create = (data) => StudentReducerUtils.create.dispatch(dispatch, data);
	const createState = useSelector(StudentReducerUtils.create.map);
	const listState = useSelector(StudentReducerUtils.listPage.map);
	const students = listState.data || [];
	const [status, setStatus] = useState('');
	const [page, setPage] = useState(0);
	const [query, setQuery] = useState('');
	const list = useCallback((page = 0, query = '', status = '') => {
		StudentReducerUtils.listPage.dispatch(dispatch, page,status, query);
	}, [dispatch]);

	useEffect(() => {
		list(page, query, status);
	}, [list, query, page, status]);

	useEffect(() => {
		if(action === 'list'){
			list();

			return () => {
				StudentReducerUtils.listPage.dispatch_clear(dispatch);
				StudentReducerUtils.create.dispatch_clear(dispatch);
			};
		}

		return () => {
			StudentReducerUtils.create.dispatch_clear(dispatch);
		};
	}, [dispatch, action, list]);

	if(action === 'list' && !listState.finished){
		return <TemplateSplash />;
	}

	// console.log(listState)
	const pagination = listState.pagination;

	return (
		<CoursePage
			breadcrumb={CourseStudentBreadcrumb(action)}
			actions={action === 'list' && (
				<LinkContainer to={CourseRoutes.STUDENT_ACTIONS.replace(':action', 'add')}>
					<Button variant="primary">
						Adicionar Aluno
					</Button>
				</LinkContainer>
			)}
		>
			{action === 'add' && (
				<NewSchoolBreadcrumbHeaderConsumer>
					{({pushPrevious}) => <FormStudent
						onSubmit={create}
						success={createState.finished}
						error={createState.error}
						afterSuccessAlert={pushPrevious}
					/>}
				</NewSchoolBreadcrumbHeaderConsumer>
			)}
			{action === 'list' && (<>
				<ButtonGroup toggle className="mb-2 btn-block">
					<Button active={!status} onClick={() => setStatus('')} variant="outline-dark" >Todos</Button>
					<Button active={'completed' === status} onClick={() => setStatus('completed')} variant="outline-success" >Pago</Button>
					<Button active={'pending' === status} onClick={() => setStatus('pending')} variant="outline-warning" >Pendente</Button>
					<Button active={'canceled' === status} onClick={() => setStatus('canceled')} variant="outline-danger" >Cancelado</Button>
					<Button active={'not_started' === status} onClick={() => setStatus('not_started')} variant="outline-danger" >Não iniciado</Button>
					<Button active={'ignore' === status} onClick={() => setStatus('ignore')} variant="outline-info" >Ignorado</Button>
				</ButtonGroup>
				<Search query={query} onSubmit={({query}) => setQuery(query)} />
				<SchoolPaginator pagination={pagination} page={page} setPage={setPage} />
				<Table>
					<tbody>
					{students.map(student => (
						<tr key={student.id}>
							<td className="w-0 align-middle action-cell">
								<LinkContainer to={CourseRoutes.STUDENT_SHOWING.replace(':id', student.id)}>
									<Button variant="outline-primary">
										<i className="fa fa-eye" />
									</Button>
								</LinkContainer>
							</td>
							<td className="avatar-cell">
								<AvatarElement src={student.avatar} />
							</td>
							<td>
								<div>
									{student.name}
								</div>
								<div className="text-muted small">
									{student.email}
								</div>
								<div className="small text-muted">
									Pagamento: {student.payment.ignore ? 'N/A' : student.payment.status_label}
								</div>
							</td>
							<td className="w-0 text-nowrap text-right">
								<Stamp entity={student} />
								<div>
									<small className="mr-1">Ultimo acesso:</small>
									<small>
										{!!student.last_access ? (<>
											{student.last_access.stamp_create.toLocaleDateString('BR')}
											<span className="mx-1">às</span>
											{student.last_access.stamp_create.toLocaleTimeString('BR')}
										</>) : 'N/A'}
									</small>
								</div>
							</td>
							<td className="w-0 text-nowrap text-right">
								<AccessButton
									student={student}
									onGrant={list}
									onRevoke={list}
								/>
							</td>
						</tr>
					))}
					</tbody>
				</Table>
				<SchoolPaginator pagination={pagination} page={page} setPage={setPage} />
			</>)}
		</CoursePage>
	);
}

export default CourseStudentPage;

Search.propTypes = {};

function Search({onSubmit, query}){
	const ref = createRef();
	const bridge = createBridge({
		title: '',
		type: 'object',
		properties: {
			query: {
				type: 'string',
				label: false,
				placeholder: 'Pesquisar...',
			},
		},
	});

	return (
		<AutoForm
			ref={ref}
			schema={bridge}
			model={{query}}
			onSubmit={onSubmit}
			placeholder={true}
			label={true}
		>
			<InputGroup className="mb-3">
				<AutoField name="query" className="mb-0 flex-grow-1 rounded-right-0" inputClassName="form-control-lg" />
				<InputGroup.Append>
					<SubmitField primary noWrap>Pesquisar</SubmitField>
				</InputGroup.Append>
			</InputGroup>
		</AutoForm>
	);
}
