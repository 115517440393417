import SchoolEntity from '_entity/SchoolEntity';

export default class CourseIntegration  extends SchoolEntity{
	get key(){
		return this.getDataValue('hidden_key')
	}

	get email(){
		return this.getDataValue('hidden_email')
	}

	get type(){
		return this.getDataValue('type')
	}

	get name(){
		switch(this.type){
			case "pagseguro":
				return "PagSeguro"
			case "royalpag":
				return "RoyalPag"
			default:
				return 'Desconhecido';
		}
	}

	get image(){
		switch(this.type){
			case "pagseguro":
				return "https://assets.pagseguro.com.br/ps-bootstrap/v6.73.3/img/logos/pagbank/pagbank-logo-animado_35px.gif"
			case "royalpag":
				return 'https://royalpag.com/images/logo-header.png'
			default:
				return null;
		}
	}
}