import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import Content from '_entity/Course/Module/Item/Content/Content';
import SchoolEntity from '_entity/SchoolEntity';

export default class ContentFile extends SchoolEntity {

	get tags(): string[]{
		return ifNull(this.getDataValue('tags'), []);
	};

	get file_name(): string{
		return this.getDataValue('file_name');
	};

	get src(): string{
		return this.getDataValue('src');
	}

	get file_extension(): string{
		return this.getDataValue('file_extension');
	};

	get icon(): string{
		switch(this.file_extension){
			case 'zip':
				return 'fa fa-file-zip';
			case 'rar':
				return 'fa fa-file-archive';
			case 'doc':
			case 'docx':
				return 'fa fa-file-word';
			case 'xls':
			case 'xlsx':
				return 'fa fa-file-excel';
			case 'csv':
				return 'fa fa-file-csv';
			case 'pdf':
				return 'fa fa-file-pdf';
			default:
				return 'fa fa-file';
		}
	}

	get content(): Content{
		return entityResolver(this.getDataValue('content'), Content);
	}
}
