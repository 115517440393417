import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import ContentFile from '_entity/Course/Module/Item/Content/ContentFile';
import {ContentFileReducerUtils} from '_reducer/Course/Module/Content/ContentFileReducer';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

/**
 *
 * @param {Content} content
 * @param afterDelete
 * @returns {*}
 * @constructor
 */
function FileSection({content, afterDelete}){
	const dispatch = useDispatch();
	const remove = id => ContentFileReducerUtils.delete.dispatch(dispatch, id);
	const removeState = useSelector(ContentFileReducerUtils.delete.map);

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Arquivo deletado!`,
				successAction: afterDelete,
				finished: removeState.finished,
				error: removeState.error,
			});
		},
		// eslint-disable-next-line
		[removeState],
	);

	return (<>
		<ListGroup className="text-left align-sef-end">
			{content.files.map((file: ContentFile) => (
				<ListGroup.Item key={file.id} className="d-flex align-items-center justify-content-between px-5">
					<Tooltiper tooltip={file.tags.join(', ')}>
						<a href={asset(file.src)} target="_blank" rel="noreferrer noopener">
							{file.file_name}
						</a>
					</Tooltiper>
					<Button
						className="btn-right"
						size="sm" variant="danger" onClick={() => SchoolConfirmAlert({
						title: 'Realmente deseja deletar esse arquivo?',
						action: () => remove(file.id),
					})}
					>
						<i className="fa fa-trash" />
					</Button>
				</ListGroup.Item>
			))}
		</ListGroup>
		<LinkContainer to={AdminRoutes.CONTENT_FILE_CREATING.replace(':id', content.id)}>
			<Button variant="primary" className="mt-3">
				Adicionar arquivo
			</Button>
		</LinkContainer>
	</>);
}

export default FileSection;
