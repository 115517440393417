import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import CourseIntegrationApi from '_api/Course/CourseIntegrationApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const CourseIntegrationReducerOptions = new SchoolReducerOptions('course_integration',CourseIntegrationApi);

/**
 *
 * @type {{
 *     create: ReducerBasicOption
 *     remove: ReducerBasicOption
 * }}
 */
export const CourseIntegrationReducerUtils:$ObjMap<string,ReducerBasicOption> = CourseIntegrationReducerOptions.options();
