import {getOrThrow, ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Item from '_entity/Course/Module/Item/Item';
import Module from '_entity/Course/Module/Module';
import {getLast} from 'hoc/utils';

class LearningObject extends Item {

	get src(){
		return this.getDataValue('src');
	}
	get actions(){
		const map = this.getDataValue('map')

		return ifEmpty(getOrThrow(map,"actions"),[]);
	}

	get module():Module{
		return this.getDataValue('module', Module);
	}

	get wanted(){
		const wanted = this.getDataValue('wanted');

		return {
			get points(){
				return getOrThrow(wanted,"points");
			},
			get badges(){
				return getOrThrow(wanted,"badges");
			},
			get item_progress(){
				return getOrThrow(wanted,"item_progress");
			},
			get module_progress(){
				return getOrThrow(wanted,"module_progress");
			},
			get step(){
				return getOrThrow(wanted,"step");
			},
		}
	}

	get feedback(){
		const feedback = this.getDataValue('feedback');

		return {
			get earnings(){
				return getOrThrow(feedback,"earnings");
			},
			get losses(){
				return getOrThrow(feedback,"losses");
			},
			get badges(){
				return getOrThrow(feedback,"badges");
			},
			get hits(){
				return getOrThrow(feedback,"hits");
			},
		}
	}

	get force_landscape(){
		return this.getDataValue('force_landscape')
	}
}

LearningObject.create = (module: Module) => new LearningObject({
	name: '',
	module: module,
	order: getLast(module.items, {order:0}).order + 1,
	map: {
		actions: [],
		type: "",
	},
	reference_items: [],
	feedback: {
		earnings: false,
		losses: false,
		badges: false,
		hits: false,
	},
	wanted: {
		points:false,
		badges:false,
		item_progress:false,
		module_progress:false,
		step:false,
	},
});

LearningObject.WantedNames = {
	'points': 'Pontos adiquiridos',
	'badges': 'Badges conquistas',
	'item_progress': 'Se foi concluído',
	'module_progress': 'Progresso no módulo',
	'step': 'Progresso no objeto de aprendizagem',
};
LearningObject.WantedDescription = {
	'module_progress': 'Progresso de conteúdos/objetos de aprendizados e quantidade de tentativas e respostas certas nas perguntas',
	'step': 'Progresso no objeto de aprendizagem',
};
LearningObject.FeedbackNames= {
	earnings: "Ganhos",
	losses: "Perdas",
	badges: "Badges",
	hits: "Acertos",
}

export default LearningObject;
