import SchoolApi from '_api/SchoolApi';
import {ItemClassSelector} from '_entity/Course/Module/Item/ItemSelector';

export default class ItemApi extends SchoolApi {
	get namespace(){
		return 'items';
	}

	get entity_class(){
		return ItemClassSelector;
	}

	read(id){
		return this.json.get(id);
	}

	remove(id){
		return this.json.delete(id);
	}
}