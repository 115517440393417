import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import NewPage from 'app/pages/NewPage';

import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

LogoutPage.propTypes = {};

function LogoutPage(props){
	const dispatch = useDispatch();

	useEffect(() => {
		SchoolAuthReducerUtils.logout.dispatch(dispatch);
	}, [dispatch]);

	return (
		<NewPage title="Logout" contentClass="login">
			Você sera redireacionado a qualquer momento...
		</NewPage>
	);
}

export default LogoutPage;