// import Content from '_entity/Course/Module/Item/Content/Content';
import ContentGlossary from '_entity/Course/Module/Item/Content/ContentGlossary';
import {ContentGlossaryReducerUtils} from '_reducer/Course/Module/Content/ContentGlossaryReducer';
import {EmptyMessage} from 'app/components/message/messages';
import WidgetSearch from 'app/pages/StudentView/Content/Widgets/WidgetSearch';
import SchoolLoader from 'app/SchoolLoader';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

/**
 *
 * @param {Content} content
 * @returns {*}
 * @constructor
 */
function WidgetGlossaries({content}){
	const dispatch = useDispatch();
	const listState = useSelector(ContentGlossaryReducerUtils.list.map);
	const glossaries: ContentGlossary[] = listState.data;
	const contentId = content.id;

	useEffect(() => {
		ContentGlossaryReducerUtils.list.dispatch(dispatch, contentId);
		return () => {
			ContentGlossaryReducerUtils.list.dispatch_clear(dispatch);
		};
	}, [dispatch, contentId]);

	if(!listState.finished){
		return <SchoolLoader basic />;
	}


	return (
		<div className="tips">
			<WidgetSearch
				className="tip-list"
				filter={['tags', 'title', 'description']}
				items={glossaries.sort((a:ContentGlossary,b:ContentGlossary) => {
					if(a.title > b.title){
						return 1
					}
					if(a.title < b.title){
						return -1
					}

					return 0;
				})}
				empty={<EmptyMessage message="Nenhum glossario disponível" />}
			>
				{tip => (
					<div key={tip.id} className="tip-entry">
						<div className="tip-header">{tip.title}</div>
						<div className="tip-body">{tip.description}</div>
					</div>
				)}
			</WidgetSearch>
		</div>
	);
}

export default WidgetGlossaries;