import Comment from '_entity/Social/Comment';
import Like from '_entity/Social/Like';
import CommentForm from '_form/Social/CommentForm';
import {CommentReducerUtils} from '_reducer/Social/CommentReducer';
import {LikeReducerUtils} from '_reducer/Social/LikeReducer';
import ForumReplyEntry from 'app/components/new/Content/Footer/Forum/ForumReplyEntry';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {connect} from 'react-redux';

class ForumReply extends React.PureComponent {
	static propTypes = {
		hideAvatar: PropTypes.bool,
		hideUser: PropTypes.bool,
		comment: PropTypes.instanceOf(Comment),
		left: PropTypes.bool,
	};

	static defaultProps = {
		hideAvatar: false,
		hideUser: false,
		left: true,
	};

	state = {
		replies: [],
		replying: false,
		isListingReplies: false,
		count_replies: this.props.comment.info.count_replies,
		count_likes: this.props.comment.info.count_likes,
	};

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void{
		if(this.props.listRepliesState.finished && !prevProps.listRepliesState.finished){
			if(this.props.listRepliesState.finished){
				const possibleReplies = [...this.props.listRepliesState.data];
				const original = possibleReplies.shift();
				if(!!original && original.id === this.props.comment.id){
					this.setState({
						replies: possibleReplies,
						count_replies: possibleReplies.length,
						isListingReplies: true,
					});
				}
			}
		}
		if(this.props.likeState.finished && !prevProps.likeState.finished){
			const like: Like = this.props.likeState.data;
			if(this.props.comment.id === like.comment.id){
				this.setState({
					count_likes: this.props.comment.info.count_likes,
				});
			}
		}
	}

	render(){
		const {isListingReplies, replying} = this.state;
		const {left} = this.props;
		const comment: Comment = this.props.comment;
		const replies: Comment[] = this.state.replies;
		const listReplies = () => {
			this.setState({replying: false});
			this.props.listReplies(comment.id);
		};

		if(isListingReplies){
			// console.log(this.props.text);
		}

		return (
			<ForumReplyEntry
				body={comment.body}
				left={left}
				avatar={comment.user.avatar}
				name={comment.user.name}
				actions={<>
					<ButtonGroup>
						<Button
							variant="success" onClick={() => {
							this.props.like(comment.id);
						}}
						>
							<span className="meta">
								{this.state.count_likes}
							</span>
							<i className="fa fa-thumbs-up" />
						</Button>
						<Button
							disabled={replying}
							variant="dark"
							onClick={() => this.setState({replying: !this.state.replying})}
						>
							<i className="fa fa-reply" />
							<span className="meta">
								{this.state.count_replies}
							</span>
						</Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button variant="outline-primary" onClick={listReplies}>
							Exibir respostas
						</Button>
					</ButtonGroup>
				</>}
			>
				{replies.map(reply =>
					<ForumReplyComponent key={reply.id} comment={reply} left={!this.props.left} />,
				)}
				{replying && (
					<CommentForm
						success={this.props.replyState.finished}
						error={this.props.replyState.error}
						afterSuccessAlert={listReplies}
						onSubmit={data => this.props.reply(comment.id, data)}
					/>
				)}
			</ForumReplyEntry>
		);
	}
}

const mapStateToProps = (state) => ({
	likeState: LikeReducerUtils.create.map(state),
	listRepliesState: CommentReducerUtils.listReplies.map(state),
	replyState: CommentReducerUtils.createReply.map(state),
});

const mapDispatchToProps = dispatch => ({
	like: (commentID) => LikeReducerUtils.create.dispatch(dispatch, commentID),
	listReplies: commentID => CommentReducerUtils.listReplies.dispatch(dispatch, commentID),
	reply: (commentID, data) => CommentReducerUtils.createReply.dispatch(dispatch, commentID, data),
});

const ForumReplyComponent = connect(mapStateToProps, mapDispatchToProps)(ForumReply);
export default connect(mapStateToProps, mapDispatchToProps)(ForumReply);