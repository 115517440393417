import React from 'react';
import InlineSVG from 'react-inlinesvg';

/**
 *
 * @param {SchoolEntity} entity
 * @returns {*}
 * @constructor
 */
export const Stamp = ({entity}) => {
	return (
		<>
			<div>
				<small className="mr-1">Criado:</small>
				<small>
					{!!entity.stamp_create && (<>
						{entity.stamp_create.toLocaleDateString('BR')}
						<span className="mx-1">às</span>
						{entity.stamp_create.toLocaleTimeString('BR')}
					</>)}
				</small>
			</div>
			<div>
				<small className="mr-1">Alterado:</small>
				<small>
					{!!entity.stamp_change && (<>
						{entity.stamp_change.toLocaleDateString('BR')}
						<span className="mx-1">às</span>
						{entity.stamp_change.toLocaleTimeString('BR')}
					</>)}
				</small>
			</div>
		</>
	);
};

export const SvgOrImage = ({src, className}) => {
	return (
		<InlineSVG src={src} className={className}>
			<img src={src} className={className} alt="" />
		</InlineSVG>
	);
};

