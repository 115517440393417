import User from '_entity/Acess/User';
import FormEntity from '_form/FormEntity';
import AvatarElement from 'app/components/avatar/AvatarElement';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default class CommentForm extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		user: PropTypes.instanceOf(User),
		submitting: PropTypes.bool,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (data) => {
		return data;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];
			if(!value){
				errors[key] = true;
			}
		}

		return errors;
	};

	hideLoader(): boolean{
		return true;
	}

	form(){
		const user: User = this.props.user;
		const done = !this.props.submitting;

		return (
			<SchoolForm
				className="forum-reply"
				onSubmit={this.onSubmit}
				validate={this.validate}
				initialValues={{
					body: '',
				}}
				hideSubmit
				asFunc
			>
				{({isSubmitting,setFieldValue}) => (
					<Form.Group className="forum-reply-content">
						{!!this.props.user && (
							<div className="forum-reply-meta">
								<div className="forum-reply-avatar">
									<AvatarElement as="img" src={user.avatar} />
								</div>
								<span className="forum-reply-user">
									{user.name}
								</span>
							</div>
						)}
						<div className="forum-reply-input">
							<SchoolFormSimpleField
								component="textarea"
								name="body"
								placeholder="Digite a mensagem..."
								size="lg"
								withoutGroup
								withoutLabel
							/>
							<Button
								type="submit"
								variant="primary"
								size="lg"
								disabled={!done && isSubmitting}
								className="overflow-hidden"
							>
								{!done && isSubmitting
									? <i className="fa fa-spinner fa-spin" />
									: <i className="fa fa-paper-plane" />
								}
							</Button>
						</div>
					</Form.Group>
				)}
			</SchoolForm>
		);
	}
}