const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
const eventer = window[eventMethod];

const removeMethod = window.addEventListener ? 'removeEventListener' : 'detachEvent';
const remover = window[removeMethod];

const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

export type IframeUtilsType = {
	stop: () => any;
	start: () => any;
	// height: ActionType;
	// save: ActionType;
	// step: ActionType;
	// clear: ActionType;
	// multiple_data: ActionType;
	// answer: ActionType;
}

let STARTED = false;

/**
 *
 * @param callbacks
 * @param log
 * @returns {IframeUtilsType}
 */
export default (callbacks={}, log = false) => {
	const types = ['height', 'save', 'step', 'clear', 'multiple_data', 'answer',"save_on_step"];
	// const locked = {}
	function eventCallback(event){
		// console.log("fuck")
		const {data} = event;
		if(types.includes(data.type)){
			console.log("Message found",data.type,event)
			const callback = callbacks[data.type];
			if(log){
				console.log(data);
			}
			if(!!callback && !callback(data.payload)){
				return;
			}

			event.source.postMessage({
				type: 'ack',
				payload: data.type,
			}, '*');
		}
		else if(log){

		}
	}

	return {
		stop: () => {
			remover(messageEvent,eventCallback,true)
			STARTED = false;
		},
		start: () => {
			if(!STARTED){
				eventer(messageEvent, eventCallback, true);
			}
			STARTED = true;
		},
	};
};

export const iframeTX = (window,type, payload = null,onAck) => {
	const saveInterval = setInterval(function(){
		// console.log('sending',type)
		window.postMessage({
			type: type,
			payload: payload,
		}, '*');
	}, 300);

	eventer(messageEvent, (e) => {
		var data = e.data;
		if(data.type === 'ack' && data.payload === type){
			// console.log('sending stopped',type)
			clearInterval(saveInterval);
			if(typeof onAck === 'function'){
				onAck();
			}
		}
	});
}