import Content from '_entity/Course/Module/Item/Content/Content';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';

export const ItemClassSelector = {
	getClass(data){
		if(data.type === 'content'){
			return Content;
		}
		if(data.type === 'learning_object'){
			return LearningObject;
		}
	},
};