import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import {faCogs, faGraduationCap, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CourseUser from '_entity/Acess/CourseUser';
import SchoolSidebarLeft from 'app/components/new/Content/SchoolSidebarLeft';
import NewSchoolBreadcrumbHeader from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import PageLogo from 'app/components/PageLogo';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import NewPage from 'app/pages/NewPage';
import {CourseAwarenessProvider} from 'app/pages/StudentView/Access/CourseAwareness';
import icon_faq from 'assets/menu/faq.svg';
import icon_range from 'assets/menu/range.svg';
import React, {useEffect} from 'react';
import Card from 'react-bootstrap/Card';

function CoursePage({breadcrumb, actions, doubleTitle, title, className, children, pageClassName}){
	const user: CourseUser = useAppitUserAwareness();
	const course = user.course;
	if(!title && breadcrumb.length > 0){
		title = breadcrumb[breadcrumb.length - 1].text;
		if(doubleTitle && breadcrumb.length > 1){
			title = breadcrumb[breadcrumb.length - 2].text + ' - ' + title;
		}
	}
	useEffect(() => {
		document.title = title ? `${course.name} - ${title}` : course.name;
	}, [title, course]);

	return (
		<CourseAwarenessProvider course={course}>
			<NewPage
				containerClass="d-flex justify-content-strech"
				className={pageClassName}
				showLogout={true}
				logo={asset(course.image)}
				title={<PageLogo course={course} />}
				sidebarLeft={<SchoolSidebarLeft
					items={{
						[CourseRoutes.STUDENT_ACTIONS.replace(':action', 'list')]: {
							isTo: true,
							icon: <FontAwesomeIcon icon={faGraduationCap}/>
						},
						[CourseRoutes.FAQ_ACTIONS.replace(':action', 'list')]: {
							isTo: true,
							icon: icon_faq,
							svg: true,
						},
						[CourseRoutes.RANGE_ACTIONS.replace(":action",'list')]: {
							isTo: true,
							icon: icon_range,
							svg: true,
						},
						[CourseRoutes.SETTINGS]: {
							isTo: true,
							icon:  <FontAwesomeIcon icon={faCogs} />,
						},
						[CourseRoutes.INFO]: {
							isTo: true,
							icon:  <FontAwesomeIcon icon={faInfoCircle} />,
						},
					}}
				/>}
			>
				<NewSchoolBreadcrumbHeader links={breadcrumb}>
					<div className="container">
						<Card>
							<Card.Header className="d-flex flex-row">
								<Card.Title>
									{title}
								</Card.Title>
								<div className="div ml-auto">
									{actions}
								</div>
							</Card.Header>
							<Card.Body className={className}>
								{children}
							</Card.Body>
						</Card>
					</div>
				</NewSchoolBreadcrumbHeader>
			</NewPage>
		</CourseAwarenessProvider>
	);
}

export default CoursePage;
