import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';

export const getLast = (arr: [], defaultValue) => arr.length > 0 ? ifNull(arr[arr.length - 1], defaultValue) : defaultValue;

export const getHeight = () => {
	return document.getElementById('#container').offsetHeight;
};

export const getWidth = () => {
	return document.getElementById('#content').offsetWidth;
};

export const toTime = (duration) => {
	let minutes = Math.floor(duration / 60).toString();
	let seconds = Math.ceil(duration % 60).toString();
	if(minutes.length === 1){
		minutes = `0${minutes}`;
	}
	if(seconds.length === 1){
		seconds = `0${seconds}`;
	}

	return `${minutes}:${seconds}`;
};

export const openPopup = (url,target="Popup") => {
	const popup = window.open(url, target, 'height=450,width=450');
	if(popup.focus){
		popup.focus();
	}
};
