import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import RewardMapApi from '_api/Game/RewardMapApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const RewardMapReducerOptions = new SchoolReducerOptions('reward_map', RewardMapApi);
/**
 *
 * @type {{
 *     remove: ReducerBasicOption
 *     read: ReducerBasicOption
 *     createForItem: ReducerBasicOption
 *     createForModule: ReducerBasicOption
 *     createForCourse: ReducerBasicOption
 * }}
 */
export const RewardMapReducerUtils: $ObjMap<string, ReducerBasicOption> = RewardMapReducerOptions.options();
