import Student from '_entity/Acess/Student';
import SchoolEntity from '_entity/SchoolEntity';


export default class StudentAccess extends SchoolEntity {

	get student(): Student{
		return this.getDataValue('student',Student);
	}

	get ip(){
		return this.getDataValue("ip")
	}

}