import Course from '_entity/Course/Course';
import BadgeType from '_entity/Game/Badge';
import {BadgeTypeReducerUtils} from '_reducer/Game/BadgeTypeReducer';
import {SvgOrImage} from '_ui/EntityUi';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolOperationAlert} from 'hoc/alert';
import Tooltiper from 'hoc/ui/Tooltiper';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {ButtonGroup, ListGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

BadgeSection.propTypes = {
	course: PropTypes.instanceOf(Course),
};

/**
 *
 * @param {Course} course
 * @param {Function} afterDelete
 * @param children
 * @returns {*}
 * @constructor
 */
function BadgeSection({course, afterDelete, children}){
	const dispatch = useDispatch();
	//eslint-disable-next-line
	const remove = (id) => BadgeTypeReducerUtils.remove.dispatch(dispatch, id);
	const removeState = useSelector(BadgeTypeReducerUtils.remove.map);
	const addPath = AdminRoutes.BADGE_CREATING.replace(':id', course.id);

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Badge deletado!`,
				finished: removeState.finished,
				error: removeState.error,
				successAction: () => {
					afterDelete();
					BadgeTypeReducerUtils.remove.dispatch_clear(dispatch);
				},
			});
		},
		//eslint-disable-next-line
		[dispatch, removeState],
	);

	return (
		<>
			<ListGroup horizontal>
				{course.badges.map((badge: BadgeType) => (
					<ListGroup.Item
						className="p-0 d-flex flex-column flex-grow-1"
						style={{maxWidth: '300px'}}
						key={badge.id}
					>
						<Tooltiper
							tooltip={<div>
								<div>{badge.description}</div>
								<div className="text-muted">{badge.slug}</div>
							</div>}
							placement="top"
						>
							<div className="p-3 text-uppercase h5 mb-0 flex-grow-1">
								<SvgOrImage src={badge.image} className="square-75px" />
								{badge.name}
							</div>
						</Tooltiper>
						<ButtonGroup>
							<LinkContainer to={AdminRoutes.BADGE_EDITING.replace(':id', badge.id)}>
								<Button
									size="sm"
									variant="secondary"
									className="rounded-0"
								>
									<i className="fa fa-edit" />
								</Button>
							</LinkContainer>
						</ButtonGroup>
					</ListGroup.Item>
				))}
			</ListGroup>
			{children}
			<LinkContainer to={addPath}>
				<Button variant="primary" className="mt-3">
					Adicionar Badge
				</Button>
			</LinkContainer>
		</>
	);
}

export default BadgeSection;