import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import Content from '_entity/Course/Module/Item/Content/Content';
import SchoolEntity from '_entity/SchoolEntity';


export default class ContentTip extends SchoolEntity {
	get title(): string{
		return this.getDataValue('title');
	}

	get description(): string{
		return this.getDataValue('description');
	}

	get tags(): string[]{
		return this.getDataValue('tags');
	}

	get content(): Content{
		return entityResolver(this.getDataValue('content'), Content);
	}
}
