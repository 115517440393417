import Course from '_entity/Course/Course';
import FormEntity from '_form/FormEntity';
import BadgeSelect from '_form/Game/BadgeSelect';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolSelect from 'app/components/form/SchoolSelect';
import {Field} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

export default class FormRewardMap extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course).isRequired,
		showOnView: PropTypes.bool,
		showOnStep: PropTypes.bool,
		showOnRanking: PropTypes.bool,
		showOnEvent: PropTypes.bool,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (data) => {
		data = {...data}
		if(data.badge_or_points){
			delete data.points_value
		}
		else{
			delete data.badge
		}
		delete data.badge_or_points;
		if(data.type !== 'on_step'){
			delete data.step_activity;
			delete data.step_level;
			delete data.step_attempt;
		}
		if(data.type !== 'on_ranking'){
			delete data.position_value;
		}
		if(data.type !== 'on_event'){
			delete data.event_name;
		}
		// delete data.type;

		return data;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];

			if(key === (values.badge_or_points ? 'points_value' : 'badge')){
				continue;
			}
			if((key === 'step_activity' || key === 'step_level' || key === 'step_attempt') && values.type !== 'on_step'){
				continue;
			}
			if(key === 'position_value' && values.type !== 'on_ranking'){
				continue;
			}
			if(key === 'event_name' && values.type !== 'on_event'){
				continue;
			}
			if(key === 'badge_or_points'){
				continue;
			}

			if(!value){
				errors[key] = true;
			}
		}
		// console.log(errors)

		return errors;
	};

	form(){
		// const changeType = (type, validatedForm: ValidatedForm) => {
		// 	this.setState({type});
		// 	validatedForm();
		// };
		const course: Course = this.props.course;
		let defaultType = '';
		const {showOnView, showOnStep, showOnRanking, showOnEvent} = this.props;
		if(showOnView){
			defaultType = 'on_view';
		}
		else if(showOnStep){
			defaultType = 'on_step';
		}
		else if(showOnRanking){
			defaultType = 'on_ranking';
		}
		else if(showOnEvent){
			defaultType = 'on_event';
		}

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				validate={this.validate}
				initialValues={{
					points_value: '',
					badge: '',
					step_activity: '',
					step_level: '',
					step_attempt: '',
					position_value: '',
					event_name: '',
					type: defaultType,
					badge_or_points: false,
				}}
				submitText="Adicionar"
				asFunc
			>
				{({setFieldValue, values}) => (<>
					<Button className="mb-3" onClick={() => setFieldValue("badge_or_points",!values.badge_or_points)}>
						{values.badge_or_points
							? 'Mudar para recompensa de pontos'
							: 'Mudar para recompensa de badges'
						}
					</Button>
					{values.badge_or_points
						? <BadgeSelect
							name="badge"
							label="Badge"
							defaultValue={values.badge}
							onChange={({value}) => setFieldValue('badge', value)}
							placeholder="Selecione um badge..."
							badges={course.badges}
						/>
						: <SchoolFormSimpleField name="points_value" label="Pontos" />
					}
					<Form.Row>
						<Col xs="auto">
							<Form.Group>
								<Form.Label>
									Tipo:
								</Form.Label>
								{showOnView && (
									<Field
										as={Form.Check}
										custom
										type="radio"
										name="type"
										id="on_view"
										value="on_view"
										label="Visualização"
									/>
								)}
								{showOnStep && (
									<Field
										as={Form.Check}
										custom
										type="radio"
										name="type"
										id="on_step"
										value="on_step"
										label="Progresso"
									/>
								)}
								{showOnRanking && (
									<Field
										as={Form.Check}
										custom
										type="radio"
										name="type"
										id="on_ranking"
										value="on_ranking"
										label="Ranking"
									/>
								)}
								{showOnEvent && (
									<Field
										as={Form.Check}
										custom
										type="radio"
										name="type"
										id="on_event"
										value="on_event"
										label="Por evento"
									/>
								)}
							</Form.Group>
						</Col>
						<Col>
							{showOnStep && values.type === 'on_step' && (
								<Row>
									<Col>
										<SchoolFormSimpleField componentProps={{min: 0,step: 1}} type="number" name="step_activity" label="Atividade" />
									</Col>
									<Col>
										<SchoolFormSimpleField componentProps={{min: 0,step: 1}} type="number" name="step_level" label="Nivel" />
									</Col>
									<Col>
										<SchoolFormSimpleField componentProps={{min: 0,step: 1}} type="number" name="step_attempt" label="Tentativa" />
									</Col>
								</Row>
							)}
							{showOnRanking && values.type === 'on_ranking' && (
								<SchoolFormSimpleField type="number" name="position_value" label="Posição no ranking" />
							)}
							{showOnEvent && values.type === 'on_event' && (
								<SchoolSelect
									name="event_name"
									label="Evento"
									onChange={({value}) => setFieldValue('event_name', value)}
									options={[
										{value: 'access', label: 'Acesso'},
										{value: 'intro', label: 'Depois de ver a intro'},
										{value: 'outro', label: 'Depois d e ver a outro'},
									]}
								/>
							)}
						</Col>
					</Form.Row>
				</>)}
			</SchoolForm>
		);
	}
}