import SchoolApi from '_api/SchoolApi';
import Admin from '_entity/Acess/Admin';
import CourseUser from '_entity/Acess/CourseUser';
import Student from '_entity/Acess/Student';


export default class UserApi extends SchoolApi {
	_handleData = true;

	get entity_class(): *{
		return {
			getClass(data){
				switch(data.main_role){
					case 'ROLE_STUDENT':
					case 'ROLE_STUDENT_WAITING':
						return Student;
					case 'ROLE_COURSE':
						return CourseUser;
					case 'ROLE_ADMIN':
						return Admin;
					default:
						return Object;
				}
			},
		};
	}

	login(username, password,course_username){
		return this.base.post('authenticate', {username, password,course_username}, {
			transformResponse: SchoolApi.transformResponses(response => response.token),
		});
	}

	create(data){
		return this.base.post('register', data);
	}

	forget({course_username,username,id_personal,email}){
		return this.base.patch('reset-password', {course_username,username,id_personal,email});
	}

	check(){
		return this.json.get('check');
	}
}