import Question from '_entity/Course/Module/Item/Content/Question/Question';
import FormQuestion from '_form/Course/Module/Item/Question/FormQuestion';
import {QuestionReducerUtils} from '_reducer/Course/Module/Content/Question/QuestionReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ContentShowingBreadcrumb} from 'app/pages/AdminView/Content/ContentShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const QuestionCreatingBreadcrumb = (question: Question) => ([
	...ContentShowingBreadcrumb(question.content),
	{path: AdminRoutes.QUESTION_MODIFYING.replace(':id', question.content.id), text: 'Modificar pergunta'},
]);

function QuestionCreatingPage(props){
	const readState = useSelector(QuestionReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: contentID} = useParams();

	const question: Question = readState.data;
	const writeState = useSelector(QuestionReducerUtils.write.map);

	useEffect(() => {
			QuestionReducerUtils.read.dispatch(dispatch, contentID);

			return () => {
				QuestionReducerUtils.read.dispatch_clear(dispatch);
				QuestionReducerUtils.write.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	const write = (data) => QuestionReducerUtils.write.dispatch(dispatch, contentID, data);

	return (
		<AdminPage
			breadcrumb={QuestionCreatingBreadcrumb(question)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormQuestion
					onSubmit={write}
					question={question}
					afterSuccessAlert={pushPrevious}
					success={writeState.finished}
					error={writeState.error}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default QuestionCreatingPage;