import SchoolSidebarLeft from 'app/components/new/Content/SchoolSidebarLeft';
import NewSchoolBreadcrumbHeader from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import {AdminLoaderProvider} from 'app/pages/AdminView/AdminLoader';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import NewPage from 'app/pages/NewPage';
import elos from 'assets/images/logo_principal.png';
import React, {useEffect} from 'react';
import Card from 'react-bootstrap/Card';

function AdminPage({fluid, absolute, title, actions, className, doubleTitle, children, breadcrumb = []}){
	if(!title && breadcrumb.length > 0){
		title = breadcrumb[breadcrumb.length - 1].text;
		if(doubleTitle && breadcrumb.length > 1){
			title = breadcrumb[breadcrumb.length - 2].text + ' - ' + title;
		}
	}
	useEffect(() => {
		document.title = title ? `Elos - ${title}` : 'Elos';
	}, [title]);
	// document.documentElement.setAttribute("data-color", 'poker-mind-school')
	return (
		<NewPage
			logo={elos}
			title={<div className="h3 mb-0">Administrador</div>}
			className="border-0 h-100"
			containerClass="active-content"
			showLogout={true}
			sidebarLeft={
				<SchoolSidebarLeft
					items={{
						[AdminRoutes.COURSE_LISTING]: {
							isTo: true,
							icon: 'fa fa-2x fa-users',
							active: true,
						},
					}}
				/>
			}
		>
			<AdminLoaderProvider>
				<NewSchoolBreadcrumbHeader links={breadcrumb}>
					<div className="container">
						<Card className={`mb-4 ${fluid ? 'fluid-card' : ''} ${absolute ? 'absolute-card' : ''}`}>
							<Card.Header className="d-flex flex-row">
								<div className="h3">
									{title}
								</div>
								<div className="ml-auto">
									{actions}
								</div>
							</Card.Header>
							<Card.Body className={className}>
								{children}
							</Card.Body>
						</Card>
					</div>
				</NewSchoolBreadcrumbHeader>
			</AdminLoaderProvider>
		</NewPage>
	);
}

export default AdminPage;