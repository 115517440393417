import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolRichField from 'app/components/form/SchoolRichField';
import PropTypes from 'prop-types';
import React from 'react';

class ChangeEmailForm extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (values) => {

		return values;
	};

	getAlertTitle(): string{
		return `Configurações de email foram alterados com sucesso`;
	}

	getAlertMessage(): string{
		return 'Configurações de email foram salvos!';
	}

	form(){
		const course:Course = this.props.course;
		const welcome = ifEmpty(course.welcome,"");
		const initial = {
			welcome,
			password: ''
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
				asFunc
			>
				{({values, setFieldValue}) => (<>
					<SchoolFormSimpleField name="password" label="Senha" type="password" autoComplete="new-password"/>
					<SchoolRichField
						name="welcome"
						label="Bem vindo"
						value={values.welcome}
						onChange={v => setFieldValue('welcome',v)} />
				</>)}
			</SchoolForm>
		);
	}
}

export default ChangeEmailForm;
