import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import Course from '_entity/Course/Course';


export default class CourseApi extends SchoolApi {
	get namespace(){
		return 'courses';
	}

	get entity_class(){
		return Course;
	}

	list(){
		return this.json.get();
	}

	read(id){
		return this.json.get(id);
	}

	info(id){
		return this.jsonData.get(`${id}/details`);
	}

	changePassword(data){
		return this.json.patch(`/change/password`,data);
	}

	changeCost(data){
		return this.json.patch(`/change/cost`,data);
	}

	openClose(data){
		return this.json.patch(`/change/open_close`,data);
	}

	changeEmails(data){
		return this.json.patch(`/change/emails`,data);
	}

	_anon_read(id){
		return this.json.get(`/anon/${id}`);
	}

	edit(id, data){
		expectsString(id);
		expectsObject(data);

		return this.multipart.post(id, data);
	}

	editVideos(id, data){
		expectsString(id);
		expectsObject(data);

		return this.multipart.post(`${id}/videos`, data);
	}

	create(data){
		expectsObject(data);
		return this.json.post('', data);
	}
}
