import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Content from '_entity/Course/Module/Item/Content/Content';
import Comment from '_entity/Social/Comment';
import CommentForm from '_form/Social/CommentForm';
import {CommentReducerUtils} from '_reducer/Social/CommentReducer';
import ForumReply from 'app/components/new/Content/Footer/Forum/ForumReply';
import SchoolLoader from 'app/SchoolLoader';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

WidgetComments.propTypes = {
	content: PropTypes.instanceOf(Content),
};

/**
 *
 * @param {Content} content
 * @returns {*}
 * @constructor
 */
function WidgetComments({content}){
	const dispatch = useDispatch();
	// const [replyTo,setReplyTo] = useState(null)
	const comment = useCallback((data) => {
		CommentReducerUtils.create.dispatch(dispatch, content.id, data);
	}, [dispatch, content.id]);
	const listing = useCallback(() => {
		CommentReducerUtils.list.dispatch(dispatch, content.id);
		CommentReducerUtils.create.dispatch_clear(dispatch);
	}, [dispatch, content.id]);
	const listState = useSelector(CommentReducerUtils.list.map);
	const commentState = useSelector(CommentReducerUtils.create.map);

	useEffect(() => {
		listing();

		return () => {
			CommentReducerUtils.list.dispatch_clear(dispatch);
			CommentReducerUtils.create.dispatch_clear(dispatch);
		};
	}, [dispatch,listing]);

	useEffect(() => {
		if(commentState.finished){
			listing();
		}
	}, [commentState,listing]);

	const comments: Comment[] = listState.data || [];
	const user = useAppitUserAwareness();

	return (
		<div className="forum">
			<CommentForm
				user={user}
				submitting={commentState.begined && !commentState.finished}
				afterSuccessAlert={listing}
				onSubmit={comment}
			/>
			<div className="forum-replies">
				{comments.map((comment, index) => <ForumReply
					key={comment.id}
					comment={comment}
					avatar={comment.user.avatar}
					text={index + '/' + comments.length}
				/>)}
				{!listState.finished && <SchoolLoader basic />}
			</div>
		</div>
	);
}

export default WidgetComments;
