import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import CourseMailingApi from '_api/Course/CourseMailingApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const CourseMailingReducerOptions = new SchoolReducerOptions('course_mailing',CourseMailingApi);

/**
 *
 * @type {{
 *     create: ReducerBasicOption
 * }}
 */
export const CourseMailingReducerUtils:$ObjMap<string,ReducerBasicOption> = CourseMailingReducerOptions.options();
