import NewStudentContentPage from 'app/pages/StudentView/Content/NewStudentContentPage';
import StudentModulePage from 'app/pages/StudentView/Module/StudentModulePage';
import ContactPage from 'app/pages/StudentView/Profile/ContactPage';
import ProfileShowPage from 'app/pages/StudentView/Profile/ProfileShowPage';


export const StudentRoutes = {
	MODULES: '/modules',
	CONTENT: '/module/:module',
	PROFILE_SHOW: '/profile/show',
	CONTACT: '/contact',
};

export const StudentPages = {
	'routes': {
		[StudentRoutes.MODULES]: StudentModulePage,
		[StudentRoutes.CONTENT]: NewStudentContentPage,
		[StudentRoutes.PROFILE_SHOW]: ProfileShowPage,
		[StudentRoutes.CONTACT]: ContactPage,
	},
	'redirect': StudentRoutes.MODULES,
};