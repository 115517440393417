import Course from '_entity/Course/Course';
import FormCourse from '_form/Course/FormCourse';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseListingPageBreadcrumb} from 'app/pages/AdminView/Course/CourseListingPage';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const CourseCreatingPageBreadcrumb = () => [
	...CourseListingPageBreadcrumb(),
	{
		path: AdminRoutes.COURSE_CREATING,
		text: 'Adicionar curso',
	},
];

function CourseCreatingPage(props){
	const dispatch = useDispatch();

	const creatingState = useSelector(CourseReducerUtils.create.map);
	const creating = (data) => CourseReducerUtils.create.dispatch(dispatch, data);

	return (
		<AdminPage breadcrumb={CourseCreatingPageBreadcrumb()}>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormCourse
					success={creatingState.finished}
					onSubmit={creating}
					error={creatingState.error}
					course={new Course({
						name: '',
						cost: '',
						avatars: '',
						logo_text_src: '',
						image_src: '',
						user: {
							name: '',
							email: '',
							username: '',
						},
					})}
					afterSuccessAlert={pushPrevious}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default CourseCreatingPage;
