import Content from '_entity/Course/Module/Item/Content/Content';
import FormContent from '_form/Course/Module/Item/Content/FormContent';
import {ContentReducerUtils} from '_reducer/Course/Module/Content/ContentReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ContentShowingBreadcrumb} from 'app/pages/AdminView/Content/ContentShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const ContentEditingBreadcrumb = (content: Content) => ([
	...ContentShowingBreadcrumb(content),
	{path: AdminRoutes.CONTENT_EDITING.replace(':id', content.id), text: 'Editar'},
]);

function ContentEditingPage(props){
	const editState = useSelector(ContentReducerUtils.edit.map);
	const readState = useSelector(ContentReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: contentID} = useParams();
	const content: Content = readState.data;
	const edit = (data) => ContentReducerUtils.edit.dispatch(dispatch,contentID,data);

	useEffect(() => {
			ContentReducerUtils.read.dispatch(dispatch, contentID);

			return () => {
				ContentReducerUtils.read.dispatch_clear(dispatch);
				ContentReducerUtils.edit.dispatch_clear(dispatch);
			};
		},
		[contentID,dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={ContentEditingBreadcrumb(content)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormContent
					onSubmit={edit}
					success={editState.finished}
					error={editState.error}
					afterSuccessAlert={pushPrevious}
					content={content}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default ContentEditingPage;