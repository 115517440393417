// import Content from '_entity/Course/Module/Item/Content/Content';
import {ContentRangeReducerUtils} from '_reducer/Course/Module/Content/ContentRangeReducer';
import {EmptyMessage} from 'app/components/message/messages';
import RangeWidget from 'app/components/new/Content/Footer/Range/RangeElement';
import WidgetSearch from 'app/pages/StudentView/Content/Widgets/WidgetSearch';
import SchoolLoader from 'app/SchoolLoader';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

WidgetRanges.propTypes = {};

/**
 *
 * @param onSelectRange
 * @param {ContentRange} currentRange
 * @param {Content} content
 * @returns {*}
 * @constructor
 */
function WidgetRanges({onSelectRange, currentRange, content}){
	const dispatch = useDispatch();
	const listState = useSelector(ContentRangeReducerUtils.list.map);
	const ranges: ContentRange[] = listState.data;
	const contentId = content.id;

	useEffect(() => {
		ContentRangeReducerUtils.list.dispatch(dispatch, contentId);

		return () => {
			ContentRangeReducerUtils.list.dispatch_clear(dispatch);
		};
	}, [dispatch,contentId]);

	if(!listState.finished){
		return <SchoolLoader basic />;
	}

	return (
		<WidgetSearch
			empty={<EmptyMessage message="Nenhum range disponível" />}
			items={ranges}
			filter={['name']}
			className="range-table-list"
		>
			{range => {
				return <RangeWidget
					size="sm"
					range={range}
					onClick={!!onSelectRange ? () => onSelectRange(range) : undefined}
					key={range.id}
					clicked={!!currentRange && currentRange.id === range.id}
				/>;
			}}
		</WidgetSearch>
	);
}

export default WidgetRanges;