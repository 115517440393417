import React from 'react';
import {Popover} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {isFragment} from 'react-is';
import v4 from 'uuid';

export default ({tooltip, children, placement = 'auto'}) => {
	if(!tooltip){
		return children;
	}
	const group = (a, To) => {
		if(typeof a === 'string' || isFragment(a) || Array.isArray(a)){
			return <To>{a}</To>;
		}

		return a;
	};
	return (
		<OverlayTrigger
			placement={placement}
			overlay={(props) => (
				<Tooltip id={v4()} {...props}>
					{group(tooltip, 'div')}
				</Tooltip>
			)}
		>
			{group(children, 'span')}
		</OverlayTrigger>
	);
}
export const Popupper = ({tooltip,tooltipTitle, children, placement = 'auto',className=""}) => {
	const group = (a, To) => {
		if(typeof a === 'string' || isFragment(a) || Array.isArray(a)){
			return <To>{a}</To>;
		}

		return a;
	};
	return (
		<OverlayTrigger
			placement={placement}
			overlay={(props) => (
				<Popover id={v4()} {...props} className="mw-100">
					{tooltipTitle && <Popover.Title as="h3">{tooltipTitle}</Popover.Title>}
					<Popover.Content className={`text-center ${className}`}>
						{tooltip}
					</Popover.Content>
				</Popover>
			)}
		>
			{group(children, 'span')}
		</OverlayTrigger>
	);
}