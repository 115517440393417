import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Student from '_entity/Acess/Student';
import ChangePasswordForm from '_form/Access/ChangePasswordForm';
import FormStudent from '_form/Access/FormStudent';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import Profile from 'app/components/new/Page/Student/Profile';
import StudentPage from 'app/pages/StudentView/StudentPage';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect, useState} from 'react';
import {Accordion, Button, Card, Nav} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

function ProfileShowPage(props){
	const dispatch = useDispatch();
	const [editing, setEditing] = useState(false);
	const user: Student = useAppitUserAwareness();
	// const course = user.course;
	const read = () => StudentReducerUtils.read.dispatch(dispatch, student.id);
	const edit = (data) => StudentReducerUtils.editMe.dispatch(dispatch, data);
	const changePassword = (data) => StudentReducerUtils.changePassword.dispatch(dispatch,data)
	const changePasswordState = useSelector(StudentReducerUtils.changePassword.map);
	const editState = useSelector(StudentReducerUtils.editMe.map);
	const readState = useSelector(StudentReducerUtils.read.map);
	const student: Student = readState.data;

	useEffect(() => {
		StudentReducerUtils.read.dispatch(dispatch, user.id);

		return () => {
			StudentReducerUtils.read.dispatch_clear(dispatch);
			StudentReducerUtils.editMe.dispatch_clear(dispatch);
			StudentReducerUtils.changePassword.dispatch_clear(dispatch);
		};
	}, [dispatch, user.id]);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<StudentPage
			// showSidebarToggler={false}
			mainClass="main-profile h-100"
			headerClass="showing-modules"
			levelHeader={(
				<Nav id="level-header" className="level-header-fixed">
					<Tooltiper tooltip="Ir para Módulo">
						<LinkContainer to={StudentRoutes.MODULES}>
							<Button variant="light" as={Nav.Link}>
								<i className="fa fa-chevron-left" />
							</Button>
						</LinkContainer>
					</Tooltiper>
					<Tooltiper tooltip="Editar perfil">
						<Button as={Nav.Link} variant="secondary" onClick={() => setEditing(true)}>
							<i className="fa fa-edit" />
						</Button>
					</Tooltiper>
				</Nav>
			)}
		>
			<div id="profile" className="container">
				{editing
					? (<Accordion>
						<Card>
							<Accordion.Toggle as={Card.Header} className="h5" eventKey="info">
								Informações
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="info">
								<Card.Body>
									<FormStudent
										onSubmit={edit}
										success={editState.finished}
										error={editState.error}
										student={student}
										afterSuccessAlert={() => {
											setEditing(false);
											read();
										}}
									/>
								</Card.Body>
							</Accordion.Collapse>
							<Accordion.Toggle as={Card.Header} className="h5" eventKey="password">
								Senha
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="password">
								<Card.Body>
									<ChangePasswordForm
										onSubmit={changePassword}
										success={changePasswordState.finished}
										error={changePasswordState.error}
										afterSuccessAlert={() => {
											setEditing(false)
											read();
										}}
									/>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>)
					: <Profile student={student} showRefund>
						<div className="profile-actions">
							<Tooltiper tooltip="Ir para Módulo">
								<LinkContainer to={StudentRoutes.MODULES}>
									<Button variant="light" as={Nav.Link}>
										<i className="fa fa-chevron-left" />
									</Button>
								</LinkContainer>
							</Tooltiper>
							<Tooltiper tooltip="Editar perfil">
								<Button as={Nav.Link} variant="secondary" onClick={() => setEditing(true)}>
									<i className="fa fa-edit" />
								</Button>
							</Tooltiper>
						</div>
					</Profile>
				}
			</div>
		</StudentPage>
	);
}

export default ProfileShowPage;
