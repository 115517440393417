import {asset, toFixedLocale} from '@appit.com.br/react-utils/src/utils/utils';
import {getModuleAvatar} from '_entity/Course/Module/Module';
import {DisplayInfo} from 'app/components/display/DisplayInfo';
import {EmptyMessage} from 'app/components/message/messages';
import SchoolVideo from 'app/components/new/Content/Item/SchoolVideo';
import {SchoolCourseAlert} from 'hoc/alert';
import {LoremIpsum} from 'lorem-ipsum';
import React, {useState} from 'react';
import {ButtonGroup, Col, Image, Media, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

/**
 *
 * @param {boolean} videos
 * @param {Course} course
 * @returns {*}
 * @constructor
 */
function CourseSection({course}){
	const [videos, setVideos] = useState(null);
	const [feedback, setFeedback] = useState(false);

	let content;
	if(videos){
		if(videos === 'intro'){
			content =
				<div>
					<div>Intro:</div>
					{course.intro
						? <SchoolVideo src={course.intro_external ? course.intro : asset(course.intro)} key={''} />
						: <EmptyMessage message="Video indisponível" />
					}
				</div>;
		}
		if(videos === 'outro'){
			content = <div>
				<div>Outro:</div>
				{course.outro
					? <SchoolVideo src={course.outro_external ? course.outro : asset(course.outro)} key={''} />
					: <EmptyMessage message="Video indisponível" />
				}
			</div>;
		}
	}
	else{
		content = <Row>
			<Col md="auto">
				{course.image &&
				<Image style={{maxHeight: '15em', minWidth: '10em'}} fluid src={asset(course.image)} />}
			</Col>
			<Col>
				<div className="d-flex flex-column h4">
					{course.logo &&
					<Image style={{minHeight: '10em', maxHeight: '15em'}} fluid src={asset(course.logo)} />}
					<div>
						<DisplayInfo
							text="Curso:"
							value={course.name}
						/>
					</div>
					<div>
						<DisplayInfo
							text="Usuário responsavel:"
							value={course.user.name}
						/>
					</div>
					<div>
						<DisplayInfo
							text="Usuário de acesso:"
							value={course.username}
						/>
					</div>
					<div>
						<DisplayInfo
							value={course.user.email}
							text="Email:"
							valueTag={'a'}
							valueProps={{href: `mailto:${course.user.email}`}}
						/>
					</div>
					<div>
						<DisplayInfo
							value={course.methods.join(', ')}
							text="Formas de pagamento:"
						/>
					</div>
					<div>
						<DisplayInfo
							value={`R$ ${toFixedLocale(course.cost)}`}
							text="Custo:"
						/>
					</div>
				</div>
			</Col>
		</Row>;
	}

	if(feedback){
		content = <Avatars course={course} />;
	}

	return (<>
		<div className="rounded overflow-hidden">
			<div className="d-flex w-100" data-color={course.username}>
				<span className="p-2 w-100 bg-primary" />
				<span className="p-2 w-100 ml-2 bg-secondary" />
			</div>
			<Row noGutters data-color={course.username} className="flex-nowrap">
				<Col xs="auto" className="bg-primary px-2" />
				<Col className="p-3">
					{content}
				</Col>
				<Col xs="auto" className="bg-secondary px-2" />
			</Row>
			<div className="d-flex w-100" data-color={course.username}>
				<span className="p-2 w-100 bg-primary" />
				<span className="p-2 w-100 ml-2 bg-secondary" />
			</div>
		</div>
		<div className="d-flex justify-content-end mt-2">
			<ButtonGroup>
				{(videos || feedback) && (
					<Button variant="info" onClick={() => setVideos(null)}>
						Mostrar informações
					</Button>
				)}
				<Button variant="warning" onClick={() => setFeedback(true)}>
					Mostrar feedback
				</Button>
				<Button
					onClick={() => {
						if(videos === 'intro'){
							setVideos('outro');
						}
						else{
							setVideos('intro');
						}
					}}
				>
					Mostrar {videos === 'intro' ? 'outro' : 'intro'}
				</Button>
			</ButtonGroup>
		</div>
	</>);
}

export default CourseSection;

/**
 *
 * @param {Course} course
 * @returns {*}
 * @constructor
 */
function Avatars({course}){
	const [current,setCurrent] = useState(0)

	console.log(current)

	const currentAvatar = course.avatars[current]

	return <div>
		<Media>
			<img className="react-select__value-container" src={getModuleAvatar(currentAvatar)[0]} alt="" />
			<Media.Body className="my-auto">
				{currentAvatar}
				<Button
					size="sm" variant="outline-primary" className="float-right"
					onClick={() => {
						const ispum = new LoremIpsum();
						SchoolCourseAlert(course, null, getModuleAvatar(currentAvatar)[0])
							.Feedback(ispum.generateSentences(1), ispum.generateSentences(1));
					}}
				>
					<i className="fa fa-eye" />
				</Button>
			</Media.Body>
		</Media>
		<div className="d-flex justify-content-between mt-1 pt-1 border-top">
			<Button size="sm" onClick={() => setCurrent(current-1)} disabled={current === 0}>
				ANTERIOR
			</Button>
			<Button size="sm" onClick={() => setCurrent(current+1)} disabled={current+1 >= course.avatars.length}>
				PROXIMO
			</Button>
		</div>
	</div>
};
