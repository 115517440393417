import ContentGlossary from '_entity/Course/Module/Item/Content/ContentGlossary';
import {ContentGlossaryReducerUtils} from '_reducer/Course/Module/Content/ContentGlossaryReducer';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

/**
 *
 * @param {Content} content
 * @param afterDelete
 * @returns {*}
 * @constructor
 */
function GlossarySection({content, afterDelete}){
	const dispatch = useDispatch();
	const removeState = useSelector(ContentGlossaryReducerUtils.delete.map);
	const remove = (id) => ContentGlossaryReducerUtils.delete.dispatch(dispatch, id);

	useEffect(() => {
		SchoolOperationAlert({
			successMessage: `Glossario deletado!`,
			successAction: afterDelete,
			finished: removeState.finished,
			error: removeState.error,
		});
	},
		// eslint-disable-next-line
		[removeState]
	);

	return (<>
		<ListGroup className="text-left align-sef-end">
			{content.glossaries.map((glossary: ContentGlossary) => (
				<ListGroup.Item
					key={glossary.id}
					className="d-flex align-items-center justify-content-between px-5"
				>
					<Tooltiper tooltip={glossary.tags.join(', ')}>
						{glossary.title}
						<div className="small">
							{glossary.description}
						</div>
					</Tooltiper>
					<Button
						className="btn-right"
						size="sm" variant="danger"
						onClick={() => SchoolConfirmAlert({
							title: 'Realmente deseja deletar esse glossario?',
							action: () => remove(glossary.id),
						})}
					>
						<i className="fa fa-trash" />
					</Button>
				</ListGroup.Item>
			))
			}
		</ListGroup>
		<LinkContainer to={AdminRoutes.CONTENT_GLOSSARY_CREATING.replace(':id', content.id)}>
			<Button variant="primary" className="mt-3">
				Adicionar glossario
			</Button>
		</LinkContainer>
	</>);
}

export default GlossarySection;