import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import Content from '_entity/Course/Module/Item/Content/Content';
import SchoolEntity from '_entity/SchoolEntity';
import * as immutable from 'object-path-immutable';

class ContentRange extends SchoolEntity {

	get name(){
		return this.getDataValue('name');
	}

	get info(){
		return ContentRange.rows(this.getDataValue('info'))
	}

	get content(): Content{
		return entityResolver(this.getDataValue('content'), Content);
	}
}
ContentRange.cellToString = (a,b) => {
	if(a < b){
		return ContentRange.indexToString(a) + ContentRange.indexToString(b);
	}

	return ContentRange.indexToString(b) + ContentRange.indexToString(a)
}
ContentRange.indexToString = (index) => {
	index = ["A","K","Q","J","T","9","8","7","6","5","4","3","2"][index];

	if(!index){
		return '~'
	}

	return index;
}
ContentRange.index = (rows, rowIndex, colIndex,maxEmphasis=4,increment) => {
	let index = 0;
	if(!!rows[rowIndex]){
		index = immutable.get(rows,[rowIndex,colIndex], 0);
	}
	if(index === true){
		index = 1;
	}
	if(index > maxEmphasis){
		index = maxEmphasis;
	}
	if(increment){
		index = (index + 1) % maxEmphasis
	}

	return index;
}

ContentRange.rows = (rows) => {
	let newRows = [];
	if(Array.isArray(rows)){
		newRows = [...rows];
		if(newRows.length <= 13){
			newRows = newRows.map(ContentRange.row)
			while(newRows.length < 13){
				newRows.push(ContentRange.row())
			}
		}
	}

	return newRows;
}
ContentRange.row = (row) => {
	let newLine = [];
	if(!!row && Array.isArray(row)){
		newLine= [...row];
		while(newLine.length < 13){
			newLine.push(0)
		}
	}
	else{
		newLine = Array(13).fill(0)
	}

	return newLine
}

export default ContentRange;
