import SchoolHeader from 'app/components/new/Header/SchoolHeader';
import React from 'react';

NewPage.propTypes = {

};

function NewPage({sidebarRight, sidebarLeft, header, headerClassName, title, logo, hideLogo, showLogout, logoutText, containerClass, children, footer, containerContent, className, contentClass}){
	if(!sidebarRight){
		containerClass += ' hide-sidebar-right';
	}
	if(!sidebarLeft){
		containerClass += ' hide-sidebar-left';
	}
	return (<>
		<SchoolHeader
			className={headerClassName}
			title={title}
			logo={logo}
			hideLogo={hideLogo}
			showLogout={showLogout}
		>
			{header}
		</SchoolHeader>
		<div id="container" className={containerClass}>
			<div id="content" data-env={process.env.REACT_APP_ENV} className={contentClass}>
				{sidebarLeft}
				{sidebarRight}
				<main id="main" className={className}>
					{children}
				</main>
				{footer}
			</div>
			{containerContent}
		</div>
	</>);
}

export default NewPage;