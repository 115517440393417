import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import FormLearningObject from '_form/Course/Module/Item/LearningObject/FormLearningObject';
import {LearningObjectReducerUtils} from '_reducer/Course/Module/LearningObject/LearningObjectReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {LearningObjectShowingBreadcrumb} from 'app/pages/AdminView/LearningObject/LearningObjectShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const LearningObjectEditingBreadcrumb = (learningObject: LearningObject) => ([
	...LearningObjectShowingBreadcrumb(learningObject),
	{path: AdminRoutes.LEARNING_OBJECT_EDITING.replace(':id', learningObject.id), text: 'Editar'},
]);

function LearningObjectEditingPage(props){
	const editState = useSelector(LearningObjectReducerUtils.edit.map);
	const readState = useSelector(LearningObjectReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: learningObjectID} = useParams();
	const learningObject: LearningObject = readState.data;
	const edit = (data) => LearningObjectReducerUtils.edit.dispatch(dispatch,learningObjectID,data);

	useEffect(() => {
			LearningObjectReducerUtils.read.dispatch(dispatch, learningObjectID);

			return () => {
				LearningObjectReducerUtils.read.dispatch_clear(dispatch);
				LearningObjectReducerUtils.edit.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={LearningObjectEditingBreadcrumb(learningObject)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormLearningObject
					course={learningObject.module.course}
					success={editState.finished}
					error={editState.error}
					onSubmit={edit}
					afterSuccessAlert={pushPrevious}
					learningObject={learningObject}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default LearningObjectEditingPage;