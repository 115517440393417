import Content from '_entity/Course/Module/Item/Content/Content';
import ContentRange from '_entity/Course/Module/Item/Content/ContentRange';
import {ContentRangeReducerUtils} from '_reducer/Course/Module/Content/ContentRangeReducer';
import RangeExporterButton from 'app/components/new/Content/Footer/Range/RangeExporterButton';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

RangeSection.propTypes = {
	content: PropTypes.instanceOf(Content),
	afterDelete: PropTypes.func,
};

/**
 *
 * @param {function} afterDelete
 * @param {Content} content
 * @returns {*}
 * @constructor
 */
function RangeSection({afterDelete, content}){
	const dispatch = useDispatch();
	const removeState = useSelector(ContentRangeReducerUtils.delete.map);
	const remove = (id) => ContentRangeReducerUtils.delete.dispatch(dispatch, id);

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Range deletado!`,
				successAction: afterDelete,
				finished: removeState.finished,
				error: removeState.error,
			});
		},
		// eslint-disable-next-line
		[removeState],
	);

	useEffect(() => {
		return () => {
			ContentRangeReducerUtils.delete.dispatch_clear(dispatch);
		};
	}, [dispatch]);

	return (<>
		<ListGroup className="text-left align-sef-end">
			{content.ranges.map((range: ContentRange) => (
				<ListGroup.Item
					key={range.id}
					className="d-flex align-items-center justify-content-between px-5"
				>
					<LinkContainer to={AdminRoutes.CONTENT_RANGE_EDITING.replace(':id', range.id)}>
						<Button
							className="btn-left"
							size="sm"
							variant="warning"
						>
							<i className="fa fa-edit" />
						</Button>
					</LinkContainer>
					<RangeExporterButton range={range} />
					<Button
						className="btn-right"
						size="sm"
						variant="danger"
						onClick={() => SchoolConfirmAlert({
							title: 'Realmente deseja deletar esse range?',
							action: () => remove(range.id),
						})}
					>
						<i className="fa fa-trash" />
					</Button>
				</ListGroup.Item>
			))}
		</ListGroup>
		<LinkContainer to={AdminRoutes.CONTENT_RANGE_CREATING.replace(':id', content.id)}>
			<Button variant="primary" className="mt-3">
				Adicionar Range
			</Button>
		</LinkContainer>
	</>);
}

export default RangeSection;
