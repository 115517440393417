import {AppitAlert} from '@appit.com.br/react-misc/src/alert/AppitAlert';
import RangeElement from 'app/components/new/Content/Footer/Range/RangeElement';
import domtoimage from 'dom-to-image';
import {Popupper} from 'hoc/ui/Tooltiper';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

RangeExporterButton.propTypes = {};

/**
 *
 * @param {Course} course
 * @param {ContentRange} range
 * @returns {*}
 * @constructor
 */
function RangeExporterButton({range}){


	const SizeButton = ({size,format}) => <Button
		variant="link"
		onClick={() => AppitAlert({
			title: `Deseja exportar esse range em ${size}x${size} ?`,
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonText: 'Exportar',
			width: 'auto',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				const element = document.getElementById('range-to-export');
				element.style.setProperty('font-size',`${size/32.5}px`)
				let image;

				switch(format){
					case 'svg':
						image = domtoimage.toSvg(element, {quality: 1});
						break;
					case 'jpeg':
						image = domtoimage.toJpeg(element, {quality: 1});
						break;
					case 'png':
					default:
						image = domtoimage.toPng(element, {quality: 1});
						break;
				}

				return image
					.then(dataUrl => {
						var link = document.createElement('a');
						link.download = `${range.name}`;
						link.href = dataUrl;
						link.click();
						element.style = "";
					});
			},
			html: (
				<div
					data-color={range.content.module.course.username}
				>
					<RangeElement
						id="range-to-export"
						className="p-0 d-flex justify-content-center align-items-center"
						noTitle
						range={range}
					/>
				</div>
			),
		})}
	>
		.{format} {size}x{size}
	</Button>

	return (
		<Popupper
			key={range.id}
			tooltipTitle={range.name}
			tooltip={<div data-color={range.content.module.course.username}>
				<RangeElement size="sm" noTitle range={range} />
			</div>}
		>
			<div className="px-3">
				{range.name}
			</div>
			{/*<ButtonGroup>*/}
			{/*	<SizeButton size={480}/>*/}
			{/*	<SizeButton size={650}/>*/}
			{/*	<SizeButton size={720}/>*/}
			{/*	<SizeButton size={1080}/>*/}
			{/*</ButtonGroup>*/}
			<ButtonGroup>
				<SizeButton size={1080} format="jpeg"/>
				<SizeButton size={2160} format="jpeg"/>
				<SizeButton size={4320} format="jpeg"/>
				<SizeButton size={6500} format="jpeg"/>
			</ButtonGroup>
		</Popupper>
	);
}

export default RangeExporterButton;
