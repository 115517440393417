import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import StudentEvent from '_entity/Acess/StudentEvent';
import SchoolHeader from 'app/components/new/Header/SchoolHeader';
import SchoolProfileHeader from 'app/components/new/Header/SchoolProfileHeader';
import PageLogo from 'app/components/PageLogo';
import {CourseAwarenessProvider} from 'app/pages/StudentView/Access/CourseAwareness';
import IntroVideo from 'app/pages/StudentView/IntroVideo';
import PerfectScrollbar from 'perfect-scrollbar';
import React, {useLayoutEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';

function StudentPage({ignoreIntro,headerClass = '', containerClass = '', hideLogo, levelHeader, showSidebarToggler = true, showLogout, contentClass, mainClass, sidebarLeft, sidebarRight, children, footer, outside}){
	const user: Student = useAppitUserAwareness();
	const title = <PageLogo course={user.course} />;
	const logo = asset(user.course.image);
	const [visibleSidebar, setVisibleSidebar] = useState(false);
	headerClass += visibleSidebar ? ' active-sidebar' : ' active-content';
	containerClass += visibleSidebar ? ' active-sidebar' : ' active-content';
	if(!sidebarRight){
		containerClass += ' hide-sidebar-right';
	}
	if(!sidebarLeft){
		containerClass += ' hide-sidebar-left';
	}
	let contentRef;
	// const random = new Date().getTime();
	useLayoutEffect(() => {
		const el = contentRef
		if(el){
			// console.log(PerfectScrollbar)
			const ps = new PerfectScrollbar(el);
			// console.log(ps)

			const si = setInterval(() =>{
				ps.update();
			},300)

			return () => {
				// console.log("bye")
				clearInterval(si)
				ps.destroy();
			}
		}
	},[contentRef]);

	// SchoolFeedback(new LoremIpsum().generateSentences(3),new LoremIpsum().generateSentences(3))

	if(!ignoreIntro && !user.has_event(StudentEvent.VIEWED_INTRO) && !!user.course.intro){
		return (
			<div className="intro-container">
				<IntroVideo course={user.course} />
			</div>
		);
	}

	if(!ignoreIntro && !user.has_event(StudentEvent.VIEWED_OUTRO) && !!user.course.outro && user.completed){
		return (
			<div className="intro-container">
				<IntroVideo course={user.course} />
			</div>
		);
	}

	return (
		<CourseAwarenessProvider course={user.course}>
			<SchoolHeader
				className={headerClass}
				title={title}
				logo={logo}
				hideLogo={hideLogo}
				showLogout={showLogout}
			>
				{levelHeader}
				{showSidebarToggler && (
					<Button
						variant="primary"
						id="mobile-selector"
						key="mobile-selector"
						onClick={() => setVisibleSidebar(!visibleSidebar)}
					>
						<i className="icon icon-user-info" />
					</Button>
				)}
				<SchoolProfileHeader />
			</SchoolHeader>
			<div id="container" className={containerClass}>
				<div
					id="content"
					ref={ref => (contentRef = ref)}
					data-env={process.env.REACT_APP_ENV}
					className={contentClass}
				>
					{sidebarLeft}
					{sidebarRight}
					<main id="main" className={mainClass}>
						{children}
					</main>
					{footer}
				</div>
				{outside}
			</div>
		</CourseAwarenessProvider>
	);
}

export default StudentPage;
