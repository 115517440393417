import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import RewardMap from '_entity/Game/RewardMap';

export default class RewardMapApi extends SchoolApi {
	get namespace(){
		return 'reward_maps';
	}

	get entity_class(){
		return RewardMap;
	}

	remove(id){
		expectsString(id);
		return this.json.delete(id);
	}

	read(id){
		expectsString(id);
		return this.json.get(id);
	}

	createForItem(id, data){
		expectsString(id);
		expectsObject(data);
		return this.json.post(`by_item/${id}`, data);
	}

	createForModule(id, data){
		expectsString(id);
		expectsObject(data);
		return this.json.post(`by_module/${id}`, data);
	}

	createForCourse(id, data){
		expectsString(id);
		expectsObject(data);
		return this.json.post(`by_course/${id}`, data);
	}
}
