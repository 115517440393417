import {asset, expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import defaultAvatar from 'assets/images/avatar.svg';

export default class LearningObjectApi extends SchoolApi {
	get namespace(){
		return 'learning_objects';
	}

	get entity_class(){
		return LearningObject;
	}

	read(id){
		return this.json.get(id);
	}

	edit(id, data){
		expectsString(id);
		expectsObject(data);
		return this.multipart.post(id, data);
	}

	create(moduleID, data){
		expectsString(moduleID);
		expectsObject(data);
		return this.multipart.post(`by_module/${moduleID}`, data);
	}

	view(id, data){
		return this.json.post(`view/${id}`, data);
	}

	data(ID){
		expectsString(ID);
		return this.jsonData.get(`${ID}/data.json`).then(data => {
			data.avatar = data.avatar ? asset(data.avatar) : defaultAvatar

			return data;
		});
	}
	data_multiple(ID,amount){
		expectsString(ID);
		return this.jsonData.get(`${ID}/data_${amount}.json`).then(data => {
			data.avatar = data.avatar ? asset(data.avatar) : defaultAvatar

			return data;
		});
	}
	data_keyname(ID,keyname){
		expectsString(ID);
		return this.jsonData.get(`${ID}/${keyname}.json`).then(data => {
			data.avatar = data.avatar ? asset(data.avatar) : defaultAvatar

			return data;
		});
	}

	sim_view(id,data){
		expectsObject(data);
		return this.jsonData.post(`${id}/sim_view`,data);
	}
}
