import SchoolApi from '_api/SchoolApi';
import Question from '_entity/Course/Module/Item/Content/Question/Question';

export default class QuestionApi extends SchoolApi {
	get namespace(){
		return 'questions';
	}

	get entity_class(){
		return Question;
	}

	write(contentID, data){
		return this.json.post(`by_content/${contentID}`, data);
	}

	read(contentID, data){
		return this.json.get(`by_content/${contentID}`, data);
	}
}