import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import Student from '_entity/Acess/Student';
import {InformativeReducerUtils} from '_reducer/Informative/InformativeReducer';
import AvatarElement from 'app/components/avatar/AvatarElement';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import stack from 'assets/images/stack.svg';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import CountUp from 'react-countup';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

SchoolProfileHeader.propTypes = {
	className: PropTypes.string,
};
SchoolProfileHeader.defaultProps = {
	className: '',
};

function SchoolProfileHeader({className}){
	const user: Student = useAppitUserAwareness();
	const dispatch = useDispatch();
	const pointsState:ReducerStateType = useSelector(InformativeReducerUtils.points.map)
	const go = !pointsState.touched || pointsState.clear;
	const [points,setPoints] = useState(0);
	const [previousPoints,setPreviousPoints] = useState(0);
	useEffect(() => {
		if(pointsState.finished){
			setPreviousPoints(points)
			setPoints(pointsState.data)
		}
	},[points,pointsState])

	useEffect(() => {
		if(go){
			InformativeReducerUtils.points.dispatch(dispatch)
		}
	},[dispatch,go])
	let username = user instanceof Student ? user.internal_username : user.username;
	if(username.length > 10){
		username = username.substr(0,7) + '...';
	}

	return (
		<Nav id="profile-header" className={className}>
			<Dropdown as={Nav.Item} className="profile-avatar">
				<Dropdown.Toggle>
					<AvatarElement src={user.avatar} />
					<span className="profile-avatar-name">{username}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<LinkContainer to={StudentRoutes.PROFILE_SHOW}>
						<Dropdown.Item>Perfil</Dropdown.Item>
					</LinkContainer>
					<Dropdown.Item href={`https://wa.me/${user.course.contact.whatsapp_support}`} target="_blank">Suporte e sugestões</Dropdown.Item>
					<Dropdown.Divider />
					<LinkContainer to="/logout">
						<Dropdown.Item>Logout</Dropdown.Item>
					</LinkContainer>
				</Dropdown.Menu>
			</Dropdown>
			<Nav.Item className="profile-points">
				<div className="profile-points-value">
					<CountUp start={previousPoints} end={points ? points : 0}/>
				</div>
				<div className="profile-points-icon">
					<img src={stack} alt="" />
				</div>
			</Nav.Item>
		</Nav>
	);
}

export default SchoolProfileHeader;
