import AppitRouter from '@appit.com.br/react-misc/src/auth/AppitRouter';
import {useAppitDomain} from '@appit.com.br/react-misc/src/router/router';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import CourseUser from '_entity/Acess/CourseUser';
import Student from '_entity/Acess/Student';
import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import AuthView from 'app/pages/AuthView/AuthView';
import LogoutPage from 'app/pages/AuthView/LogoutPage';
import Views from 'app/pages/Views';
import {TemplateLoaderProvider, TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {SchoolCourseAlert, SchoolFeedback} from 'hoc/alert';
import {Security} from 'hoc/security';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useHistory, useLocation} from 'react-router-dom';

function SchoolRouter(props){
	const dispatch = useDispatch();
	const check = useCallback(() => SchoolAuthReducerUtils.check.dispatch(dispatch),[dispatch]);
	// const logout = useCallback(() => SchoolAuthReducerUtils.logout.dispatch(dispatch),[dispatch]);
	const [fromDc,setDc] = useState(false)
	const history= useHistory();



	const checkState:ReducerStateType = useSelector(SchoolAuthReducerUtils.check.map);
	const loginState:ReducerStateType = useSelector(SchoolAuthReducerUtils.login.map);
	const logoutState:ReducerStateType = useSelector(SchoolAuthReducerUtils.logout.map);

	const location = useLocation();
	const pathname = location.pathname;

	useEffect(() => {
		if(['login', 'logout'].indexOf(pathname.replace(/^\/|\/$/g, '')) < 0){
			// setPathname(location);
		}
	}, [pathname]);

	const {domain} = useAppitDomain();

	useEffect(() => {
		let color = domain.split('.').splice(0, 1).join('.');
		if(!['poker-mind-school',"pokermindschool"].includes(color)){
			color = ""
		}
		document.documentElement.setAttribute('data-color',color)
	},[domain])
	//
	// console.log(loginState.error)

	useEffect(() => {
		if(checkState.finished && !checkState.error){

		}
	},[checkState])

	const toLogin = useCallback(() => history.push("/login"),[history])
	const logoutFinishedHandler = useCallback((user: User) => {
		let course;
		if(user instanceof Student){
			course = user.course;
		}
		if(user instanceof CourseUser){
			course = user.course;
		}

		SchoolAuthReducerUtils.check.dispatch_clear(dispatch);
		SchoolAuthReducerUtils.login.dispatch_clear(dispatch);
		SchoolAuthReducerUtils.logout.dispatch_clear(dispatch)
		if(fromDc){
			setDc(false)
			return;
		}

		if(!!course){
			SchoolCourseAlert(course).Feedback('Até breve...','',toLogin);
		}
		else{
			SchoolFeedback('Até breve...','',toLogin);
		}
	},[dispatch,fromDc,toLogin]);

	const serverErrorHandler = useCallback(() => {
		SchoolFeedback(
			'Não foi possível fazer o login',
			'Tente novamente mais tarde',
			toLogin
		);
		SchoolAuthReducerUtils.check.dispatch_clear(dispatch)
		SchoolAuthReducerUtils.login.dispatch_clear(dispatch)
	},[dispatch,toLogin]);

	const loginErrorHandler = useCallback((error) => {
		SchoolFeedback(
			'Não foi possível fazer o login',
			'Usuário e/ou senha incorretos',
			() => {
				SchoolAuthReducerUtils.check.dispatch_clear(dispatch)
				SchoolAuthReducerUtils.login.dispatch_clear(dispatch)
				toLogin()
			}
		);

	},[dispatch,toLogin])


	const tokenErrorHandler = useCallback((error) => {
		if(error.message === 'Network Error'){
			serverErrorHandler()
		}
		else if(error.isAxiosError){
			switch(error.response.status){
				case 503:
					SchoolFeedback('Manutenção programada.', "Tente novamente mais tarde");
					return;
				case 423:
					SchoolFeedback('Não foi possível acessar', error.response.data.message);
					return;
				default:
					break;
			}
		}
		if(!fromDc){
			setDc(true)
			SchoolFeedback('Sua sessão expirou', 'Faça o login novamente',toLogin);
		}
	},[toLogin,serverErrorHandler,fromDc])

	return (
		<TemplateLoaderProvider>
			<AppitRouter
				token={Security.load()}
				refreshToken={check}
				onTokenError={tokenErrorHandler}
				onLogoutFinished={logoutFinishedHandler}
				onLoginError={loginErrorHandler}
				tokenState={checkState}
				loginState={loginState}
				logoutState={logoutState}
				authView={AuthView}
				loader={TemplateSplash}
			>
				<Route exact path="/logout" component={LogoutPage} />
				<Views />
			</AppitRouter>
		</TemplateLoaderProvider>
	);
}

export default SchoolRouter;
