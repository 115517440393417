import CourseFAQ from '_entity/Course/CourseFAQ';
import FormCourseFAQ from '_form/Course/FormCourseFAQ';
import {CourseFaqReducerUtils} from '_reducer/Course/CourseFAQReducer';
import {EmptyMessage} from 'app/components/message/messages';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import CoursePage from 'app/pages/CourseView/CoursePage';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams} from 'react-router-dom';

export const FaqBreadcrumb = (action) => {
	if(action === 'add'){
		return [
			...FaqBreadcrumb('list'),
			{path: CourseRoutes.FAQ_ACTIONS.replace(':action', 'add'), text: 'Adicionar pergunta FAQ'},
		];
	}

	return [
		{path: CourseRoutes.FAQ_ACTIONS.replace(':action', 'list'), text: 'Perguntas de FAQ'},
	];
};

function FAQPage(props){
	const dispatch = useDispatch();
	const removeState = useSelector(CourseFaqReducerUtils.delete.map);
	const createState = useSelector(CourseFaqReducerUtils.create.map);
	const listState = useSelector(CourseFaqReducerUtils.list.map);
	const {action} = useParams();

	const remove = (id) => CourseFaqReducerUtils.delete.dispatch(dispatch, id);
	const create = (data) => CourseFaqReducerUtils.create.dispatch(dispatch, data);

	const faqs: CourseFAQ[] = listState.data;

	useEffect(() => {
		SchoolOperationAlert({
			successMessage: 'Pergunta de FAQ deletada com sucesso',
			successAction: () => CourseFaqReducerUtils.list.dispatch(dispatch),
			finished: removeState.finished,
			error: removeState.error,
		});
	}, [dispatch, removeState]);


	useEffect(() => {
		if(action === 'list'){
			CourseFaqReducerUtils.list.dispatch(dispatch);

			return () => {
				CourseFaqReducerUtils.list.dispatch_clear(dispatch);
			};
		}
	}, [dispatch, action]);

	if(action === 'list' && !listState.finished){
		return <TemplateSplash />;
	}

	return (
		<CoursePage breadcrumb={FaqBreadcrumb(action)}>
			{action === 'add' && (
				<NewSchoolBreadcrumbHeaderConsumer>
					{({pushPrevious}) => <FormCourseFAQ
						success={createState.finished}
						onSubmit={create}
						error={createState.error}
						afterSuccessAlert={pushPrevious}
					/>}
				</NewSchoolBreadcrumbHeaderConsumer>
			)}
			{action === 'list' && (<>
				<Table>
					<tbody>
					{faqs.length === 0 && (<tr>
						<td><EmptyMessage message="Não há perguntas de FAQ disponives" /></td>
					</tr>)}
					{faqs.map(faq => (
						<tr key={faq.id} className="align-middle">
							<td>
								<div>
									{faq.description_question}
								</div>
								<div className="small text-muted">
									{faq.description_answer}
								</div>
							</td>
							<td className="w-0">
								<Button
									variant="outline-danger"
									onClick={() => SchoolConfirmAlert({
										message: 'Realmente deseja deletar essa pergunta de faq?',
										action: () => remove(faq.id),
									})}
								>
									<i className="fa fa-trash" />
								</Button>
							</td>
						</tr>
					))}
					</tbody>
				</Table>
				<LinkContainer to={CourseRoutes.FAQ_ACTIONS.replace(':action', 'add')}>
					<Button variant="primary">
						Adicionar FAQ
					</Button>
				</LinkContainer>
			</>)}
		</CoursePage>
	);
}

export default FAQPage;