// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ModuleApi from '_api/Course/Module/ModuleApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ModuleReducerOptions = new SchoolReducerOptions('module', ModuleApi);

/**
 *
 * @type {{
 *     create: ReducerBasicOption
 *     read: ReducerBasicOption
 *     readByOrder: ReducerBasicOption
 *     list: ReducerBasicOption
 *     edit: ReducerBasicOption
 *     remove: ReducerBasicOption
 * }}
 */
export const ModuleReducerUtils = ModuleReducerOptions.options();
