import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import Course from '_entity/Course/Course';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {TemplateSplash, useTemplateLoaderContext} from 'app/Template/TemplateLoaderContext';
import React, {createContext, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

const CourseAwarenessContext = createContext({
	course: null,
	getUsername: (username) => null,
	course_username: null,
	getInternalUsername: (username) => null,
});

// eslint-disable-next-line
interface CourseAwarenessContextType {
	course: Course,
	getUsername: (username) => string,
	course_username: string,
	getInternalUsername: (username) => string,
}

// noinspection JSClosureCompilerSyntax
/**
 *
 * @returns {CourseAwarenessContextType}
 */
export function useCourseAwareness(){
	return useContext(CourseAwarenessContext);
}

export function withCourseAwareness(Component){
	class withCourseAwareness extends React.Component {
		static displayName = `WithCourseAwareness(${Component.displayName || Component.name})`;

		static contextType = CourseAwarenessContext;

		render(){
			return <Component {...this.props} courseAwareness={this.context} />;
		}
	}

	return withCourseAwareness;
}

export const CourseAwarenessConsumer = CourseAwarenessContext.Consumer;
export const CourseAwarenessProvider = ({course_username, course, children, onError}) => {
	const {setCourse} = useTemplateLoaderContext();
	course_username = course ? course.username : course_username;
	const courseState: ReducerStateType = useSelector(CourseReducerUtils._anon_read.map);
	// const logoutState: ReducerStateType = useSelector(SchoolAuthReducerUtils.logout.map);
	const getInternalUsername = (username) => username.replace(course_username + '.', '');
	const getUsername = (username) => course_username === username ? course_username : [
		course_username,
		username,
	].join('.');
	const dispatch = useDispatch();
	const fixColor = (color) => {
		if(['poker-mind-school',"pokermindschool"].indexOf(color) >= 0){
			return color;
		}

		return '';
	};

	// console.log(course_username,fixColor(course_username))
	document.documentElement.setAttribute('data-color', fixColor(course_username));
	const hasCourse = !!course;
	course = hasCourse ? course : courseState.data;

	useEffect(() => {
			if(!hasCourse){
				CourseReducerUtils._anon_read.dispatch(dispatch, course_username);

				return () => {
					CourseReducerUtils._anon_read.dispatch_clear(dispatch, course_username);
				};
			}
			else{
				setCourse(course);
			}
		},
		// eslint-disable-next-line
		[dispatch, hasCourse],
	);

	useEffect(() => {
			setCourse(courseState.data);
			if(courseState.error && typeof onError === 'function'){
				onError();
			}
		},
		// eslint-disable-next-line
		[courseState],
	);

	if(!course){
		return <TemplateSplash />;
	}

	if(courseState.error){
		return <Redirect to="/login" />;
	}

	return (
		<CourseAwarenessContext.Provider value={{course, course_username, getUsername, getInternalUsername}}>
			{children}
		</CourseAwarenessContext.Provider>
	);
};
