import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import SchoolEntity from '_entity/SchoolEntity';
import Comment from '_entity/Social/Comment';


export default class Like extends SchoolEntity {

	get comment(): Comment{
		return entityResolver(this.getDataValue('comment'), Comment);
	}
}
