import {getOrThrow} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import Item from '_entity/Course/Module/Item/Item';
import {ItemClassSelector} from '_entity/Course/Module/Item/ItemSelector';
import RewardMap from '_entity/Game/RewardMap';
import {RewardMapSelector} from '_entity/Game/RewardMapSelector';
import SchoolEntity from '_entity/SchoolEntity';

export default class Module extends SchoolEntity {
	get name(){
		return this.getDataValue('name');
	}

	get order(){
		return this.getDataValue('order');
	}

	get avatar(){
		return this.getDataValue('avatar');
	}

	get info(){
		const data = this.getData();
		return {
			get count_item(){
				return getOrThrow(data, 'info_count_item');
			},
			get count_question(){
				return getOrThrow(data, 'info_count_question');
			},
			get count_complete_question(){
				return getOrThrow(data, 'info_count_complete_question');
			},
			get count_complete_item(){
				return getOrThrow(data, 'info_count_complete_item');
			},
			get count_attempts_question(){
				return getOrThrow(data, 'info_count_attempts_question');
			},
			get sum_points(){
				return getOrThrow(data, 'info_sum_points');
			},
			get progress_item(){
				if(!this.count_item){
					return 0;
				}
				return Math.round(this.count_complete_item / this.count_item * 100);
			},
			get progress_question(){
				if(!this.count_question){
					return 0;
				}
				return Math.round(this.count_complete_question / this.count_question * 100);
			},
			get success_index(){
				if(!this.count_attempts_question){
					return 0;
				}
				return Math.round(this.count_complete_question / this.count_attempts_question * 100);
			},
		};
	}

	get course(): Course{
		return this.getDataValue('course', Course);
	}

	get items(): Item[]{
		return this.getDataValue('items', ItemClassSelector, {module: this});
	}

	get reward_maps(): RewardMap[]{
		return this.getDataValue('reward_maps', RewardMapSelector);
	}
}

/**
 *
 * @returns {[]}
 * @constructor
 */
export const ModuleAvatars = () => {
	const contextFiles = require.context('app/assets/feedback', true);

	const files = contextFiles.keys().map(contextFiles);
	const names = contextFiles.keys().map(v => v.replace(/^\.\/|\.\w+$/, ''));

	return files.map((f,i) => [f,names[i]]);
};

export const getModuleAvatar = (name) => {
	return ModuleAvatars().find(([f,n]) => n === name)
}
