import Student from '_entity/Acess/Student';
import SchoolEntity from '_entity/SchoolEntity';

export default class StudentPayment extends SchoolEntity{
	get student(){
		return this.getDataValue("student",Student)
	}

	get id(){
		return `student` + this.student.id
	}

	get notification_info(){
		return this.getDataValue('notification_info')
	}
	get create_info(){
		return this.getDataValue('create_info')
	}

	get status():'pending'|'evaluating'|'completed'|'canceled'|'refunded'|'disputing'|'not_started'{
		return this.getDataValue("status")
	}

	get type():'pagseguro'|'royalpag'{
		return this.getDataValue("type")
	}

	get refundable(){
		return this.getDataValue('is_refundable')
	}

	get status_label(){
		switch(this.status){
			case 'pending':
				return 'Pendente'
			case 'not_started':
				return 'Aguardando'
			case 'evaluating':
				return 'Avaliando';
			case 'completed':
				return 'Pago';
			case 'canceled':
				return 'Cancelado';
			case 'refunded':
				return 'Estornado';
			case 'disputing':
				return 'Em disputa';
			default:
				return 'Não definido';
		}
	}

	get is_completed(){
		return this.status === 'completed';
	}

	get type_label(){
		switch(this.type){
			case 'royalpag':
				return 'RoyalPag';
			case 'pagseguro':
				return `PagSeguro (${this.boleto_url ? 'Boleto' : 'Cartão de credito'})`;
			default:
				return 'Não definido';
		}
	}

	get ignore(){
		return this.getDataValue('ignore')
	}

	get in_process(){
		return !!this.type;
	}

	get is_boleto(){
		return this.type === 'boleto'
	}

	get boleto_url(){
		return this.getDataValue('boleto_url')
	}


	get royalpag_url(){
		return this.getDataValue('royalpag_url')
	}

	get cost(){
		return this.getDataValue('cost')
	}

	get fichas(){
		return this.getDataValue('fichas')
	}
}