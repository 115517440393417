// import Content from '_entity/Course/Module/Item/Content/Content';
import ContentTip from '_entity/Course/Module/Item/Content/ContentTip';
import {ContentTipReducerUtils} from '_reducer/Course/Module/Content/ContentTipReducer';
import {EmptyMessage} from 'app/components/message/messages';
import WidgetSearch from 'app/pages/StudentView/Content/Widgets/WidgetSearch';
import SchoolLoader from 'app/SchoolLoader';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

WidgetTips.propTypes = {

};

/**
 *
 * @param {Content} content
 * @returns {*}
 * @constructor
 */
function WidgetTips({content}){
	const dispatch = useDispatch();
	const listState = useSelector(ContentTipReducerUtils.list.map)
	const {finished} = listState;
	const tips: ContentTip[] = listState.data;
	const contentID = content.id

	useEffect(() => {
		ContentTipReducerUtils.list.dispatch(dispatch,contentID)

		return () => {
			ContentTipReducerUtils.list.dispatch_clear(dispatch)
		}
	},[dispatch,contentID])

	if(!finished){
		return <SchoolLoader basic />;
	}

	return (
		<div className="tips">
			<WidgetSearch
				className="tip-list"
				items={tips}
				filter={['tags', 'title', 'description']}
				empty={<EmptyMessage message="Nenhuma dica disponível" />}
			>
				{tip => (
					<div key={tip.id} className="tip-entry">
						<div className="tip-header">{tip.title}</div>
						<div className="tip-body">{tip.description}</div>
					</div>
				)}
			</WidgetSearch>
		</div>
	);
}

export default WidgetTips;
