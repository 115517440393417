import Course from '_entity/Course/Course';
import BadgeType from '_entity/Game/Badge';
import FormBadge from '_form/Game/FormBadge';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {BadgeTypeReducerUtils} from '_reducer/Game/BadgeTypeReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const BadgeCreatingBreadcrumb = (course: Course) => ([
	...CourseShowingBreadcrumb(course),
	{path: AdminRoutes.BADGE_CREATING.replace(':id', course.id), text: 'Adicionar badge'},
]);

function BadgeCreatingPage(props){
	const createState = useSelector(BadgeTypeReducerUtils.create.map);
	const readState = useSelector(CourseReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: courseID} = useParams();
	const course: Course = readState.data;
	const create = (data) => BadgeTypeReducerUtils.create.dispatch(dispatch, courseID, data);

	useEffect(() => {
			CourseReducerUtils.read.dispatch(dispatch, courseID);

			return () => {
				CourseReducerUtils.read.dispatch_clear(dispatch);
				BadgeTypeReducerUtils.create.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={BadgeCreatingBreadcrumb(course)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormBadge
					onSubmit={create}
					error={createState.error}
					afterSuccessAlert={() => pushPrevious}
					success={!!createState.finished}
					badge={new BadgeType({
						name: '',
						slug: '',
						description: '',
						image_src: '',
					})}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default BadgeCreatingPage;