import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import jsonwebtoken from 'jsonwebtoken';

export const Security = {
	load: () => localStorage.getItem('TOKEN'),
	token: () => {
		const user = Security.load();
		if(!!user){
			return user.token;
		}

		return null;
	},
	save: (token) => localStorage.setItem('TOKEN', token),
	save_username: (username) => sessionStorage.setItem('last_username', username),
	save_avatar: (avatar) => avatar && sessionStorage.setItem('last_avatar', avatar),
	last_avatar: () => sessionStorage.getItem('last_avatar'),
	erase_avatar: () => sessionStorage.removeItem('last_avatar'),
	last_username: () => ifNull(sessionStorage.getItem('last_username'), ''),
	erase: () => localStorage.removeItem('TOKEN'),
	has: (mainRole) => {
		const token = Security.load();
		if(token === null){
			return false;
		}
		const jwt = jsonwebtoken.decode(token);

		return jwt.roles.indexOf(mainRole) >= 0;
	},
	save_pathname: (pathname) => sessionStorage.setItem('last_pathname', pathname),
	last_pathname: () => sessionStorage.getItem('last_pathname'),
};
