import {asset, ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import AvatarFeedbackField from '_form/AvatarFeedbackField';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFileImage from 'app/components/form/SchoolFormFileImage';
import SchoolSelect from 'app/components/form/SchoolSelect';
import elos from 'assets/images/logo_principal.png';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Form, InputGroup, Row} from 'react-bootstrap';

class FormCourse extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		course: new Course({user: {}}),
	};
	state = {
		image: null,
		logo: null,
	};


	handleData = (data) => {
		data.image = this.state.image;
		data.logo = this.state.logo;

		if(this.isNew()){
			delete data.image;
			delete data.avatars;
		}
		return data;
	};

	isNew = () => !this.props.course.id;

	getAlertMessage(): string{
		return 'Curso foi salvo!';
	}

	form(){
		const course: Course = this.props.course;
		const initial = {
			name: ifNull(course.name, ''),
			logo: ifNull(course.logo),
			image: ifNull(course.image),
			cost: ifNull(course.cost),
			paymentMethods: [],
			user: {
				name: ifNull(course.user.name, ''),
				username: ifNull(course.user.username, ''),
				email: ifNull(course.user.email, ''),
			},
			avatars: course.avatars
		};
		const paymentMethodsOptions = [
			{label: 'PagSeguro', value: 'pagseguro'},
			{label: 'RoyalPag', value: 'royalpag'},
		];
		const methods = paymentMethodsOptions.map(({value}) => value);

		if(this.isNew()){
			initial.user.password = '';
		}
		initial.paymentMethods = [methods[0]];
		// console.log(methods)
		// console.log(paymentMethodsOptions)

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
				asFunc
			>
				{({setFieldValue}) => (<>
					<SchoolFormSimpleField name="user.name" label="Nome do Usuário" />
					<Row>
						<Col>
							<SchoolFormSimpleField name="user.username" label="Apelido de usuário" />
						</Col>
						<Col>
							<SchoolFormSimpleField name="user.email" label="Email" type="email" />
						</Col>
					</Row>
					{this.isNew() && <SchoolFormSimpleField name="user.password" label="Senha" type="password" />}
					<SchoolFormSimpleField name="name" label="Nome do curso" />
					<SchoolSelect
						multiple
						defaultValue={paymentMethodsOptions.filter(p => initial.paymentMethods.includes(p.value))}
						name="paymentMethods"
						onChange={(options) => setFieldValue("paymentMethods", options.map(({value}) => value))}
						placeholder="Selecione as formas de pagamento"
						label="Formas de pagamento"
						options={paymentMethodsOptions}
					/>
					<div className="form-group">
						<Form.Label>Custo</Form.Label>
						<InputGroup>
							<InputGroup.Prepend>
								<InputGroup.Text>
									R$
								</InputGroup.Text>
							</InputGroup.Prepend>
							<SchoolFormSimpleField withoutGroup withoutLabel name="cost" label="custo" type="number" componentProps={{min:0,step: 0.01}} />
						</InputGroup>
					</div>
					{!this.isNew() && (
						<>
							<SchoolFormFileImage
								src={initial.image ? asset(initial.image) : elos}
								imgClassName="header-logo"
								label="Logo"
								fileProps={{onChange: ({file: image}) => this.setState({image})}}
							/>
							<SchoolFormFileImage
								src={initial.logo ? asset(initial.logo) : elos}
								imgClassName="header-logo"
								label="Logo em texto"
								fileProps={{onChange: ({file: logo}) => this.setState({logo})}}
							/>
							<AvatarFeedbackField name="avatars" initial={initial.avatars} setFieldValue={setFieldValue}/>
						</>
					)}
				</>)}
			</SchoolForm>
		);
	}
}

export default FormCourse;
