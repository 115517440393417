import ReducerApiOptions from '@appit.com.br/react-utils/src/reducer/ReducerApiOptions';
import {Security} from 'hoc/security';


export default class SchoolReducerOptions extends ReducerApiOptions {

	retrieveTokenFromStorage(){
		return Security.load();
	}
}
