import {getPagseguroCheckoutUrl} from '@appit.com.br/react-misc/src/integration/PagseguroAwareness';
import {InformativeReducerUtils} from '_reducer/Informative/InformativeReducer';
import ChangeCpfForm from 'app/pages/WaitingView/ChangeCpfForm';
import ChangeNameForm from 'app/pages/WaitingView/ChangeNameForm';
import {openPopup} from 'hoc/utils';
import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

function PaymentPagseguro({asFrame, refresh}){
	const dispatch = useDispatch();
	const initPayState = useSelector(InformativeReducerUtils.generatePayPagseguro.map);
	// const student: Student = useAppitUserAwareness();
	const initPay = () => InformativeReducerUtils.generatePayPagseguro.dispatch(dispatch);
	const [payUrl, setPayUrl] = useState();

	useEffect(() => {
		if(initPayState.finished && !initPayState.error){
			const {code, sandbox} = initPayState.data;
			const url = getPagseguroCheckoutUrl(code, sandbox);
			if(asFrame){
				setPayUrl(url);
			}
			else{
				openPopup(url,"Pagamento")
			}
			refresh();
		}
	}, [asFrame, initPayState, refresh]);

	if(initPayState.error){
		console.log(initPayState.error);
		if(initPayState.error.isAxiosError && initPayState.error.response.data.error){
			const error = initPayState.error.response.data.error;

			return <ul className="p-4">
				{error.code === '11012' && <li>
					Nome precisa ser completo
					<ChangeNameForm />
				</li>}
				{error.code === '11164' && <li>
					CPF incorreto
					<ChangeCpfForm />
				</li>}
				{['10044', '11007'].includes(error.code) && <li>
					Não foi possivel iniciar a operação com o pagseguro
				</li>}
			</ul>;
		}

		return 'Erro desconhecido';
	}

	if(!asFrame){
		return <>
			<div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
				<Button
					variant="link"
					onClick={initPay}
				>
					Clique aqui para fazer o pagamento pelo PagSeguro
				</Button>
				<div className="small text-muted flex-shrink-0">Um popup será aberto</div>
			</div>
		</>;
	}
	const {sandbox} = initPayState.data;

	return (
		<iframe title="Pagamento" src={payUrl} frameBorder="0" data-sandbox={sandbox ? 'yes' : undefined} />
	);
}

export default PaymentPagseguro;
