// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import CommentApi from '_api/Social/CommentApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const CommentReducerOptions = new SchoolReducerOptions('comment', CommentApi);

/**
 *
 * @type {{
 *     like: ReducerBasicOption
 *     list: ReducerBasicOption
 *     listReplies: ReducerBasicOption
 *     create: ReducerBasicOption
 *     createReply: ReducerBasicOption
 * }}
 */
export const CommentReducerUtils: $ObjMap<string, ReducerBasicOption> = CommentReducerOptions.options();
