// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import StudentEventApi from '_api/Student/StudentEventApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const StudentEventReducerOptions = new SchoolReducerOptions('student_event', StudentEventApi);

/**
 *
 * @type {{
 *     set: ReducerBasicOption
 * }}
 */
export const StudentEventReducerUtils: $ObjMap<string, ReducerBasicOption> = StudentEventReducerOptions.options();
