import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';

class WidgetSearch extends React.Component {
	static propTypes = {
		filter: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string),
		]).isRequired,
		className: PropTypes.string,
		items: PropTypes.array.isRequired,
		children: PropTypes.func.isRequired,
		empty: PropTypes.any,
	};
	static defaultProps = {
		className: '',
	};

	state = {
		query: null,
	};

	render(){
		const {items, children, empty, className} = this.props;
		const {query} = this.state;
		let filter = this.props.filter;
		if(typeof filter === 'string'){
			filter = [filter];
		}
		let filterCallback = () => true;
		if(Array.isArray(filter)){
			filterCallback = item => {
				// console.log(filter);
				if(!query){
					return true;
				}
				const reg = new RegExp(query.toLowerCase(), 'g');

				console.log(reg);
				for(const f of filter){
					const value = item[f];
					if(Array.isArray(value)){
						for(const tag of value){
							const match = tag.toLowerCase().match(reg)
							if(!!match && match.length > 0){
								return true;
							}
						}
					}
					else{
						const match = value.toLowerCase().match(reg)
						// console.log(item[f]);
						if(!!match && match.length > 0){
							return true;
						}
					}
				}

				return false;
			};
		}

		const filteredItems = items.filter(filterCallback);
		return (<>
			<Form.Group className="widget-form">
				<Form.Label>Buscar...</Form.Label>
				<Form.Control
					autoComplete="off"
					name="query" size="lg"
					onChange={(e) => this.setState({query: e.currentTarget.value})}
				/>
			</Form.Group>
			<div className={className}>
				{filteredItems.length === 0
					? empty
					: filteredItems.map(children)
				}
			</div>
		</>);
	}
}

export default WidgetSearch;