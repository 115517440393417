import {Formik} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

class SchoolForm extends React.Component {
	static propTypes = {
		validate: PropTypes.func,
		onSubmit: PropTypes.func,
		onChange: PropTypes.func,
		className: PropTypes.string,
		initialValues: PropTypes.any,
		formProps: PropTypes.any,
		submitProps: PropTypes.any,
		submitText: PropTypes.any,
		submittingText: PropTypes.any,
		hideSubmit: PropTypes.bool,
		asFunc: PropTypes.bool,
		wrap: PropTypes.bool,
		wrapClassName: PropTypes.string
	};
	static defaultProps = {
		submitText: 'Enviar',
		submittingText: 'Enviando...',
		wrapClassName: '',
		submitProps: {},
		submittingProps: {},
		initialValues: {},
		className: '',
		hideSubmit: false,
		asFunc: false,
		validate: () => ({}),
	};

	render(){
		const Wrap = ({children}) => {
			if(this.props.wrap || this.props.wrapClassName){
				return (
					<div className={this.props.wrapClassName}>
						{children}
					</div>
				)
			}

			return children;
		}

		return (
			<Formik
				validate={this.props.validate}
				onSubmit={this.props.onSubmit}
				onChange={this.props.onChange}
				initialValues={this.props.initialValues}
				validateOnMount={true}
				validateOnBlur={true}
			>
				{
					({isSubmitting, handleSubmit, errors,values, setFieldValue, isValid, validateForm,...extraProps}) => {
						let text = this.props.submitText;
						let props = this.props.submitProps;
						const isSending = isSubmitting ? !isValid : false;
						if(isSending){
							text = this.props.submittingText;
							props = this.props.submittingProps;
						}

						return (
							<Form
								onSubmit={handleSubmit}
								className={`form ${this.props.className}`}
								{...this.props.formProps}
							>
								{this.props.asFunc ? this.props.children({
									isSubmitting,
									values,
									setFieldValue,
									validateForm,
									errors,
									isValid,
									isSending,
									text,
									props,
									extraProps
								}) : this.props.children}
								{!this.props.hideSubmit && (
									<Wrap>
										<Button
											type="submit"
											variant="primary"
											disabled={isSending} {...props}>
											{text}
										</Button>
									</Wrap>
								)}
							</Form>
						);
					}
				}
			</Formik>
		);
	}
}

export default SchoolForm;