import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Student from '_entity/Acess/Student';
import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import StudentPage from 'app/pages/StudentView/StudentPage';
import PaymentSelector from 'app/pages/WaitingView/PaymentSelector';
import PaymentShowing from 'app/pages/WaitingView/PaymentShowing';
import React, {useCallback, useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

function WaitingPage(props){
	const dispatch = useDispatch();
	const user: Student = useAppitUserAwareness();
	const payment = user.payment;

	const refresh = useCallback(() => SchoolAuthReducerUtils.check_aux.dispatch(dispatch), [dispatch]);
	// const checkPayment = useCallback(() => StudentReducerUtils.check_my_payment.dispatch(dispatch),[dispatch])
	// const refreshState = useSelector(SchoolAuthReducerUtils.check_aux.map);
	const checkPaymentState = useSelector(StudentReducerUtils.check_my_payment.map);
	const hideSelector = !!payment.stamp_create || ['pending'].includes(payment.status);
	const showSelector = !hideSelector || ['canceled', 'refunded','not_started'].includes(payment.status);
	// const exclusive = true;
	const [si,setSi] = useState();
	const clearSi = useCallback(() => {
		if(!!si){
			clearInterval(si);
		}
	},[si])

	useEffect(() => {
		const si = setInterval(() => StudentReducerUtils.check_my_payment.dispatch(dispatch),15000)
		setSi(si)
	},[dispatch])

	useEffect(() => {
		if(['completed','canceled','pending'].includes(user.payment.status)){
			clearSi()
			return;
		}
		if(checkPaymentState.finished){
			const auxUser:Student = checkPaymentState.data;
			if(auxUser){
				const changeStatus = auxUser.payment.status !== user.payment.status;
				if(changeStatus){
					SchoolAuthReducerUtils.check.dispatch(dispatch)
				}
			}
		}
		else{
			// SchoolAuthReducerUtils.check_aux.dispatch(dispatch)
		}
	},[dispatch,checkPaymentState,user,clearSi])

	return (
		<StudentPage
			ignoreIntro
			mainClass="main-profile h-100"
			contentClass="container py-4"
			headerClass="showing-payment"
		>
			{showSelector
				? <PaymentSelector methods={user.course.methods} refresh={refresh} />
				: <Card className={'rounded-0'}>
					<Card.Header>
						<h3>Informações de pagamento</h3>
					</Card.Header>
					<Card.Body className="payments-form rounded-0">
						<PaymentShowing showChat={payment.type === 'royalpag'} payment={payment} refresh={refresh} />
					</Card.Body>
				</Card>
			}
		</StudentPage>
	);
}

export default WaitingPage;
