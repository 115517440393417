import Content from '_entity/Course/Module/Item/Content/Content';
import ContentRange from '_entity/Course/Module/Item/Content/ContentRange';
import FormContentRange from '_form/Course/Module/Item/Content/FormContentRange';
import {ContentRangeReducerUtils} from '_reducer/Course/Module/Content/ContentRangeReducer';
import {ContentReducerUtils} from '_reducer/Course/Module/Content/ContentReducer';
import {Stamp} from '_ui/EntityUi';
import RangeElement from 'app/components/new/Content/Footer/Range/RangeElement';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import ContentSelector from 'app/pages/CourseView/ContentRange/ContentSelector';
import CoursePage from 'app/pages/CourseView/CoursePage';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import React, {useCallback, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useHistory, useParams} from 'react-router-dom';

export const ContentRangeBreadcrumb = (action, range: ContentRange) => {
	if(action === 'add'){
		return [
			...ContentRangeBreadcrumb('list'),
			{path: CourseRoutes.RANGE_ACTIONS.replace(':action', 'add'), text: 'Adicionar Range'},
		];
	}
	if(action === 'edit' && !!range){
		return [
			...ContentRangeBreadcrumb('list'),
			{path: CourseRoutes.RANGE_ACTIONS.replace(':action', 'edit'), text: `${range.name} / Editar`},
		];
	}

	return [
		{path: CourseRoutes.RANGE_ACTIONS.replace(':action', 'list'), text: 'Ranges'},
	];
};

function ContentRangePage(props){
	const [range: ContentRange, setRange] = useState();
	const {action} = useParams();
	const history = useHistory();
	const [content: Content, setContent] = useState();
	const dispatch = useDispatch();
	const remove = (rangeId) => ContentRangeReducerUtils.delete.dispatch(dispatch, rangeId);
	const edit = (data) => !!range && ContentRangeReducerUtils.edit.dispatch(dispatch, range.id, data);
	const create = (data) => !!content && ContentRangeReducerUtils.create.dispatch(dispatch, content.id, data);
	const listingState = useSelector(ContentRangeReducerUtils.listAll.map);
	const removeState = useSelector(ContentRangeReducerUtils.delete.map);
	const editState = useSelector(ContentRangeReducerUtils.edit.map);
	const contents = useSelector(ContentReducerUtils.list.map).data || [];
	const creatingState = useSelector(ContentRangeReducerUtils.create.map);

	const start = useCallback(() => {
		ContentRangeReducerUtils.listAll.dispatch(dispatch);
		ContentReducerUtils.list.dispatch(dispatch);
	}, [dispatch]);

	useEffect(() => {
		if(action === 'list'){
			ContentRangeReducerUtils.listAll.dispatch(dispatch);
			ContentReducerUtils.list.dispatch(dispatch);

			return () => {
				ContentRangeReducerUtils.listAll.dispatch_clear(dispatch);
				ContentReducerUtils.list.dispatch_clear(dispatch);
			};
		}

		ContentRangeReducerUtils.listAll.dispatch(dispatch);
		return () => {
			ContentRangeReducerUtils.listAll.dispatch_clear(dispatch);
		};
	}, [dispatch, action]);

	useEffect(() => {
			if(!!range){
				history.push(CourseRoutes.RANGE_ACTIONS.replace(':action', 'edit'));
			}
		},
		// eslint-disable-next-line
		[range],
	);

	useEffect(() => {
		SchoolOperationAlert({
			successMessage: `Range editado com sucesso!`,
			successAction: start,
			finished: editState.finished,
			error: editState.error,
		});
	}, [editState, start]);

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Range foi removido!`,
				successAction: start,
				finished: removeState.finished,
				error: removeState.error,
			});
		},
		// eslint-disable-next-line
		[removeState],
	);

	// console.log(action);
	if(action === 'list' && !listingState.finished){
		return <TemplateSplash />;
	}

	let ranges: ContentRange[] = listingState.data;

	if(!!content){
		ranges = ranges.filter((r) => r.content.id === content.id);
	}

	return (
		<CoursePage
			pageClassName="h-100"
			breadcrumb={ContentRangeBreadcrumb(action, range)}
			actions={action === 'list'
				? <LinkContainer to={CourseRoutes.RANGE_ACTIONS.replace(':action', 'create')}>
					<Button variant="primary">
						Adicionar
					</Button>
				</LinkContainer>
				: <LinkContainer to={CourseRoutes.RANGE_ACTIONS.replace(':action', 'list')}>
					<Button variant="primary">
						Voltar
					</Button>
				</LinkContainer>
			}
		>
			{action !== 'edit' && (
				<ContentSelector
					onSelect={setContent}
					contents={contents}
				/>
			)}
			{action === 'list' && (
				<Table className="">
					<tbody>
					{ranges.map((range) => (
						<tr key={range.id}>
							<td className="w-0">
								<Button
									variant="warning"
									onClick={() => {
										setRange(range);
									}}
								>
									<i className="fa fa-edit" />
								</Button>
							</td>
							<td className="w-0">
								<RangeElement
									range={range}
									size="sm"
								/>
							</td>
							<td>
								<div>
									{range.name}
								</div>
								<div className="text-muted small">
									Conteúdo: #{range.content.order} {range.content.name}
								</div>
								<div className="text-muted small">
									Módulo: #{range.content.module.order} {range.content.module.name}
								</div>
							</td>
							<td className="w-0 text-nowrap text-right">
								<Stamp entity={range} />
							</td>
							<td className="w-0 align-middle action-cell">
								<Button
									variant="danger" onClick={() => SchoolConfirmAlert({
									title: 'Realmente deseja deletar esse range?',
									message: 'Essa operação não pode ser revertida!',
									action: () => remove(range.id),
								})}
								>
									<i className="fa fa-trash" />
								</Button>
							</td>
						</tr>
					))}
					</tbody>
				</Table>
			)}
			{action === 'edit' && (
				<FormContentRange
					range={range}
					onSubmit={edit}
					error={editState.error}
					success={editState.finished}
					afterSuccessAlert={start}
				/>
			)}
			{action === 'add' && (
				<NewSchoolBreadcrumbHeaderConsumer>
					{({pushPrevious}) => <FormContentRange
						validate={(errors) => {
							if(!content){
								errors.content = true;
							}
						}}
						onSubmit={create}
						error={creatingState.error}
						success={creatingState.finished}
						afterSuccessAlert={pushPrevious}
					/>}
				</NewSchoolBreadcrumbHeaderConsumer>
			)}
		</CoursePage>
	);
}

export default ContentRangePage;