import Module from '_entity/Course/Module/Module';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import ModuleCard from 'app/components/new/Page/Student/ModuleCard';
import StudentPage from 'app/pages/StudentView/StudentPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slick from 'react-slick';

StudentModulePage.propTypes = {};

const breakpoint = {
	xs: 576,
	sm: 1200,
	md: 1400,
};

function StudentModulePage(props){
	const dispatch = useDispatch();
	// const course = useAppitUserAwareness().course;

	useEffect(() => {
		ModuleReducerUtils.list.dispatch(dispatch);

		return () => {
			ModuleReducerUtils.list.dispatch_clear(dispatch);
		};
	}, [dispatch]);

	const listState = useSelector(ModuleReducerUtils.list.map);
	const modules: Module[] = listState.data || [];
	// const course = useAppitUserAwareness().course;

	if(!listState.finished){
		return <TemplateSplash />;
	}

	return (
		<StudentPage contentClass="module-listing" headerClass="showing-modules">
			<Slick
				dots speed={500} infinite={false} slidesToShow={4} responsive={[
				{
					breakpoint: breakpoint.xs,
					settings: {
						slidesToShow: 1,
						dots: false
					},
				},
				{
					breakpoint: breakpoint.sm,
					settings: {
						slidesToShow: 3,
						dots: false
					},
				},
				{
					breakpoint: breakpoint.md,
					settings: {
						slidesToShow: 3,
					},
				},
			]}
			>
				{modules.map(module => (
					<ModuleCard
						key={module.id}
						module={module}
					/>
				))
				}
			</Slick>
		</StudentPage>
	);
}

export default StudentModulePage;