import {ErrorMessage, Field} from 'formik';
import React from 'react';
import Form from 'react-bootstrap/Form';

const SchoolFormSimpleField = ({value,style,disabled,readOnly,autoComplete,noAutocomplete,placeholder, name, className = '', label, type = 'string', size = 'lg', component, componentProps = {}, withoutGroup = false, withoutLabel = false, beforeControl = (a) => a,children}) => {
	const Element = withoutGroup ? (!!className ? 'div' : React.Fragment) : Form.Group;
	const props = {};
	if(Element === 'div'){
		props.className = className;
	}
	if(value !== undefined && value !== null){
		componentProps.value = value
	}

	return (
		<Element {...props}>
			{!withoutLabel && (
				<Form.Label>{label ? label : name[0].toUpperCase() + name.substr(1, name.length)}</Form.Label>
			)}
			{(beforeControl(<Form.Control
				size={size}
				className={`Control ${type === 'file' ? 'custom-file-input' : ''}`}
				as={Field}
				style={style}
				component={component}
				disabled={disabled}
				readOnly={readOnly}
				type={type}
				name={name}
				autoComplete={autoComplete ? autoComplete : noAutocomplete && "Off"}
				placeholder={placeholder ? placeholder : label ? label : name}
				id={`input_${name}`}
				{...componentProps}
			/>))}
			<ErrorMessage name={name} component="div" />
			{children}
		</Element>
	);
};

export default SchoolFormSimpleField;