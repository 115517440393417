import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ItemApi from '_api/Course/Module/Item/ItemApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ItemReducerOptions = new SchoolReducerOptions('item', ItemApi);

/**
 *
 * @type {{
 *     read: ReducerBasicOption
 *     remove: ReducerBasicOption
 * }}
 */
export const ItemReducerUtils: $ObjMap<string, ReducerBasicOption> = ItemReducerOptions.options();
