// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ContentApi from '_api/Course/Module/Content/ContentApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ContentReducerOptions = new SchoolReducerOptions('content', ContentApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 *     read: ReducerBasicOption
 *     create: ReducerBasicOption
 *     edit: ReducerBasicOption
 *     delete: ReducerBasicOption
 *     deactivate: ReducerBasicOption
 *     activate: ReducerBasicOption
 * }}
 */
export const ContentReducerUtils: $ObjMap<string, ReducerBasicOption> = ContentReducerOptions.options();
