import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import Student from '_entity/Acess/Student';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import {LearningObjectReducerUtils} from '_reducer/Course/Module/LearningObject/LearningObjectReducer';
import {ViewedItemReducerUtils} from '_reducer/Progress/ViewedItemReducer';
import SchoolLearningObject from 'app/components/new/Content/Item/SchoolLearningObject';
import ItemInfoElement from 'app/pages/StudentView/Content/Element/ItemInfoElement';
import {useSchoolCourseAlert} from 'hoc/alert';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

LearningObjectElement.propTypes = {
	learning_object: PropTypes.instanceOf(LearningObject),
	student: PropTypes.instanceOf(Student),
};

/**
 *
 * @param {LearningObject} learning_object
 * @param {Student} student
 * @returns {*}
 * @constructor
 */
function LearningObjectElement({learning_object, refreshPoints}){
	const dispatch = useDispatch();
	const [dataForFeedback, setDataForFeedback] = useState(null);
	const [isOnlySave, setIsOnlySave] = useState(null);
	// const [locked,setLocked] = useState(false)
	const viewState: ReducerStateType = useSelector(ViewedItemReducerUtils.view.map);
	const stepState: ReducerStateType = useSelector(ViewedItemReducerUtils.step.map);
	const stepAndViewState: ReducerStateType = useSelector(ViewedItemReducerUtils.step_and_view.map);
	const viewLock = viewState.begined && !viewState.finished;
	const stepLock = stepState.begined && !stepState.finished;
	const saveOnStepLock = stepAndViewState.begined && !stepAndViewState.finished;
	const feedbackState: ReducerStateType = useSelector(ViewedItemReducerUtils.feedback.map);
	const simFeedbackState: ReducerStateType = useSelector(LearningObjectReducerUtils.sim_view.map);
	const [feedbackOrSim, setFeedbackOrSim] = useState(true);

	const view = useCallback((data) => {
		if(viewLock){
			return;
		}
		console.log('VIEW')
		setDataForFeedback(data);
		setIsOnlySave(true);
		ViewedItemReducerUtils.view.dispatch(dispatch, learning_object.id, data);
	}, [viewLock, dispatch, learning_object.id]);
	const step = useCallback((data) => {
		if(stepLock){
			return;
		}
		console.log('STEP')
		setIsOnlySave(false);
		ViewedItemReducerUtils.step.dispatch(dispatch, learning_object.id, data);
	}, [dispatch, stepLock, learning_object.id]);
	const saveOnStep = useCallback((stepData) => {
		if(saveOnStepLock){
			return;
		}
		console.log('SAVE ON STEP')
		ViewedItemReducerUtils.step_and_view.dispatch(dispatch, learning_object.id, stepData, {});
	}, [dispatch, saveOnStepLock, learning_object.id]);
	const clear = useCallback(() => {
		console.log("CLEAR")
		ViewedItemReducerUtils.view.dispatch_clear(dispatch);
		ViewedItemReducerUtils.step_and_view.dispatch_clear(dispatch);
		ViewedItemReducerUtils.feedback.dispatch_clear(dispatch);
		LearningObjectReducerUtils.sim_view.dispatch_clear(dispatch);
		setDataForFeedback(null);
	}, [dispatch]);

	const error = isOnlySave ? viewState.error : stepAndViewState.error;
	const finished = isOnlySave ? viewState.finished : stepAndViewState.finished;
	const begined = isOnlySave ? viewState.begined : stepAndViewState.begined;
	const feedback = feedbackOrSim ? feedbackState.data : simFeedbackState.data;
	const isFeedbackLoading = feedbackOrSim ? !feedbackState.finished : !simFeedbackState.finished;
	const user: Student = useAppitUserAwareness();

	const Alerts = useSchoolCourseAlert(user.course, learning_object.module);

	const showFeedback = useCallback(() => {
		if(!!error){
			setFeedbackOrSim(false);
			LearningObjectReducerUtils.sim_view.dispatch(dispatch, learning_object.id, dataForFeedback);
		}
		else{
			setFeedbackOrSim(true);
			ViewedItemReducerUtils.feedback.dispatch(dispatch, learning_object.id);
		}
	},[dispatch,error,learning_object.id,dataForFeedback])
	const afterFeedback = useCallback(() => {
		clear();
		refreshPoints();
	},[clear,refreshPoints])

	useEffect(() => clear, [clear]);

	useEffect(() => {
		// console.log("1")
		if(finished){
			let loadFeedback = !error;
			if(!!error){
				loadFeedback = error.isAxiosError && error.response.status === 406;
				if(loadFeedback && dataForFeedback !== null){
					showFeedback();
					Alerts.Feedback(
						'Esse objeto de aprendizagem já foi concluído!',
						'Os pontos anteriores serão substituidos caso sua pontuação sejá maior.',
						afterFeedback,
					);
				}
			}
			else{
				showFeedback();
			}
			if(!loadFeedback){
				Alerts.Feedback(
					'Nao foi possível completar a operação',
					'',
					afterFeedback,
				);
			}
		}
	}, [Alerts, error, finished, dispatch, dataForFeedback, refreshPoints, learning_object.id, clear,showFeedback,afterFeedback]);

	useEffect(() => {
		if(!!feedbackState.error){
			Alerts.Feedback('Nao foi possível visualizar o feedback');
		}
	}, [Alerts, feedbackState]);

	return (
		<SchoolLearningObject
			// className="container"
			onlyLandscape
			willSendPoints
			willSendBadges
			learningObject={learning_object}
			feedback={feedback}
			isFeedbackLoading={isFeedbackLoading}
			// isFeedbackLoading={false}
			isFeedbackShowing={begined}
			// isFeedbackShowing={true}
			onSave={view}
			onStep={step}
			onSaveOnStep={saveOnStep}
			onClear={clear}
			locked={viewLock || stepLock || saveOnStepLock}
		>
			<ItemInfoElement is_sending={begined && isFeedbackLoading} element={learning_object} />
		</SchoolLearningObject>
	);
}

export default LearningObjectElement;
