import {dateOrNull, getOrThrow} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import User from '_entity/Acess/User';
import CourseContact from '_entity/Course/CourseContact';
import CourseFAQ from '_entity/Course/CourseFAQ';
import CourseIntegration from '_entity/Course/CourseIntegration';
import CourseMailing from '_entity/Course/CourseMailing';
import Module from '_entity/Course/Module/Module';
import BadgeType from '_entity/Game/Badge';
import SchoolEntity from '_entity/SchoolEntity';

export default class Course extends SchoolEntity {

	get name(){
		return this.getDataValue('name');
	}

	get cost(){
		return this.getDataValue('cost');
	}

	get is_open(){
		return this.getDataValue('is_open');
	}

	get payment_name(){
		return this.getDataValue('payment_name');
	}

	get avatars():string[]{
		return this.getDataValue('avatars') || [];
	}

	get username(): Course{
		return this.getDataValue('username');
	}

	get image(){
		return this.getDataValue('image_src');
	}

	get methods():string[]{
		return this.getDataValue("methods")
	}

	get logo(){
		return this.getDataValue('logo_text_src');
	}

	get points_name(){
		return this.getDataValue('points_name');
	}

	get reward_maps(){
		return this.getDataValue('reward_maps');
	}

	get intro(){
		return this.getDataValue('intro_src');
	}

	get outro(){
		return this.getDataValue('outro_src');
	}

	get intro_external(){
		return this.getDataValue('intro_external');
	}

	get outro_external(){
		return this.getDataValue('outro_external');
	}

	get image_stamp(){
		return dateOrNull(this.getDataValue('stamp_image'));
	}

	get faqs(): CourseFAQ[]{
		return this.getDataValue('faqs', CourseFAQ);
	}

	get badges():BadgeType[]{
		return this.getDataValue("badges",BadgeType,{course:this})
	}

	get user(): User{
		return this.getDataValue('user', User);
	}

	get modules(): Module[]{
		return this.getDataValue('modules', Module, {course: this});
	}

	get students(): Student[]{
		return this.getDataValue('students', Student);
	}

	get apis():CourseIntegration[]{
		return this.getDataValue('apis',CourseIntegration)
	}

	get welcome(){
		return this.getDataValue('welcome');
	}

	get mailing():CourseMailing{
		return this.getDataValue('mailing',CourseMailing)
	}

	get contact():CourseContact{
		return this.getDataValue('contact',CourseContact)
	}

	get info(){
		const info = this.getDataValue("info")

		return {
			students: getOrThrow(info,"students"),
			students_ignore: getOrThrow(info,"students_ignore"),
			students_paid: getOrThrow(info,"students_paid"),
			students_pending: getOrThrow(info,"students_pending"),
			students_others: getOrThrow(info,"students_others")
		}
	}
}

export const CourseTerms = () => {
	const contextFiles = require.context('app/assets/terms', true);

	const files = contextFiles.keys().map(contextFiles);
	const names = contextFiles.keys().map(v => v.replace(/^\.\/|\.\w+$/, '').replace(/\.\w+$/,""));

	return files.map((f,i) => [f,names[i]]);
};

export const getCourseTerm = (name) => {
	return CourseTerms().find(([f,n]) => n === name)
}
