import Question from '_entity/Course/Module/Item/Content/Question/Question';
import Item from '_entity/Course/Module/Item/Item';
import React from 'react';
import Badge from 'react-bootstrap/Badge';

/**
 *
 * @param {Item|Question} element
 * @param {boolean} is_sending
 * @param {any} children
 * @returns {*}
 */
export default ({element, is_sending, children}) => {
	let fallback = is_sending ? 'Visualizando' : 'Não visto';
	let message = 'Visto';
	let useFallback = true;
	let variant = 'success';
	if(element instanceof Question){
		message = element.locked ? 'Melhor resposta' : 'Respondido';
		variant = !!element.last_answer && element.last_answer.correct ? variant : 'info';
		fallback = is_sending ? 'Respondendo' : 'Não respondido';
		useFallback = element.is_answered;
	}
	if(element instanceof Item){
		useFallback = element.is_viewed;
	}

	return (
		<div className="info-bar" key={element.id + (useFallback ? "_sim" : "_nao")}>
			{useFallback
				? <Badge variant={variant} className={!!children ? 'mr-2' : ''}>{message}</Badge>
				: <Badge variant="warning" className={!!children ? 'mr-2' : ''}>{fallback}</Badge>
			}
			{children}
		</div>
	);
}