import Student from '_entity/Acess/Student';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import {SchoolOperationAlert} from 'hoc/alert';
import Tooltiper from 'hoc/ui/Tooltiper';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';

AccessButton.propTypes = {
	student: PropTypes.instanceOf(Student),
	onGrant: PropTypes.func,
	onRevoke: PropTypes.func,
};

function AccessButton({student, onGrant, onRevoke}){
	const dispatch = useDispatch();
	const revoke = useCallback(() => StudentReducerUtils.revoke.dispatch(dispatch, student.id),[dispatch,student.id]);
	const revokeState = useSelector(StudentReducerUtils.revoke.map);
	const grant = useCallback(() => StudentReducerUtils.grant.dispatch(dispatch, student.id),[dispatch,student.id]);
	const grantState = useSelector(StudentReducerUtils.grant.map);

	useEffect(() => {
			return () => {
				StudentReducerUtils.revoke.dispatch_clear(dispatch);
				StudentReducerUtils.grant.dispatch_clear(dispatch);
			};
		},
		[dispatch]);

	useEffect(() => {
			if(grantState.finished){
				SchoolOperationAlert({
					successMessage: 'Acesso garantido',
					successAction: onGrant,
					finished: grantState.finished,
					error: grantState.error,
				});
			}
		},
		// eslint-disable-next-line
		[grantState],
	);

	useEffect(() => {
			if(revokeState.finished){
				SchoolOperationAlert({
					successMessage: 'Acesso revogado',
					successAction: onRevoke,
					finished: revokeState.finished,
					error: revokeState.error,
				});
			}
		},
		// eslint-disable-next-line
		[revokeState],
	);

	if(student.active){
		return (
			<Tooltiper tooltip="Revogar acesso">
				<Button variant="will-lock" onClick={revoke} />
			</Tooltiper>
		);
	}

	return (
		<Tooltiper tooltip="Garantir acesso">
			<Button variant="will-unlock" onClick={grant} />
		</Tooltiper>
	);
}

export default AccessButton;