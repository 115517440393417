import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import Content from '_entity/Course/Module/Item/Content/Content';

export default class ContentApi extends SchoolApi {
	get namespace(){
		return 'contents';
	}

	get entity_class(){
		return Content;
	}

	read(id){
		return this.json.get(id);
	}

	list(){
		return this.json.get();
	}

	edit(id, data){
		expectsString(id);
		expectsObject(data);
		return this.multipart.post(id, data);
	}

	create(moduleID, data){
		expectsString(moduleID);
		expectsObject(data);
		return this.multipart.post(`by_module/${moduleID}`, data);
	}

	delete(contentID){
		expectsString(contentID);
		return this.json.delete(contentID);
	}

	deactivate(contentID){
		expectsString(contentID);
		return this.json.patch(`${contentID}/deactivate`);
	}

	activate(contentID){
		expectsString(contentID);
		return this.json.patch(`${contentID}/activate`);
	}
}
