import ContentRangePage from 'app/pages/CourseView/ContentRange/ContentRangePage';
import FAQPage from 'app/pages/CourseView/FAQ/FAQPage';
import InfoPage from 'app/pages/CourseView/Info/InfoPage';
import SettingsPage from 'app/pages/CourseView/Settings/SettingsPage';
import CourseStudentPage from 'app/pages/CourseView/Student/CourseStudentPage';
import StudentShowingPage from 'app/pages/CourseView/Student/StudentShowingPage';

export const CourseRoutes = {
	STUDENT_ACTIONS: '/students/:action',
	STUDENT_SHOWING: '/students/:id/show',
	FAQ_ACTIONS: '/faq/:action',
	RANGE_ACTIONS: '/range/:action',
	SETTINGS: '/settings',
	INFO: '/info',
};

export const CoursePages = {
	'routes': {
		[CourseRoutes.STUDENT_SHOWING]: StudentShowingPage,
		[CourseRoutes.STUDENT_ACTIONS]: CourseStudentPage,
		[CourseRoutes.FAQ_ACTIONS]: FAQPage,
		[CourseRoutes.RANGE_ACTIONS]: ContentRangePage,
		[CourseRoutes.SETTINGS]: SettingsPage,
		[CourseRoutes.INFO]: InfoPage,
	},
	'redirect': CourseRoutes.STUDENT_ACTIONS.replace(':action', 'list'),
};