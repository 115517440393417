import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import BadgeCreatingPage from 'app/pages/AdminView/Badge/BadgeCreatingPage';
import BadgeEditingPage from 'app/pages/AdminView/Badge/BadgeEditingPage';
import ContentCreatingPage from 'app/pages/AdminView/Content/ContentCreatingPage';
import ContentEditingPage from 'app/pages/AdminView/Content/ContentEditingPage';
import ContentShowingPage from 'app/pages/AdminView/Content/ContentShowingPage';
import ContentFileCreatingPage from 'app/pages/AdminView/ContentFile/ContentFileCreatingPage';
import ContentGlossaryCreatingPage from 'app/pages/AdminView/ContentGlossary/ContentGlossaryCreatingPage';
import ContentRangeCreatingPage from 'app/pages/AdminView/ContentRange/ContentRangeCreatingPage';
import ContentRangeEditingPage from 'app/pages/AdminView/ContentRange/ContentRangeEditingPage';
import ContentTipCreatingPage from 'app/pages/AdminView/ContentTip/ContentTipCreatingPage';
import CourseIntegrationAddPage from 'app/pages/AdminView/Course/CourseAddApiPage';
import CourseCreatingPage from 'app/pages/AdminView/Course/CourseCreatingPage';
import CourseEditingPage from 'app/pages/AdminView/Course/CourseEditingPage';
import CourseInfoPage from 'app/pages/AdminView/Course/CourseInfoPage';
import CourseListingPage from 'app/pages/AdminView/Course/CourseListingPage';
import CourseShowingPage from 'app/pages/AdminView/Course/CourseShowingPage';
import CourseVideosWritePage from 'app/pages/AdminView/Course/CourseVideosWritePage';
import LearningObjectCreatingPage from 'app/pages/AdminView/LearningObject/LearningObjectCreatingPage';
import LearningObjectEditingPage from 'app/pages/AdminView/LearningObject/LearningObjectEditingPage';
import LearningObjectShowingPage from 'app/pages/AdminView/LearningObject/LearningObjectShowingPage';
import ModuleCreatingPage from 'app/pages/AdminView/Module/ModuleCreatingPage';
import ModuleEditingPage from 'app/pages/AdminView/Module/ModuleEditingPage';
import ModuleShowingPage from 'app/pages/AdminView/Module/ModuleShowingPage';
import QuestionCreatingPage from 'app/pages/AdminView/Question/QuestionCreatingPage';
import RewardMapForCourseCreatingPage from 'app/pages/AdminView/RewardMap/RewardMapForCourseCreatingPage';
import RewardMapForItemCreatingPage from 'app/pages/AdminView/RewardMap/RewardMapForItemCreatingPage';
import RewardMapForModuleCreatingPage from 'app/pages/AdminView/RewardMap/RewardMapForModuleCreatingPage';

export const AdminPages = {
	'routes': {
		[AdminRoutes.COURSE_LISTING]: CourseListingPage,
		[AdminRoutes.COURSE_SHOWING]: CourseShowingPage,
		[AdminRoutes.COURSE_CREATING]: CourseCreatingPage,
		[AdminRoutes.COURSE_EDITING]: CourseEditingPage,

		[AdminRoutes.MODULE_CREATING]: ModuleCreatingPage,
		[AdminRoutes.MODULE_SHOWING]: ModuleShowingPage,
		[AdminRoutes.MODULE_EDITING]: ModuleEditingPage,

		[AdminRoutes.CONTENT_SHOWING]: ContentShowingPage,
		[AdminRoutes.CONTENT_CREATING]: ContentCreatingPage,
		[AdminRoutes.CONTENT_EDITING]: ContentEditingPage,

		[AdminRoutes.LEARNING_OBJECT_SHOWING]: LearningObjectShowingPage,
		[AdminRoutes.LEARNING_OBJECT_CREATING]: LearningObjectCreatingPage,
		[AdminRoutes.LEARNING_OBJECT_EDITING]: LearningObjectEditingPage,

		[AdminRoutes.CONTENT_TIP_CREATING]: ContentTipCreatingPage,
		[AdminRoutes.CONTENT_GLOSSARY_CREATING]: ContentGlossaryCreatingPage,
		[AdminRoutes.CONTENT_FILE_CREATING]: ContentFileCreatingPage,
		[AdminRoutes.CONTENT_RANGE_CREATING]: ContentRangeCreatingPage,
		[AdminRoutes.CONTENT_RANGE_EDITING]: ContentRangeEditingPage,

		[AdminRoutes.QUESTION_MODIFYING]: QuestionCreatingPage,

		[AdminRoutes.REWARD_MAP_ITEM_CREATING]: RewardMapForItemCreatingPage,
		[AdminRoutes.REWARD_MAP_MODULE_CREATING]: RewardMapForModuleCreatingPage,
		[AdminRoutes.REWARD_MAP_COURSE_CREATING]: RewardMapForCourseCreatingPage,

		[AdminRoutes.BADGE_CREATING]: BadgeCreatingPage,
		[AdminRoutes.BADGE_EDITING]: BadgeEditingPage,

		[AdminRoutes.COURSE_INTEGRATION_CREATING]: CourseIntegrationAddPage,
		[AdminRoutes.COURSE_EDITING_VIDEOS]: CourseVideosWritePage,

		[AdminRoutes.COURSE_INFO]: CourseInfoPage,
	},
	'redirect': AdminRoutes.COURSE_LISTING,
};