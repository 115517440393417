import Content from '_entity/Course/Module/Item/Content/Content';
import FormContentRange from '_form/Course/Module/Item/Content/FormContentRange';
import {ContentRangeReducerUtils} from '_reducer/Course/Module/Content/ContentRangeReducer';
import {ContentReducerUtils} from '_reducer/Course/Module/Content/ContentReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ContentShowingBreadcrumb} from 'app/pages/AdminView/Content/ContentShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const ContentRangeCreatingBreadcrumb = (content: Content) => ([
	...ContentShowingBreadcrumb(content),
	{path: AdminRoutes.CONTENT_SHOWING.replace(':id', content.id), text: 'Adicionar Range'},
]);

function ContentRangeCreatingPage(props){
	const createState = useSelector(ContentRangeReducerUtils.create.map);
	const readState = useSelector(ContentReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: contentID} = useParams();
	const content: Content = readState.data;
	const create = (data) => ContentRangeReducerUtils.create.dispatch(dispatch, contentID, data);

	useEffect(() => {
		ContentReducerUtils.read.dispatch(dispatch, contentID);

		return () => {
			ContentReducerUtils.read.dispatch_clear(dispatch);
			ContentRangeReducerUtils.create.dispatch_clear(dispatch);
		};
	}, [dispatch, contentID]);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage breadcrumb={ContentRangeCreatingBreadcrumb(content)}>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormContentRange
					course={content.module.course}
					onSubmit={create}
					error={createState.error}
					success={createState.finished}
					afterSuccessAlert={pushPrevious}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default ContentRangeCreatingPage;