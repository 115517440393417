import React from 'react';

/**
 *
 * @param {string} className
 * @param {*} message
 * @param {boolean} center
 * @returns {*}
 * @constructor
 */
export const EmptyMessage = ({className = '', message = 'Não há items disponiveis', center}) => (
	<div className={`text-muted px-1 text-lowercase ${center ? 'text-center' : ''} ${className}`}>
		{message}
	</div>
);
export const HeaderMessage = ({className = '', message}) => (
	<h4 className={`display-4 font-weight-bold text-uppercase h-100 w-100 d-flex justify-content-center align-items-center ${className}`}>
		{message}
	</h4>
);

export const pluralization = (str, value) => {
	const replaces = [...str.matchAll(/\((?:\w|\W[^:()])*(?::(?:\w|\W[^:()])*\))+/g)];
	value = !value ? 0 : value;
	for(const target of replaces){
		const targets = target[0].split(':');
		const targetValue = value >= targets.length ? targets.length - 1 : value;
		const replace = targets[targetValue].replace(/^\(|\)$/, '');

		str = str.replace(target, replace);
	}

	return str;
};

export const StandardMessage = ({className = '', children}) => (
	<div className={`h5 mb-0 font-weight-bold text-uppercase ${className}`}>
		{children}
	</div>
);