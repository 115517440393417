export const AdminRoutes = {
	base: '/admin',
	COURSE_LISTING: '/admin/courses',
	COURSE_CREATING: '/admin/course/new',
	COURSE_SHOWING: '/admin/course/:id/show',
	COURSE_EDITING: '/admin/course/:id/edit',
	COURSE_INFO: '/admin/course/:id/info',
	COURSE_EDITING_VIDEOS: '/admin/course/:id/videos',


	COURSE_INTEGRATION_CREATING: '/admin/course/:id/api/new',

	MODULE_CREATING: '/admin/course/:id/module/new',
	MODULE_SHOWING: '/admin/module/:id/show',
	MODULE_EDITING: '/admin/module/:id/edit',

	CONTENT_CREATING: '/admin/module/:id/content/new',
	CONTENT_SHOWING: '/admin/content/:id/show',
	CONTENT_EDITING: '/admin/content/:id/edit',

	LEARNING_OBJECT_CREATING: '/admin/module/:id/learning_object/new',
	LEARNING_OBJECT_SHOWING: '/admin/learning_object/:id/show',
	LEARNING_OBJECT_EDITING: '/admin/learning_object/:id/edit',

	CONTENT_TIP_CREATING: '/admin/content/:id/tip/new',
	CONTENT_GLOSSARY_CREATING: '/admin/content/:id/glossary/new',
	CONTENT_FILE_CREATING: '/admin/content/:id/file/new',
	CONTENT_RANGE_CREATING: '/admin/content/:id/range/new',
	CONTENT_RANGE_EDITING: '/admin/content_range/:id/edit',

	QUESTION_MODIFYING: '/admin/content/:id/question/modify',

	REWARD_MAP_ITEM_CREATING: '/admin/content/:id/reward/add',
	REWARD_MAP_MODULE_CREATING: '/admin/module/:id/reward/add',
	REWARD_MAP_COURSE_CREATING: '/admin/course/:id/reward/add',

	BADGE_CREATING: '/admin/course/:id/badge/new',
	BADGE_EDITING: '/admin/badge/:id/edit',
};