import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import User from '_entity/Acess/User';
import {AdminPages} from 'app/pages/AdminView/AdminRouterConfig';
import {CoursePages} from 'app/pages/CourseView/CourseRouterConfig';
import {StudentPages} from 'app/pages/StudentView/StudentRouterConfig';
import {WaitingPages} from 'app/pages/WaitingView/WaitingRouterConfig';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

function Views(props){
	const PAGES = {
		ROLE_STUDENT: StudentPages,
		ROLE_STUDENT_WAITING: WaitingPages,
		ROLE_ADMIN: AdminPages,
		ROLE_COURSE: CoursePages,
	};
	const user = useAppitUserAwareness();

	if(!(user instanceof User)){
		throw new Error(`User in context is is not a instance of User`);
	}

	return Object.keys(PAGES).map((ROLE, index) => {
		const config = PAGES[ROLE];
		const routes = config.routes;
		const redirect = config.redirect;
		// const lastPathname = localStorage.getItem('last_pathname');
		if(user.main_role === ROLE){
			return (
				<Switch key={index + ROLE}>
					{Object.keys(routes).map(route => {
						const Page = routes[route];

						return (
							<Route key={index + 'School' + ROLE + route} exact path={route} component={Page} />
						);
					})}
					<Redirect to={redirect} />
				</Switch>
			);
		}

		return null;
	});
}

export default Views;
