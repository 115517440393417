import Tooltiper from 'hoc/ui/Tooltiper';
import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React, {useLayoutEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import InlineSVG from 'react-inlinesvg';
import {LinkContainer} from 'react-router-bootstrap';

SchoolSidebarLeft.propTypes = {
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	className: PropTypes.string,
	active: PropTypes.string,
	items: PropTypes.objectOf(PropTypes.shape({
		icon: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.element,
		]),
		title: PropTypes.string,
		svg: PropTypes.bool,
		isTo: PropTypes.bool,
	})),
};

SchoolSidebarLeft.defaultProps = {
	onSelect: (eventKey, event) => null,
	className: '',
};

function SchoolSidebarLeft({items, onClose, onSelect, active, className, hidden, setHidden}){
	const [hiddenState, setHiddenState] = useState(false);
	if(hidden === undefined || setHidden === undefined){
		hidden = hiddenState;
		setHidden = setHiddenState;
	}

	useLayoutEffect(() => {
		new PerfectScrollbar('#sidebar-left', {
			wheelPropagation: false,
		});
	}, []);

	return (
		<>
			<aside id="sidebar-left" className={className + ` ${hidden ? 'hidden-sidebar' : ''}`}>
				<Nav activeKey={active} onSelect={onSelect}>
					{Object.keys(items).map((itemName, index) => {
						let icon = items[itemName].icon;

						if(typeof icon === 'string'){
							icon = items[itemName].svg
								? <InlineSVG src={icon} />
								: <i className={icon} />
							;
						}

						return (
							<Tooltiper tooltip={items[itemName].title} key={`sidebar-${index}`}>
								{items[itemName].isTo
									? (
										<LinkContainer to={itemName}>
											<Button
												as={Nav.Link}
												active={items[itemName].active} {...items[itemName].props}>
												{icon}
											</Button>
										</LinkContainer>
									)
									: (
										<Button
											as={Nav.Link}
											eventKey={itemName}
											active={items[itemName].active} {...items[itemName].props}>
											{icon}
										</Button>
									)
								}
							</Tooltiper>
						);
					})}
				</Nav>
			</aside>
			<div id="sidebar-left-actions">
				<Button
					variant="primary"
					disabled={!!items[active]}
					onClick={() => setHidden(!hidden)}
				>
					<i className={`fa fa-angle-double-left ${hidden ? 'fa-rotate-180' : ''}`} />
				</Button>
				<Button variant="primary" onClick={onClose}>
					<i className="fa fa-times" />
				</Button>
			</div>
		</>
	);
}

export default SchoolSidebarLeft;