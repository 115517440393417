import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import {UserReducerUtils} from '_reducer/Auth/AuthReducer';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import PageLogo from 'app/components/PageLogo';
import NewPage from 'app/pages/NewPage';
import {useCourseAwareness} from 'app/pages/StudentView/Access/CourseAwareness';
import {useSchoolCourseAlert} from 'hoc/alert';
import {Security} from 'hoc/security';
import React, {useCallback, useEffect} from 'react';
import {Form} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

function StudentForgetPasswordPage({loginPath}){
	const {course, course_username, getInternalUsername} = useCourseAwareness();
	const lastUsername = getInternalUsername(Security.last_username());
	const dispatch = useDispatch();
	const forgetState = useSelector(UserReducerUtils.forget.map);
	const history = useHistory();

	const forget = ({username, id_personal, email}) => {
		UserReducerUtils.forget.dispatch(dispatch, {
			username,
			id_personal,
			email,
			course_username,
		});
	};
	const successAction = useCallback(() => {
		UserReducerUtils.forget.dispatch_clear(dispatch);
		history.push(loginPath)
	},[dispatch,history,loginPath])
	const Alerts = useSchoolCourseAlert(course)

	useEffect(() => {
		Alerts.OperationAlert({
			successTitle: "Link com reset da senha enviado com sucesso",
			successMessage: "Um link para resetar a senha foi enviado para seu email.",
			error: forgetState.error,
			finished: forgetState.finished,
			successAction
		})
	},[Alerts,forgetState,successAction])

	return (
		<NewPage logo={asset(course.image)} title={<PageLogo course={course} />} contentClass="login">
			<SchoolForm
				hideSubmit
				className="login"
				submitText="Resetar senha"
				submittingText="Aguarde..."
				initialValues={{
					username: lastUsername,
					password: '',
				}}
				onSubmit={forget}
				asFunc
			>
				{({isSending, isValid, text, props}) => (
					<div className="login-card">
						<div className="login-title">
							<img src={asset(course.image)} alt="" />
							<PageLogo course={course} />
						</div>
						<Form.Group>
							<SchoolFormSimpleField
								name="username"
								label="Usuário, email ou cpf"
								componentProps={{disabled: isSending}}
							/>
							<Button
								size="lg"
								block
								type="submit"
								variant="light"
								disabled={isSending} {...props}>
								{text}
							</Button>
						</Form.Group>
						<div className="login-title">
							<img src={asset(course.image)} alt="" />
							<PageLogo course={course} />
						</div>
						<Link to={loginPath} className="login-link">
							Voltar
						</Link>
					</div>)}
			</SchoolForm>
		</NewPage>
	);
}

export default StudentForgetPasswordPage;
