import SchoolEntity from '_entity/SchoolEntity';


export default class StudentEvent extends SchoolEntity {
	static VIEWED_INTRO = 'viewed_intro';
	static VIEWED_OUTRO = 'viewed_outro';

	get name(){
		return this.getDataValue('name');
	}

	get value(){
		return this.getDataValue('value');
	}
}