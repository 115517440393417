import SchoolApi from '_api/SchoolApi';
import StudentEvent from '_entity/Acess/StudentEvent';

export default class StudentEventApi extends SchoolApi {
	get namespace(){
		return 'events';
	}

	get entity_class(){
		return StudentEvent;
	}

	set(name, value){
		return this.json.post(name, {value});
	}
}