import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolLoader from 'app/SchoolLoader';
import {SchoolFeedback} from 'hoc/alert';
import * as immutable from 'object-path-immutable';
import PropTypes from 'prop-types';
import React from 'react';

class FormEntity extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func,
		afterSuccessAlert: PropTypes.func,
		success: PropTypes.bool,
		error: PropTypes.any,
	};

	static defaultProps = {
		onSubmit: (data) => null,
		afterSuccessAlert: () => null,
		error: null,
	};

	state = {
		showError: false,
		hideForm: false,
	};

	hideForm = () => this.setState({
		hideForm: true,
		showError: true,
	});
	showForm = () => this.setState({
		hideForm: false,
		showError: false,
	});
	isShowing = () => !this.state.hideForm;
	setSubmitting = (b) => null;

	handleData = (data) => {
		console.log(data)


		return data;
	};

	onSubmit = (values, {setSubmitting}) => {
		const handledValues = this.handleData(values);
		this.props.onSubmit(handledValues);
		// this.hideForm();
		// this.setSubmitting = setSubmitting;
	};

	getAlertTitle(){
		return 'Operação realizada com sucesso';
	}

	getAlertMessage(){
		return 'Os dados foram salvos com sucesso';
	}

	getAlertErrorTitle(){
		return 'Operação não foi realizada';
	}

	getErrorPropName(name){
		return null;
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		if(this.props.success && !this.isShowing() && this.state.showError){
			SchoolFeedback(
				this.getAlertTitle(),
				this.getAlertMessage(),
				() => {
					this.showForm();
					this.props.afterSuccessAlert();
				},
			);
		}
		if(!!this.props.error && this.state.showError){
			const msgs = [];
			// console.log(this.props.error);
			const errors = immutable.get(this.props.error, 'response.data.result',
				immutable.get(this.props.error, 'response.data.violations', []),
			);
			for(const error of errors){
				let path = error.property_path || error.field;
				if(!!path){
					path = ifNull(this.getErrorPropName(path), path);
					msgs.push(`${path}, ${error.message}`);
				}
				else{
					msgs.push(error.message);
				}
			}
			SchoolFeedback(this.getAlertErrorTitle(), msgs.join('<br>'), () => {
				this.showForm();
			});
		}
	}

	hideLoader(){
		return false;
	}

	form(){
		return null;
	}

	render(): React.ReactNode{
		if(this.isShowing() || this.hideLoader()){
			return this.form();
		}

		return <SchoolLoader basic />;
	}
}

export default FormEntity;
