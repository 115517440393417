import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import {StudentAccessReducerUtils} from '_reducer/Student/StudentAccessReducer';
import SchoolPaginator from 'app/components/Paginator/SchoolPaginator';
import SchoolLoader from 'app/SchoolLoader';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Table} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

/**
 *
 * @param {Student} student
 * @returns {*}
 * @constructor
 */
function AccessList({student}){
	const dispatch = useDispatch();
	const accessesState = useSelector(StudentAccessReducerUtils.list.map);
	const accesses = accessesState.data || [];
	const [page,setPage] = useState(0)

	const list = useCallback(() => {
		StudentAccessReducerUtils.list.dispatch(dispatch,student.id,page)
	},[dispatch,student.id,page])

	useEffect(() => {
		list();

		return () => {
			StudentAccessReducerUtils.list.dispatch_clear(dispatch)
		}
	},[dispatch,list])

	const pagination = useMemo(() => {
		if(!accessesState.pagination){
			return pagination;
		}
		return accessesState.pagination;
	},[accessesState.pagination]);
	const paginator = pagination && <SchoolPaginator pagination={pagination} setPage={setPage} page={page}/>

	return (<>
		{paginator}
		{accessesState.finished
			? <Table>
				<thead>
				<tr>
					<th>
						<div>IP</div>
						<div className="small text-muted">Data</div>
					</th>
				</tr>
				</thead>
				<tbody>
				{accesses.map(access => (
					<tr key={access.id}>
						<td className="text-center text-sm-left">
							<div>{ifEmpty(access.ip,"N/A")}</div>
							<div className="small text-muted">{access.stamp_create.toLocaleString("BR")}</div>
						</td>
					</tr>
				))}
				</tbody>
			</Table>
			: <SchoolLoader basic/>
		}
		{paginator}
	</>);
}

export default AccessList;
