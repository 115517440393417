import ReducerSettings from '@appit.com.br/react-utils/src/reducer/ReducerSettings';
import {SchoolAuthReducerOptions, UserReducerOptions} from '_reducer/Auth/AuthReducer';
import {CourseFaqReducerOptions} from '_reducer/Course/CourseFAQReducer';
import {CourseIntegrationReducerOptions} from '_reducer/Course/CourseIntegrationReducer';
import {CourseMailingReducerOptions} from '_reducer/Course/CourseMailingReducer';
import {CourseReducerOptions} from '_reducer/Course/CourseReducer';
import {ContentFileReducerOptions} from '_reducer/Course/Module/Content/ContentFileReducer';
import {ContentGlossaryReducerOptions} from '_reducer/Course/Module/Content/ContentGlossaryReducer';
import {ContentRangeReducerOptions} from '_reducer/Course/Module/Content/ContentRangeReducer';
import {ContentReducerOptions} from '_reducer/Course/Module/Content/ContentReducer';
import {ContentTipReducerOptions} from '_reducer/Course/Module/Content/ContentTipReducer';
import {QuestionReducerOptions} from '_reducer/Course/Module/Content/Question/QuestionReducer';
import {ItemReducerOptions} from '_reducer/Course/Module/Item/ItemReducer';
import {LearningObjectReducerOptions} from '_reducer/Course/Module/LearningObject/LearningObjectReducer';
import {ModuleReducerOptions} from '_reducer/Course/Module/ModuleReducer';
import {BadgeTypeReducerOptions} from '_reducer/Game/BadgeTypeReducer';
import {RewardMapReducerOptions} from '_reducer/Game/RewardMapReducer';
import {InformativeReducerOptions} from '_reducer/Informative/InformativeReducer';
import {AnsweredQuestionReducerOptions} from '_reducer/Progress/AnsweredQuestionReducer';
import {ViewedItemReducerOptions} from '_reducer/Progress/ViewedItemReducer';
import {CommentReducerOptions} from '_reducer/Social/CommentReducer';
import {LikeReducerOptions} from '_reducer/Social/LikeReducer';
import {StudentAccessReducerOptions} from '_reducer/Student/StudentAccessReducer';
import {StudentEventReducerOptions} from '_reducer/Student/StudentEventReducer';
import {StudentReducerOptions} from '_reducer/Student/StudentReducer';
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import SchoolRouter from './SchoolRouter';

class SchoolApp extends React.Component {
	store = createStore(
		combineReducers({
			[ReducerSettings.AUTH_DOMAIN]: combineReducers({
				[SchoolAuthReducerOptions.REDUCER_NAME]: SchoolAuthReducerOptions.reducer,
				[UserReducerOptions.REDUCER_NAME]: UserReducerOptions.reducer,
			}),
			[ReducerSettings.ENTITY_DOMAIN]: combineReducers({
				[StudentReducerOptions.REDUCER_NAME]: StudentReducerOptions.reducer,

				[CourseReducerOptions.REDUCER_NAME]: CourseReducerOptions.reducer,
				[ModuleReducerOptions.REDUCER_NAME]: ModuleReducerOptions.reducer,

				[ContentReducerOptions.REDUCER_NAME]: ContentReducerOptions.reducer,
				[QuestionReducerOptions.REDUCER_NAME]: QuestionReducerOptions.reducer,
				[ContentGlossaryReducerOptions.REDUCER_NAME]: ContentGlossaryReducerOptions.reducer,
				[ContentRangeReducerOptions.REDUCER_NAME]: ContentRangeReducerOptions.reducer,
				[ContentTipReducerOptions.REDUCER_NAME]: ContentTipReducerOptions.reducer,
				[ContentFileReducerOptions.REDUCER_NAME]: ContentFileReducerOptions.reducer,

				[LikeReducerOptions.REDUCER_NAME]: LikeReducerOptions.reducer,
				[CommentReducerOptions.REDUCER_NAME]: CommentReducerOptions.reducer,

				[LearningObjectReducerOptions.REDUCER_NAME]: LearningObjectReducerOptions.reducer,

				[ViewedItemReducerOptions.REDUCER_NAME]: ViewedItemReducerOptions.reducer,
				[AnsweredQuestionReducerOptions.REDUCER_NAME]: AnsweredQuestionReducerOptions.reducer,

				[InformativeReducerOptions.REDUCER_NAME]: InformativeReducerOptions.reducer,

				[StudentEventReducerOptions.REDUCER_NAME]: StudentEventReducerOptions.reducer,
				[CourseFaqReducerOptions.REDUCER_NAME]: CourseFaqReducerOptions.reducer,

				[RewardMapReducerOptions.REDUCER_NAME]: RewardMapReducerOptions.reducer,
				[ItemReducerOptions.REDUCER_NAME]: ItemReducerOptions.reducer,
				[BadgeTypeReducerOptions.REDUCER_NAME]: BadgeTypeReducerOptions.reducer,

				[CourseIntegrationReducerOptions.REDUCER_NAME]: CourseIntegrationReducerOptions.reducer,
				[StudentAccessReducerOptions.REDUCER_NAME]: StudentAccessReducerOptions.reducer,
				[CourseMailingReducerOptions.REDUCER_NAME]: CourseMailingReducerOptions.reducer,
			}),
		}),
		applyMiddleware(thunk),
	);

	render(){
		return (
			<Provider store={this.store}>
				<BrowserRouter basename={this.props.basename}>
					<SchoolRouter />
					<ToastContainer />
					<Route path="/redirect" component={Redirect}/>
				</BrowserRouter>
			</Provider>
		);
	}
}

const Redirect = () => {
	window.parent.reload();

	return null;
}

export default SchoolApp;
