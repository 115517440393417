import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import React from 'react';

class ChangePasswordForm extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (values) => {

		return values;
	};

	getAlertTitle(): string{
		return `Senha alterada com sucesso`;
	}

	getAlertMessage(): string{
		return 'Senha alterada salvo!';
	}

	form(){
		// const student: Student = this.props.student;
		const initial = {
			password_new: "",
			password: "",
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
			>
				<SchoolFormSimpleField name="password" label="Senha atual" type="password" autoComplete="new-password" />
				<SchoolFormSimpleField name="password_new" label="Nova senha" type="password" autoComplete="new-password" />
			</SchoolForm>
		);
	}
}

export default ChangePasswordForm;