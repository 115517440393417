import ReducerAuthOptions from '@appit.com.br/react-utils/src/reducer/ReducerAuthOptions';
// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ReducerSettings from '@appit.com.br/react-utils/src/reducer/ReducerSettings';
import UserApi from '_api/User/UserApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';
import {Security} from 'hoc/security';

class SchoolAuth extends ReducerAuthOptions {
	promiseUser({getFromStorage}): Promise{
		return new UserApi(getFromStorage())
			.check()
			.then(({data}) => {
				Security.save_avatar(data.avatar);
				return data;
			});
	}

	promiseToken(login, password,course_username): Promise{
		Security.save_username(login);
		return new UserApi().login(login, password,course_username)
			.then(({data}) => data);
	}


	_eraseToken(){
		super._eraseToken();
		// Security.erase_last_avatar()
	}
}

const options = new SchoolAuth('auth');

export {options as SchoolAuthReducerOptions};

/**
 *
 * @type {{logout: ReducerBasicOption, check_aux: ReducerBasicOption, check: ReducerBasicOption, login: ReducerBasicOption}}
 */
export const SchoolAuthReducerUtils = options.options;

const userOptions = new SchoolReducerOptions("users",UserApi,{domain: ReducerSettings.AUTH_DOMAIN})
export {userOptions as UserReducerOptions}

/**
 *
 * @type {{
 *     create: ReducerBasicOption
 *     forget: ReducerBasicOption
 * }}
 */
export const UserReducerUtils = userOptions.options();
