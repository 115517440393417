import {getOrThrow} from '@appit.com.br/react-utils/src/utils/utils';
import RewardMapOnStep from '_entity/Game/RewardMapOnStep';
import RewardMapOnView from '_entity/Game/RewardMapOnView';
import {RewardMapSelector} from '_entity/Game/RewardMapSelector';
import ViewedItem from '_entity/Progress/ViewedItem';
import SchoolEntity from '_entity/SchoolEntity';

export default class Item extends SchoolEntity {

	get order(): number{
		return this.getDataValue('order');
	}

	get name(): string{
		return this.getDataValue('name');
	}

	get is_viewed(): boolean{
		return this.getDataValue('is_viewed');
	}

	get module(){
		throw new Error("should not be called from this context")
	}

	get info(){
		const data = this.getData();
		return {
			get count_viewed(){
				return getOrThrow(data, 'info_count_viewed');
			},
		};
	}

	get viewed_items(): ViewedItem[]{
		return this.getDataValue('viewed_items', ViewedItem, {item: this});
	}

	get viewed(): ViewedItem{
		return this.getDataValue('viewed_item', ViewedItem, {item: this});
	}

	get reward_maps(): RewardMapOnView[] | RewardMapOnStep[]{
		return this.getDataValue('reward_maps', RewardMapSelector, {item: this});
	}
}

