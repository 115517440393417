import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import BadgeType from '_entity/Game/Badge';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFileImage from 'app/components/form/SchoolFormFileImage';
import PropTypes from 'prop-types';
import React from 'react';

class FormBadge extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		badge: PropTypes.instanceOf(BadgeType),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		badge: {},
	};

	isNew = () => !this.props.badge.id;

	getAlertTitle(): string{
		return `Badge ${this.isNew() ? 'criado' : 'editado'} com sucesso`;
	}

	getAlertMessage(): string{
		return 'Badge foi salvo!';
	}

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];

			if(!value){
				errors[key] = true;
			}
		}

		return errors;
	};

	form(){
		const badge: BadgeType = this.props.badge;
		const initial = {
			name: ifNull(badge.name, ''),
			slug: ifNull(badge.slug, ''),
			description: ifNull(badge.description, ''),
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				validate={this.validate}
				submitText="Salvar"
				asFunc
			>
				{({setFieldValue}) => (<>
					<SchoolFormFileImage
						name="image"
						label="Imagem"
						src={badge.image}
						accept=".svg"
						fileProps={{
							onChange: ({file}) => setFieldValue('image', file),
						}}
					/>
					<SchoolFormSimpleField name="name" label="Nome" />
					<SchoolFormSimpleField
						name="slug"
						label="Identificador"
						componentProps={{
							onChange: (e) => {
								let value = e.target.value;
								value = value.replace(/\s|\W/,"")
								setFieldValue('slug',value)
							}
						}}
					/>
					<SchoolFormSimpleField name="description" label="Descrição" component="textarea" />
				</>)}
			</SchoolForm>
		);
	}
}

export default FormBadge;
