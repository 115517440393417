// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import LikeApi from '_api/Social/LikeApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const LikeReducerOptions = new SchoolReducerOptions('like', LikeApi);

/**
 *
 * @type {{
 *     create: ReducerBasicOption
 *     read: ReducerBasicOption
 *     list: ReducerBasicOption
 * }}
 */
export const LikeReducerUtils = LikeReducerOptions.options();
