import SchoolEntity from '_entity/SchoolEntity';


export default class User extends SchoolEntity {
	get username(): string{
		return this.getDataValue('username');
	}

	get password(): string{
		return this.getDataValue('password');
	}

	get email(): string{
		return this.getDataValue('email');
	}

	get name(): string{
		return this.getDataValue('name');
	}

	get cpf(): string{
		return this.getDataValue('cpf');
	}

	get main_role(): string{
		return this.getDataValue('main_role');
	}

	get roles(): string[]{
		return this.getDataValue('roles');
	}

	get info(){
		return {
			get count_likes(){
				throw new Error();
			},
			get count_comments(){
				throw new Error();
			},
		};
	}
}