import {withAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import logo from 'logo.png';
import PropTypes from 'prop-types';
import * as React from 'react';
import {createRef} from 'react';

class SchoolLoader extends React.Component {
	static propTypes = {
		global: PropTypes.bool,
		basic: PropTypes.bool,
		absolute: PropTypes.bool,
		relative: PropTypes.bool,
		resizeContainer: PropTypes.bool,
		className: PropTypes.string,
	};
	static defaultProps = {
		global: false,
		resizeContainer: false,
		absolute: false,
		relative: true,
		basic: false,
	};

	ref = createRef();


	componentDidMount(): void{
		if(this.props.global){
			// document.body.classList.add("loader-container")
		}
		if(this.props.resizeContainer){
			this.ref.current.parentElement.classList.add('resize-for-loader');
		}
	}

	componentWillUnmount(): void{
		if(this.props.global){
			// document.body.classList.remove("loader-container")
		}
		if(this.props.resizeContainer){
			this.ref.current.parentElement.classList.remove('resize-for-loader');
		}
	}

	render(): React.ReactNode{
		const {global, basic, className, absolute, relative} = this.props;
		if(global){
			// throw new Error();
			console.warn('global is deprecated, use TemplateSplash instead');
		}

		const user: Student = this.props.appitUserAwareness;
		let loadingText = 'Carregando...';
		if(!!user){
			if(user.main_role === 'ROLE_STUDENT'){
				loadingText = ifEmpty(user.course.name, loadingText);
			}
		}

		const classes = [];
		if(global){
			classes.push('loader-global');
		}
		else{
			if(absolute){
				classes.push('position-absolute');
			}
			if(relative){
				classes.push('position-relative');
			}
		}
		if(!!className){
			classes.push(className);
		}

		if(basic){
			return (
				<div
					ref={this.ref}
					className={`loader loader-basic ${classes.join(' ')}`}
				>
					<span className="loader-spinner">
						<i className="fas fa-circle-notch fa-spin" />
					</span>
					{loadingText}
				</div>
			);
		}
		return (
			<div ref={this.ref} className={`loader loader-card ${classes.join(' ')}`}>
				<div className="loader-content">
					<div className="loader-title">
						<span className="loader-spinner">
							<i className="fas fa-circle-notch fa-spin" />
						</span>
						{loadingText}
					</div>
					<div className="loader-logo">
						<img src={logo} alt="Loading..." />
					</div>
					<div className="loader-title">
						<span className="loader-spinner">
							<i className="fas fa-circle-notch fa-spin" />
						</span>
						{loadingText}
					</div>
				</div>
			</div>
		);
	}
}

export default withAppitUserAwareness(SchoolLoader);
