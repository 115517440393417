import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import PropTypes from 'prop-types';

import React, {useState} from 'react';

PageLogo.propTypes = {
	course: PropTypes.instanceOf(Course),
};

/**
 *
 * @param {Course} course
 * @returns {*}
 * @constructor
 */
function PageLogo({course}){
	const [success,setSuccess] = useState(true)
	if(course.logo && success){
		return <img src={asset(course.logo)} alt="" onError={() => setSuccess(false)} />;
	}

	return course.name;
}

export default PageLogo;
