import logo from 'logo.png';
import React, {createContext, useContext, useEffect, useState} from 'react';

const TemplateLoaderContext = createContext({});

export const useTemplateLoaderContext = () => useContext(TemplateLoaderContext);

export function Loader({id, global, course}){
	if(!course){
		return <div id={id} className={`loader loader-basic ${global ? 'loader-global' : ''}`}>
			<span className="loader-spinner">
				<i className="fas fa-circle-notch fa-spin" />
			</span>
			<span className="bg-light text-primary text-uppercase mt-2 p-2">
				Carregando...
			</span>
		</div>;
	}

	return (
		<div id={id} className={`loader loader-card ${global ? 'loader-global' : ''}`}>
			<div className="loader-content">
				<div className="loader-title">
					<span className="loader-spinner">
						<i className="fas fa-circle-notch fa-spin" />
					</span>
					{course.name}
				</div>
				<div className="loader-logo">
					<img src={logo} alt="Loading..." />
				</div>
				<div className="loader-title">
					<span className="loader-spinner">
						<i className="fas fa-circle-notch fa-spin" />
					</span>
					{course.name}
				</div>
			</div>
		</div>
	);
}

export function TemplateLoaderProvider({children}){
	const [count, setCount] = useState(0);
	const [course, setCourse] = useState(null);

	let visible = count > 0;
	useEffect(() => {
		// console.log("loading", count,visible)
		const splashScreen = document.getElementById('loader-global');
		// Show SplashScreen
		if(splashScreen && visible){
			splashScreen.classList.remove('loader-hide');

			return () => {
				splashScreen.classList.add('loader-hide');
			};
		}

		// Hide SplashScreen
		let timeout;
		if(splashScreen && !visible){
			timeout = setTimeout(() => {
				splashScreen.classList.add('loader-hide');
			}, 1000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [visible]);

	return (
		<TemplateLoaderContext.Provider value={{setCount, setCourse}}>
			<Loader id="loader-global" global course={course} />
			{children}
		</TemplateLoaderContext.Provider>
	);
}

export function TemplateSplash({visible = true}){
	const {setCount} = useTemplateLoaderContext();

	useEffect(() => {
		if(!visible){
			return;
		}

		setCount(prev => {
			return prev + 1;
		});

		return () => {
			setCount(prev => {
				return prev - 1;
			});
		};
	}, [setCount, visible]);

	return null;
}
