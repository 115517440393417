import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import avatar from 'assets/images/avatar.svg';
import PropTypes from 'prop-types';
import React from 'react';
import {Image} from 'react-bootstrap';

class AvatarElement extends React.Component {
	static propTypes = {
		src: PropTypes.string,
		basic: PropTypes.bool,
	};
	static defaultProps = {
		basic: false,
	};
	state = {
		error: false,
	};

	render(){
		if(this.props.basic){
			return this.state.error || !this.props.src
				? <img src={avatar} alt="" />
				: <img src={asset(this.props.src)} alt="" onError={(e) => this.setState({error: true})} />;
		}

		return this.state.error || !this.props.src
			? <Image fluid src={avatar} alt="" />
			: <Image fluid src={asset(this.props.src)} alt="" onError={(e) => this.setState({error: true})} />;
	}
}

export default AvatarElement;
