import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import CourseFAQ from '_entity/Course/CourseFAQ';

export default class CourseFAQApi extends SchoolApi {
	get namespace(){
		return 'course_faqs';
	}

	get entity_class(){
		return CourseFAQ;
	}

	list(){
		return this.json.get();
	}

	delete(id){
		expectsString(id);
		return this.json.delete(id);
	}

	create(data){
		expectsObject(data);
		return this.json.post('', data);
	}
}
