import Content from '_entity/Course/Module/Item/Content/Content';
import FormContentFile from '_form/Course/Module/Item/Content/FormContentFile';
import {ContentFileReducerUtils} from '_reducer/Course/Module/Content/ContentFileReducer';
import {ContentReducerUtils} from '_reducer/Course/Module/Content/ContentReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ContentShowingBreadcrumb} from 'app/pages/AdminView/Content/ContentShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const ContentFileCreatingBreadcrumb = (content: Content) => ([
	...ContentShowingBreadcrumb(content),
	{path: AdminRoutes.CONTENT_FILE_CREATING.replace(':id', content.id), text: 'Adicionar Arquivo'},
]);

function ContentFileCreatingPage(props){
	const createState = useSelector(ContentFileReducerUtils.create.map);
	const readState = useSelector(ContentReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: contentID} = useParams();
	const content: Content = readState.data;
	const create = (data) => ContentFileReducerUtils.create.dispatch(dispatch, contentID, data);

	useEffect(() => {
			ContentReducerUtils.read.dispatch(dispatch, contentID);

			return () => {
				ContentReducerUtils.read.dispatch_clear(dispatch);
				ContentFileReducerUtils.create.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={ContentFileCreatingBreadcrumb(content)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormContentFile
					error={createState.error}
					success={createState.finished}
					afterSuccessAlert={pushPrevious}
					onSubmit={create}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default ContentFileCreatingPage;