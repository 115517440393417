import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import React from 'react';

export default class FormContentTip extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (data) => {
		data.tags = data.tags.split(',');
		return data;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];
			if(!value){
				errors[key] = true;
			}
		}

		return errors;
	};


	getAlertTitle(): string{
		return 'Criação concluída';
	}

	getAlertMessage(): string{
		return 'Dica adicionado com sucesso';
	}

	form(){
		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				validate={this.validate}
				initialValues={{
					title: '',
					description: '',
					tags: '',
				}}
				submitText="Adicionar"
			>
				<SchoolFormSimpleField name="title" label="Titulo" />
				<SchoolFormSimpleField component="textarea" name="description" label="Descrição" />
				<SchoolFormSimpleField name="tags" />
			</SchoolForm>
		);
	}
}