import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {toFixedLocale} from '@appit.com.br/react-utils/src/utils/utils';
import Student from '_entity/Acess/Student';
import {openPopup} from 'hoc/utils';
import React, {useEffect, useLayoutEffect} from 'react';
import {Button, ButtonGroup, Col, Row} from 'react-bootstrap';

/**
 *
 * @param {StudentPayment} payment
 * @param refundHandler
 * @returns {*}
 * @constructor
 */
function PaymentShowing({payment, showRefund, refresh, showChat}){
	useEffect(() => {
		if(!!refresh){
			refresh();
			const i = setInterval(refresh, 1000 * 60);
			return () => {
				clearInterval(i);
			};
		}
	}, [refresh]);

	useLayoutEffect(() => {
		if(showChat){
			if(!window.Tawk_API){
				window.Tawk_API = {};
				window.Tawk_LoadStart = new Date();
				const s1 = document.createElement('script');
				const s0 = document.getElementsByTagName('script')[0];
				s1.async = true;
				s1.src = 'https://embed.tawk.to/5fb6dd20920fc91564c8bfdd/default';
				s1.charset = 'UTF-8';
				s1.setAttribute('crossorigin', '*');
				s0.parentNode.insertBefore(s1, s0);
			}
			else{
				window.Tawk_API.showWidget();
			}

			return () => {
				window.Tawk_API.hideWidget();
			};
		}
	}, [showChat]);

	const user = useAppitUserAwareness();

	return (
		<>
			<Row sm={3} md={4}>
				<Col>
					<div className="text-muted mt-2">Forma de pagamento</div>
					<div>{payment.type_label}</div>
				</Col>
				<Col>
					<div className="text-muted mt-2">Situação</div>
					<div>{payment.status_label}</div>
				</Col>
				<Col>
					<div className="text-muted mt-2">Valor pago:</div>
					<div>
						R$ {toFixedLocale(payment.cost)}
						{payment.type === 'royalpag' && (<>
							{' '}
							-
							{' '}
							<span className="text-muted">{toFixedLocale(payment.fichas)} fichas</span>
						</>)}
					</div>
				</Col>
				<Col>
					<div className="text-muted mt-2">Custo da matricula</div>
					<div>R$ {toFixedLocale(payment.student.course.cost)}</div>
				</Col>
				<Col>
					<div className="text-muted mt-2">Iniciado em</div>
					<div>{payment.stamp_create ? payment.stamp_create.toLocaleString('BR') : 'N/A'}</div>
				</Col>
				<Col>
					<div className="text-muted mt-2">Atualizado em</div>
					<div>{payment.stamp_change ? payment.stamp_change.toLocaleString('BR') : 'N/A'}</div>
				</Col>
				{!!payment.boleto_url && (
					<Col>
						<div className="text-muted mt-2">Boleto</div>
						<Button
							variant="link"
							className="p-0"
							onClick={() => openPopup(payment.boleto_url, 'Boleto')}
						>
							Clique aqui para ver o boleto
						</Button>
					</Col>
				)}
			</Row>
			{!!payment.boleto_url && (<>
				<hr />
				<iframe
					title="Boleto"
					src={payment.boleto_url}
					frameBorder="0"
					className="d-sm-block d-none w-100 h-100"
					style={{minHeight: '300px'}}
				/>
				<div>
				</div>
			</>)}
			{user instanceof Student && payment.type === 'royalpag' && (<>
				{/*<hr />*/}
				{/*<div>*/}
				{/*	<Button variant="link" onClick={() => openPopup(payment.royalpag_url,"RoyalPag")}>Clique aqui para ver ir a RoyalPag</Button>*/}
				{/*</div>*/}
			</>)}
			{showRefund && (<>
				<hr />
				<ButtonGroup className="float-right">
					<Button variant="outline-primary" href="https://wa.me/554891044764">
						Entre em contato
					</Button>
				</ButtonGroup>
			</>)}
		</>
	);
}

export default PaymentShowing;
