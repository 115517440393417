import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Student from '_entity/Acess/Student';
import Course from '_entity/Course/Course';
import CourseFAQ from '_entity/Course/CourseFAQ';
import {CourseFaqReducerUtils} from '_reducer/Course/CourseFAQReducer';
import ForumReplyEntry from 'app/components/new/Content/Footer/Forum/ForumReplyEntry';
import SchoolLoader from 'app/SchoolLoader';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

/**
 *
 * @returns {*}
 * @constructor
 */
function WidgetFaq(props){
	const user:Student = useAppitUserAwareness();
	const course: Course = user.course;
	const dispatch = useDispatch();
	const listState = useSelector(CourseFaqReducerUtils.list.map)
	const faqs: CourseFAQ[] = listState.data;

	useEffect(() => {
		CourseFaqReducerUtils.list.dispatch(dispatch)

		return () => {
			CourseFaqReducerUtils.list.dispatch_clear(dispatch)
		}
	},[dispatch])

	if(!listState.finished){
		return <SchoolLoader />;
	}

	return (
		<div className="forum">
			{faqs.map(faq => (
				<ForumReplyEntry
					key={faq.id}
					styledAvatar
					avatar={course.image}
					name={course.name}
					body={<>
						<div>
							<strong className="text-info">P:</strong> {faq.description_question}
						</div>
						<div>
							<strong className="text-success">R:</strong> {faq.description_answer}
						</div>
					</>}
				/>
			))}
		</div>
	);
}

export default WidgetFaq;
