import ContentRange from '_entity/Course/Module/Item/Content/ContentRange';
import FormContentRange from '_form/Course/Module/Item/Content/FormContentRange';
import {ContentRangeReducerUtils} from '_reducer/Course/Module/Content/ContentRangeReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ContentShowingBreadcrumb} from 'app/pages/AdminView/Content/ContentShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const ContentRangeEditingBreadcrumb = (range: ContentRange) => ([
	...ContentShowingBreadcrumb(range.content),
	{path: AdminRoutes.CONTENT_RANGE_EDITING.replace(':id', range.id), text: `Editar Range ${range.name}`},
]);

function ContentRangeEditingPage(props){
	const editState = useSelector(ContentRangeReducerUtils.edit.map);
	const readState = useSelector(ContentRangeReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: rangeID} = useParams();
	const range: ContentRange = readState.data;
	const edit = (data) => ContentRangeReducerUtils.edit.dispatch(dispatch, rangeID, data);

	useEffect(() => {
			ContentRangeReducerUtils.read.dispatch(dispatch, rangeID);

			return () => {
				ContentRangeReducerUtils.read.dispatch_clear(dispatch);
				ContentRangeReducerUtils.edit.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={ContentRangeEditingBreadcrumb(range)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormContentRange
					course={range.content.module.course}
					onSubmit={edit}
					error={editState.error}
					success={editState.finished}
					range={range}
					afterSuccessAlert={pushPrevious}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default ContentRangeEditingPage;