import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import Student from '_entity/Acess/Student';
import Content from '_entity/Course/Module/Item/Content/Content';
import Item from '_entity/Course/Module/Item/Item';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import Module from '_entity/Course/Module/Module';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {InformativeReducerUtils} from '_reducer/Informative/InformativeReducer';
import {ViewedItemReducerUtils} from '_reducer/Progress/ViewedItemReducer';
import RangeWidget from 'app/components/new/Content/Footer/Range/RangeElement';
import SchoolMainFooter from 'app/components/new/Content/Footer/SchoolMainFooter';
import SchoolSidebarLeft from 'app/components/new/Content/SchoolSidebarLeft';
import SchoolSidebarRight from 'app/components/new/Content/SchoolSidebarRight';
import SchoolLevelHeader from 'app/components/new/Header/SchoolLevelHeader';
import ContentElement from 'app/pages/StudentView/Content/Element/ContentElement';
import LearningObjectElement from 'app/pages/StudentView/Content/Element/LearningObjectElement';
import QuestionElement from 'app/pages/StudentView/Content/Element/QuestionElement';
import WidgetComments from 'app/pages/StudentView/Content/Widgets/WidgetComments';
import WidgetFAQ from 'app/pages/StudentView/Content/Widgets/WidgetFAQ';
import WidgetFiles from 'app/pages/StudentView/Content/Widgets/WidgetFiles';
import WidgetGlossaries from 'app/pages/StudentView/Content/Widgets/WidgetGlossaries';
import WidgetRanges from 'app/pages/StudentView/Content/Widgets/WidgetRanges';
import WidgetTips from 'app/pages/StudentView/Content/Widgets/WidgetTips';
import StudentPage from 'app/pages/StudentView/StudentPage';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import {useStudentViewConfig} from 'app/pages/StudentView/StudentViewConfig';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import icon_comments from 'assets/menu/comments.svg';
import icon_faq from 'assets/menu/faq.svg';
import icon_file from 'assets/menu/file.svg';
import icon_glossary from 'assets/menu/glossary.svg';
import icon_range from 'assets/menu/range.svg';
import icon_tip from 'assets/menu/tip.svg';
import {useSchoolCourseAlert} from 'hoc/alert';
import React, {useCallback, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

NewStudentContentPage.propTypes = {};

function NewStudentContentPage(props){
	const [active, setActive] = useState(null);
	const [currentItem: Item, setCurrentItem] = useState(null);
	const [currentRange, setCurrentRange] = useState(null);
	const [asQuestion, setAsQuestion] = useState(false);
	const [module: Module, setModule] = useState(null);
	const [hiddenLeftSidebar, setHiddenLeftSidebar] = useState(false);

	const dispatch = useDispatch();
	const {module: moduleId} = useParams();
	const moduleState: ReducerStateType = useSelector(ModuleReducerUtils.readByOrder.map);
	const viewState: ReducerStateType = useSelector(ViewedItemReducerUtils.view.map);
	const user: Student = useAppitUserAwareness();
	const history = useHistory();
	const forceViewPrevious = false;
	const Alerts = useSchoolCourseAlert(user.course, currentItem && currentItem.module);
	const {ranking: top} = useStudentViewConfig();
	const currentItemID = currentItem && currentItem.id;

	const refreshPoints = useCallback(() => {
		InformativeReducerUtils.ranking.dispatch(dispatch, top);
		InformativeReducerUtils.points.dispatch(dispatch);
	}, [dispatch, top]);

	const toQuestion = useCallback(() => {
		setActive(null);
		setCurrentRange(null);
		setAsQuestion(true);
	}, []);
	const selectItem = useCallback((item: Item) => {
		let previous_item: Item = null;
		let current_item: Item = null;
		let last_possible: Item = null;
		if(forceViewPrevious && !!item){
			for(const i of module.items){
				if(i.id === item.id){
					if(previous_item !== null && !previous_item.is_viewed){
						Alerts.Feedback(
							`Não foi possível mudar para o nivel ${i.order}`,
							`Você ainda não visualizou o nivel ${previous_item.order}`,
						);
						current_item = last_possible;
						break;
					}
					current_item = i;
					break;
				}
				if(previous_item === null || previous_item.is_viewed){
					last_possible = i;
				}
				previous_item = i;
			}
		}
		else{
			current_item = item;
		}

		if(!!current_item){
			sessionStorage.setItem('current_item_id', `${user.id}:${current_item.id}`);
		}
		setActive(null);
		setCurrentRange(null);
		setCurrentItem(current_item);
		setAsQuestion(false);
		if(document.body.querySelector('.active-sidebar') !== null){
			document.getElementById('mobile-selector').click();
		}
	}, [Alerts, forceViewPrevious, user.id, module]);

	useEffect(() => {
		if(!!module && module.items.length === 0){
			Alerts.Feedback(
				'Módulo inacessivel',
				'Se acha que isso é um erro contate um administrador',
				() => setTimeout(
					() => history.push(StudentRoutes.MODULES),
					300,
				),
			);
		}
	}, [Alerts,history,module]);

	useEffect(() => {
		ModuleReducerUtils.readByOrder.dispatch(dispatch, moduleId);

		return () => {
			ModuleReducerUtils.readByOrder.dispatch_clear(dispatch);
		};
	}, [dispatch, moduleId]);

	useEffect(() => {
		if(viewState.finished){
			ModuleReducerUtils.readByOrder.dispatch(dispatch, moduleId);
		}
	}, [dispatch, moduleId, viewState]);

	useEffect(() => {
		const module: Module = moduleState.data;
		if(module !== null){
			setModule(module);
			const showLastViewed = false;
			const getCurrent = () => {
				if(!!currentItemID){
					return module.items.find(i => i.id === currentItemID);
				}
				const userId = sessionStorage.getItem('current_item_id');
				if(!!userId){
					const currentItemId = userId.replace(`${user.id}:`, '');
					if(currentItemId !== userId){
						return module.items.find(i => i.id === currentItemId);
					}
				}

				let lastViewed;
				for(const i of module.items){
					if(i.is_viewed){
						lastViewed = i;
					}
					else if(!!lastViewed){
						if(showLastViewed){
							return lastViewed;
						}
						return i;
					}
				}
			};
			let newCurrentItem: Item = getCurrent();
			if(newCurrentItem && newCurrentItem.id === currentItemID){
				newCurrentItem = module.items.find(i => i.id === newCurrentItem.id);
			}

			if(!newCurrentItem){
				setCurrentItem(module.items[0]);
			}
			else{
				setCurrentItem(newCurrentItem);
			}
		}
	}, [moduleState, user.id, currentItemID]);

	let containerClass = [];
	let headerClass = [];
	let contentClass = [];
	if(currentItem instanceof LearningObject){
		containerClass.push('show-learning-object');
		headerClass.push('show-learning-object');
	}
	else if(active !== null){
		containerClass.push('active-content-bar');
		contentClass.push('show-footer');
	}
	if(currentRange !== null){
		contentClass.push('show-range');
	}
	if(asQuestion && !!currentItem.question){
		contentClass.push('show-question');
	}

	if(currentItem === null){
		return <TemplateSplash />;
	}
	let widgets = {};
	let widget_items = {};

	if(currentItem instanceof Content){
		widgets = {
			comments: <WidgetComments
				key={`comment-${currentItemID}`}
				content={currentItem}
			/>,
			glossaries: <WidgetGlossaries
				key={`glossary-${currentItemID}`}
				content={currentItem}
			/>,
			tips: <WidgetTips
				key={`tip-${currentItemID}`}
				content={currentItem}
			/>,
			files: <WidgetFiles
				key={`file-${currentItemID}`}
				content={currentItem}
			/>,
			ranges: <WidgetRanges
				key={`range-${currentItemID}`}
				currentRange={currentRange}
				onSelectRange={currentRange => setCurrentRange(currentRange)}
				content={currentItem}
			/>,
			faq: <WidgetFAQ />,
		};
		widget_items = {
			comments: {
				icon: icon_comments,
				title: 'Fórum',
				svg: true,
			},
			ranges: {
				icon: icon_range,
				title: 'Ranges',
				svg: true,
			},
			glossaries: {
				icon: icon_glossary,
				title: 'Glossário',
				svg: true,
			},
			tips: {
				icon: icon_tip,
				title: 'Dicas',
				svg: true,
			},
			files: {
				icon: icon_file,
				title: 'Materiais complementares',
				svg: true,
			},
			faq: {
				icon: icon_faq,
				title: 'FAQ',
				svg: true,
			},
		};
	}

	return (
		<StudentPage
			headerClass={headerClass.join(' ')}
			containerClass={containerClass.join(' ')}
			contentClass={contentClass.join(' ')}
			showSidebarToggler
			levelHeader={<SchoolLevelHeader
				key={`level-header-${currentItemID}`}
				item={currentItem}
				onSelect={selectItem}
			/>}
			sidebarLeft={currentItem instanceof Content && (
				<SchoolSidebarLeft
					onSelect={(a) => {
						if(a === active){
							a = null;
						}
						setActive(a);
						setCurrentRange(null);
					}}
					onClose={() => {
						if(active === null){
							setHiddenLeftSidebar(true);
						}
						setActive(null);
						setAsQuestion(false);
						setCurrentRange(null);
					}}
					setHidden={setHiddenLeftSidebar}
					hidden={hiddenLeftSidebar}
					active={active}
					items={widget_items}
				/>
			)}
			sidebarRight={<SchoolSidebarRight
				onSelect={selectItem}
				item={currentItem}
			/>}
			footer={currentItem instanceof Content && (
				<SchoolMainFooter
					activeWidgetName={active}
					widgets={widgets}
					widgetsTitles={widget_items}
				/>
			)}
		>
			{currentItem instanceof LearningObject
				? <LearningObjectElement
					refreshPoints={refreshPoints}
					learning_object={currentItem}
				/>
				: (currentRange !== null
						? <div className="range">
							<Button
								size="sm"
								variant="danger"
								onClick={() => {
									// setActive(null);
									setCurrentRange(null);
								}}
								className="close-button"
							>
								<i className="fa fa-times" />
							</Button>
							{/*{widgets.ranges}*/}
							<RangeWidget showLegend range={currentRange} />
						</div>
						: (asQuestion && !!currentItem.question
								? <QuestionElement
									refreshPoints={refreshPoints}
									content={currentItem}
									toItem={selectItem}
								/>
								: <ContentElement
									refreshPoints={refreshPoints}
									content={currentItem}
									toItem={selectItem}
									toQuestion={toQuestion}
								/>
						)
				)
			}
		</StudentPage>
	);
}

export default NewStudentContentPage;
