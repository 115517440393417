import {toFixedLocale} from '@appit.com.br/react-utils/src/utils/utils';
import React from 'react';
import {Card, CardColumns} from 'react-bootstrap';

function CourseInfo({data: {students, students_ignore, students_paid, students_pending,students_not_started,students_canceled, paid}}){
	return (
		<CardColumns>
			<Card bg="success" text="light-success">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">{students}</h3>
					<div className="small text-uppercase">alunos</div>
				</Card.Body>
			</Card>
			<Card bg="info" text="light-info">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">{students_ignore}</h3>
					<div className="small text-uppercase">Matriculas que não precisam pagar</div>
				</Card.Body>
			</Card>
			<Card bg="warning" text="light-warning">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">{students_pending}</h3>
					<div className="small text-uppercase">Matriculas com pagamento pendente</div>
				</Card.Body>
			</Card>
			<Card bg="danger" text="light-danger">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">{students_not_started}</h3>
					<div className="small text-uppercase">Matriculas sem pagamento iniciado</div>
				</Card.Body>
			</Card>
			<Card bg="danger" text="light-danger">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">{students_canceled}</h3>
					<div className="small text-uppercase">Matriculas canceladas</div>
				</Card.Body>
			</Card>
			<Card bg="success" text="light-success">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">{students_paid}</h3>
					<div className="small text-uppercase">Matriculas com pagamento concluido</div>
				</Card.Body>
			</Card>
			{(!!paid || paid === 0) && <Card bg="success" text="light-success">
				<Card.Body className="d-flex flex-column justify-content-center align-items-center">
					<h3 className="text-nowrap">R$ {toFixedLocale(paid)}</h3>
					<div className="small text-uppercase">Total pago</div>
				</Card.Body>
			</Card>}
		</CardColumns>
	);
}

export default CourseInfo;
