import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Student from '_entity/Acess/Student';
import ViewedItem from '_entity/Progress/ViewedItem';
import {SvgOrImage} from '_ui/EntityUi';
import Tooltiper from 'hoc/ui/Tooltiper';
import PropTypes from 'prop-types';
import React from 'react';

SchoolSidebarRightWidgetBadges.propTypes = {
	viewed_item: PropTypes.instanceOf(ViewedItem),
};

/**
 *
 * @param {ViewedItem} viewed_item
 * @returns {*}
 * @constructor
 */
function SchoolSidebarRightWidgetBadges({viewed_item}){
	const user:Student = useAppitUserAwareness();

	return (
		<div className="badges-section">
			<div className="badges-title">Badges:</div>
			<div className="badges-list">
				{user.course.badges.map((badgeType) => {
					const active = !!viewed_item && !!viewed_item.badges.find((b) => b.badge.id === badgeType.id);

					return (
						<Tooltiper
							key={badgeType.id}
							tooltip={<>
								{!active && (<>
									<div className="text-muted mt-n1">Não conquistado</div>
								</>)}
								<div className="d-inline-block border-bottom mb-2 border-secondary">{badgeType.name}</div>
								<div className="small">{badgeType.description}</div>
							</>}
						>
							<div className={`container-badge ${active ? '' : 'o-50'}`}>
								<SvgOrImage src={badgeType.image} className={`square-50px text-primary`}/>
							</div>
						</Tooltiper>
					);
				})
				}
			</div>
		</div>
	);
}

export default SchoolSidebarRightWidgetBadges;
