import React from 'react';
import {Pagination} from 'react-bootstrap';

/**
 *
 * @param {{count:number,max:number,total:number,pages:number,min:number}} pagination
 * @param setPage
 * @param page
 * @returns {*}
 * @constructor
 */
function SchoolPaginator({pagination,setPage,page}){
	if(!pagination){
		pagination = {
			pages: 0,
			min: 0,
			max: 0,
			total: 0,
		}
	}
	return (
		<div className="d-flex flex-row justify-content-center align-items-center">
			<Pagination className="flex-grow-1">
				{Array(pagination.pages).fill('').map((_, p) => (
					<Pagination.Item key={`page-${p}`} active={p === page} onClick={() => setPage(p)}>
						{p + 1}
					</Pagination.Item>
				))}
			</Pagination>
			<div className="mb-2 flex-root">
				{pagination.max > 0 ? pagination.min+1 : 0} à {pagination.max} de {pagination.total}
			</div>
		</div>
	);
}

export default SchoolPaginator;