import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import StudentAccessApi from '_api/Student/StudentAccessApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const StudentAccessReducerOptions = new SchoolReducerOptions('student_access',StudentAccessApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 * }}
 */
export const StudentAccessReducerUtils:$ObjMap<string,ReducerBasicOption> = StudentAccessReducerOptions.options();
