import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Alternative from '_entity/Course/Module/Item/Content/Question/Alternative';
import Question from '_entity/Course/Module/Item/Content/Question/Question';
import AnsweredQuestion from '_entity/Progress/AnsweredQuestion';
import Tooltiper from 'hoc/ui/Tooltiper';
import PerfectScrollbar from 'perfect-scrollbar';
import PropsType from 'prop-types';
import React, {useLayoutEffect, useState} from 'react';
import {ButtonGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';


SchoolQuestion.propTypes = {
	question: PropsType.instanceOf(Question).isRequired,
	last_answer: PropsType.instanceOf(AnsweredQuestion),
	onChange: PropsType.func,
	onSubmit: PropsType.func,
	hideSubmit: PropsType.bool,
	submitText: PropsType.string,
	retry: PropsType.bool,
	readOnly: PropsType.bool,
	showFeedback: PropsType.bool,
	showOdds: PropsType.bool,
	showPoints: PropsType.bool,
	sending: PropsType.bool,
	showPointsProgression: PropsType.bool,
	submitButton: PropsType.any,
};

SchoolQuestion.defaultProps = {
	onChange: (a) => {
	},
	onSubmit: (props = {}) => {
	},
	hideSubmit: false,
	retry: false,
	readOnly: false,
	showOdds: false,
	sending: false,
	showPointsProgression: false,
	showFeedback: false,
	submitButton: null,
};

/**
 *
 * @param {AnsweredQuestion} last_answer
 * @param onChange
 * @param sending
 * @param children
 * @param showOdds
 * @param showPoints
 * @param showFeedback
 * @param readOnly
 * @param showPointsProgression
 * @param hideSubmit
 * @param onSubmit
 * @param submitButton
 * @param {Question} question
 * @param retry
 * @param previousHandler
 * @param previousHandlerDisabled
 * @param nextHandler
 * @param nextHandlerDisabled
 * @param retryHandler
 * @param retryDisabled
 * @param retryShowing
 * @returns {*}
 * @constructor
 */
function SchoolQuestion({last_answer, onChange, sending, children, showOdds, showPoints, showFeedback, readOnly, showPointsProgression, hideSubmit, onSubmit, submitButton, question, retry, previousHandler, previousHandlerDisabled, nextHandler, nextHandlerDisabled, retryHandler, retryDisabled, retryShowing}){
	const [selectedId, setSelectedId] = useState(null);
	const [touched, setTouched] = useState(false);
	const canAnswer = question.locked ? false : (!touched && !question.is_answered) || retry;

	useLayoutEffect(() => {
		// console.log("hi")
		const el = document.querySelector('.question');
		const ps = new PerfectScrollbar(el);

		const si = setInterval(() =>{
			ps.update();
		},300)

		return () => {
			clearInterval(si)
			ps.destroy();
		}
	}, []);

	const select = (alternative: Alternative) => {
		if(!canAnswer){
			return;
		}
		onChange(alternative);
		setSelectedId(alternative.id);
	};
	const tryClass = canAnswer || readOnly ? '' : 'none-events';
	const submitProps = {
		children: sending ? 'Resposta sendo enviada' : (canAnswer ? 'Responder' : 'Pergunta já foi respondida'),
		disabled: readOnly,
	};
	const isActive = (alternative: Alternative) => {
		if(!canAnswer && !!last_answer){
			return last_answer.alternative.id === alternative.id;
		}

		return selectedId === alternative.id;
	};
	const correctClass = (alternative: Alternative) => {
		if(!!last_answer && !canAnswer){
			if(last_answer.alternative.id === alternative.id){
				if(last_answer.correct){
					return 'correct';
				}

				return 'incorrect';
			}
		}

		return '';
	};

	return (
		<div className="form question">
			{children}
			<div className="question-header">
				{question.description}
			</div>
			<div className="question-alternatives">
				{question.alternatives.map((alternative: Alternative) => {
					let content = (
						<span className="d-inline-block">
							{showOdds && !alternative.is_poker && (
								alternative.is_correct
									? <i className="fa fa-fw fa-check text-success" />
									: <i className="fa fa-fw fa-times text-danger" />
							)}
							{alternative.description}
							{showOdds && alternative.is_poker && (
								<span className="d-block small text-muted">
									<small>odds:&nbsp;{alternative.odds.toString()}</small>
								</span>
							)}
							{showPoints && (
								<span className="d-block small text-muted">
									<small>{question.content.module.course.points_name}:&nbsp;{alternative.points_value.toString()}</small>
								</span>
							)}
						</span>
					);
					if(showFeedback){
						content = (
							<Tooltiper
								key={'tooltip-' + alternative.id}
								tooltip={(
									<div className="d-flex">
										<div className="p-2">
											<div className="text-nowrap text-muted">Padrão:</div>
											{ifEmpty(alternative.feedback, 'N/A')}
										</div>
										{alternative.is_poker && (<>
											<div className="border-right mx-2" style={{width: '1px'}} />
											<div className="p-2">
												<div className="text-nowrap text-muted">por odds:</div>
												{ifEmpty(alternative.feedback_odds, 'N/A')}
											</div>
										</>)}
									</div>
								)}
							>
								{content}
							</Tooltiper>
						);
					}

					return (
						<Button
							onClick={() => select(alternative)}
							block
							disabled={readOnly}
							className={`${tryClass} ${correctClass(alternative)}`}
							size="lg"
							key={alternative.id}
							active={isActive(alternative)}
							variant="question-alternative"
						>
							{content}
						</Button>
					);
				})}
				{showPointsProgression && (
					<div className="text-muted">
						{question.points_progression.map(pp => (
							<div key={`points_progression_${pp.attempt}_${pp.percentage}`}>
								{pp.percentage}% <small>na</small> {pp.attempt}
								<small>tentativa</small>
							</div>
						))}
					</div>
				)}
			</div>
			<ButtonGroup className="question-answer">
				{previousHandler && (
					<Button className="flex-grow-0" variant="light" onClick={previousHandler} disabled={previousHandlerDisabled}>
						<i className="fa fa-angle-left" />
					</Button>
				)}
				{!hideSubmit && (
					!canAnswer && retryShowing
						? <Button
							onClick={retryHandler}
							disabled={retry || question.locked}
							variant="secondary-question-answer"
							size="lg"
							block
						>
							{question.locked
								? 'Você já possui a melhor resposta'
								: 'Tentar novamente'
							}
						</Button>
						: <Button
							onClick={() => {
								setTouched(true);
								onSubmit(selectedId);
							}}
							variant="question-answer"
							size="lg"
							{...submitProps} />
				)}
				{submitButton}
				{nextHandler && (
					<Button className="flex-grow-0" variant="light" onClick={nextHandler} disabled={nextHandlerDisabled}>
						<i className="fa fa-angle-right" />
					</Button>
				)}
			</ButtonGroup>
		</div>

	);
}

export default SchoolQuestion;
