// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import QuestionApi from '_api/Course/Module/Content/Question/QuestionApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const QuestionReducerOptions = new SchoolReducerOptions('questions', QuestionApi);

/**
 *
 * @type {{
 *     write: ReducerBasicOption
 *     read: ReducerBasicOption
 *     answer: ReducerBasicOption
 * }}
 */
export const QuestionReducerUtils = QuestionReducerOptions.options();
