import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {AdminSplash} from 'app/pages/AdminView/AdminLoader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

export const CourseListingPageBreadcrumb = () => ([
	{path: AdminRoutes.COURSE_LISTING, text: 'Cursos'},
]);

function CourseListingPage(props){
	const dispatch = useDispatch();
	const listingState = useSelector(CourseReducerUtils.list.map);
	const courses = listingState.data || [];

	useEffect(() => {
		CourseReducerUtils.list.dispatch(dispatch);

		return () => {
			CourseReducerUtils.list.dispatch_clear(dispatch);
		};
	}, [dispatch]);

	return (
		<AdminPage title="Cursos" breadcrumb={CourseListingPageBreadcrumb()}>
			{!listingState.finished && <AdminSplash />}
			<Table>
				<tbody>
				{courses.map(course => (
					<tr key={course.id}>
						<td className="w-0 text-center align-middle">
							<LinkContainer to={AdminRoutes.COURSE_SHOWING.replace(':id', course.id)}>
								<Button variant="outline-primary">
									<i className="fa fa-eye" />
								</Button>
							</LinkContainer>
						</td>
						<td className="w-100">
							<h3>
								{course.name}
							</h3>
							<div>
								<small className="text-muted mr-1">Usuário:</small>
								{course.user.username}
							</div>
							<div>
								<small className="text-muted mr-1">Email:</small>
								{course.user.email}
							</div>
							<div>
								<small className="text-muted mr-1">Nome do usuário:</small>
								{course.user.name}
							</div>
							<div className="d-flex w-100" data-color={course.username}>
								<span className="p-2 w-100 bg-primary" />
								<span className="p-2 w-100 ml-2 bg-secondary" />
							</div>
						</td>
						<td className="w-0 text-center align-middle">
							<LinkContainer to={AdminRoutes.COURSE_EDITING.replace(':id', course.id)}>
								<Button variant="secondary">
									<i className="fa fa-edit" />
								</Button>
							</LinkContainer>
						</td>
					</tr>
				))
				}
				</tbody>
			</Table>
			<LinkContainer to={AdminRoutes.COURSE_CREATING}>
				<Button variant="primary">
					Adicionar
				</Button>
			</LinkContainer>
		</AdminPage>
	);
}

export default CourseListingPage;