import PropTypes from 'prop-types';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap';

SchoolHeader.propTypes = {
	profiler: PropTypes.any,
	showLogout: PropTypes.bool,
	hideLogo: PropTypes.bool,
	extra: PropTypes.any,
	className: PropTypes.string,
	title: PropTypes.any.isRequired,
	logo: PropTypes.any,
};

SchoolHeader.defaultProps = {
	profiler: null,
	showLogout: false,
	hideLogo: false,
	extra: null,
}

function SchoolHeader({className,hideLogo,logo,title,children,showLogout}){
	return (
		<header id="header" className={className}>
			<Navbar bg="primary" variant="dark" id="brand-header">
				{!hideLogo && (
					<LinkContainer to="/">
						<Navbar.Brand className="text-light">
							{logo
								? <img
									src={logo}
									width="auto"
									height="100%"
									className="d-inline-block"
									alt=""
								/>
								: <i className="fa fa-home" />
							}
						</Navbar.Brand>
					</LinkContainer>
				)}
				<LinkContainer to="/">
					<Nav.Link className="text-light">
						{title}
					</Nav.Link>
				</LinkContainer>
			</Navbar>
			{children}
			{showLogout && (
				<Nav className="ml-auto pr-3">
					<Nav.Item>
						<LinkContainer to="/logout">
							<Nav.Link className="h3 text-uppercase text-light h-100 d-flex justify-content-center align-items-center">
								<i className="fa fa-power-off" />
							</Nav.Link>
						</LinkContainer>
					</Nav.Item>
				</Nav>
			)}
		</header>
	);
}

export default SchoolHeader;