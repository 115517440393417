// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import StudentApi from '_api/Student/StudentApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const StudentReducerOptions = new SchoolReducerOptions('student', StudentApi);

/**
 *
 * @type {{
 *     create: ReducerBasicOption
 *     list: ReducerBasicOption
 *     listPage: ReducerBasicOption
 *     read: ReducerBasicOption
 *     editMe: ReducerBasicOption
 *     changePassword: ReducerBasicOption
 *     revoke: ReducerBasicOption
 *     grant: ReducerBasicOption
 *     pay_boleto: ReducerBasicOption
 *     pay_credit_card: ReducerBasicOption
 *     refund: ReducerBasicOption
 *     changeCPF: ReducerBasicOption
 *     changeName: ReducerBasicOption
 *     ignore: ReducerBasicOption
 *     consider: ReducerBasicOption
 *     check_my_payment: ReducerBasicOption
 *     change_field: ReducerBasicOption
 * }}
 */
export const StudentReducerUtils: $ObjMap<string, ReducerBasicOption> = StudentReducerOptions.options();
