import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import Comment from '_entity/Social/Comment';

export default class CommentApi extends SchoolApi {
	get namespace(){
		return 'comments';
	}

	get entity_class(){
		return Comment;
	}

	like(id){
		return this.json.put(`${id}/like`);
	}

	list(contentID){
		expectsString(contentID);
		return this.json.get(`by_content/${contentID}`);
	}

	create(contentID, data){
		expectsString(contentID);
		expectsObject(data);
		return this.json.post(`by_content/${contentID}`, data);
	}

	createReply(commentID, data){
		expectsString(commentID);
		expectsObject(data);
		return this.json.post(`by_comment/${commentID}`, data);
	}

	listReplies(commentID){
		expectsString(commentID);
		return this.json.get(`by_comment/${commentID}`);
	}
}
