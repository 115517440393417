import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import Alternative from '_entity/Course/Module/Item/Content/Question/Alternative';
import Question from '_entity/Course/Module/Item/Content/Question/Question';
import Points from '_entity/Game/Points';
import SchoolEntity from '_entity/SchoolEntity';


export default class AnsweredQuestion extends SchoolEntity {
	get question(): Question{
		return entityResolver(this.getDataValue('question'), Question);
	}

	get alternative(): Alternative{
		return entityResolver(this.getDataValue('alternative'), Alternative);
	}

	get feedback(){
		return this.getDataValue('feedback');
	}

	get correct(){
		return this.getDataValue('correct');
	}

	get points(): Points{
		return this.getDataValue('points', Points);
	}
}
