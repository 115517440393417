import WaitingPage from 'app/pages/WaitingView/WaitingPage';


export const WaitingRoutes = {
	PAYMENT: '/payment',
};

export const WaitingPages = {
	'routes': {
		[WaitingRoutes.PAYMENT]: WaitingPage,
	},
	'redirect': WaitingRoutes.PAYMENT,
};