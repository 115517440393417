import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import StudentEvent from '_entity/Acess/StudentEvent';
import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import {StudentEventReducerUtils} from '_reducer/Student/StudentEventReducer';
import SchoolVideo from 'app/components/new/Content/Item/SchoolVideo';
import {useSchoolCourseAlert} from 'hoc/alert';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

function IntroVideo(props){
	const dispatch = useDispatch();
	const user: Student = useAppitUserAwareness();
	const course = user.course;

	const {finished, error} = useSelector(StudentEventReducerUtils.set.map);
	const onEnd = useCallback(() => StudentEventReducerUtils.set.dispatch(dispatch, StudentEvent.VIEWED_INTRO, true), [dispatch]);
	const check = useCallback(() => {
		SchoolAuthReducerUtils.check.dispatch(dispatch);
		StudentEventReducerUtils.set.dispatch_clear(dispatch);
	}, [dispatch]);
	const Alerts = useSchoolCourseAlert(course)

	useEffect(() => {
		if(finished && !error){
			Alerts.Feedback(
				<span>
					Bem vindo à <span className="text-capitalize">{course.name}</span>
				</span>,
				'',
				check
			);
		}
	}, [error,finished,check,Alerts,course.name]);

	return (
		<SchoolVideo src={course.intro_external ? course.intro : asset(course.intro)} onEnded={onEnd} />
	);
}

export default IntroVideo;
