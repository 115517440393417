import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import Question from '_entity/Course/Module/Item/Content/Question/Question';
import AnsweredQuestion from '_entity/Progress/AnsweredQuestion';
import SchoolEntity from '_entity/SchoolEntity';


export default class Alternative extends SchoolEntity {
	get description(): string{
		return this.getDataValue('description');
	}

	get type(){
		return this.getDataValue('type_name')
	}

	get is_poker():boolean{
		return this.type === 'poker';
	}

	get is_correct(): boolean{
		return this.getDataValue('is_correct');
	}

	get feedback(): string{
		return this.getDataValue('feedback');
	}

	get feedback_odds(): string{
		return this.getDataValue('feedback_odds');
	}

	get odds(): number{
		return this.getDataValue('odds');
	}

	get points_value(): number{
		return this.getDataValue('points_value');
	}


	get question(): Question{
		return entityResolver(this.getDataValue('question'), Question);
	}

	get answers(): AnsweredQuestion{
		return entityResolver(this.getDataValue('answers'), AnsweredQuestion, {alternative: this});
	}
}
