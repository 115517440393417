import FormCourseIntegration from '_form/Course/FormCourseIntegration';
import {CourseIntegrationReducerUtils} from '_reducer/Course/CourseIntegrationReducer';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';

const CourseIntegrationAddPageBreadcrumb = (course) => [
	...CourseShowingBreadcrumb(course),
	{
		path: AdminRoutes.COURSE_INTEGRATION_CREATING,
		text: 'Adicionar api',
	},
];

function CourseIntegrationAddPage(props){
	const dispatch = useDispatch();
	const {id:courseID} = useParams();
	const readState = useSelector(CourseReducerUtils.read.map)
	const creatingState = useSelector(CourseIntegrationReducerUtils.create.map);
	const creating = (data) => CourseIntegrationReducerUtils.create.dispatch(dispatch,courseID, data);

	useEffect(() => {
		CourseReducerUtils.read.dispatch(dispatch,courseID)

		return () => {
			CourseReducerUtils.read.dispatch_clear(dispatch)
		}
	},[dispatch,courseID])

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage breadcrumb={CourseIntegrationAddPageBreadcrumb(readState.data)}>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormCourseIntegration
					success={creatingState.finished}
					onSubmit={creating}
					error={creatingState.error}
					afterSuccessAlert={pushPrevious}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default CourseIntegrationAddPage;