import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import AvatarElement from 'app/components/avatar/AvatarElement';
import PropTypes from 'prop-types';
import React from 'react';

function ForumReplyEntry({avatar, hideAvatar, hideName, body, children, name, left, actions, styledAvatar}){
	return (
		<div className={`forum-reply ${!left ? 'forum-reply-right' : ''}`}>
			<div className="forum-reply-content">
				<div className="forum-reply-meta">
					{!hideAvatar && (
						<div className={`forum-reply-avatar ${styledAvatar ? 'forum-reply-avatar-styled' : ''}`}>
							<AvatarElement basic src={avatar} />
						</div>
					)}
					{!hideName && (
						<span className="forum-reply-user">
							{ifEmpty(name, '-')}
						</span>
					)}
				</div>
				<div className="forum-reply-text">
					{body}
				</div>
			</div>
			{!!actions && (
				<div className="forum-reply-action">
					{actions}
				</div>
			)}
			{children}
		</div>
	);

}

ForumReplyEntry.propTypes = {
	avatar: PropTypes.string,
	hideName: PropTypes.bool,
	hideAvatar: PropTypes.bool,
	styledAvatar: PropTypes.bool,
	left: PropTypes.bool,
	name: PropTypes.string,
	body: PropTypes.any.isRequired,
	children: PropTypes.any,
	actions: PropTypes.any,
};
ForumReplyEntry.defaultProps = {
	hideName: false,
	styledAvatar: false,
	hideAvatar: false,
	left: true,
	avatarClassName: '',
};

export default ForumReplyEntry;
