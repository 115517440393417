import {ErrorMessage} from 'formik';
import React from 'react';
import Form from 'react-bootstrap/Form';
import ReactSelect from 'react-select';

function SchoolSelect({defaultValue ,name, onChange, placeholder,multiple, options, styles = {}, className = '', label, withoutGroup = false, withoutLabel = false, beforeControl = (a) => a}){
	const Element = withoutGroup ? (!!className ? 'div' : React.Fragment) : Form.Group;
	const props = {};
	if(Element === 'div'){
		props.className = className;
	}

	return (
		<Element {...props}>
			{!withoutLabel && (
				<Form.Label className="text-capitalize">{label ? label : name}</Form.Label>
			)}
			{(beforeControl(<ReactSelect
				theme={theme => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: 'var(--primary25)',
						primary50: 'var(--primary50)',
						primary75: 'var(--primary75)',
						primary: 'var(--primary)',
					},
				})}
				isMulti={multiple}
				defaultValue={defaultValue}
				styles={styles}
				name={name}
				onChange={onChange}
				className="react-select form-control"
				classNamePrefix="react-select"
				placeholder={placeholder}
				options={options}
			/>))}
			<ErrorMessage name={name} component="div" />
		</Element>
	);
}

export default SchoolSelect;