import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import Student from '_entity/Acess/Student';
import Content, {ContentUtil} from '_entity/Course/Module/Item/Content/Content';
import Item from '_entity/Course/Module/Item/Item';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {AnsweredQuestionReducerUtils} from '_reducer/Progress/AnsweredQuestionReducer';
import {ViewedItemReducerUtils} from '_reducer/Progress/ViewedItemReducer';
import SchoolVideo from 'app/components/new/Content/Item/SchoolVideo';
import ItemInfoElement from 'app/pages/StudentView/Content/Element/ItemInfoElement';
import {useSchoolCourseAlert} from 'hoc/alert';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';

ContentElement.propTypes = {
	content: PropTypes.instanceOf(Content).isRequired,
	toQuestion: PropTypes.func.isRequired,
};

/**
 *
 * @param {Content} content
 * @param toQuestion
 * @param toItem
 * @param refreshPoints
 * @returns {*}
 * @constructor
 */
function ContentElement({content, toQuestion,toItem,refreshPoints}){
	const dispatch = useDispatch();
	const viewState: ReducerStateType = useSelector(ViewedItemReducerUtils.view.map);
	const moduleFinished = useSelector(ModuleReducerUtils.readByOrder.map).finished
	const finished = viewState.finished;
	const begined = viewState.begined;
	const error = viewState.error;
	const user: Student = useAppitUserAwareness();
	const [sending,setSending] = useState(0);
	const Alerts = useSchoolCourseAlert(user.course,content.module)
	const view = useCallback((id) => ViewedItemReducerUtils.view.dispatch(dispatch, id),[dispatch]);
	const items = useMemo(
		() => content.module.items,
		// eslint-disable-next-line
		[content.id,content.module.id]
	);
	const endHandler = useCallback(() => {
		const currentIndex = items.findIndex(i => i.id === content.id);
		const nextItem:Item = items[currentIndex+1]
		if(!content.is_viewed){
			view(content.id)
		}
		else if(content.has_question){
			// console.log('to question')
			toQuestion()
		}
		else{
			if(currentIndex+1 === items.length){
				Alerts.ConfirmAlert({
					title: "Deseja ir para o Próximo módulo?",
					message: "Você será redirecionado para o primeiro conteúdo do Próximo módulo",
					action: () => toItem(nextItem)
				})
			}
			else{
				Alerts.ConfirmAlert({
					title: "Deseja ir para o Próximo conteúdo?",
					action: () => toItem(nextItem)
				})
			}
		}
	},[Alerts,view,toQuestion,toItem,content.is_viewed,content.has_question,content.id,items])


	useEffect(() => {
		return () => {
			ViewedItemReducerUtils.view.dispatch_clear(dispatch)
			AnsweredQuestionReducerUtils.answer.dispatch_clear(dispatch);
			AnsweredQuestionReducerUtils.answerFeedback.dispatch_clear(dispatch);
		}
	},[dispatch])

	useEffect(() => {
		if(sending === 0 && begined){
			setSending(1)
		}
		if(sending === 1 && moduleFinished){
			setSending(2)
		}
	},[sending,begined,moduleFinished])

	useEffect(
		() => {
			if(finished){
				refreshPoints();
				if(error){
					Alerts.Feedback('Não foi possível visualizar o conteúdo', 'Tente novamente mais tarde');
				}
				else{
					toQuestion();
				}
			}
		},
		[Alerts,finished, error,toQuestion,refreshPoints],
	);

	return (
		<SchoolVideo
			className="container-fluid p-0"
			key={ContentUtil.getSrcUrl(content)}
			src={ContentUtil.getSrcUrl(content)}
			onEnded={endHandler}
			extra={(
				<Button
					variant="light"
					size="sm"
					disabled={!content.question || !content.is_viewed}
					className="video-control-extra"
					onClick={toQuestion}
				>
					<i className="fa fa-question" />
				</Button>
			)}
		>
			<ItemInfoElement element={content} is_sending={sending === 1} />
		</SchoolVideo>
	);
}

export default ContentElement;
