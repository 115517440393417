import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Content from '_entity/Course/Module/Item/Content/Content';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFile from 'app/components/form/SchoolFormFile';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Form} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

class FormContent extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		content: PropTypes.instanceOf(Content),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		content: {},
	};

	isNew = () => !this.props.content.id;

	handleData = (values) => {
		values = {...values}
		if(values.external){
			delete values.file;
		}

		return values;
	};

	validate = (values) => {
		const errors = {};
		if(values.external){
			if(!values.url){
				// errors.url = true;
			}
		}
		else{
			if(!values.file){
				errors.file = true;
			}
		}
		if(!values.name){
			errors.name = true;
		}
		// console.log(values.file)

		return errors;
	};

	form(){
		const content: Content = this.props.content;

		return (
			<SchoolForm
				validate={this.validate}
				onSubmit={this.onSubmit}
				initialValues={{
					name: ifEmpty(content.name, ''),
					order: ifEmpty(content.order, 1),
					url: ifEmpty(content.url, ''),
					external: ifEmpty(content.external, false),
					file: '',
				}}
				submitText="Salvar"
				asFunc
			>
				{({values,setFieldValue}) => (<>
					<Form.Row>
						<Col>
							<SchoolFormSimpleField name="name" label="Nome" />
						</Col>
						<Col>
							<SchoolFormSimpleField name="order" label="Ordem" />
						</Col>
					</Form.Row>
					<Form.Row>
						<Col xs="auto">
							<div className="d-flex justify-content-center align-items-end h-100">
								<Button
									className="form-group"
									variant="outline-primary"
									onClick={() => setFieldValue("external",!values.external)}
								>
									{values.external ? 'Usando url' : 'Usando arquivo'}
								</Button>
							</div>
						</Col>
						<Col className={!values.external ? 'd-none' : ''}>
							<SchoolFormSimpleField name="url" label="Url do video" />
						</Col>
						<Col className={values.external ? 'd-none' : ''}>
							<SchoolFormFile
								fileProps={{
									onChange: ({file}) => setFieldValue("file",file),
									accept: 'video/*',
								}}
							/>
						</Col>
					</Form.Row>
				</>)}
			</SchoolForm>
		);
	}
}

export default FormContent;
