import PropTypes from 'prop-types';

import React, {useCallback, useEffect, useState} from 'react';

SchoolMainFooter.propTypes = {
	activeWidgetName: PropTypes.string,
	widgets: PropTypes.object,
	widgetsTitles: PropTypes.object,
};

function SchoolMainFooter({widgets, activeWidgetName,widgetsTitles}){
	const [lastActiveWidgetName,setLastActive] = useState("")
	const [initiateds,setInitiateds] = useState({})
	const activateWidget = useCallback((activeWidgetName) => {
		setLastActive(activeWidgetName)
		setInitiateds({
			...initiateds,
			[activeWidgetName]: true
		})
	},[initiateds])

	useEffect(() => {
		if(activeWidgetName !== null && activeWidgetName !== lastActiveWidgetName){
			activateWidget(activeWidgetName);
		}
	},[activateWidget,activeWidgetName,lastActiveWidgetName])


	return (
		<footer id="main-footer" data-widget-active={activeWidgetName} data-widget-active-last={lastActiveWidgetName}>
			{Object.keys(widgets).map(widgetName => {
				const Widget = widgets[widgetName];
				return <div className="mx-auto" key={`main-footer-widget-${widgetName}`} data-widget={widgetName}>
					<h5>{widgetsTitles[widgetName].title}</h5>
					{initiateds[widgetName] && Widget}
				</div>;
			})}
		</footer>
	);
}

export default SchoolMainFooter;