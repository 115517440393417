import {expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import AnsweredQuestion from '_entity/Progress/AnsweredQuestion';


export default class AnsweredQuestionApi extends SchoolApi {
	get namespace(){
		return 'answer';
	}

	get entity_class(){
		return AnsweredQuestion;
	}

	answer(contentID, alternativeID){
		expectsString(contentID);
		expectsString(alternativeID);

		return this.json.post(``, {
			content: contentID,
			alternative: alternativeID
		});
	}

	answerFeedback(contentID){
		expectsString(contentID);

		return this.jsonData.get(`${contentID}/feedback`);
	}
}
