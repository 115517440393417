import Content, {ContentUtil} from '_entity/Course/Module/Item/Content/Content';
import {ContentReducerUtils} from '_reducer/Course/Module/Content/ContentReducer';
import {HeaderMessage, pluralization, StandardMessage} from 'app/components/message/messages';
import SchoolQuestion from 'app/components/new/Content/Item/SchoolQuestion';
import SchoolVideo from 'app/components/new/Content/Item/SchoolVideo';
import {useNewSchoolBreadcrumbHeader} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import FileSection from 'app/pages/AdminView/Content/Sections/FileSection';
import GlossarySection from 'app/pages/AdminView/Content/Sections/GlossarySection';
import RangeSection from 'app/pages/AdminView/Content/Sections/RangeSection';
import RewardSection from 'app/pages/AdminView/Content/Sections/RewardSection';
import TipSection from 'app/pages/AdminView/Content/Sections/TipSection';
import {ModuleShowingBreadcrumb} from 'app/pages/AdminView/Module/ModuleShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import Buttons from 'hoc/ui/Buttons';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import {Accordion, Badge, Card} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams} from 'react-router-dom';

export const ContentShowingBreadcrumb = (content: Content) => ([
	...ModuleShowingBreadcrumb(content.module),
	{path: AdminRoutes.CONTENT_SHOWING.replace(':id', content.id), text: content.name},
]);

function Messages({contentID}){
	const dispatch = useDispatch();
	const deactivateContentState = useSelector(ContentReducerUtils.deactivate.map);
	const activateContentState = useSelector(ContentReducerUtils.activate.map);
	const deleteContentState = useSelector(ContentReducerUtils.delete.map);
	const read = () => ContentReducerUtils.read.dispatch(dispatch, contentID);
	const {pushPrevious} = useNewSchoolBreadcrumbHeader();

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Conteúdo foi ativado!`,
				successAction: read,
				finished: activateContentState.finished,
				error: activateContentState.error,
			});
		},
		// eslint-disable-next-line
		[activateContentState],
	);
	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Conteúdo foi desativado!`,
				successAction: read,
				finished: deactivateContentState.finished,
				error: deactivateContentState.error,
			});
		},
		// eslint-disable-next-line
		[deactivateContentState],
	);
	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Conteúdo foi deletado!`,
				successAction: pushPrevious,
				finished: deleteContentState.finished,
				error: deleteContentState.error,
			});
		},
		// eslint-disable-next-line
		[deleteContentState],
	);

	return null;
}

function ContentShowingPage(props){
	const readState = useSelector(ContentReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: contentID} = useParams();
	const content: Content = readState.data;
	const deactivateContent = () => ContentReducerUtils.deactivate.dispatch(dispatch, contentID);
	const activateContent = () => ContentReducerUtils.activate.dispatch(dispatch, contentID);
	const deleteContent = () => ContentReducerUtils.delete.dispatch(dispatch, contentID);

	useEffect(() => {
			ContentReducerUtils.read.dispatch(dispatch, contentID);

			return () => {
				ContentReducerUtils.read.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			actions={(
				<ButtonGroup>
					<LinkContainer to={AdminRoutes.CONTENT_EDITING.replace(':id', contentID)}>
						{Buttons.EDIT}
					</LinkContainer>
					{
						content.active
							? (
								<Tooltiper tooltip={'Desativar'}>
									<Button
										variant="warning"
										onClick={() => SchoolConfirmAlert({
											title: 'Realmente deseja desativar esse conteúdo?',
											action: () => deactivateContent(contentID),
										})}
									>
										<i className="fa fa-times" />
									</Button>
								</Tooltiper>
							)
							: (
								<Tooltiper tooltip={'Ativar'}>
									<Button
										variant="success"
										onClick={() => SchoolConfirmAlert({
											title: 'Realmente deseja ativar esse conteúdo?',
											action: () => activateContent(contentID),
										})}
									>
										<i className="fa fa-expand" />
									</Button>
								</Tooltiper>
							)
					}
					<Tooltiper tooltip={'Remover'}>
						<Button
							variant="danger"
							disabled={content.info.count_viewed > 0}
							onClick={() => SchoolConfirmAlert({
								title: 'Realmente deseja deletar esse conteúdo?',
								action: () => deleteContent(contentID),
							})}
						>
							<i className="fa fa-trash" />
						</Button>
					</Tooltiper>
				</ButtonGroup>
			)}
			breadcrumb={ContentShowingBreadcrumb(content)}
		>
			<Messages contentID={contentID} />
			<StandardMessage className="p-2 px-3 overflow-hidden mb-n2 rounded-top text-light bg-primary d-inline-block">
				{content.info.count_viewed}
				&nbsp;
				{pluralization('visualizaç(ões:ão:ões)', content.info.count_viewed)}
			</StandardMessage>
			<div data-color={content.module.course.username}>
				<SchoolVideo
					className="rounded-top"
					src={ContentUtil.getSrcUrl(content)}
					extra={
						<Button
							variant="light"
							size="sm"
							disabled={true}
							className="video-control-extra"
						>
							<i className="fa fa-question" />
						</Button>
					}
					controls
					childrenOnEnd
				/>
			</div>
			<Accordion defaultActiveKey="5" className="mt-3 pt-3 border-top">
				<Card>
					<Accordion.Toggle eventKey="0" as={Card.Header} className="h5 mb-0">
						<Badge variant="info">{content.tips.length}</Badge> Dicas
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<TipSection content={content} afterDelete={() => this.componentDidMount()} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle eventKey="1" as={Card.Header} className="h5 mb-0">
						<Badge variant="info">{content.glossaries.length}</Badge> Glossario
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="1">
						<Card.Body>
							<GlossarySection content={content} afterDelete={() => this.componentDidMount()} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle eventKey="2" as={Card.Header} className="h5 mb-0">
						<Badge variant="info">{content.files.length}</Badge> Arquivos
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="2">
						<Card.Body>
							<FileSection content={content} afterDelete={() => this.componentDidMount()} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle eventKey="3" as={Card.Header} className="h5 mb-0">
						<Badge variant="info">{content.ranges.length}</Badge> Ranges
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="3">
						<Card.Body>
							<RangeSection
								content={content}
								afterDelete={() => this.componentDidMount()}
								afterEdit={() => this.componentDidMount()}
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle eventKey="4" as={Card.Header} className="h5 mb-0">
						Recompensas
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="4">
						<Card.Body>
							<RewardSection element={content} afterDelete={() => this.componentDidMount()} />
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Card>
					<Accordion.Toggle eventKey="5" as={Card.Header} className="h5 mb-0">
						Pergunta
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="5">
						<Card.Body>
							{!!content.question
								? (<>
									<SchoolQuestion
										question={content.question}
										hideSubmit
										readOnly
										showOdds
										showFeedback
										showPoints
										showPointsProgression
										submitButton={(
											<LinkContainer to={AdminRoutes.QUESTION_MODIFYING.replace(':id', content.id)}>
												<Button
													variant="secondary-question-answer"
													size="lg"
													block
												>
													Editar
												</Button>
											</LinkContainer>
										)}
									/>
								</>)
								: (<Alert variant="secondary" className="p-5 text-center">
									<Alert.Heading>
										<HeaderMessage message="Pergunta não atribuida" />
									</Alert.Heading>
									<LinkContainer
										className="mt-5"
										size="lg"
										to={AdminRoutes.QUESTION_MODIFYING.replace(':id', content.id)}
									>
										<Button variant="primary">Associar pergunta</Button>
									</LinkContainer>
								</Alert>)
							}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</AdminPage>
	);
}

export default ContentShowingPage;