import Course from '_entity/Course/Course';
import FormRewardMap from '_form/Game/FormRewardMap';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {RewardMapReducerUtils} from '_reducer/Game/RewardMapReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const RewardMapForCourseCreatingBreadcrumb = (course: Course) => ([
	...CourseShowingBreadcrumb(course),
	{path: AdminRoutes.REWARD_MAP_COURSE_CREATING.replace(':id', course.id), text: 'Adicionar Recompensa'},
]);

function RewardMapForCourseCreatingPage(props){
	const createState = useSelector(RewardMapReducerUtils.createForCourse.map);
	const readState = useSelector(CourseReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: courseID} = useParams();
	const course: Course = readState.data;
	const create = (data) => RewardMapReducerUtils.createForCourse.dispatch(dispatch, courseID, data);

	useEffect(() => {
			CourseReducerUtils.read.dispatch(dispatch, courseID);

			return () => {
				CourseReducerUtils.read.dispatch_clear(dispatch);
				RewardMapReducerUtils.createForCourse.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={RewardMapForCourseCreatingBreadcrumb(course)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormRewardMap
					showOnEvent
					course={course}
					error={createState.error}
					success={createState.finished}
					afterSuccessAlert={pushPrevious}
					onSubmit={create}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default RewardMapForCourseCreatingPage;