import {ModuleAvatars} from '_entity/Course/Module/Module';
import SchoolSelect from 'app/components/form/SchoolSelect';
import React from 'react';
import {Media} from 'react-bootstrap';

function AvatarFeedbackField({initial,name,setFieldValue}){
	const avatarOptions = ModuleAvatars().map(([image,name]) => ({
		value: name,
		label: <Media>
			<img className="react-select__value-container p-1" src={image} alt="" />
			<Media.Body className="my-auto">
				{name}
			</Media.Body>
		</Media>
	}));
	const defaultValue = Array.isArray(initial) ? avatarOptions.filter(a => a.value === initial) : avatarOptions.find( a => a.value === initial)

	return (
		<SchoolSelect
			defaultValue={defaultValue}
			name={name}
			onChange={value => setFieldValue(name, Array.isArray(value) ? value.map(({value}) => value) : value.value)}
			placeholder="Selecione um avatar para o feedback"
			label="Avatar de feedback"
			options={avatarOptions}
			multiple={Array.isArray(initial)}
		/>
	);
}

export default AvatarFeedbackField;