import {asset, getOrThrow, throwIfUndefined} from '@appit.com.br/react-utils/src/utils/utils';
import ContentFile from '_entity/Course/Module/Item/Content/ContentFile';
import ContentGlossary from '_entity/Course/Module/Item/Content/ContentGlossary';
import ContentRange from '_entity/Course/Module/Item/Content/ContentRange';
import ContentTip from '_entity/Course/Module/Item/Content/ContentTip';
import Question from '_entity/Course/Module/Item/Content/Question/Question';
import Item from '_entity/Course/Module/Item/Item';
import Module from '_entity/Course/Module/Module';
import Comment from '_entity/Social/Comment';

export default class Content extends Item {
	get info(){
		const data = this.getData();
		return {
			get count_viewed(){
				return getOrThrow(data, 'info_count_viewed');
			},
			get count_comment(){
				return throwIfUndefined(data.info_count_comment);
			},
			get count_range(){
				return throwIfUndefined(data.info_count_range);
			},
		};
	}

	get has_question(): boolean{
		return !!this.question
	}

	get module():Module{
		return this.getDataValue('module', Module);
	}

	get active(): boolean{
		return this.getDataValue('active');
	}

	get src(): string{
		return this.getDataValue('src');
	}

	get url(): string{
		return this.getDataValue('url');
	}

	get external(): string{
		return this.getDataValue('is_external');
	}



	get comments(): Comment[]{
		return this.getDataValue('comments', Comment, {content: this});
	}

	get tips(): ContentTip[]{
		return this.getDataValue('tips', ContentTip, {content: this});
	}

	get glossaries(): ContentGlossary[]{
		return this.getDataValue('glossaries', ContentGlossary, {content: this});
	}

	get files(): ContentFile[]{
		return this.getDataValue('files', ContentFile, {content: this});
	}

	get ranges(): ContentRange[]{
		return this.getDataValue('ranges', ContentRange, {content: this});
	}

	get question(): ?Question{
		return this.getDataValue('question', Question, {content: this});
	}
}

export const ContentUtil = {
	getSrcUrl(content:Content){
		if(content.external){
			return !!content.url ? content.url : "";
		}

		return !!content.src ? asset(content.src) : "";
	}
}
