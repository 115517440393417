import RewardMap from '_entity/Game/RewardMap';

export default class RewardMapOnStep extends RewardMap {

	get step_activity(){
		return this.getDataValue('step_activity');
	}
	get step_level(){
		return this.getDataValue('step_level');
	}
	get step_attempt(){
		return this.getDataValue('step_attempt');
	}
}