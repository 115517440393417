import {Loader} from 'app/Template/TemplateLoaderContext';
import React, {createContext, useContext, useEffect, useState} from 'react';

const AdminLoaderContext = createContext();

export function AdminLoaderProvider({children}){
	const [count, setCount] = useState(0);
	let visible = count > 0;

	useEffect(() => {
		const splashScreen = document.getElementById('loader-admin');
		// Show SplashScreen
		if(splashScreen && visible){
			splashScreen.classList.remove('loader-hide');

			return () => {
				splashScreen.classList.add('loader-hide');
			};
		}

		// Hide SplashScreen
		let timeout;
		if(splashScreen && !visible){
			timeout = setTimeout(() => {
				splashScreen.classList.add('loader-hide');
			}, 1000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [visible]);

	return (
		<AdminLoaderContext.Provider value={setCount}>
			<Loader id="loader-admin" />
			{children}
		</AdminLoaderContext.Provider>
	);
}

export function AdminSplash({visible = true}){
	const setCount = useContext(AdminLoaderContext);

	useEffect(() => {
		if(!visible){
			return;
		}

		setCount(prev => {
			return prev + 1;
		});

		return () => {
			setCount(prev => {
				return prev - 1;
			});
		};
	}, [setCount, visible]);

	return null;
}