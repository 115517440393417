import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import PropTypes from 'prop-types';
import React from 'react';
import {Form, InputGroup} from 'react-bootstrap';

class ChangeCostForm extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (values) => {

		return values;
	};

	getAlertTitle(): string{
		return `Custo com sucesso`;
	}

	getAlertMessage(): string{
		return 'Custo salvo!';
	}

	form(){
		const course:Course = this.props.course;
		const initial = {
			cost: ifEmpty(course.cost,""),
			payment_name: ifEmpty(course.payment_name,""),
			password: ''
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
			>
				<SchoolFormSimpleField name="password" label="Senha" type="password" autoComplete="new-password"/>
				<SchoolFormSimpleField name="payment_name" label="Descrição no pagamento" />
				<div className="form-group">
					<Form.Label>Custo</Form.Label>
					<InputGroup>
						<InputGroup.Prepend>
							<InputGroup.Text>
								R$
							</InputGroup.Text>
						</InputGroup.Prepend>
						<SchoolFormSimpleField withoutGroup withoutLabel name="cost" label="custo" type="number" componentProps={{min:0,step: 0.01}} />
					</InputGroup>
				</div>
			</SchoolForm>
		);
	}
}

export default ChangeCostForm;
