import {InformativeReducerUtils} from '_reducer/Informative/InformativeReducer';
import CourseInfo from 'app/components/new/Page/Course/CourseInfo';
import CoursePage from 'app/pages/CourseView/CoursePage';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

function InfoPage(props){
	const dispatch = useDispatch();
	const infoState = useSelector(InformativeReducerUtils.myCourseInfo.map)

	useEffect(() => {
		InformativeReducerUtils.myCourseInfo.dispatch(dispatch)

		return () => {
			InformativeReducerUtils.myCourseInfo.dispatch_clear(dispatch)
		}
	},[dispatch])

	if(!infoState.finished){
		return <TemplateSplash/>
	}

	return (
		<CoursePage breadcrumb={[{path: CourseRoutes.INFO, text: 'Informações'}]}>
			<CourseInfo data={infoState.data || {}}/>
		</CoursePage>
	);
}

export default InfoPage;