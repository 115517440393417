import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import BadgeTypeApi from '_api/Game/BadgeTypeApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const BadgeTypeReducerOptions = new SchoolReducerOptions('game_badge',BadgeTypeApi);

/**
 *
 * @type {{
 *     edit: ReducerBasicOption
 *     create: ReducerBasicOption
 *     read: ReducerBasicOption
 *     remove: ReducerBasicOption
 * }}
 */
export const BadgeTypeReducerUtils:$ObjMap<string,ReducerBasicOption> = BadgeTypeReducerOptions.options();
