import StudentPage from 'app/pages/StudentView/StudentPage';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import Tooltiper from 'hoc/ui/Tooltiper';
import React from 'react';
import {Button, Col, Nav, Row} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

function ContactPage(props){
	// const dispatch = useDispatch();
	// const user: Student = useAppitUserAwareness();
	// const course = user.course;

	return (
		<StudentPage
			showSidebarToggler={false}
			mainClass="main-profile h-100"
			levelHeader={(
				<Nav id="level-header" className="level-header-fixed">
					<Tooltiper tooltip="Ir para Módulo">
						<LinkContainer to={StudentRoutes.MODULES}>
							<Button variant="light" as={Nav.Link}>
								<i className="fa fa-chevron-left" />
							</Button>
						</LinkContainer>
					</Tooltiper>
				</Nav>
			)}
		>
			<div id="profile" className="container">
				<Row md={2} xs={1}>
					<Col>
						<Button
							block
							variant="whatsapp"
							size="lg"
							className="d-flex justify-content-between align-items-center"
							href={`https://wa.me/554891044764`}
							target="_blank"
						>
							<i className="fab fa-3x fa-fw fa-whatsapp" />
							Fale conosco
						</Button>
					</Col>
					<Col>
						<Button
							block
							variant="discord"
							size="lg"
							className="d-flex justify-content-between align-items-center"
							href={'https://discord.gg/ctV6Z2A'}
							target="_blank"
						>
							<i className="fab fa-3x fa-fw fa-discord" />
							Canal do discord
						</Button>
					</Col>
				</Row>
				<Row md={2} xs={1} className="mt-2">
					<Col>
						<Button
							block
							variant="whatsapp"
							size="lg"
							className="d-flex justify-content-between align-items-center"
							href={`https://wa.me/557196905711`}
							target="_blank"
						>
							<i className="fab fa-3x fa-fw fa-whatsapp" />
							Suporte técnico
						</Button>
					</Col>
				</Row>
			</div>
		</StudentPage>
	);
}

export default ContactPage;
