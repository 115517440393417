import SchoolApi from '_api/SchoolApi';
import Student from '_entity/Acess/Student';

export default class InformativeApi extends SchoolApi {

	ranking(top = ''){
		return this.jsonData.get(`ranking/top${top}`).then(response => {
			if(!!response.data && response.data.ranking){
				response.data.ranking = response.data.ranking.map(d => {
					return new Student({...d.student,info_sum_points: d.sum_points})
				})
			}

			return response;
		});
	}

	myCourseInfo(){
		return this.json.get("courses/my/info")
	}

	points(){
		return this.jsonData.get("/students/profile/points")
	}

	course(course){
		return this.json.get(`anon/course/${course}`)
	}

	generatePayPagseguro(){
		return this.json.post("/students/generate/pagamento/pagseguro")
	}

	redirectRoyalpag(){
		return this.jsonData.get("/students/redirect/pagamento/royalpag")
	}

	initPayRoyalpag(){
		return this.json.get("/students/init/pagamento/royalpag")
	}

	generatePayRoyalpag(data){
		return this.json.post("/students/generate/pagamento/royalpag",data)
	}
}