import Item from '_entity/Course/Module/Item/Item';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import FormRewardMap from '_form/Game/FormRewardMap';
import {ItemReducerUtils} from '_reducer/Course/Module/Item/ItemReducer';
import {RewardMapReducerUtils} from '_reducer/Game/RewardMapReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ContentShowingBreadcrumb} from 'app/pages/AdminView/Content/ContentShowingPage';
import {LearningObjectShowingBreadcrumb} from 'app/pages/AdminView/LearningObject/LearningObjectShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const RewardMapForItemCreatingBreadcrumb = (item: Item) => {
	const previous = item instanceof LearningObject ? LearningObjectShowingBreadcrumb(item) : ContentShowingBreadcrumb(item);

	return [
		...previous,
		{path: AdminRoutes.REWARD_MAP_ITEM_CREATING.replace(':id', item.id), text: item.name},
	];
};

function RewardMapForItemCreatingPage(props){
	const createState = useSelector(RewardMapReducerUtils.createForItem.map);
	const readState = useSelector(ItemReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: itemID} = useParams();
	const item: Item = readState.data;
	const create = (data) => RewardMapReducerUtils.createForItem.dispatch(dispatch, itemID, data);

	useEffect(() => {
			ItemReducerUtils.read.dispatch(dispatch, itemID);

			return () => {
				ItemReducerUtils.read.dispatch_clear(dispatch);
				RewardMapReducerUtils.createForItem.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={RewardMapForItemCreatingBreadcrumb(item)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormRewardMap
					showOnStep={item instanceof LearningObject}
					showOnView
					showOnRanking
					course={item.module.course}
					error={createState.error}
					success={createState.finished}
					afterSuccessAlert={pushPrevious}
					onSubmit={create}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default RewardMapForItemCreatingPage;