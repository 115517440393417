// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import CourseApi from '_api/Course/CourseApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const CourseReducerOptions = new SchoolReducerOptions('course', CourseApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 *     info: ReducerBasicOption
 *     create: ReducerBasicOption
 *     edit: ReducerBasicOption
 *     editVideos: ReducerBasicOption
 *     read: ReducerBasicOption
 *     changeCost: ReducerBasicOption
 *     changePassword: ReducerBasicOption
 *     openClose: ReducerBasicOption
 *     changeEmails: ReducerBasicOption
 *     _anon_read: ReducerBasicOption
 * }}
 */
export const CourseReducerUtils: $ObjMap<string, ReducerBasicOption> = CourseReducerOptions.options();
