import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import avatarSRC from 'app/pages/AuthView/avatar.svg';
import NewPage from 'app/pages/NewPage';
import logo from 'assets/images/logo_principal.png';
import {Security} from 'hoc/security';
import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import Form from 'react-bootstrap/Form';
import {useDispatch} from 'react-redux';


function LoginPage(props){
	const lastUsername = Security.last_username();
	const lastAvatar = Security.last_avatar();

	const [isCurrent, setIsCurrent] = useState(true);
	const [avatarLoaded, setAvatarLoaded] = useState(true);
	const dispatch = useDispatch();
	const authenticate = ({username, password}) => SchoolAuthReducerUtils.login.dispatch(dispatch, username, password);

	const useInput = !isCurrent || !lastUsername;
	const avatarSrc = isCurrent && avatarLoaded && !!lastAvatar ? asset(lastAvatar) : avatarSRC;

	return (
		<NewPage
			logo={logo}
			title={<h3 className="m-0">Acesso</h3>}
			contentClass="login"
		>
			<SchoolForm
				className="login"
				submitText="ACESSAR"
				submittingText="ACESSANDO..."
				initialValues={{
					username: lastUsername,
					password: '',
				}}
				onSubmit={authenticate}
				asFunc
			>
				{
					({isSubmitting}) => (
						<>
							<Form.Group>
								<div className="avatar">
									<img
										src={avatarSrc}
										onError={() => setAvatarLoaded(false)}
										alt=""
									/>
								</div>
							</Form.Group>
							<Form.Group>
								<Fade in={useInput} mountOnEnter>
									<SchoolFormSimpleField
										withoutLabel
										withoutGroup
										name="username"
										label="Usuário"
										placeholder="Usuário..."
										componentProps={{disabled: isSubmitting}}
									/>
								</Fade>
								<Fade in={!useInput} mountOnEnter unmountOnExit>
									<div className="h3 text-center">
										{lastUsername}
									</div>
								</Fade>
								<SchoolFormSimpleField
									withoutLabel
									withoutGroup
									name="password"
									label="Senha"
									type="password"
									placeholder="Senha..."
									className="mt-2"
									componentProps={{disabled: isSubmitting}}
								/>
								{
									!useInput && (
										<Button
											variant="link" className="form-text float-right text-right" onClick={() => {
											Security.erase_avatar();
											setIsCurrent(false);
										}}
										>
											Não sou {lastUsername}
										</Button>
									)
								}
							</Form.Group>
						</>
					)
				}
			</SchoolForm>
		</NewPage>
	);
}

export default LoginPage;
