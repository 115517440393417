import {createBridge} from '@appit.com.br/react-misc/src/uniform/AppitUniform';
import AppitSwitchField from '@appit.com.br/react-misc/src/uniform/components/AppitSwitchField';
import AppitTextField from '@appit.com.br/react-misc/src/uniform/components/AppitTextField';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import {getCourseTerm} from '_entity/Course/Course';
import {SubmitField} from '_form/SubmitField';
import {SchoolAuthReducerUtils, UserReducerUtils} from '_reducer/Auth/AuthReducer';
import PageLogo from 'app/components/PageLogo';
import NewPage from 'app/pages/NewPage';
import {useCourseAwareness} from 'app/pages/StudentView/Access/CourseAwareness';
import {SchoolCourseAlert, useSchoolCourseAlert} from 'hoc/alert';
import React, {createRef, useCallback, useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {AutoFields, AutoForm} from 'uniforms-bootstrap4';

function StudentRegisterPage({loginPath}){
	const dispatch = useDispatch();
	const registerState: ReducerStateType = useSelector(UserReducerUtils.create.map);
	const {course} = useCourseAwareness();
	// const history = useHistory();
	const [accessData, setAccessData] = useState({});
	const register = (data) => {
		setAccessData({login: data.email, password: data.password});
		if(!data.id_personal){
			delete data.id_personal;
		}
		UserReducerUtils.create.dispatch(dispatch, {
			...data,
			course: course.id,
		});
	};
	const login = useCallback(() => {
		SchoolAuthReducerUtils.login.dispatch(dispatch, accessData.login, accessData.password, course.username);
	}, [dispatch,accessData, course]);
	const Alerts = useSchoolCourseAlert(course)

	// const validate = (values) => {
	// 	const errors = {};
	// 	for(const key of Object.keys(values)){
	// 		if(!values[key]){
	// 			errors[key] = true;
	// 		}
	// 	}
	//
	// 	// if(!CPFValidator.isValid(values['id_personal'])){
	// 	// 	errors['id_personal'] = true;
	// 	// }
	//
	// 	return errors;
	// };

	useEffect(() => {
		if(!course.is_open){
			Alerts.Feedback("Matriculas encerradas","Você não pode fazer o registro pois o curso se encerrou")
		}
	},[Alerts,course.is_open])

	useEffect(() => {
		return () => {
			UserReducerUtils.create.dispatch_clear(dispatch);
		};
	}, [dispatch]);

	const terms = getCourseTerm(course.username);


	useEffect(
		() => {
			SchoolCourseAlert(course).OperationAlert({
				error: registerState.error,
				finished: registerState.finished,
				successAction: () => {
					UserReducerUtils.create.dispatch_clear(dispatch);
					login();
				},
				failureAction: () => {
					UserReducerUtils.create.dispatch_clear(dispatch)
				},
				errorMap: {
					'internalUsername': 'usuário',
					'username': 'usuário',
					'idPersonal': 'CPF',
				},
			});
		},
		// eslint-disable-next-line
		[registerState, login],
	);

	const ref = createRef();
	const bridge = createBridge({
		title: '',
		type: 'object',
		properties: {
			id_personal: {
				type: 'string',
				label: 'CPF',
				mask: true,
				inputProps: {
					mask: "999.999.999-99",
					maskChar: '_'
				},
				uniforms: {
					component: AppitTextField
				}
			},
			name: {
				type: 'string',
				label: 'Nome',
				uniforms: {
					// wrap: false
				},
			},
			internal_username: {
				type: 'string',
				label: 'Usuário',
				pattern: /[\w\d_-]+/.toString(),
				uniforms: {
					component:  AppitTextField,
				},
			},
			email: {
				type: 'string',
				label: 'Email',
				uniforms: {
					component:  AppitTextField,
					inputProps: {
						autoComplete: 'chrome-off'
					},
				},
			},
			password: {
				type: 'string',
				label: 'Senha',
				uniforms: {
					component:  AppitTextField,
					inputProps: {
						autoComplete: 'new-password'
					},
					type: 'password',
				},
			},
			terms: {
				type: 'boolean',
				label: () => (<>
					Aceito&nbsp;os&nbsp;<a target="_blank" rel="noopener noreferrer" href={terms[0]}>Termos&nbsp;de&nbsp;uso</a>
				</>),
				uniforms: {
					component: AppitSwitchField,
					wrapClassName: "mb-0 h-100 d-flex align-items-center bg-white rounded p-1 text-dark"
				},
			},
		},
		required: ['name', 'internal_username', 'email', 'password','terms'],
	});

	return (
		<NewPage logo={asset(course.image)} title={<PageLogo course={course} />} contentClass="login">
			<AutoForm
				autoComplete="chrome-off"
				className="login justify-content-start"
				ref={ref}
				schema={bridge}
				onSubmit={(data) => {
					data.id_personal = data.id_personal ? data.id_personal.replace(/\s|-|\.|\//g,"") : ""
					register(data)
				}}

				// onValidate={(model) => {
				// 	console.log(model)
				//
				// 	return {
				// 		details: [
				// 			{
				// 				dataPath: 'terms',
				// 				params: {},
				// 				message: 'Você deve aceitar os termos de uso',
				// 			},
				// 		],
				// 	};
				// }}
				placeholder={true}
				label={true}
			>
				<div className="login-card">
					<div className="login-title">
						<img src={asset(course.image)} alt="" />
						<PageLogo course={course} />
					</div>
					<Form.Group>
						<AutoFields />
						<SubmitField>
							Registrar
						</SubmitField>
					</Form.Group>
					<div className="login-title">
						<img src={asset(course.image)} alt="" />
						<PageLogo course={course} />
					</div>
					<Link to={loginPath} className="login-link">
						Voltar
					</Link>
				</div>
			</AutoForm>
		</NewPage>
	);
}

export default StudentRegisterPage;
