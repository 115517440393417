export const mapResult = (result, ClassName, dataMerge = {}) => {
	if(result === undefined){
		throw new Error('Property not found on data');
	}
	if(Array.isArray(result)){
		return result.map((i) => mapResult(i, ClassName));
	}
	if(result === null){
		return null;
	}

	return result instanceof ClassName ? result : new ClassName({
		...result,
		...dataMerge,
	});
};

export const learningMain = (id) => {
	return '//' + process.env.REACT_APP_API_HOST + '/learning_object/' + id + '/main.html';
};

export const ifNull = (value, defaultValue) => !value && value !== false ? defaultValue : value;

