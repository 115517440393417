import SchoolApi from '_api/SchoolApi';
import StudentAccess from '_entity/Acess/StudentAccess';

export default class StudentAccessApi extends SchoolApi {
	get namespace(){
	    return 'accesses';
	}

	get entity_class(){
	    return StudentAccess;
	}

	list(studentID,page){
		return this.json.get(`by_student/${studentID}?page=${page}`)
	}
}