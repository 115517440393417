import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Form} from 'react-bootstrap';

export default class SchoolFormFile extends React.Component {
	static propTypes = {
		fileProps: PropTypes.object,
		label: PropTypes.string,
		src: PropTypes.string,
		name: PropTypes.string,
	};
	static defaultProps = {
		fileProps: {},
		label: 'Selecione um arquivo',
		name: 'file'
	};
	state = {
		labelText: this.props.label,
		touched: !!this.props.src
	};

	render(){
		const {fileProps, label,name} = this.props;
		const onChange = (e) => {
			const file = e.target.files[0];
			if(typeof fileProps.onChange === 'function'){
				fileProps.onChange({event: e, file});
			}
			this.setState({
				labelText: file.name,
				touched: true
			});
		};
		fileProps.className = 'form-control-lg ' + ifNull(fileProps.className, '');
		fileProps.label = <span className={this.state.touched ? "text-primary" : ""}>{this.state.labelText}</span>;
		return (
			<Form.Group className="d-flex align-items-end">
				<div className="w-100">
					{!!label && <Form.Label>{label}</Form.Label>}
					<Form.File
						{...fileProps}
						name={name}
						custom
						onChange={onChange}
					/>
				</div>
			</Form.Group>
		);
	}
}
