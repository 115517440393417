// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import CourseFAQApi from '_api/Course/CourseFAQApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const CourseFaqReducerOptions = new SchoolReducerOptions('course_faq', CourseFAQApi);

/**
 *
 * @type {{
 *     list: ReducerBasicOption
 *     create: ReducerBasicOption
 *     delete: ReducerBasicOption
 * }}
 */
export const CourseFaqReducerUtils: $ObjMap<string, ReducerBasicOption> = CourseFaqReducerOptions.options();
