import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolSelect from 'app/components/form/SchoolSelect';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

class FormCourseIntegration extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	getAlertMessage(): string{
		return 'Integração foi salva!';
	}

	form(){
		const initial = {
			email: '',
			key: '',
			type: '',
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
				asFunc
			>
				{({setFieldValue}) => (<>
					<SchoolSelect
						name={`type`}
						onChange={({value}) => setFieldValue(`type`, value)}
						placeholder="Selecione as formas de pagamento"
						label="Tipo"
						options={[
							{
								label: 'PagSeguro',
								value: 'pagseguro',
							},
							{
								label: 'RoyalPag',
								value: 'royalpag',
							},
						]}
					/>
					<Row>
						<Col>
							<SchoolFormSimpleField name="key" label="Key" />
						</Col>
						<Col>
							<SchoolFormSimpleField name="email" label="Email" />
						</Col>
					</Row>
				</>)}
			</SchoolForm>
		);
	}
}

export default FormCourseIntegration;