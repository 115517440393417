import SchoolEntity from '_entity/SchoolEntity';


export default class Points extends SchoolEntity {
	get value(){
		return this.getDataValue('value');
	}

	get valid(): boolean{
		return this.getDataValue('valid');
	}
}