import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import React from 'react';

export default class FormCourseFAQ extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
	};
	static defaultProps = {
		...FormEntity.defaultProps,
	};

	handleData = (data) => {
		return data;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];
			if(!value){
				errors[key] = true;
			}
		}

		return errors;
	};

	form(){
		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				validate={this.validate}
				initialValues={{
					description_question: '',
					description_answer: '',
				}}
				submitText="Adicionar"
			>
				<SchoolFormSimpleField component="textarea" name="description_question" label="Descrição da pergunta" />
				<SchoolFormSimpleField component="textarea" name="description_answer" label="Descrição da resposta" />
			</SchoolForm>
		);
	}
}