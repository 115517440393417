import Course from '_entity/Course/Course';
import FormCourseVideos from '_form/Course/FormCourseVideos';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const CourseVideosWriteBreadcrumb = (course: Course) => ([
	...CourseShowingBreadcrumb(course),
	{
		path: AdminRoutes.COURSE_EDITING_VIDEOS.replace(':id', course.id),
		text: 'Editar vídeos',
	},
]);

function CourseVideosWritePage(props){
	const dispatch = useDispatch();
	const {id: courseID} = useParams();


	const editingState = useSelector(CourseReducerUtils.editVideos.map);
	const showingState = useSelector(CourseReducerUtils.read.map);
	const editing = (data) => CourseReducerUtils.editVideos.dispatch(dispatch, courseID, data);
	const course: Course = showingState.data;

	useEffect(() => {
		CourseReducerUtils.read.dispatch(dispatch, courseID);
		return () => {
			CourseReducerUtils.read.dispatch_clear(dispatch);
			CourseReducerUtils.edit.dispatch_clear(dispatch);
		};
	}, [dispatch, courseID]);

	if(!showingState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage breadcrumb={CourseVideosWriteBreadcrumb(course)} doubleTitle>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormCourseVideos
					success={editingState.finished}
					onSubmit={editing}
					course={course}
					error={editingState.error}
					afterSuccessAlert={pushPrevious}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default CourseVideosWritePage;