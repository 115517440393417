import Module from '_entity/Course/Module/Module';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import PropTypes from 'prop-types';

import * as React from 'react';
import {Link} from 'react-router-dom';

ModuleCard.propTypes = {
	// to: PropTypes.string,
	module: PropTypes.instanceOf(Module).isRequired,
};


const Info = ({name,desc, value}) => (
	<span className="module-info-line">
		<span className="module-info-name">
			<span>{name}</span>
			{!!desc && <span className="small text-muted mt-n2">{desc}</span>}
		</span>
		<span className="module-info-separator">
			<span/>
			{!!desc && <span className="small text-muted mt-n2">&nbsp;</span>}
		</span>
		<span className="module-info-value">
			<span>{value}</span>
			{!!desc && <span className="small text-muted mt-n2">&nbsp;</span>}
		</span>
	</span>
);

/**
 *
 * @param {Module} module
 * @returns {*}
 * @constructor
 */
function ModuleCard({module}){
	const to = StudentRoutes.CONTENT.replace(':module', module.order);

	return (
		<div className="module-card-container">
			<Link to={to} className="module-card">
				<span className="module-title">
					<span>{module.name}</span>
					<span>{module.order > 9 ? module.order : `0${module.order}`}</span>
				</span>
				<span className="module-info">
					<Info name="Progresso&nbsp;conteúdos" value={`${module.info.progress_item}%`} />
					<Info name="Progresso&nbsp;perguntas" value={`${module.info.progress_question}%`} />
					<Info name="Índice&nbsp;sucesso" desc="Nas perguntas" value={`${module.info.success_index}%`} />
					<Info name="Tentativas" desc="Nas perguntas" value={`${module.info.count_attempts_question}`} />
					{/*<Info name="Acertos" desc="Nas perguntas" value={`${module.info.count_complete_question}`} />*/}
					<Info name="Fichas" value={`${module.info.sum_points}`} />
				</span>
			</Link>
		</div>
	);
}

export default ModuleCard;