import {asset} from '@appit.com.br/react-utils/src/utils/utils';
import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import PageLogo from 'app/components/PageLogo';
import avatarSRC from 'app/pages/AuthView/avatar.svg';
import NewPage from 'app/pages/NewPage';
import {useCourseAwareness} from 'app/pages/StudentView/Access/CourseAwareness';
import {Security} from 'hoc/security';
import React, {useCallback, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import Form from 'react-bootstrap/Form';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

function StudentLoginPage({registerPath,forgetPath}){
	const [isCurrent, setIsCurrent] = useState(true);
	const [avatarLoaded, setAvatarLoaded] = useState(true);

	const {course, getInternalUsername,course_username} = useCourseAwareness();

	const lastUsername = getInternalUsername(Security.last_username());
	const lastAvatar = Security.last_avatar();
	const useInput = !isCurrent || !lastUsername;
	const avatarSrc = isCurrent && avatarLoaded && !!lastAvatar ? asset(lastAvatar) : avatarSRC;
	const dispatch = useDispatch();

	// console.log(course_username)
	const authenticate = useCallback(({username, password}) => {
		// console.log(username, password,course_username)
		SchoolAuthReducerUtils.login.dispatch(dispatch, username, password, course_username);
	},[dispatch,course_username])

	return (
		<NewPage logo={asset(course.image)} title={<PageLogo course={course} />} contentClass="login" headerClassName="login-header">
			<SchoolForm
				className="login"
				submitText="ACESSAR"
				submittingText="ACESSANDO..."
				initialValues={{
					username: lastUsername,
					password: '',
				}}
				onSubmit={authenticate}
				asFunc
			>
				{({isSubmitting,isValid,extraProps,errors}) => (<>
					<Form.Group>
						<div className="avatar">
							<img
								src={avatarSrc}
								onError={() => setAvatarLoaded(false)}
								alt=""
							/>
						</div>
					</Form.Group>
					<Form.Group className="position-relative">
						<Fade in={useInput} mountOnEnter>
							<SchoolFormSimpleField
								withoutLabel
								withoutGroup
								name="username"
								label="Usuário"
								componentProps={{disabled: isSubmitting ? !isValid : false}}
							/>
						</Fade>
						<Fade in={!useInput} mountOnEnter unmountOnExit>
							<div className="h3 text-center">
								{lastUsername}
							</div>
						</Fade>
						<SchoolFormSimpleField
							withoutLabel
							withoutGroup
							name="password"
							label="Senha"
							type="password"
							className="mt-2"
							componentProps={{disabled: isSubmitting ? !isValid : false}}
						/>
						<div className="d-flex justify-content-between">
							{course.is_open && (
								<Form.Text as={Link} to={registerPath} className="btn btn-link">
									Cadastre-se
								</Form.Text>
							)}
							{forgetPath && (
								<Form.Text as={Link} to={forgetPath} className="btn btn-link">
									Esqueci a senha
								</Form.Text>
							)}
							{!useInput && (
								<Button
									variant="link" className="form-text float-right text-right"
									onClick={() => {
										Security.erase_avatar();
										setIsCurrent(false);
									}}
								>
									Não sou {lastUsername}
								</Button>
							)}
						</div>
					</Form.Group>
				</>)}
			</SchoolForm>
		</NewPage>
	);
}

export default StudentLoginPage;
