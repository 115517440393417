import ContentRage from '_entity/Course/Module/Item/Content/ContentRange';
import PropTypes from 'prop-types';

import React from 'react';
import Button from 'react-bootstrap/Button';

RangeElement.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
	maxEmphasis: PropTypes.number,
	onCellClick: PropTypes.func,
	onClick: PropTypes.func,
	clicked: PropTypes.bool,
	size: PropTypes.oneOf([
		'',
		'sm',
	]),
};
RangeElement.defaultProps = {
	onCellClick: null,
	clicked: false,
	className: '',
};

/**
 *
 * @param className
 * @param noTitle
 * @param {ContentRange} range
 * @param onCellClick
 * @param onClick
 * @param clicked
 * @param size
 * @param id
 * @param style
 * @param showLegend
 * @returns {*}
 * @constructor
 */
function RangeElement({className, noTitle, range, onCellClick, onClick, clicked, size,id,style,showLegend}){
	const rangePrefix = 'range-table';
	const classes = [rangePrefix];
	const maxEmphasis = 4;
	if(typeof onClick === 'function'){
		classes.push(`${rangePrefix}-action`);
		if(clicked){
			classes.push(`${rangePrefix}-action-active`);
		}
	}
	if(!!size){
		classes.push(`${rangePrefix}-${size}`);
	}
	if(!!className){
		classes.push(`${className}`);
	}

	return (<>
		<div
			style={style}
			className={classes.join(' ')}
			onClick={onClick}
			id={id}
		>
			{!noTitle && (
				<div className="range-title">
					{range.name}
				</div>
			)}
			{Array(13).fill('').map((_, row) => {
				return (
					<div className="range-row" key={`row-${row}`}>
						{Array(13).fill('').map((_, col) => {
							let cell = ContentRage.cellToString(row, col);
							if(row !== col){
								if(row < col){
									cell = (<span className="text-nowrap">
										{cell}
										<small className="o-60">s</small>
									</span>);
								}
								else{
									cell = (<span className="text-nowrap">
										{cell}
										<small className="o-60">o</small>
									</span>);
								}
							}

							const index = ContentRage.index(range.info, row, col, maxEmphasis);
							let cellClass = `range-cell-emphasis-${index}`;
							if(maxEmphasis > 4){
								cellClass += ' range-cell-extended';
							}

							if(typeof onCellClick === 'function'){
								return (
									<Button
										key={`cel-${row}-${col}`}
										variant={cellClass}
										className="range-cell"
										onClick={() => onCellClick(row, col)}
										onMouseEnter={(e) => {
											if(e.buttons === 1){
												onCellClick(row, col);
											}
										}}
										onMouseDown={() => onCellClick(row, col)}
									>
										{cell}
									</Button>
								);
							}

							return (
								<div
									key={`cel-${row}-${col}`}
									className={`range-cell ${cellClass}`}
								>
									{cell}
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
		{showLegend && (
			<div className="range-legend">
				<div>
					<span className="bg-emphasis bg-emphasis-1"/> acima de 90% das vezes
				</div>
				<div>
					<span className="bg-emphasis bg-emphasis-2"/>50 a 90% das vezes
				</div>
				<div>
					<span className="bg-emphasis bg-emphasis-3"/> abaixo de 50% das vezes
				</div>
			</div>
		)}
	</>);
}

export default RangeElement;