import Course from '_entity/Course/Course';
import SchoolEntity from '_entity/SchoolEntity';

export default class BadgeType extends SchoolEntity{
	get name(){
		return this.getDataValue("name")
	}

	get description(){
		return this.getDataValue("description")
	}

	get slug(){
		return this.getDataValue("slug")
	}

	get course(){
		return this.getDataValue("course",Course)
	}

	get image(){
		return this.getDataValue("image_src")
	}
}