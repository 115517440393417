import {expectsObject, expectsString} from '@appit.com.br/react-utils/src/utils/utils';
import SchoolApi from '_api/SchoolApi';
import ContentFile from '_entity/Course/Module/Item/Content/ContentFile';

export default class ContentFileApi extends SchoolApi {
	get namespace(){
		return 'content_files';
	}

	get entity_class(){
		return ContentFile;
	}

	list(contentID){
		expectsString(contentID);
		return this.json.get(`by_content/${contentID}`);
	}

	create(contentID, data){
		expectsString(contentID);
		expectsObject(data);
		return this.multipart.post(`by_content/${contentID}`, data);
	}

	delete(id){
		expectsString(id);
		return this.json.delete(id);
	}
}
