import {AppitAlertOperation} from '@appit.com.br/react-misc/src/alert/AppitAlert';
import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import {toFixedLocale} from '@appit.com.br/react-utils/src/utils/utils';
import Admin from '_entity/Acess/Admin';
import CourseUser from '_entity/Acess/CourseUser';
import Student from '_entity/Acess/Student';
import Course from '_entity/Course/Course';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import {SvgOrImage} from '_ui/EntityUi';
import {DisplayInfo} from 'app/components/display/DisplayInfo';
import AccessList from 'app/components/new/Page/Student/AccessList';
import PaymentIgnoreSelector from 'app/pages/CourseView/Student/Buttons/PaymentIgnoreSelector.js';
import PaymentShowing from 'app/pages/WaitingView/PaymentShowing';
import Tooltiper from 'hoc/ui/Tooltiper';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, FormControl, InputGroup, ProgressBar} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import InputMask from 'react-input-mask'

Profile.propTypes = {
	course: PropTypes.instanceOf(Course),
	student: PropTypes.instanceOf(Student).isRequired,
	className: PropTypes.string,
};

/**
 *
 * @param {Student} student
 * @param {Course} course
 * @param className
 * @param children
 * @param showAccesses
 * @param showRefund
 * @param showIgnorePayment
 * @param refreshAction
 * @returns {*}
 * @constructor
 */
function Profile({student, course, className, children, showAccesses, showRefund, showIgnorePayment, refreshAction}){
	const points_name = !!course ? course.points_name : student.course.points_name;
	const user = useAppitUserAwareness();
	const total = student.info.index.total * 100;
	const [edit, setEdit] = useState('');
	const dispatch = useDispatch();
	const changeField = useCallback((field, value) => {
		StudentReducerUtils.change_field.dispatch(dispatch, student.id, {field, value});
	}, [dispatch, student.id]);
	const changeFieldState = StudentReducerUtils.change_field.useMap();

	useEffect(() => {
		AppitAlertOperation({
			finished:changeFieldState.finished,
			error: changeFieldState.error,
			always: () => StudentReducerUtils.change_field.dispatch_clear(dispatch),
			successAction: refreshAction
		})
	},[dispatch,changeFieldState,refreshAction])

	const ToEdit = ({children, name}) => {
		if(user instanceof CourseUser){
			return (<>
				<Button
					variant="outline-warning"
					size="sm"
					className="mr-1"
					onClick={() => setEdit(name)}
				>
					<i className="fa fa-edit" />
				</Button>
				{children}
			</>);
		}
		return children;
	};

	const Edit = ({value: initialValue, name, valueTag: Tag, valueProps,mask}) => {
		const [value,setValue] = useState(initialValue || "")
		if(name === edit && user instanceof CourseUser){
			const submit = (e) => {
				e.preventDefault();
				changeField(name,value)
			};
			const props = {};
			if(mask){
				props.as = InputMask;
				props.mask = mask
			}

			return (
				<form className="d-inline-block" onSubmit={submit}>
					<InputGroup>
						<FormControl
							className="rounded-right-0"
							style={{miWidth: '100px'}}
							value={value}
							onChange={(e) => setValue(e.target.value)}
							size="sm"
							name="field"
							{...props}
						/>
						<InputGroup.Append>
							<Button
								size="sm"
								variant="outline-success"
								type="submit"
							><i className="fa fa-save" /></Button>
						</InputGroup.Append>
					</InputGroup>
				</form>
			);
		}

		if(Tag){
			return <Tag {...valueProps}>{value}</Tag>;
		}
		else{
			return value || 'N/A';
		}
	};

	return (
		<div className={`student ${className ? className : ''}`}>
			{children}
			<div className="d-flex flex-column h4">
				<div className="d-flex">
					<DisplayInfo
						inlineBlock
						textProps={{className: 'd-inline d-sm-block'}}
						text={<ToEdit name="name">Nome:</ToEdit>}
						value={<Edit value={student.name} name="name" />}
					/>
				</div>
				{user instanceof CourseUser && (
					<div className="d-flex">
						<DisplayInfo
							inlineBlock
							textProps={{className: 'd-inline d-sm-block'}}
							text={<ToEdit name="cpf">CPF:</ToEdit>}
							value={<Edit value={student.cpf} name="cpf" mask="999.999.999-99" />}
						/>
					</div>
				)}
				<div className="d-flex">
					<DisplayInfo
						inlineBlock
						textProps={{className: 'd-inline d-sm-block'}}
						value={<Edit
							value={student.email} name="email"
							valueTag={'a'}
							valueProps={{href: ``}}
						/>}
						text={<ToEdit name="email">Email:</ToEdit>}
					/>
				</div>
				{user instanceof CourseUser && (
					<div className="d-flex">
						<DisplayInfo
							inlineBlock
							textProps={{className: 'd-inline d-sm-block'}}
							text={<ToEdit name="internal_username">Usuario:</ToEdit>}
							value={<Edit value={student.internal_username} name="internal_username" />}
						/>
					</div>
				)}
				{user instanceof Admin && (
					<div className="d-flex">
						<DisplayInfo
							inlineBlock
							textProps={{className: 'd-inline d-sm-block'}}
							text={<ToEdit name="username">Usuario:</ToEdit>}
							value={<Edit value={student.username} name="username" />}
						/>
					</div>
				)}
				{!student.payment.is_completed && showIgnorePayment && (<>
					<div>
						<DisplayInfo
							inlineBlock
							textProps={{className: 'd-inline d-sm-block'}}
							text="Considerar Pagamento:"
							valueProps={{style: {width: '100px'}}}
							value={
								<PaymentIgnoreSelector
									onConsider={refreshAction}
									onIgnore={refreshAction}
									student={student}
									className="float-right"
									size="sm"
								/>
							}
						/>
					</div>
				</>)}
			</div>
			{!student.payment.ignore && (<>
				<hr />
				<div className="d-flex flex-column">
					<h4>Pagamento</h4>
					<PaymentShowing notRefresh payment={student.payment} showRefund={showRefund} />
				</div>
			</>)}
			<hr />
			<div>
				<DisplayInfo
					reverse
					value={student.info.progress.count_complete_content}
					text="Conteúdos visualizados"
				/>
			</div>
			<div>
				<DisplayInfo
					reverse
					value={student.info.progress.count_complete_learning_object}
					text="Objeto de aprendizagem concluídos"
				/>
			</div>
			<div>
				<DisplayInfo
					reverse
					value={student.info.progress.count_complete_question}
					text="Perguntas respondidas"
				/>
			</div>
			<div>
				<DisplayInfo
					value={<ProgressBar title="Progresso" now={total} label={`${toFixedLocale(total)}%`} />}
					text="Progresso"
				/>
			</div>
			<hr />
			<div>
				<DisplayInfo
					reverse
					value={student.info.sum_points}
					text={points_name}
				/>
			</div>
			<div>
				<DisplayInfo
					reverse
					value={student.info.ranking ? `#${student.info.ranking}` : '-'}
					text="no ranking"
				/>
			</div>
			<div className="d-flex flex-column">
				<h4>Badges</h4>
				<div className="d-flex flex-row flex-wrap justify-content-sm-start justify-content-center">
					{student.course.badges.map(badgeType => {
						const count = student.info.count_badges[badgeType.id];

						return (
							<Tooltiper
								key={badgeType.id}
								placement="bottom"
								tooltip={count > 0 ? `Conquistado ${count} vezes` : 'Ainda não foi conquistado'}
							>
								<div className={`h1 m-1 bg-secondary py-2 px-3 rounded ${count > 0 ? 'text-primary' : 'text-white o-40'}`}>
									<SvgOrImage src={badgeType.image} className="square-75px" />
									<div className="small text-center">
										{
											count > 0
												? `x ${count}`
												: ' - '
										}
									</div>
								</div>
							</Tooltiper>

						);
					})}
				</div>
			</div>
			{showAccesses && (<>
				<hr />
				<div>
					<h4>Acessos</h4>
					<AccessList student={student} />
				</div>
			</>)}
		</div>
	);
}

export default Profile;
