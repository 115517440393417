import RewardMap from '_entity/Game/RewardMap';

class RewardMapOnEvent extends RewardMap {

	get event_name(){
		return this.getDataValue('event_name');
	}
}

RewardMapOnEvent.Names = {
	"viewed_intro": "Iniciou o curso",
	"viewed_outro": "Terminou o curso",
	"access": "Acesso",
}

export default RewardMapOnEvent;