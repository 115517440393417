import ContentTip from '_entity/Course/Module/Item/Content/ContentTip';
import {ContentTipReducerUtils} from '_reducer/Course/Module/Content/ContentTipReducer';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';

/**
 *
 * @param {Content} content
 * @param afterDelete
 * @returns {*}
 * @constructor
 */
function TipSection({content, afterDelete}){
	const dispatch = useDispatch();
	const removeState = useSelector(ContentTipReducerUtils.delete.map);
	const remove = id => ContentTipReducerUtils.delete.dispatch(dispatch, id);

	useEffect(() => {
			SchoolOperationAlert({
				successMessage: `Dica deletada!`,
				successAction: afterDelete,
				finished: removeState.finished,
				error: removeState.error,
			});
		},
		// eslint-disable-next-line
		[removeState],
	);

	return (<>
		<ListGroup className="text-left align-sef-end">
			{content.tips.map((tip: ContentTip) => (
				<ListGroup.Item key={tip.id} className="d-flex align-items-center justify-content-between px-5">
					<Tooltiper tooltip={tip.tags.join(',')}>
						{tip.title}
						<div className="small">
							{tip.description}
						</div>
					</Tooltiper>
					<Button
						className="btn-right"
						size="sm" variant="danger"
						onClick={() => SchoolConfirmAlert({
							title: 'Realmente deseja deletar essa dica?',
							action: () => remove(tip.id),
						})}
					>
						<i className="fa fa-trash" />
					</Button>
				</ListGroup.Item>
			))
			}
		</ListGroup>
		<LinkContainer to={AdminRoutes.CONTENT_TIP_CREATING.replace(':id', content.id)}>
			<Button variant="primary" className="mt-3">
				Adicionar dica
			</Button>
		</LinkContainer>
	</>);
}

export default TipSection;