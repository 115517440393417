import {AppitAlert, AppitAlertSettings} from '@appit.com.br/react-misc/src/alert/AppitAlert';
import {asset, callIfFunction, ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import {getModuleAvatar, ModuleAvatars} from '_entity/Course/Module/Module';
import React, {useMemo} from 'react';
import ReactIs from 'react-is';


AppitAlertSettings.confirmButtonClass = 'btn btn-primary btn-lg';
AppitAlertSettings.cancelButtonClass = 'btn btn-link btn-lg';
AppitAlertSettings.showCloseButton = true;
AppitAlertSettings.closeButtonHtml = '<i classname="fa fa-times"></i>';

const ajustContent = (message, avatar) => {
	let top = message;
	let bottom = '';

	if(Array.isArray(message)){
		top = message[0];
		bottom = message[1];
	}
	else if(!ReactIs.isElement(message) && message instanceof Object){
		top = message.top || message.htmlTop;
		bottom = message.bottom || message.htmlBottom;
	}

	return (<>
		{avatar && (
			<div className="message-avatar" style={{backgroundImage: `url(${avatar})`}} />
		)}
		<div className={`messages ${avatar ? '' : 'without-avatar'}`}>
			<div className="message-top">
				<div>
					{top}
				</div>
			</div>
			<div className="message-bottom">
				<div>
					{bottom}
				</div>
			</div>
		</div>
	</>);
};

export const SchoolAlertWithSettings = (settings = {}, then = () => null, footer, avatar) => {
	AppitAlert({
		...settings,
		customClass: {
			popup: 'school-popup',
		},
		showCloseButton: true,
		closeButtonHtml: '<i class="fa fa-times"></i>',
		html: ajustContent(settings.html, avatar),
		// grow: "column",
		footer,
		width: '50vw',
	}, then);
};

export const FeedbackWithCancel = (top, bottom, confirmText, then, footer, avatar) => {
	SchoolAlertWithSettings({
		showConfirmButton: true,
		html: {top, bottom},
		headerClass: 'd-none',
		confirmButtonText: confirmText,
		confirmButtonClass: 'btn btn-info',
		// confirmButtonText: 'Ir para o Próximo conteúdo',
	}, then, footer, avatar);
};

export const SchoolAlert = (title, message, then = () => null, footer, avatar) => {
	SchoolAlertWithSettings({
		title: title,
		html: message,
	}, then, footer, avatar);
};

export const SchoolFeedback = (top, bottom, then = () => null, footer, avatar) => {
	SchoolAlertWithSettings({
		showConfirmButton: false,
		html: {top, bottom},

	}, then, footer, avatar);
};
/**
 *
 * @param {string} title
 * @param {string} message
 * @param {function} action
 * @param footer
 * @param avatar
 * @constructor
 */
export const SchoolConfirmAlert = ({title, message, action}: { message: string, action: () => void }, footer, avatar) => {
	const extraSettings = {};
	extraSettings.cancelButtonText = 'Cancelar';
	extraSettings.showCancelButton = true;
	extraSettings.confirmButtonText = 'Confirmar';

	AppitAlert(
		{
			title: ifEmpty(title, 'Aviso'),
			...extraSettings,
			html: ajustContent(message, avatar),
		},
		({value}) => {
			if(value){
				action();
			}
		},
	);
};

export const SchoolOperationAlert = (
	{
		successTitle, successMessage, successAction,
		failureTitle, failureMessage, failureAction,
		error,
		errorMap = {},
		finished,
		always,
	},
	footer,
) => {
	successTitle = ifEmpty(successTitle, 'Operação realizada com sucesso');
	failureTitle = ifEmpty(failureTitle, 'Operação não foi realizada');
	successAction = ifEmpty(successAction, () => null);
	failureAction = ifEmpty(failureAction, () => null);
	if(finished){
		if(error){
			let fallbackMessage = callIfFunction(failureMessage);
			const toMap = (v) => errorMap[v.field] ? errorMap[v.field] : v.field;
			const onlyUnique = (value, index, self) => {

				return self.findIndex(v => toMap(v) === toMap(value)) === index;
			};
			if(!fallbackMessage && error.isAxiosError && error.response.data){
				fallbackMessage = [];
				if(error.response.data.error){
					fallbackMessage = error.response.data.violations.filter(onlyUnique).map((v, index) => (
						<li key={`violation-${index}`}>
							{v.message}
							<small>[{toMap(v)}]</small>
						</li>
					));
					fallbackMessage = <ul>{fallbackMessage}</ul>;
				}
			}
			SchoolFeedback(
				callIfFunction(failureTitle),
				fallbackMessage,
				failureAction,
				footer,
			);
		}
		else{
			SchoolFeedback(
				callIfFunction(successTitle),
				callIfFunction(successMessage),
				successAction,
				footer,
			);
		}
		if(typeof always === 'function'){
			always();
		}
	}
};
export const SchoolCourseAlert = (course: Course, module: Module, avatar = true) => {
	const footer = !!course && (
		<div className="footer">
			<div className="footer-image" style={{backgroundImage: `url(${asset(course.image)})`}} />
			<div className="footer-logo-text" style={{backgroundImage: `url(${asset(course.logo)})`}}>
				{!course.logo && course.name}
			</div>
		</div>
	);
	let avatarSrc;
	if(avatar){
		if(typeof avatar === 'string'){
			avatarSrc = avatar;
		}
		else{
			const moduleAvatars = ModuleAvatars();
			let find = [];
			if(!!module){
				find = moduleAvatars.find(([image, name]) => module.avatar === name) || [];
			}
			else if(!!course){
				const random = course.avatars.length <= 1 ? 0 : Math.floor(Math.random() * (course.avatars.length - 1));
				find = getModuleAvatar(course.avatars[random]);
			}
			avatarSrc = (find && find[0]) || '';
		}
	}

	return {
		AlertWithSettings: (settings = {}, then = () => null) => SchoolAlertWithSettings(settings, then, footer, avatarSrc),
		Alert: (title, message, then = () => null) => SchoolAlert(title, message, then, footer, avatarSrc),
		Feedback: (top, bottom, then = () => null) => SchoolFeedback(top, bottom, then, footer, avatarSrc),
		FeedbackWithCancel: (top, bottom, cancelText, then = () => null) => FeedbackWithCancel(top, bottom, cancelText, then, footer, avatarSrc),
		ConfirmAlert: ({title, message, action}) => SchoolConfirmAlert(title, message, action, footer, avatarSrc),
		OperationAlert: ({
							 successTitle, successMessage, successAction,
							 failureTitle, failureMessage, failureAction,
							 error, errorMap, finished, always,
						 }) => SchoolOperationAlert({
			successTitle, successMessage, successAction,
			failureTitle, failureMessage, failureAction,
			errorMap,
			error,
			finished,
			always,
		}, footer, avatarSrc),
	};
};

export const useSchoolCourseAlert = (course: Course, module: Module, avatar = true) => {
	return useMemo(
		() => SchoolCourseAlert(course, module, avatar),
		// eslint-disable-next-line
		[course && course.id, module && module.id, avatar],
	);
};
