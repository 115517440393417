import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import Student from '_entity/Acess/Student';
import Item from '_entity/Course/Module/Item/Item';
import EarnedBadge from '_entity/Game/EarnedBadge';
import SchoolEntity from '_entity/SchoolEntity';


export default class ViewedItem extends SchoolEntity {

	get student(): Student{
		return entityResolver(this.getDataValue('student'), Student);
	}

	get item(): Item{
		return entityResolver(this.getDataValue('item'), Item);
	}

	get badges(): EarnedBadge[]{
		return entityResolver(this.getDataValue('badges'), EarnedBadge);
	}

	get points(): Points{
		return entityResolver(this.getDataValue('points'), Points);
	}
}
