import {HeaderMessage} from 'app/components/message/messages';
import SchoolLoader from 'app/SchoolLoader';
import {toTime} from 'hoc/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {findDOMNode} from 'react-dom';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

export default class SchoolVideo extends React.Component {
	static propTypes = {
		src: PropTypes.string,
		onEnded: PropTypes.func,
		className: PropTypes.string,
		childrenOnEnd: PropTypes.bool,
		extra: PropTypes.any,
		loader: PropTypes.elementType,
	};
	static defaultProps = {
		className: '',
		controls: true,
	};
	state = {
		error: false,
		url: null,
		playing: false,
		light: false,
		volume: 1,
		muted: false,
		speed: 0,
		seeking: false,
		played: 0,
		disabled_speed: false,
		loading: true,
		duration: 0,
		fullscreen: false
	};
	speeds = [1, 1.25, 1.5, 2];

	// static isLocal = (src) => !src || console.log(src);

	handlePlayPause = (e) => {
		this.setState({playing: !this.state.playing});
	};

	handleClickFullscreen = (e) => {
		screenfull.toggle(findDOMNode(this.refVideo));
	};

	handleToggleMuted = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({muted: !this.state.muted});
	};

	handleVolumeChange = e => {
		this.setState({volume: parseFloat(e.target.value)});
	};

	handleUpSpeed = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({speed: (this.state.speed + 1) % this.speeds.length});
	};

	handleDownSpeed = e => {
		e.preventDefault();
		e.stopPropagation();
		let speed = (this.state.speed - 1);
		if(speed < 0){
			speed = this.speeds.length - speed;
		}
		this.setState({speed: speed % this.speeds.length});
	};

	handleSeekMouseDown = e => {
		// e.preventDefault();
		// e.stopPropagation();
		// console.log(e)
		this.setState({seeking: true});
	};

	handleSeekChange = e => {
		// e.preventDefault();
		// e.stopPropagation();
		// console.log(e)
		this.setState({played: parseFloat(e.target.value)});
	};

	handleSeekMouseUp = e => {
		// e.preventDefault();
		// e.stopPropagation();
		this.setState({seeking: false});
		this.player.seekTo(parseFloat(e.target.value));
	};

	handleProgress = state => {
		// console.log('onProgress', state)
		// We only want to update time slider if we are not currently seeking
		if(!this.state.seeking){
			this.setState(state);
		}
	};

	handleError = error => {
		if(error.method === 'setPlaybackRate'){
			this.setState({disabled_speed: true});
		}
		else if(error.type === 'error'){
			this.setState({error: true});
		}
	};

	handleBackward = e => {
		e.preventDefault();
		e.stopPropagation();

		const tenSeconds = 10 / this.state.duration;
		const result = this.state.played - tenSeconds;
		this.player.seekTo(result < 0 ? 0 : result);
	};

	handleForward = e => {
		e.preventDefault();
		e.stopPropagation();

		const tenSeconds = 10 / this.state.duration;
		const result = this.state.played + tenSeconds;
		this.player.seekTo(result > this.state.duration ? this.state.duration : result);
	};

	handleDuration = (duration) => {
		this.setState({duration});
	};

	onKey = (e) => {
		if(e.target !== document.body){
			return;
		}
		if(e.keyCode === 32){
			this.handlePlayPause(e);
		}
		if(e.keyCode === 37){
			this.handleBackward(e);
		}
		if(e.keyCode === 39){
			this.handleForward(e);
		}
		// if(e.keyCode === 38){
		// 	this.handleUpSpeed(e);
		// }
		// if(e.keyCode === 40){
		// 	this.handleDownSpeed(e);
		// }
	};

	elementResize = () => {
		let header = document.getElementById('header');
		header = header ? header.offsetHeight : 0;
		let controlsHeight = document.getElementById('video-controls');
		controlsHeight = controlsHeight ? controlsHeight.offsetHeight : 0;
		const mobileControls = document.querySelectorAll(".video-controls-landscape-mobile")
		let controlsWidth = 0;
		for(const mobileControl of mobileControls){
			controlsWidth += mobileControl.offsetWidth
		}
		const ratio = 16 / 9;

		const el = findDOMNode(this.refVideo);
		const height = el.offsetHeight;
		const width = el.offsetWidth;

		const maxHeight = document.body.offsetHeight - header - controlsHeight;
		const maxWidthElement = findDOMNode(this.playerContainer) || findDOMNode(this.refVideo).parentElement;
		const maxWidth = maxWidthElement.offsetWidth - controlsWidth;

		const setRatio = (el, onlyWidth = false) => {
			if(!el){
				return;
			}
			const useWidthForRatio = height * ratio > width;
			let usedHeight = useWidthForRatio ? width / ratio : height;
			let usedWidth = useWidthForRatio ? width : height * ratio;
			let usedMaxHeight = maxHeight;
			const actionsEl = document.getElementById('sidebar-left-actions');

			const footerHeight = !actionsEl ? 0 : actionsEl.offsetHeight || 0;

			usedHeight = usedMaxHeight - footerHeight;
			usedWidth = usedHeight * ratio;
			if(usedWidth > maxWidth){
				usedWidth = maxWidth;
				usedHeight = usedWidth / ratio;
			}

			if(!!document.querySelector('#content.show-footer')){
				usedHeight = usedHeight * .9;
				usedWidth = usedHeight * ratio;
			}

			// console.log("height",usedHeight)
			// console.log("width",usedWidth)
			if(!onlyWidth){
				el.style.setProperty('height', usedHeight + 'px');
			}
			el.style.setProperty('width', usedWidth + 'px');
		};

		setRatio(findDOMNode(this.player));
		setRatio(findDOMNode(this.loader),true);
		setRatio(findDOMNode(this.mainPlay));
		setRatio(findDOMNode(this.progress), true);
		setRatio(findDOMNode(this.controls), true);

		for(const el of document.querySelectorAll("[data-widget]")){
			setRatio(el, true);
		}
		// setRatio(el);
	};

	componentDidMount(): void{
		this.setState({speed: 0});
		document.addEventListener('keydown', this.onKey);
		this.elementResize();
		this.si = setInterval(this.elementResize, 200);
		if (screenfull.isEnabled) {
			screenfull.on('change', () => {
				this.setState({fullscreen: screenfull.isFullscreen})
			});
		}
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void{
		this.elementResize();
	}

	componentWillUnmount(): void{
		document.removeEventListener('keydown', this.onKey);
		clearInterval(this.si);
	}

	render(): React.ReactNode{
		const controls = false;
		const classes = ['video', this.props.className, this.state.playing ? 'video-playing' : 'video-pause'];
		if(controls || !this.props.src){
			classes.push('hide-controls');
		}
		// console.log(this.state.fullscreen)
		if(this.state.fullscreen){
			classes.push('video-fullscreen');
		}
		const extra = this.props.extra;

		const speed = <Button
			className="video-control-speed"
			variant="light"
			onClick={this.handleUpSpeed}
			disabled={this.state.disabled_speed}
		>
			<span>
				<strong>{this.speeds[this.state.disabled_speed ? 0 : this.state.speed]}</strong>x
			</span>
		</Button>;
		const backward = <Button
			className="video-control-backward"
			variant="light"
			onClick={this.handleBackward}
		>
			<span className="position-relative">
				<i className="fa fa-undo-alt" />
				<small className="position-absolute" style={{bottom: '-1.2em',left:0}}>-10s</small>
			</span>
		</Button>;
		const play = <Button
			className="video-control-play"
			variant="light"
			onClick={this.handlePlayPause}
		>
			{this.state.playing
				? <i className="fa fa-pause" />
				: <i className="fa fa-play" />
			}
		</Button>;
		const forward = <Button
			className="video-control-forward"
			variant="light"
			onClick={this.handleForward}
		>
			<span className="position-relative">
				<i className="fa fa-redo-alt" />
				<small className="position-absolute" style={{bottom: '-1.2em',left:0}}>+10s</small>
			</span>
		</Button>;
		const mute = <Button
			className="video-control-small video-control-mute"
			variant="light"
			onClick={this.handleToggleMuted}
		>
			{this.state.muted
				? <i className="fa fa-volume-mute" />
				: <i className="fa fa-volume-up" />
			}
		</Button>;
		const fullscreen = <Button
			variant="light"
			onClick={this.handleClickFullscreen}
			className="video-control-fullscreen"
		>
			<i className="fa fa-expand" />
		</Button>;

		const showVideo = !!this.props.src && !this.state.error;
		const Loader = this.props.loader || SchoolLoader;
		const totalDuration = this.state.duration;
		const currentDuration = this.state.duration * this.state.played;
		const remainingDuration = totalDuration - currentDuration + 0.5

		return (
			<div
				className={classes.join(' ')}
				ref={ref => (this.refVideo = ref)}
			>
				{(this.state.loading) && (<>
					<div className="video-element video-element-loader" ref={ref => (this.loader = ref)}>
						{showVideo
							? <Loader />
							: <HeaderMessage message="Video não disponível" className="text-white mb-0" />
						}
					</div>
				</>)}
				{!showVideo && <div className="video-element-container" ref={ref => (this.loader = ref)} />}
				{showVideo && (<>
					<div className="video-element-container" ref={ref => (this.playerContainer = ref)}>
						<ReactPlayer
							onReady={() => this.setState({loading: false})}
							// width="100%"
							// height="100%"
							ref={ref => (this.player = ref)}
							onClick={this.handlePlayPause}
							onDoubleClick={this.handleClickFullscreen}
							playing={this.state.playing}
							muted={this.state.muted}
							volume={this.state.volume}
							controls={controls}
							url={this.props.src}
							onEnded={this.props.onEnded}
							playbackRate={this.speeds[this.state.speed]}
							onProgress={this.handleProgress}
							onError={this.handleError}
							onDuration={this.handleDuration}
							className="video-element"
							config={{
								youtube: {
									embedOptions: {
										// rel: 0,
										// modestbranding: 1
									},
								},
								vimeo: {
									playerOptions: {
										byline: true,
									},
								},
							}}
						>
						</ReactPlayer>
						<Fade in={!this.state.playing} mountOnEnter unmountOnExit>
							<div
								className="container-video-main-play"
								onClick={this.handlePlayPause}
								ref={ref => (this.mainPlay = ref)}
							>
								{!this.props.childrenOnEnd && this.props.children}
								<Button
									variant="secondary"
									className="video-main-play"
								>
									<i className="fa fa-2x fa-play" />
								</Button>
							</div>
						</Fade>
						<div className="video-control-progress" ref={ref => (this.progress = ref)}>
							<div className="video-time">
								<Fade in={(remainingDuration <= 5)} mountOnEnter unmountOnExit>
									<div className="video-redirect-message">
										Você será redirecinado em {Math.round(remainingDuration)}s
									</div>
								</Fade>
								<div className="video-timer"><span>{toTime(currentDuration)}</span> / <span>{toTime(totalDuration)}</span></div>
							</div>
							<input
								type="range" min={0} max={1} step="any"
								id="progress-range"
								value={this.state.played}
								readOnly
								onMouseDown={this.handleSeekMouseDown}
								onTouchStart={this.handleSeekMouseDown}
								onInput={this.handleSeekChange}
								onMouseUp={this.handleSeekMouseUp}
								onTouchEnd={this.handleSeekMouseUp}
							/>
							<ProgressBar variant="secondary" min={0} max={1} now={this.state.played} />
						</div>
						<ButtonGroup className="video-controls" ref={ref => (this.controls = ref)} id="video-controls">
							{speed}
							{mute}
							{backward}
							{play}
							{forward}
							{fullscreen}
							{extra}
						</ButtonGroup>
					</div>
					<ButtonGroup className="video-controls video-controls-landscape-mobile">
						{mute}
						{speed}
						{play}
						{backward}
					</ButtonGroup>
					<ButtonGroup className="video-controls video-controls-landscape-mobile">
						{extra}
						{fullscreen}
						{play}
						{forward}
					</ButtonGroup>
				</>)}
				{this.props.childrenOnEnd && this.props.children}
			</div>
		);
	}
}