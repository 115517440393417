// eslint-disable-next-line
import ReducerBasicOption from '@appit.com.br/react-utils/src/reducer/ReducerBasicOption';
import ViewedItemApi from '_api/Progress/ViewedItemApi';
import SchoolReducerOptions from '_reducer/SchoolReducerOptions';

export const ViewedItemReducerOptions = new SchoolReducerOptions('viewed_item', ViewedItemApi);

/**
 *
 * @type {{
 *     view: ReducerBasicOption
 *     step: ReducerBasicOption
 *     step_and_view: ReducerBasicOption
 *     feedback: ReducerBasicOption
 * }}
 */
export const ViewedItemReducerUtils = ViewedItemReducerOptions.options();
