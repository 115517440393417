import Course from '_entity/Course/Course';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {EmptyMessage} from 'app/components/message/messages';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import RewardSection from 'app/pages/AdminView/Content/Sections/RewardSection';
import {CourseListingPageBreadcrumb} from 'app/pages/AdminView/Course/CourseListingPage';
import BadgeSection from 'app/pages/AdminView/Course/Sections/BadgeSection';
import CourseIntegrationSection from 'app/pages/AdminView/Course/Sections/CourseIntegrationSection';
import CourseSection from 'app/pages/AdminView/Course/Sections/CourseSection';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {SchoolConfirmAlert, SchoolOperationAlert} from 'hoc/alert';
import Buttons from 'hoc/ui/Buttons';
import Tooltiper from 'hoc/ui/Tooltiper';
import React, {useEffect} from 'react';
import {Accordion, AccordionCollapse, AccordionToggle, ButtonGroup, Card} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams} from 'react-router-dom';

export const CourseShowingBreadcrumb = (course: Course) => ([
	...CourseListingPageBreadcrumb(),
	{
		path: AdminRoutes.COURSE_SHOWING.replace(':id', course.id),
		text: course.name,
	},
]);

function CourseShowingPage(props){
	const dispatch = useDispatch();
	const readState = useSelector(CourseReducerUtils.read.map);
	const {id: courseID} = useParams();
	const course: Course = readState.data;
	const remove = (moduleID) => ModuleReducerUtils.remove.dispatch(dispatch, moduleID);
	const removeState = useSelector(ModuleReducerUtils.remove.map);

	useEffect(() => {
		CourseReducerUtils.read.dispatch(dispatch, courseID);
		return () => {
			CourseReducerUtils.read.dispatch_clear(dispatch);
		};
	}, [dispatch, courseID]);

	useEffect(() => {
		SchoolOperationAlert({
			error: removeState.error,
			finished: removeState.finished,
			successAction: () => CourseReducerUtils.read.dispatch(dispatch),
		});
	}, [dispatch, removeState]);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={CourseShowingBreadcrumb(course)}
			actions={(<>
				<LinkContainer to={AdminRoutes.COURSE_INFO.replace(':id', courseID)}>
					<Button variant="secondary" className="mr-2">
						<i className="fa fa-info-circle"/>
					</Button>
				</LinkContainer>
				<LinkContainer to={AdminRoutes.COURSE_EDITING.replace(':id', courseID)}>
					{Buttons.EDIT}
				</LinkContainer>
				<Tooltiper tooltip="Editar vídeos">
					<LinkContainer to={AdminRoutes.COURSE_EDITING_VIDEOS.replace(':id', courseID)}>
						<Button variant="secondary" className="ml-2">
							<i className="fa fa-video" />
						</Button>
					</LinkContainer>
				</Tooltiper>
			</>)}
			title={`Exibindo ${course.name}`}
		>
			<CourseSection course={course} />
			<h4>
				Módulo
			</h4>
			<Table>
				<tbody>
				{course.modules.length === 0 && (<tr>
					<td><EmptyMessage message="Não há módulos disponives" /></td>
				</tr>)}
				{course.modules.map(module => (
					<tr key={module.id} className="align-middle">
						<td className="w-0">
							<LinkContainer to={AdminRoutes.MODULE_SHOWING.replace(':id', module.id)}>
								<Button variant="outline-primary">
									<i className="fa fa-eye" />
								</Button>
							</LinkContainer>
						</td>
						<td className="w-100">
							<div>
								<span className="mr-1">
									#{module.order}
								</span>
								{module.name}
							</div>
							<div className="small text-muted">
								{module.info.count_item} conteúdos/objetos de aprendizagem
							</div>
						</td>
						<td className="w-0">
							<ButtonGroup>
								<LinkContainer to={AdminRoutes.MODULE_EDITING.replace(':id', module.id)}>
									<Button variant="secondary">
										<i className="fa fa-edit" />
									</Button>
								</LinkContainer>
								<Button
									variant="danger"
									onClick={() => SchoolConfirmAlert({
										title: 'Deseja deletar esse módulo?',
										message: 'Essa ação não pode ser desfeita',
										action: () => remove(module.id),
									})}
								>
									<i className="fa fa-trash" />
								</Button>
							</ButtonGroup>
						</td>
					</tr>
				))}
				</tbody>
			</Table>
			<LinkContainer to={AdminRoutes.MODULE_CREATING.replace(':id', course.id)}>
				<Button variant="primary">
					Adicionar módulo
				</Button>
			</LinkContainer>
			<Accordion as={Card} className="mt-3" defaultActiveKey="integrations">
				<AccordionToggle as={Card.Header} className="h5 mb-0" eventKey="badges">
					Badges
				</AccordionToggle>
				<AccordionCollapse eventKey="badges">
					<Card.Body>
						<BadgeSection course={course} />
					</Card.Body>
				</AccordionCollapse>
				<AccordionToggle as={Card.Header} className="h5 mb-0" eventKey="rewards">
					Recompensas
				</AccordionToggle>
				<AccordionCollapse eventKey="rewards">
					<Card.Body>
						<RewardSection element={course} />
					</Card.Body>
				</AccordionCollapse>
				<AccordionToggle as={Card.Header} className="h5 mb-0" eventKey="integrations">
					Integrações
				</AccordionToggle>
				<AccordionCollapse eventKey="integrations">
					<Card.Body>
						<CourseIntegrationSection course={course} />
					</Card.Body>
				</AccordionCollapse>
			</Accordion>
		</AdminPage>
	);
}

export default CourseShowingPage;