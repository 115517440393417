import Content from '_entity/Course/Module/Item/Content/Content';
import Module from '_entity/Course/Module/Module';
import FormContent from '_form/Course/Module/Item/Content/FormContent';
import {ContentReducerUtils} from '_reducer/Course/Module/Content/ContentReducer';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {ModuleShowingBreadcrumb} from 'app/pages/AdminView/Module/ModuleShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {getLast} from 'hoc/utils';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const ContentCreatingBreadcrumb = (module: Module) => ([
	...ModuleShowingBreadcrumb(module),
	{
		path: AdminRoutes.CONTENT_CREATING.replace(':id', module.id),
		text: 'Editar',
	},
]);

function ContentCreatingPage(props){
	const createState = useSelector(ContentReducerUtils.create.map);
	const readState = useSelector(ModuleReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: moduleID} = useParams();
	const module: Module = readState.data;
	const create = (data) => ContentReducerUtils.create.dispatch(dispatch, moduleID, data);

	useEffect(() => {
			ModuleReducerUtils.read.dispatch(dispatch, moduleID);

			return () => {
				ContentReducerUtils.create.dispatch_clear(dispatch);
				ModuleReducerUtils.read.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={ContentCreatingBreadcrumb(module)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormContent
					onSubmit={create}
					success={createState.finished}
					error={createState.error}
					afterSuccessAlert={pushPrevious}
					content={
						new Content({
							name: '',
							src: '',
							order: getLast(module.items, {}).order + 1,
							is_external: false,
							url: '',
						})
					}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default ContentCreatingPage;