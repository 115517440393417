import Student from '_entity/Acess/Student';
import {StudentReducerUtils} from '_reducer/Student/StudentReducer';
import Profile from 'app/components/new/Page/Student/Profile';
import CoursePage from 'app/pages/CourseView/CoursePage';
import {CourseRoutes} from 'app/pages/CourseView/CourseRouterConfig';
import AccessButton from 'app/pages/CourseView/Student/Buttons/AccessButton';
import {CourseStudentBreadcrumb} from 'app/pages/CourseView/Student/CourseStudentPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const StudentShowingBreadcrumb = (student: Student) => ([
	...CourseStudentBreadcrumb(),
	{path: CourseRoutes.STUDENT_SHOWING.replace(':id', student.id), text: student.name},
]);

function StudentShowingPage(props){
	const dispatch = useDispatch();
	const {id: studentID} = useParams();
	const readState = useSelector(StudentReducerUtils.read.map);
	const student: Student = readState.data;

	const read = useCallback(() => {
		StudentReducerUtils.read.dispatch(dispatch, studentID)
	}, [dispatch, studentID]);

	useEffect(() => {
		StudentReducerUtils.read.dispatch(dispatch, studentID);
		return () => {
			StudentReducerUtils.read.dispatch_clear(dispatch);
		};

	}, [dispatch, studentID]);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<CoursePage
			breadcrumb={StudentShowingBreadcrumb(student)}
			actions={(
				<AccessButton
					student={student}
					onGrant={read}
					onRevoke={read}
				/>
			)}
		>
			<Profile
				showAccesses
				showIgnorePayment
				student={student} course={student.course}
				refreshAction={read}
			/>
		</CoursePage>
	);
}

export default StudentShowingPage;