import {useAppitUserAwareness} from '@appit.com.br/react-misc/src/auth/AppitUserAwareness';
import Student from '_entity/Acess/Student';
import Item from '_entity/Course/Module/Item/Item';
import SchoolSidebarRightWidgetBadges from 'app/components/new/Content/Widget/SchoolSidebarRightWidgetBadges';
import SchoolSidebarRightWidgetLevels from 'app/components/new/Content/Widget/SchoolSidebarRightWidgetLevels';
import SchoolSidebarRightWidgetRanking from 'app/components/new/Content/Widget/SchoolSidebarRightWidgetRanking';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import Hammer from 'hammerjs';
import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React, {useLayoutEffect} from 'react';
import {Button} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import {LinkContainer} from 'react-router-bootstrap';

SchoolSidebarRight.propTypes = {
	item: PropTypes.instanceOf(Item),
	onSelect: PropTypes.func,
};

function SchoolSidebarRight({onSelect, item,onlyProfileItems,className}){
	const user: Student = useAppitUserAwareness();

	useLayoutEffect(() => {
		new PerfectScrollbar("#sidebar-right",{
			wheelPropagation: false,
			// minScrollbarLength: 12,
			// maxScrollbarLength: 20
		})
		new PerfectScrollbar("#tab-content",{
			// wheelPropagation: false,
			// minScrollbarLength: 12,
			// maxScrollbarLength: 20
		})
		const hammer = new Hammer(document.getElementById('sidebar-right'));
		hammer.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL});
		hammer.on('pan', ({distance}) => {
			if(distance >= 30){
				document.getElementById('mobile-selector').click();
			}
		});
	}, []);

	return (
		<aside id="sidebar-right" className={className}>
			<div className="levels-standalone">
				<SchoolSidebarRightWidgetLevels
					horizontal
					student={user}
					currentItem={item}
					onSelect={onSelect}
				/>
			</div>
			{!onlyProfileItems && (<>
				<div id="widget-tabs">
					<Tab.Container defaultActiveKey="level">
						<Nav fill className="widget-selector">
							<Nav.Link eventKey="ranking">
								Ranking
							</Nav.Link>
							<Nav.Link eventKey="level">
								Level
							</Nav.Link>
						</Nav>
						<Tab.Content id="tab-content">
							<Tab.Pane eventKey="ranking">
								<SchoolSidebarRightWidgetRanking student={user} />
							</Tab.Pane>
							<Tab.Pane eventKey="level">
								<SchoolSidebarRightWidgetLevels
									student={user}
									currentItem={item}
									onSelect={onSelect}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</div>
				<SchoolSidebarRightWidgetBadges viewed_item={item.viewed} />
			</>)}
			<div className="profiler-standalone">
				<div className="profile-points profile-points-standalone">
					<LinkContainer to={StudentRoutes.PROFILE_SHOW}>
						<Button variant="light" size="lg">
							Perfil
						</Button>
					</LinkContainer>
				</div>
				<div className="profile-points">
					<div className="profile-points-value">
						{user.info.sum_points}
					</div>
					<div className="profile-points-icon" />
				</div>
			</div>
			<div className="logout-standalone">
				<Button variant="outline-light" href={`https://wa.me/${user.course.contact.whatsapp_support}`} target="_blank">
					Suporte e sugestões
				</Button>

				<LinkContainer to="/logout">
					<Button variant="outline-light">
						Sair
					</Button>
				</LinkContainer>
			</div>
		</aside>
	);
}

export default SchoolSidebarRight;
