import {ifEmpty} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import ContentRange from '_entity/Course/Module/Item/Content/ContentRange';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import RangeWidget from 'app/components/new/Content/Footer/Range/RangeElement';
import PropTypes from 'prop-types';
import React from 'react';
import {Form} from 'react-bootstrap';

export default class FormContentRange extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		validate: PropTypes.func,
		range: PropTypes.instanceOf(ContentRange),
		course: PropTypes.instanceOf(Course)
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		range: new ContentRange({
			id: null,
			name: '',
			info: null,
		}),
	};

	handleData = (data) => {
		return data;
	};

	validate = (values) => {
		const errors = {};
		for(const key of Object.keys(values)){
			const value = values[key];
			if(!value){
				errors[key] = true;
			}
		}
		if(typeof this.props.validate === 'function'){
			this.props.validate(errors, values);
		}

		return errors;
	};


	getAlertTitle(): string{
		return `${!!this.props.range.id ? "Criação" : "Edição"} concluída`;
	}

	getAlertMessage(): string{
		return 'Range salvo com sucesso';
	}

	form(){
		const range: ContentRange = this.props.range;
		const course:Course = this.props.course;
		const props = {};
		if(!!course){
			props['data-color']= course.username
		}

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				validate={this.validate}
				initialValues={{
					name: ifEmpty(range.name,""),
					info: ContentRange.rows(range.info),
				}}
				submitText="Salvar"
				asFunc
			>
				{({values, setFieldValue}) => (<>
					<SchoolFormSimpleField name="name" label="Nome" />
					<Form.Group className="text-center" {...props}>
						<RangeWidget
							range={values}
							onCellClick={(row, col) => {
								const info = ContentRange.rows(values.info);
								info[row][col] = ContentRange.index(info, row, col, 3, true);

								setFieldValue('info', info);

								this.setState({
									range: new ContentRange({
										name: '',
										info,
									}),
								});
							}}
						/>
					</Form.Group>
				</>)}
			</SchoolForm>
		);
	}
}
