import Course from '_entity/Course/Course';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import CourseInfo from 'app/components/new/Page/Course/CourseInfo';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';
import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const CourseInfoBreadcrumb = (course: Course) => ([
	...CourseShowingBreadcrumb(course),
	{
		path: AdminRoutes.COURSE_INFO.replace(':id', course.id),
		text: "Mais detalhes",
	},
]);

function CourseInfoPage(props){
	const dispatch = useDispatch();
	const readState = useSelector(CourseReducerUtils.read.map);
	const infoState = useSelector(CourseReducerUtils.info.map);
	const {id: courseID} = useParams();
	const course: Course = readState.data;

	useEffect(() => {
		CourseReducerUtils.read.dispatch(dispatch, courseID);
		CourseReducerUtils.info.dispatch(dispatch, courseID);
		return () => {
			CourseReducerUtils.read.dispatch_clear(dispatch);
			CourseReducerUtils.info.dispatch_clear(dispatch);
		};
	}, [dispatch, courseID]);

	if(!readState.finished || !infoState.finished){
		return <TemplateSplash />;
	}
	return (
		<AdminPage
			breadcrumb={CourseInfoBreadcrumb(course)}
			title={`Detalhes de ${course.name}`}
		>
			<CourseInfo data={infoState.data || {}}/>
		</AdminPage>
	);
}

export default CourseInfoPage;