import SchoolApi from '_api/SchoolApi';
import ViewedItem from '_entity/Progress/ViewedItem';

export default class ViewedItemApi extends SchoolApi {
	get entity_class(){
		return ViewedItem;
	}

	view(id, data={}){
		return this.json.post(`view/by_item/${id}`, data);
	}

	step_and_view(id, stepData,viewData){
		return this.json.post(`step_and_view/by_item/${id}`, {step:stepData,view:viewData});
	}

	feedback(id){
		return this.jsonData.get(`feedback/by_item/${id}`);
	}

	step(id,data){
		return this.jsonData.post(`step/by_item/${id}`, data);
	}
}