import Student from '_entity/Acess/Student';
import Content from '_entity/Course/Module/Item/Content/Content';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import AvatarElement from 'app/components/avatar/AvatarElement';
import {StudentRoutes} from 'app/pages/StudentView/StudentRouterConfig';
import PropTypes from 'prop-types';
import React, {useLayoutEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import {findDOMNode} from 'react-dom';
import {LinkContainer} from 'react-router-bootstrap';

SchoolSidebarRightWidgetLevels.propTypes = {
	currentItem: PropTypes.oneOfType([
		PropTypes.instanceOf(Content),
		PropTypes.instanceOf(LearningObject),
	]).isRequired,
	student: PropTypes.instanceOf(Student).isRequired,
	onSelect: PropTypes.func,
};

/**
 *
 * @param {Content|LearningObject} currentItem
 * @param {Student} student
 * @param {function} onSelect
 * @param horizontal
 * @returns {*}
 * @constructor
 */
function SchoolSidebarRightWidgetLevels({currentItem, student, onSelect, horizontal}){
	const refItems = {};
	let pointer, level;
	const currentItemID = currentItem.id

	useLayoutEffect(() => {
		const pointerNode = findDOMNode(pointer);
		const currentNode = findDOMNode(refItems[currentItemID]);
		const containerNode = findDOMNode(level);

		const pointerRect = pointerNode.getBoundingClientRect();
		const containerRect = containerNode.getBoundingClientRect();
		const currentRect = currentNode.getBoundingClientRect();
		const targetY = containerRect.bottom
			- currentRect.bottom
			- (currentRect.height - pointerRect.height) / 2
		;

		if(!horizontal){
			setTimeout(() => {
				if(targetY < 0){
					pointerNode.style.setProperty('bottom', `0px`);
				}
				else{
					pointerNode.style.setProperty('bottom', `calc(${targetY + 'px'} - 0.5em)`);
				}
				// pointerNode.style.setProperty('bottom', `${targetY + 'px'}`);
			}, 300);
		}
	}, [currentItemID, horizontal,pointer,level,refItems]);

	const items: LearningObject[] | Content[] = currentItem.module.items;
	const currentIndex = items.findIndex(i => i.id === currentItem.id);
	let pointerElement = <div
		className="level-pointer" ref={ref => (pointer = ref)}
	>
		<AvatarElement basic src={student.avatar} />
		{/*<i className="icon icon-arrow-up" />*/}
	</div>;
	if(horizontal){
		pointerElement = <div className="level-pointer-container">
			{horizontal && [...items].splice(0, currentIndex)
				.map((_, i) => <div className="level-space" key={`if-${i}`} />)}
			{pointerElement}
			{horizontal && [...items].splice(currentIndex, items.length - 1 - currentIndex)
				.map((_, i) => <div className="level-space" key={`il-${i}`} />)}
		</div>;
	}

	return (
		<>
			<div
				className="level" ref={ref => (level = ref)}
			>
				{!horizontal && pointerElement}
				<Nav
					className="level-right"
					onSelect={ID => onSelect(items.find(i => i.id === ID))}
					activeKey={currentItem.id}
				>
					{items.map((item) => (
						<Nav.Link
							ref={ref => (refItems[item.id] = ref)}
							key={`widget-level-link-${item.id}`}
							as={Button}
							className="level-item"
							eventKey={item.id}
						>
							{!horizontal && item.name}
							<span className="level-item-index">#{item.order}</span>
						</Nav.Link>
					))}
				</Nav>
				{horizontal && pointerElement}
			</div>
			{!horizontal && (
				<div className="level">
					<LinkContainer to={StudentRoutes.MODULES}>
						<Button
							block
							size="lg"
							variant="light"
							className="border-dark d-flex align-items-center justify-content-between"
						>
							<i className="fa fa-fw fa-angle-left" />
							<span className="flex-root">Módulo</span>
						</Button>
					</LinkContainer>
				</div>
			)}
		</>
	);
}

export default SchoolSidebarRightWidgetLevels;