import SchoolApi from '_api/SchoolApi';
import CourseMailing from '_entity/Course/CourseMailing';

export default class CourseMailingApi extends SchoolApi {
	get namespace(){
	    return 'course_mailings';
	}

	get entity_class(){
	    return CourseMailing;
	}

	create(courseID,data){
		return this.json.post(`by_course/${courseID}`,data)
	}
}