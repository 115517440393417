import entityResolver from '@appit.com.br/react-utils/src/entity/entityResolver';
import User from '_entity/Acess/User';
import Course from '_entity/Course/Course';


export default class CourseUser extends User {
	get course(): Course{
		return entityResolver(this.getDataValue('course'), Course);
	}
}
