import {useAppitDomain} from '@appit.com.br/react-misc/src/router/router';
import type {ReducerStateType} from '@appit.com.br/react-utils/src/reducer/ReducerBasicOptionTypes';
import {SchoolAuthReducerUtils} from '_reducer/Auth/AuthReducer';
import LoginPage from 'app/pages/AuthView/LoginPage';
import {CourseAwarenessProvider} from 'app/pages/StudentView/Access/CourseAwareness';
import StudentForgetPasswordPage from 'app/pages/StudentView/Access/StudentForgetPasswordPage';
import StudentLoginPage from 'app/pages/StudentView/Access/StudentLoginPage';
import StudentRegisterPage from 'app/pages/StudentView/Access/StudentRegisterPage';
import {SchoolFeedback} from 'hoc/alert';
import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch, useParams} from 'react-router-dom';

function CourseAccess({previousUser, courseUsername}){
	const {course_username: courseUsernameParam} = useParams();
	const logoutState: ReducerStateType = useSelector(SchoolAuthReducerUtils.logout.map);
	// const {validhost} = useAppitDomain();

	const toPath = (path) => {
		if(!!courseUsername){
			return `/${path}`;
		}
		if(!!path){
			path = `/${path}`;
		}

		return `/${courseUsernameParam}${path}`;
	};

	const onError= () => {
		SchoolFeedback("Não foi possivel comunicar com plataforma.")
		// window.location = `//${validhost}`;
	}

	if(logoutState.finished || (!logoutState.clear && !!previousUser)){
		return null;
	}

	return (
		<CourseAwarenessProvider
			onError={onError}
			course_username={courseUsername ? courseUsername : courseUsernameParam}
		>
			<Switch>
				<Route exact path={toPath('login')}>
					<StudentLoginPage registerPath={toPath('register')} forgetPath={toPath("forget-password")} />
				</Route>
				<Route exact path={toPath('register')}>
					<StudentRegisterPage loginPath={toPath('login')} />
				</Route>
				<Route exact path={toPath('forget-password')}>
					<StudentForgetPasswordPage loginPath={toPath('login')} />
				</Route>
				<Redirect to={toPath('login')} />
			</Switch>
			{/*<Redirect from={toPath()} to={toPath('login')} />*/}
		</CourseAwarenessProvider>
	);
}

function AuthView({previousUser, fromLogout}){
	const {domain, subdomain, unknown} = useAppitDomain();

	if(unknown){
		const possibleUsername = domain.split('.').splice(0, 1).join('.');
		document.documentElement.setAttribute('data-color',possibleUsername)
		return <CourseAccess
			courseUsername={possibleUsername}
			previousUser={previousUser}
		/>;
	}
	if(!!subdomain){
		return <CourseAccess courseUsername={subdomain} previousUser={previousUser} />;
	}

	return (
		<Switch>
			<Route exact path="/login" component={LoginPage} />
			{/*<Route path="/:course_username">*/}
			{/*	<CourseAccess previousUser={previousUser} />*/}
			{/*</Route>*/}
			{/*{!!previousUser && previousUser instanceof Student && <Redirect to={`/${previousUser.course.username}`} />}*/}
			{/*{!!previousUser && previousUser instanceof CourseUser && <Redirect to={`/${previousUser.username}`} />}*/}
			<Redirect to="/login" />
		</Switch>
	);
}

export default AuthView;
