import {ifNull} from '@appit.com.br/react-utils/src/utils/utils';
import Course from '_entity/Course/Course';
import FormEntity from '_form/FormEntity';
import SchoolForm from 'app/components/form/SchoolForm';
import SchoolFormSimpleField from 'app/components/form/SchoolFormField';
import SchoolFormFile from 'app/components/form/SchoolFormFile';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Form} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

class FormCourseVideos extends FormEntity {
	static propTypes = {
		...FormEntity.propTypes,
		course: PropTypes.instanceOf(Course),
	};
	static defaultProps = {
		...FormEntity.defaultProps,
		course: new Course({user: {}}),
	};

	handleData = (data) => {
		if(data.intro_external){
			delete data.intro
		}
		if(data.outro_external){
			delete data.outro;
		}

		return data;
	};

	isNew = () => !this.props.course.id;

	getAlertMessage(): string{
		return 'Curso foi salvo!';
	}

	form(){
		const course: Course = this.props.course;
		const initial = {
			intro_url: ifNull(course.intro, ''),
			outro_url: ifNull(course.outro,""),
			intro_external: ifNull(course.intro_external,false),
			outro_external: ifNull(course.outro_external,false),
		};

		return (
			<SchoolForm
				onSubmit={this.onSubmit}
				initialValues={initial}
				submitText="Salvar"
				submittingText="Salvando..."
				asFunc
			>
				{({setFieldValue,values}) => (<>
					<Form.Row>
						<Col>
							<Button
								className="form-group"
								variant="outline-primary"
								onClick={() => setFieldValue("intro_external",!values.intro_external)}
							>
								Video inicial - {values.intro_external ? 'url' : 'arquivo'}
							</Button>
							<div className={values.intro_external ? "" : "d-none"}>
								<SchoolFormSimpleField name="intro_url" label="Url" />
							</div>
							<div className={values.intro_external ? "d-none" : ""}>
								<SchoolFormFile
								fileProps={{
									onChange: ({file}) => setFieldValue("file",file),
									accept: 'video/*',
								}}
							/>
							</div>
						</Col>
						<Col>
							<Button
								className="form-group"
								variant="outline-primary"
								onClick={() => setFieldValue("outro_external",!values.outro_external)}
							>
								Video final - {values.outro_external ? 'url' : 'arquivo'}
							</Button>
							<div className={values.outro_external ? "" : "d-none"}>
								<SchoolFormSimpleField name="outro_url" label="Url" />
							</div>
							<div className={values.outro_external ? "d-none" : ""}>
								<SchoolFormFile
								fileProps={{
									onChange: ({file}) => setFieldValue("file",file),
									accept: 'video/*',
								}}
							/>
							</div>
						</Col>
					</Form.Row>
				</>)}
			</SchoolForm>
		);
	}
}

export default FormCourseVideos;
