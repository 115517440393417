import Course from '_entity/Course/Course';
import Module from '_entity/Course/Module/Module';
import FormModule from '_form/Course/Module/FormModule';
import {CourseReducerUtils} from '_reducer/Course/CourseReducer';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {NewSchoolBreadcrumbHeaderConsumer} from 'app/components/new/Header/NewSchoolBreadcrumbHeader';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {getLast} from 'hoc/utils';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

export const ModuleCreatingBreadcrumb = (course: Course) => ([
	...CourseShowingBreadcrumb(course),
	{path: AdminRoutes.MODULE_CREATING.replace(':id', course.id), text: 'Adicionar módulo'},
]);

function ModuleCreatingPage(props){
	const createState = useSelector(ModuleReducerUtils.create.map);
	const readState = useSelector(CourseReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: courseID} = useParams();
	const course: Course = readState.data;
	const create = (data) => ModuleReducerUtils.create.dispatch(dispatch,courseID,data);

	useEffect(() => {
			CourseReducerUtils.read.dispatch(dispatch, courseID);

			return () => {
				CourseReducerUtils.read.dispatch_clear(dispatch);
				ModuleReducerUtils.create.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	if(!readState.finished){
		return <TemplateSplash />;
	}

	return (
		<AdminPage
			breadcrumb={ModuleCreatingBreadcrumb(course)}
		>
			<NewSchoolBreadcrumbHeaderConsumer>
				{({pushPrevious}) => <FormModule
					onSubmit={create}
					error={createState.error}
					afterSuccessAlert={pushPrevious}
					success={createState.finished}
					module={new Module({
						name: '',
						order: getLast(course.modules, {}).order + 1,
					})}
				/>}
			</NewSchoolBreadcrumbHeaderConsumer>
		</AdminPage>
	);
}

export default ModuleCreatingPage;