import Content, {ContentUtil} from '_entity/Course/Module/Item/Content/Content';
import Item from '_entity/Course/Module/Item/Item';
import LearningObject from '_entity/Course/Module/Item/LearningObject/LearningObject';
import Module, {getModuleAvatar} from '_entity/Course/Module/Module';
import {ItemReducerUtils} from '_reducer/Course/Module/Item/ItemReducer';
import {ModuleReducerUtils} from '_reducer/Course/Module/ModuleReducer';
import {DisplayInfo} from 'app/components/display/DisplayInfo';
import {EmptyMessage} from 'app/components/message/messages';
import AdminPage from 'app/pages/AdminView/AdminPage';
import {AdminRoutes} from 'app/pages/AdminView/AdminRoutes';
import RewardSection from 'app/pages/AdminView/Content/Sections/RewardSection';
import {CourseShowingBreadcrumb} from 'app/pages/AdminView/Course/CourseShowingPage';

import {TemplateSplash} from 'app/Template/TemplateLoaderContext';
import {SchoolConfirmAlert, SchoolCourseAlert, SchoolOperationAlert} from 'hoc/alert';
import Buttons from 'hoc/ui/Buttons';
import {LoremIpsum} from 'lorem-ipsum';
import React, {useCallback, useEffect} from 'react';
import {ButtonGroup, Media} from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {useDispatch, useSelector} from 'react-redux';
import {LinkContainer} from 'react-router-bootstrap';
import {useParams} from 'react-router-dom';

export const ModuleShowingBreadcrumb = (module: Module) => ([
	...CourseShowingBreadcrumb(module.course),
	{path: AdminRoutes.MODULE_SHOWING.replace(':id', module.id), text: `#${module.order} ${module.name}`},
]);

function toLink(item: Content | LearningObject){
	if(item instanceof Content){
		return <LinkContainer to={AdminRoutes.CONTENT_SHOWING.replace(':id', item.id)}>
			<Button variant="outline-primary">
				<i className="fa fa-eye" />
			</Button>
		</LinkContainer>;
	}
	if(item instanceof LearningObject){
		return <LinkContainer to={AdminRoutes.LEARNING_OBJECT_SHOWING.replace(':id', item.id)}>
			<Button variant="outline-primary">
				<i className="fa fa-eye" />
			</Button>
		</LinkContainer>;
	}

	throw new Error();
}

function ModuleShowingPage(props){
	const readState = useSelector(ModuleReducerUtils.read.map);
	const dispatch = useDispatch();
	const {id: moduleID} = useParams();
	const module: Module = readState.data;
	const removeState = useSelector(ItemReducerUtils.remove.map);
	const read = useCallback(() => ModuleReducerUtils.read.dispatch(dispatch, moduleID), [dispatch, moduleID]);
	const remove = (id) => ItemReducerUtils.remove.dispatch(dispatch, id);

	useEffect(() => {
			ModuleReducerUtils.read.dispatch(dispatch, moduleID);

			return () => {
				ModuleReducerUtils.read.dispatch_clear(dispatch);
			};
		},
		// eslint-disable-next-line
		[dispatch],
	);

	useEffect(() => {
		SchoolOperationAlert({
			finished: removeState.finished,
			error: removeState.error,
			successAction: () => ModuleReducerUtils.read.dispatch(dispatch, moduleID),
		});
	}, [removeState, dispatch, moduleID]);

	if(!readState.finished){
		return <TemplateSplash />;
	}
	return (
		<AdminPage
			actions={(
				<LinkContainer to={AdminRoutes.MODULE_EDITING.replace(':id', moduleID)}>
					{Buttons.EDIT}
				</LinkContainer>
			)}
			breadcrumb={ModuleShowingBreadcrumb(module)}
		>
			<div className="d-flex flex-column h4">
				<div>
					<DisplayInfo text="Ordem:" value={`#${module.order}`} />
				</div>
				<div>
					<DisplayInfo text="Modulo:" value={module.name} />
				</div>
				<div>
					<DisplayInfo
						text="Feedback:"
						value={module.avatar && <Media>
							<img className="react-select__value-container" src={getModuleAvatar(module.avatar)[0]} alt="" />
							<Media.Body className="my-auto">
								{module.avatar}
								<Button
									size="sm" variant="outline-primary" className="float-right"
									onClick={() => {
										const ispum = new LoremIpsum();
										SchoolCourseAlert(module.course, module)
											.Feedback(ispum.generateSentences(1), ispum.generateSentences(1));
									}}
								>
									<i className="fa fa-eye" />
								</Button>
							</Media.Body>
						</Media>}
					/>
				</div>
			</div>
			<hr />
			<h4>
				Conteúdos e Objetos de aprendizagem
			</h4>
			<Table>
				<tbody>
				{module.items.length === 0 && <tr>
					<td><EmptyMessage message="Não há itens disponives" /></td>
				</tr>}
				{module.items.map((item: Item) => (
					<tr key={item.id} className="align-middle">
						<td className="w-0 align-middle">
							{toLink(item)}
						</td>
						<td className="w-100">
							<div className="d-flex align-items-start">
								<span className="mr-1">
									#{item.order}
								</span>
								{item.name}
							</div>
							<div className="text-muted">
								{item instanceof Content
									? (<>
										<Badge variant="primary">Conteúdo</Badge>
										{item.question !== null && (
											<Badge className="ml-1" variant="info">Possui pergunta</Badge>
										)}
										{!ContentUtil.getSrcUrl(item) && (
											<Badge className="ml-1" variant="danger">Video indisponível</Badge>
										)}
									</>)
									: <Badge variant="secondary">Objeto de aprendizagem</Badge>
								}
							</div>
							<div className="small text-muted">
								{item.info.count_viewed} Visualizações
							</div>
							{item instanceof Content && (<>
								<div className="small text-muted">
									{item.info.count_comment} Comentarios
								</div>
								<div className="small text-muted">
									{item.info.count_range} Ranges
								</div>
							</>)}
						</td>
						<td className="w-0">
							<ButtonGroup>
								<LinkContainer
									to={item instanceof Content
										? AdminRoutes.CONTENT_EDITING.replace(':id', item.id)
										: AdminRoutes.LEARNING_OBJECT_EDITING.replace(':id', item.id)}
								>
									<Button variant="secondary">
										<i className="fa fa-edit" />
									</Button>
								</LinkContainer>
								<Button
									variant="danger"
									onClick={() => SchoolConfirmAlert({
										title: `Deseja deletar esse ${item instanceof Content ? 'Conteúdo' : 'Objeto de aprendizagem'}`,
										message: 'Essa ação não pode ser desfeita',
										action: () => remove(item.id),
									})}
								>
									<i className="fa fa-trash" />
								</Button>
							</ButtonGroup>
						</td>
					</tr>
				))}
				</tbody>
			</Table>
			<hr />
			<LinkContainer to={AdminRoutes.CONTENT_CREATING.replace(':id', module.id)}>
				<Button variant="primary">
					Adicionar conteúdo
				</Button>
			</LinkContainer>
			<LinkContainer to={AdminRoutes.LEARNING_OBJECT_CREATING.replace(':id', module.id)}>
				<Button variant="primary" className="ml-2">
					Adicionar objeto de aprendizagem
				</Button>
			</LinkContainer>
			<hr />
			<RewardSection element={module} afterDelete={read}>
				<div className="small text-muted">
					As recompensas no módulo são distribuidas quando um aluno satisfaz o que foi descrito em todos os
					conteúdos/objetos de aprendizagem
				</div>
			</RewardSection>
		</AdminPage>
	);
}

export default ModuleShowingPage;